import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
import { EDIT_AFFILIATE_CLIENT_SUCCESS } from './newAffiliate';
var ns = 'likeablelocal/teamManagement';
var GET_CONTRACT = 'likeablelocal/contract/GET_CONTRACT';
var GET_CONTRACT_SUCCESS = 'likeablelocal/contract/GET_CONTRACT_SUCCESS';
var GET_CONTRACT_FAIL = 'likeablelocal/contract/GET_CONTRACT_FAIL';
var GET_ALL_CONTRACTS = 'likeablelocal/contract/GET_ALL_CONTRACTS';
var GET_ALL_CONTRACTS_SUCCESS = 'likeablelocal/contract/GET_ALL_CONTRACTS_SUCCESS';
var GET_ALL_CONTRACTS_FAIL = 'likeablelocal/contract/GET_ALL_CONTRACTS_FAIL';
var GET_CONTRACTS = 'likeablelocal/contract/GET_CONTRACTS';
var GET_CONTRACTS_SUCCESS = 'likeablelocal/contract/GET_CONTRACTS_SUCCESS';
var GET_CONTRACTS_FAIL = 'likeablelocal/contract/GET_CONTRACTS_FAIL';
var GET_CONTRACT_SIGN_TOKEN = 'likeablelocal/contract/GET_CONTRACT_SIGN_TOKEN';
var GET_CONTRACT_SIGN_TOKEN_SUCCESS = 'likeablelocal/contract/GET_CONTRACT_SIGN_TOKEN_SUCCESS';
var GET_CONTRACT_SIGN_TOKEN_FAIL = 'likeablelocal/contract/GET_CONTRACT_SIGN_TOKEN_FAIL';
var GET_EMAIL_AVAILABLE = 'likeablelocal/contract/GET_EMAIL_AVAILABLE';
var GET_EMAIL_AVAILABLE_SUCCESS = 'likeablelocal/contract/GET_EMAIL_AVAILABLE_SUCCESS';
var GET_EMAIL_AVAILABLE_FAIL = 'likeablelocal/contract/GET_EMAIL_AVAILABLE_FAIL';
var initialState = {
  saveError: null,
  allContracts: [],
  partnerAgreements: [],
  clientAgreements: [],
  gettingContract: false
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case EDIT_AFFILIATE_CLIENT_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), action.result.data.contract);
      }

    case GET_CONTRACT:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingContract: true
      });

    case GET_CONTRACT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingContract: false
      }, action.result.data);

    case GET_CONTRACT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        getContractError: action.error,
        gettingContract: false
      });

    case GET_EMAIL_AVAILABLE:
      return _objectSpread(_objectSpread({}, state), {}, {
        checkingEmailAvailability: true
      });

    case GET_EMAIL_AVAILABLE_SUCCESS:
      var bool = action.result.data.emailAvailable ? true : false;
      return _objectSpread(_objectSpread({}, state), {}, {
        emailAvailability: bool,
        checkingEmailAvailability: false
      });

    case GET_EMAIL_AVAILABLE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        emailAvailability: false,
        checkingEmailAvailability: false
      });

    case GET_ALL_CONTRACTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingContracts: true
      });

    case GET_ALL_CONTRACTS_SUCCESS:
      return function () {
        var agreementTypes = {
          partnerAgreements: [],
          clientAgreements: []
        };
        action.result.data.Contracts.forEach(function (contract) {
          console.log(contract);
          agreementTypes[contract.isPartner ? 'partnerAgreements' : 'clientAgreements'].push(contract);
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          allContracts: action.result.data.Contracts,
          isLoadingContracts: false
        }, agreementTypes);
      }();

    case GET_ALL_CONTRACTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingContracts: false
      });

    case GET_CONTRACTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingContracts: true
      });

    case GET_CONTRACTS_SUCCESS:
      var partnerAgreements1 = action.result.data.filter(function (a) {
        if (a.isPartner) {
          return true;
        }

        return false;
      });
      var clientAgreements1 = action.result.data.filter(function (a) {
        if (!a.isPartner) {
          return true;
        }

        return false;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        allContracts: action.result.data,
        clientAgreements: clientAgreements1,
        partnerAgreements: partnerAgreements1,
        isLoadingContracts: false
      });

    case GET_CONTRACTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingContracts: false
      });

    default:
      return state;
  }
}
export function getContractData(contractUuid) {
  var token = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return {
    types: [GET_CONTRACT, GET_CONTRACT_SUCCESS, GET_CONTRACT_FAIL],
    promise: function promise(client) {
      return client.get("/contract.json?uuid=".concat(contractUuid).concat(token ? "&token=".concat(token) : ''));
    }
  };
}
export function getAllContracts() {
  return {
    types: [GET_ALL_CONTRACTS, GET_ALL_CONTRACTS_SUCCESS, GET_ALL_CONTRACTS_FAIL],
    promise: function promise(client) {
      return client.post('/graphql', {
        data: {
          query: "query {\n  Contracts (\n    order: \"businessName\"\n    parentContractId: null\n  ) {\n    id\n    signedAt\n    contractStartsAt\n    email\n    uuid\n    firstName\n    lastName\n    businessName\n    phone\n    lastSentAt\n    ipAddress\n    userAgreed\n    signature\n    externallySigned\n    isPartner\n    paymentDetailEntryType\n    createdAt\n    updatedAt\n    salesUserId\n\n    PartnerContracts {\n      id\n      partnerType\n      whitelabelSubdomain\n    }\n  }\n}"
        }
      });
    }
  };
}
export function getContracts(searchOpts) {
  var endpoint = '/contracts?';

  if (searchOpts.search) {
    endpoint += "".concat(endpoint[endpoint.length - 1] === '?' ? '' : '&', "q=").concat(encodeURIComponent(searchOpts.search));
  }

  if (searchOpts.sortBy) {
    endpoint += "".concat(endpoint[endpoint.length - 1] === '?' ? '' : '&', "s=").concat(encodeURIComponent(searchOpts.sortBy));
  }

  if (searchOpts.order) {
    endpoint += "".concat(endpoint[endpoint.length - 1] === '?' ? '' : '&', "o=").concat(encodeURIComponent(searchOpts.order));
  }

  console.log(endpoint);
  return {
    types: [GET_CONTRACTS, GET_CONTRACTS_SUCCESS, GET_CONTRACTS_FAIL],
    promise: function promise(client) {
      return client.get(endpoint);
    }
  };
}
export function getContractSigningToken(contractUuid) {
  return {
    types: [GET_CONTRACT_SIGN_TOKEN, GET_CONTRACT_SIGN_TOKEN_SUCCESS, GET_CONTRACT_SIGN_TOKEN_FAIL],
    promise: function promise(client) {
      return client.get("/contracts/signingToken?contractUuid=".concat(contractUuid));
    }
  };
}
export function checkIfEmailAvailable(email) {
  return {
    types: [GET_EMAIL_AVAILABLE, GET_EMAIL_AVAILABLE_SUCCESS, GET_EMAIL_AVAILABLE_FAIL],
    promise: function promise(client) {
      return client.get("/contracts/emailAvailable/".concat(email));
    }
  };
}