import _typeof from "@babel/runtime/helpers/typeof";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as _ from "lodash";
import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from "./auth";
import { CREATE_NEW_CLIENT_SUCCESS } from "./newClient";
import { EDIT_AFFILIATE_CLIENT_SUCCESS } from "./newAffiliate";
import { CREATE_NEW_AFFILIATE_CLIENT_SUCCESS } from "./newAffiliate";
import { UPSERT_AVAILABLE_FEATURE_SUCCESS } from "./organization";
import app, { restApp, socket } from "app";
import localStorage from 'local-storage';
import cookie from "js-cookie";
var ns = "likeablelocal/teamManagement";
var SET_LL_TEAM_MEMBER_NOTIFICATION = "".concat(ns, "/SET_LL_TEAM_MEMBER_NOTIFICATION");
var SET_LL_TEAM_MEMBER_NOTIFICATION_SUCCESS = "".concat(ns, "/SET_LL_TEAM_MEMBER_NOTIFICATION_SUCCESS");
var SET_LL_TEAM_MEMBER_NOTIFICATION_FAIL = "".concat(ns, "/SET_LL_TEAM_MEMBER_NOTIFICATION_FAIL");
var GET_LL_TEAM_MEMBERS = "".concat(ns, "/GET_LL_TEAM_MEMBERS");
var GET_LL_TEAM_MEMBERS_SUCCESS = "".concat(ns, "/GET_LL_TEAM_MEMBERS_SUCCESS");
var GET_LL_TEAM_MEMBERS_FAIL = "".concat(ns, "/GET_LL_TEAM_MEMBERS_FAIL");
var GET_WHITELABEL = "".concat(ns, "/GET_WHITELABEL");
var GET_WHITELABEL_SUCCESS = "".concat(ns, "/GET_WHITELABEL_SUCCESS");
var GET_WHITELABEL_FAIL = "".concat(ns, "/GET_WHITELABEL_FAIL");
var SELECT_ITEM = "".concat(ns, "/SELECT_ITEM");
var DESELECT_ITEM = "".concat(ns, "/DESELECT_ITEM");
var TOGGLE_ALL_SELECTED = "".concat(ns, "/TOGGLE_ALL_SELECTED");
var TOGGLE_ORG_BILLING_VISIBILITY = "".concat(ns, "/TOGGLE_ORG_BILLING_VISIBILITY");
var TOGGLE_ORG_BILLING_VISIBILITY_SUCCESS = "".concat(ns, "/TOGGLE_ORG_BILLING_VISIBILITY_SUCCESS");
var TOGGLE_ORG_BILLING_VISIBILITY_FAIL = "".concat(ns, "/TOGGLE_ORG_BILLING_VISIBILITY_FAIL");
var EDIT_CLIENT = "".concat(ns, "/EDIT_CLIENT_DETAIL");
var EDIT_CLIENT_SUCCESS = "".concat(ns, "/EDIT_CLIENT_DETAIL_SUCCESS");
var EDIT_CLIENT_FAIL = "".concat(ns, "/EDIT_CLIENT_DETAIL_FAIL");
var SUBMIT_NEW_CLIENT = "".concat(ns, "/SUBMIT_NEW_CLIENT");
var SUBMIT_NEW_CLIENT_SUCCESS = "".concat(ns, "/SUBMIT_NEW_CLIENT_SUCCESS");
var SUBMIT_NEW_CLIENT_FAIL = "".concat(ns, "/SUBMIT_NEW_CLIENT_FAIL");
var GET_ALL_CLIENTS = "".concat(ns, "/GET_ALL_CLIENTS");
var GET_ALL_CLIENTS_SUCCESS = "".concat(ns, "/GET_ALL_CLIENTS_SUCCESS");
var GET_ALL_CLIENTS_FAIL = "".concat(ns, "/GET_ALL_CLIENTS_FAIL");
var GET_CLIENT = "".concat(ns, "/GET_CLIENT");
var GET_CLIENT_SUCCESS = "".concat(ns, "/GET_CLIENT_SUCCESS");
var GET_CLIENT_FAIL = "".concat(ns, "/GET_CLIENT_FAIL");
var GET_PARTNER_DETAILS = "".concat(ns, "/GET_PARTNER_DETAILS");
var GET_PARTNER_DETAILS_SUCCESS = "".concat(ns, "/GET_PARTNER_DETAILS_SUCCESS");
var GET_PARTNER_DETAILS_FAIL = "".concat(ns, "/GET_PARTNER_DETAILS_FAIL");
var ADJUST_PARTNER_DETAILS = "".concat(ns, "/ADJUST_PARTNER_DETAILS");
var ADJUST_PARTNER_DETAILS_SUCCESS = "".concat(ns, "/ADJUST_PARTNER_DETAILS_SUCCESS");
var ADJUST_PARTNER_DETAILS_FAIL = "".concat(ns, "/ADJUST_PARTNER_DETAILS_FAIL");
var SET_ORDER_COLUMN = "".concat(ns, "/SET_ORDER_COLUMN");
var SET_SEARCH_QUERY = "".concat(ns, "/SET_SEARCH_QUERY");
var SET_USER_TABLE_FILTER = "".concat(ns, "/SET_USER_TABLE_FILTER");
var LOAD_TEAM_MEMBERS = "".concat(ns, "/LOAD_TEAM_MEMBERS");
var LOAD_TEAM_MEMBERS_SUCCESS = "".concat(ns, "/LOAD_TEAM_MEMBERS_SUCCESS");
var LOAD_TEAM_MEMBERS_FAIL = "".concat(ns, "/LOAD_TEAM_MEMBERS_FAIL");
var SEND_INVITE = "".concat(ns, "/SEND_INVITE");
export var SEND_INVITE_SUCCESS = "".concat(ns, "/SEND_INVITE_SUCCESS");
var SEND_INVITE_FAIL = "".concat(ns, "/SEND_INVITE_FAIL");
var ASSIGN_TEAM_MEMBER = "".concat(ns, "/ASSIGN_TEAM_MEMBER");
var ASSIGN_TEAM_MEMBER_SUCCESS = "".concat(ns, "/ASSIGN_TEAM_MEMBER_SUCCESS");
var ASSIGN_TEAM_MEMBER_FAIL = "".concat(ns, "/ASSIGN_TEAM_MEMBER_FAIL");
var ASSIGN_TEAM_MEMBER_TO_ORG = "".concat(ns, "/ASSIGN_TEAM_MEMBER_TO_ORG");
var ASSIGN_TEAM_MEMBER_TO_ORG_SUCCESS = "".concat(ns, "/ASSIGN_TEAM_MEMBER_TO_ORG_SUCCESS");
var ASSIGN_TEAM_MEMBER_TO_ORG_FAIL = "".concat(ns, "/ASSIGN_TEAM_MEMBER_TO_ORG_FAIL");
var UNASSIGN_TEAM_MEMBER = "".concat(ns, "/UNASSIGN_TEAM_MEMBER");
var UNASSIGN_TEAM_MEMBER_SUCCESS = "".concat(ns, "/UNASSIGN_TEAM_MEMBER_SUCCESS");
var UNASSIGN_TEAM_MEMBER_FAIL = "".concat(ns, "/UNASSIGN_TEAM_MEMBER_FAIL");
var UNASSIGN_ORG_FROM_TEAM_MEMBER = "".concat(ns, "/UNASSIGN_ORG_FROM_TEAM_MEMBER");
var UNASSIGN_ORG_FROM_TEAM_MEMBER_SUCCESS = "".concat(ns, "/UNASSIGN_ORG_FROM_TEAM_MEMBER_SUCCESS");
var UNASSIGN_ORG_FROM_TEAM_MEMBER_FAIL = "".concat(ns, "/UNASSIGN_ORG_FROM_TEAM_MEMBER_FAIL");
var SET_CLIENT_FILTER = "".concat(ns, "/SET_CLIENT_FILTER");
var SORT_TEAM_MEMBERS = "".concat(ns, "/SORT_TEAM_MEMBERS");
var SET_TEAM_STATUS_SELECTION = "".concat(ns, "/SET_TEAM_STATUS_SELECTION");
var GET_TEAM_MEMBER = "".concat(ns, "/GET_TEAM_MEMBER");
var GET_TEAM_MEMBER_SUCCESS = "".concat(ns, "/GET_TEAM_MEMBER_SUCCESS");
var GET_TEAM_MEMBER_FAIL = "".concat(ns, "/GET_TEAM_MEMBER_FAIL");
var REVOKE_INVITE = "".concat(ns, "/REVOKE_INVITE");
var REVOKE_INVITE_SUCCESS = "".concat(ns, "/REVOKE_INVITE_SUCCESS");
var REVOKE_INVITE_FAIL = "".concat(ns, "/REVOKE_INVITE_FAIL");
var RESEND_INVITE = "".concat(ns, "/RESEND_INVITE");
var RESEND_INVITE_SUCCESS = "".concat(ns, "/RESEND_INVITE_SUCCESS");
var RESEND_INVITE_FAIL = "".concat(ns, "/RESEND_INVITE_FAIL");
var GET_INVITE = "".concat(ns, "/GET_INVITE");
var GET_INVITE_SUCCESS = "".concat(ns, "/GET_INVITE_SUCCESS");
var GET_INVITE_FAIL = "".concat(ns, "/GET_INVITE_FAIL");
var FINALIZE_INVITE = "".concat(ns, "/FINALIZE_INVITE");
var FINALIZE_INVITE_SUCCESS = "".concat(ns, "/FINALIZE_INVITE_SUCCESS");
var FINALIZE_INVITE_FAIL = "".concat(ns, "/FINALIZE_INVITE_FAIL");
var ACTIVATE_TEAM_MEMBER = "".concat(ns, "/ACTIVATE_TEAM_MEMBER");
export var ACTIVATE_TEAM_MEMBER_SUCCESS = "".concat(ns, "/ACTIVATE_TEAM_MEMBER_SUCCESS");
var ACTIVATE_TEAM_MEMBER_FAIL = "".concat(ns, "/ACTIVATE_TEAM_MEMBER_FAIL");
var DEACTIVATE_TEAM_MEMBER = "".concat(ns, "/DEACTIVATE_TEAM_MEMBER");
export var DEACTIVATE_TEAM_MEMBER_SUCCESS = "".concat(ns, "/DEACTIVATE_TEAM_MEMBER_SUCCESS");
var DEACTIVATE_TEAM_MEMBER_FAIL = "".concat(ns, "/DEACTIVATE_TEAM_MEMBER_FAIL");
var GET_ORG_PRODUCTS = "".concat(ns, "/GET_ORG_PRODUCTS");
var GET_ORG_PRODUCTS_SUCCESS = "".concat(ns, "/GET_ORG_PRODUCTS_SUCCESS");
var GET_ORG_PRODUCTS_FAIL = "".concat(ns, "/GET_ORG_PRODUCTS_FAIL");
var CLIENT_TYPE_TOGGLE = "".concat(ns, "/CLIENT_TYPE_TOGGLE");

var moment = require("moment");

var initialState = {
  clientType: "current",
  gettingWhitelabelInfo: false,
  whiteLabelById: {},
  adjustingPartnerDetails: false,
  allClients: [],
  isAssigning: false,
  isUnassigning: false,
  isLoadingClient: false,
  isLoadingLLTeamMembers: false,
  llTeamMembers: [],
  teamMembers: [],
  teamMemberDetail: {},
  selectedAssignedTeamMembers: [],
  selectedUnassignedTeamMembers: [],
  selectedUnassignedClients: [],
  selectedAssignedClients: [],
  selectedAssignedTeamMembersAllSelected: false,
  selectedUnassignedTeamMembersAllSelected: false,
  selectedUnassignedClientsAllSelected: false,
  selectedAssignedClientsAllSelected: false,
  teamSortColumnKey: "firstName",
  teamSortDirection: "ASC",
  clientDetail: [],
  clientDetailById: {},
  orgBillingVisible: false,
  togglingOrgBillingVisibility: false,
  partnerDetails: null,
  sortColumnName: "contract_businessName",
  sortColumnDirection: "ASC",
  teamListViewStatus: "ACTIVE",
  products: [],
  submittingNewClient: false,
  selectedClientTableColumns: [{
    key: "name",
    name: "Business Name"
  }, {
    key: ["Contracts[0].Owner.firstName", "Contracts[0].Owner.lastName"],
    name: "Owner Name"
  }, {
    key: "Type.name",
    name: "Client Type"
  }, {
    key: "SocialAccounts[0].platform",
    name: "Social Networks"
  }, {
    key: "Contracts[0].email",
    name: "Email"
  }, {
    key: "status",
    name: "Status",
    transform: function transform(str) {
      return _.upperFirst(str);
    }
  }, {
    key: "paidToDate",
    name: "Paid Through",
    defaultValue: "",
    transform: function transform(str) {
      return moment(str).format("ll");
    }
  }, {
    key: "SocialAccounts",
    name: "Facebook Ad Account",
    defaultValue: "",
    renderAs: function renderAs(_ref) {
      var SocialAccounts = _ref.SocialAccounts;
      var ad = SocialAccounts.find(function (sa) {
        return sa.FacebookAdAccount && sa.FacebookAdAccount.adAccountId;
      });
      return ad ? "Connected" : "None";
    }
  }],
  clientTableColumns: [[{
    title: "Contract ID",
    key: "contract_id"
  }, {
    title: "Business name",
    key: "organization_name"
  }, {
    title: "Owner Name",
    key: "contract_fullName"
  }, {
    title: "Client Type",
    key: "organizationType_name"
  }, {
    title: "Social Networks",
    key: "socialAccounts"
  }, {
    title: "Email",
    key: "contract_email"
  }, {
    title: "Phone Number",
    key: "contract_phone"
  }, {
    title: "Location",
    key: ["address_full"]
  }, {
    title: "Client Created Date",
    key: ["organization_createdAt"]
  }, {
    title: "Status",
    key: "organization_status"
  }, {
    title: "Paid Through",
    key: "organization_paidToDate"
  }], [{
    title: "Plan Type",
    key: "product_title"
  }, {
    title: "Payment Gateway",
    key: "paymentMethod_name"
  }], [{
    title: "# Running Ads",
    key: "fbad_count"
  }, {
    title: "Month to Date Spend",
    key: "fbad_monthSpend"
  }], [{
    title: "Queues",
    key: "queue_names"
  }, {
    title: "# Queued Items",
    key: "queue_contentCount"
  }, {
    title: "Available Content",
    key: "queue_totalAvailableContent"
  }], [{
    title: "Team Members",
    key: "teamMembers"
  }, {
    title: "Sales Rep",
    key: "salesUserName"
  }, {
    title: "Partner Name",
    key: "partnerName"
  }], [{
    title: "Facebook Ad Account",
    key: "fbad_id"
  }]]
};
export default function reducer() {
  var _objectSpread6, _state$clientDetailBy;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case SUBMIT_NEW_CLIENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        submittingNewClient: true
      });

    case SUBMIT_NEW_CLIENT_SUCCESS:
      // new client created using an existing license
      // client table gets reloaded to preserve sorting
      // but relies on props changing - so add the client here, even though it will be overwritten
      var newOrg = {
        organization_name: action.data.contract.businessName
      };
      return _objectSpread(_objectSpread({}, state), {}, {
        submittingNewClient: false,
        partnerDetails: _objectSpread(_objectSpread({}, state.partnerDetails), {}, {
          totals: _objectSpread(_objectSpread({}, state.partnerDetails.totals), {}, {
            clientsUsed: state.partnerDetails.totals.clientsUsed + 1
          })
        }),
        allClients: [].concat(_toConsumableArray(state.allClients), [newOrg])
      });

    case SUBMIT_NEW_CLIENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        submittingNewClient: false
      });

    case GET_WHITELABEL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingWhitelabelInfo: true
      });

    case GET_WHITELABEL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingWhitelabelInfo: false,
        whiteLabelById: _objectSpread(_objectSpread({}, state.whiteLabelById), {}, _defineProperty({}, action.orgId, action.result.data))
      });

    case GET_WHITELABEL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingWhitelabelInfo: false
      });

    case CREATE_NEW_AFFILIATE_CLIENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        allClients: [].concat(_toConsumableArray(state.allClients), [{
          organization_name: ""
        }])
      });

    case CREATE_NEW_CLIENT_SUCCESS:
      // triggered from action in newClient redux module
      // end_to_end and ads_only billing clients added by partners
      // client table gets reloaded to preserve sorting
      // but relies on props changing - so add the client here, even though it will be overwritten
      var newOrg2 = {
        organization_name: ""
      };
      return _objectSpread(_objectSpread({}, state), {}, {
        partnerDetails: _objectSpread(_objectSpread({}, state.partnerDetails), {}, {
          totals: _objectSpread(_objectSpread({}, state.partnerDetails.totals), {}, {
            adsOnlyChildren: action.data.orgConfig.billingMethod === "ads_only" ? state.partnerDetails.totals.adsOnlyChildren + 1 : state.partnerDetails.totals.adsOnlyChildren,
            endToEndChildren: action.data.orgConfig.billingMethod === "end_to_end" ? state.partnerDetails.totals.endToEndChildren + 1 : state.partnerDetails.totals.endToEndChildren,
            totalChildren: state.partnerDetails.totals.totalChildren + 1
          })
        }),
        allClients: [].concat(_toConsumableArray(state.allClients), [newOrg2])
      });

    case ASSIGN_TEAM_MEMBER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAssigning: true
      });

    case EDIT_AFFILIATE_CLIENT_SUCCESS:
      {
        var editAffiliateAllClients = state.allClients.map(function (client) {
          if (parseInt(client.organization_id) === parseInt(action.orgId)) {
            client.organization_name = action.result.data.organization.name;
          }

          return client;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          clientDetailById: _objectSpread(_objectSpread({}, state.clientDetailById), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state.clientDetailById["".concat(action.orgId)]), {}, {
            organization_name: action.result.data.organization.name
          }))),
          allClients: editAffiliateAllClients
        });
      }

    case ASSIGN_TEAM_MEMBER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAssigning: false,
        selectedAssignedTeamMembers: [],
        selectedUnassignedTeamMembers: [],
        selectedAssignedClients: [],
        selectedUnassignedClients: [],
        clientDetail: [_objectSpread(_objectSpread({}, state.clientDetail[0]), {}, {
          teamMembers: [].concat(_toConsumableArray(state.clientDetail && state.clientDetail[0] ? state.clientDetail[0].teamMembers : []), _toConsumableArray(action.result.data))
        })],
        clientDetailById: _objectSpread(_objectSpread({}, state.clientDetailById), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state.clientDetailById["".concat(action.orgId)]), {}, {
          teamMembers: [].concat(_toConsumableArray(state.clientDetailById["".concat(action.orgId)] && state.clientDetailById["".concat(action.orgId)].teamMembers ? state.clientDetailById["".concat(action.orgId)].teamMembers : []), _toConsumableArray(action.result.data))
        })))
      });

    case ASSIGN_TEAM_MEMBER_TO_ORG:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAssigning: true
      });

    case ASSIGN_TEAM_MEMBER_TO_ORG_SUCCESS:
      console.log("in ASSIGN_TEAM_MEMBER_TO_ORG_SUCCESS");
      console.log(action.result.data);
      return _objectSpread(_objectSpread({}, state), {}, {
        isAssigning: false,
        selectedAssignedTeamMembers: [],
        selectedUnassignedTeamMembers: [],
        selectedAssignedClients: [],
        selectedUnassignedClients: [],
        teamMemberDetail: _objectSpread(_objectSpread({}, state.teamMemberDetail), {}, {
          assignedClients: [].concat(_toConsumableArray(state.teamMemberDetail.assignedClients), _toConsumableArray(action.result.data))
        })
      });

    case ASSIGN_TEAM_MEMBER_TO_ORG_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAssigning: false
      });

    case UNASSIGN_ORG_FROM_TEAM_MEMBER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUnassigning: true
      });

    case UNASSIGN_ORG_FROM_TEAM_MEMBER_SUCCESS:
      return function () {
        return _objectSpread(_objectSpread({}, state), {}, {
          isUnassigning: false,
          selectedAssignedTeamMembers: [],
          selectedUnassignedTeamMembers: [],
          selectedAssignedClients: [],
          selectedUnassignedClients: [],
          teamMemberDetail: _objectSpread(_objectSpread({}, state.teamMemberDetail), {}, {
            assignedClients: _toConsumableArray(state.teamMemberDetail && state.teamMemberDetail.assignedClients ? state.teamMemberDetail.assignedClients.filter(function (c) {
              return action.result.data.orgId.indexOf(c.id) === -1;
            }) : [])
          })
        });
      }();

    case UNASSIGN_ORG_FROM_TEAM_MEMBER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUnassigning: false
      });

    case UNASSIGN_TEAM_MEMBER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUnassigning: true
      });

    case UNASSIGN_TEAM_MEMBER_SUCCESS:
      return function () {
        var pageType = action.data.pageType;

        if (pageType === "team_detail") {
          return _objectSpread(_objectSpread({}, state), {}, {
            isUnassigning: false,
            selectedAssignedTeamMembers: [],
            selectedUnassignedTeamMembers: [],
            selectedAssignedClients: [],
            selectedUnassignedClients: [],
            teamMemberDetail: _objectSpread(_objectSpread({}, state.teamMemberDetail), {}, {
              assignedClients: _toConsumableArray(state.teamMemberDetail && state.teamMemberDetail.assignedClients ? state.teamMemberDetail.assignedClients.filter(function (c) {
                return action.result.data.userId.indexOf(c.id) !== -1;
              }) : [])
            })
          });
        } else if (pageType === "client_detail") {
          return _objectSpread(_objectSpread({}, state), {}, {
            isUnassigning: false,
            selectedAssignedTeamMembers: [],
            selectedUnassignedTeamMembers: [],
            selectedAssignedClients: [],
            selectedUnassignedClients: [],
            clientDetail: [_objectSpread(_objectSpread({}, state.clientDetail[0]), {}, {
              teamMembers: _toConsumableArray(state.clientDetail[0] && state.clientDetail[0].teamMembers ? state.clientDetail[0].teamMembers.filter(function (t) {
                return action.result.data.userId.indexOf(t.id) === -1;
              }) : [])
            })],
            clientDetailById: _objectSpread(_objectSpread({}, state.clientDetailById), {}, _defineProperty({}, action.result.data.orgId, _objectSpread(_objectSpread({}, state.clientDetailById[action.result.data.orgId]), {}, {
              teamMembers: _toConsumableArray(state.clientDetailById[action.result.data.orgId] && state.clientDetailById[action.result.data.orgId].teamMembers ? state.clientDetailById[action.result.data.orgId].teamMembers.filter(function (t) {
                return action.result.data.userId.indexOf(t.id) === -1;
              }) : [])
            })))
          });
        }

        return _objectSpread({}, state);
      }();

    case UNASSIGN_TEAM_MEMBER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUnassigning: false
      });

    case ACTIVATE_TEAM_MEMBER_SUCCESS:
      var activateTeamMembers = state.teamMembers.map(function (tm) {
        if (tm.id === action.data.userId) {
          tm.UserOrganizations.map(function (uo) {
            uo.status = "ACTIVE";
            return uo;
          });
        }

        return tm;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        teamMembers: activateTeamMembers,
        teamMemberDetail: _objectSpread(_objectSpread({}, teamMemberDetail), {}, {
          orgStatus: "ACTIVE"
        })
      });

    case DEACTIVATE_TEAM_MEMBER_SUCCESS:
      var deactivateTeamMembers = state.teamMembers.map(function (tm) {
        if (tm.id === action.data.userId) {
          tm.UserOrganizations.map(function (uo) {
            uo.status = "INACTIVE";
            return uo;
          });
        }

        return tm;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        teamMembers: deactivateTeamMembers,
        teamMemberDetail: _objectSpread(_objectSpread({}, teamMemberDetail), {}, {
          orgStatus: "INACTIVE"
        })
      });

    case TOGGLE_ALL_SELECTED:
      var allSelectedName = "".concat(action.data.listName, "AllSelected");
      var allSelected = !state[allSelectedName];
      return _objectSpread(_objectSpread({}, state), {}, (_objectSpread6 = {}, _defineProperty(_objectSpread6, allSelectedName, allSelected), _defineProperty(_objectSpread6, action.data.listName, allSelected ? action.data.ids : []), _objectSpread6));

    case SELECT_ITEM:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.data.list, [].concat(_toConsumableArray(state[action.data.list]), [action.data.id])));

    case DESELECT_ITEM:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.data.list, state[action.data.list].filter(function (c) {
        return c !== action.data.id;
      })));

    case GET_LL_TEAM_MEMBERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingLLTeamMembers: true
      });

    case GET_LL_TEAM_MEMBERS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingLLTeamMembers: false,
        llTeamMembers: action.result
      });

    case GET_LL_TEAM_MEMBERS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingLLTeamMembers: false
      });

    case GET_TEAM_MEMBER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingTeamMember: true
      });

    case GET_TEAM_MEMBER_SUCCESS:
      var teamMemberDetail = action.result.data;
      return _objectSpread(_objectSpread({}, state), {}, {
        teamMemberDetail: teamMemberDetail,
        isLoadingTeamMember: false
      });

    case GET_TEAM_MEMBER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingTeamMember: false
      });

    case ADJUST_PARTNER_DETAILS:
      return _objectSpread(_objectSpread({}, state), {}, {
        adjustingPartnerDetails: true
      });

    case ADJUST_PARTNER_DETAILS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        adjustingPartnerDetails: false,
        partnerDetails: action.result.data
      });

    case ADJUST_PARTNER_DETAILS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        adjustingPartnerDetails: false
      });

    case GET_PARTNER_DETAILS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPartnerDetails: true
      });

    case GET_PARTNER_DETAILS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPartnerDetails: false,
        partnerDetails: action.result.data,
        partnerDetailsById: _objectSpread(_objectSpread({}, state.partnerDetailsById), {}, _defineProperty({}, action.orgId, action.result.data))
      });

    case GET_PARTNER_DETAILS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPartnerDetails: false
      });

    case GET_CLIENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingClient: true,
        loadingClientErrorMessage: null
      });

    case GET_CLIENT_SUCCESS:
      return function () {
        var _contract$ContactPref;

        var org = _objectSpread({}, action.result.data.Organization);

        var contract = org.Contracts && org.Contracts.length > 0 ? org.Contracts[0] : {
          Address: {},
          ContractProducts: [],
          Products: []
        };
        var legacyStructure = {
          organization_paidToDate: org.createdAt,
          organization_status: org.status,
          organization_createdAt: org.createdAt,
          organization_name: org.name,
          organization_organizationTypeId: org.organizationTypeId,
          organization_parentOrganizationId: org.parentOrganizationId,
          organization_id: org.id,
          organizationType_name: org.Type ? org.Type.name : null,
          organizationBillingConfig_BillingType: org.BillingConfig ? org.BillingConfig.BillingType : null,
          contract_signedAt: contract.signedAt,
          contract_uuid: contract.uuid,
          contract_paymentDetailEntryType: contract.paymentDetailEntryType,
          contract_email: contract.email,
          contract_firstName: contract.firstName,
          contract_lastName: contract.lastName,
          contract_phone: contract.phone,
          contract_id: contract.id,
          address_address1: contract.Address.address1,
          address_address2: contract.Address.address2,
          address_locality: contract.Address.locality,
          address_region: contract.Address.region,
          address_postcode: contract.Address.postcode,
          address_country: contract.Address.country,
          contract_overrides: ((_contract$ContactPref = contract.ContactPreferences) === null || _contract$ContactPref === void 0 ? void 0 : _contract$ContactPref.length) > 0 ? {
            contract_email: contract.ContactPreferences[0].email,
            contract_firstName: contract.ContactPreferences[0].firstName,
            contract_lastName: contract.ContactPreferences[0].lastName,
            contract_phone: contract.ContactPreferences[0].phone,
            address_address1: contract.ContactPreferences[0].Address.address1,
            address_address2: contract.ContactPreferences[0].Address.address2,
            address_locality: contract.ContactPreferences[0].Address.locality,
            address_region: contract.ContactPreferences[0].Address.region,
            address_postcode: contract.ContactPreferences[0].Address.postcode,
            address_country: contract.ContactPreferences[0].Address.country
          } : null,
          product_title: contract.Products && contract.Products.length > 0 ? contract.Products[0].title : null,
          product_description: contract.Products && contract.Products.length > 0 ? contract.Products[0].description : null,
          contractProduct_ProductId: contract.ContractProducts && contract.ContractProducts.length > 0 ? contract.ContractProducts[0].ProductId : null,
          contractProduct_monthlyAdsBudget: contract.ContractProducts && contract.ContractProducts.length > 0 ? contract.ContractProducts[0].monthlyAdsBudget : null,
          contractProduct_setupFee: contract.ContractProducts && contract.ContractProducts.length > 0 ? contract.ContractProducts[0].setupFee : null,
          contractProduct_monthlyFee: contract.ContractProducts && contract.ContractProducts.length > 0 ? contract.ContractProducts[0].monthlyFee : null,
          paymentMethod_name: contract.PaymentMethod ? contract.PaymentMethod.name : null,
          organizationBillingVisibility_visible: org.BillingVisibility ? org.BillingVisibility.visible : false,
          partnerSubtype: org.ParentOrganization && org.ParentOrganization.Subtype ? org.ParentOrganization.Subtype.subtype : null,
          queue_contentCount: 0,
          SocialAccounts: org.SocialAccounts || [],
          fbad_monthSpend: 0,
          queue_names: org.Queues ? org.Queues.map(function (o) {
            return {
              name: o.type,
              queueId: o.id
            };
          }) : [],
          queue_totalAvailableContent: 0,
          newMessages: 0,
          teamMembers: org.TeamUsers || [],
          gracePeriodState: org.gracePeriodState,
          partnerAdsCommissionPercent: contract.ContractProducts && contract.ContractProducts.length > 0 ? contract.ContractProducts[0].adsCommissionPercent : null,
          likeableAdsCommissionPercent: contract.ParentContract && contract.ParentContract.PartnerContracts && contract.ParentContract.PartnerContracts.length > 0 ? contract.ParentContract.PartnerContracts[0].adsFeePercent : "25",
          availableFeatures: org.AvailableFeatures
        };
        return _objectSpread(_objectSpread({}, state), {}, {
          clientDetail: [legacyStructure],
          loadingClientErrorMessage: null,
          clientDetailById: _objectSpread(_objectSpread({}, state.clientDetailById), {}, _defineProperty({}, action.orgId, _objectSpread({}, legacyStructure))),
          orgBillingVisible: legacyStructure.organizationBillingVisibility_visible,
          isLoadingClient: false
        });
      }();

    case GET_CLIENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingClient: false,
        loadingClientErrorMessage: function () {
          try {
            return action.error.error.message;
          } catch (e) {
            return "Unknown error occurred";
          }
        }()
      });

    case EDIT_CLIENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        editingClient: true
      });

    case EDIT_CLIENT_SUCCESS:
      var clientDetail = action.result.data;
      return _objectSpread(_objectSpread({}, state), {}, {
        editingClient: false
      });

    case EDIT_CLIENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        editingClient: false
      });

    case GET_ALL_CLIENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingClients: true
      });

    case GET_ALL_CLIENTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        allClients: action.result.data.Organizations,
        isLoadingClients: false
      });

    case GET_ALL_CLIENTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingClients: false
      });

    case SEND_INVITE:
      return _objectSpread({}, state);

    case SEND_INVITE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        teamMembers: [].concat(_toConsumableArray(state.teamMembers), [action.result.data]),
        partnerDetails: _objectSpread(_objectSpread({}, state.partnerDetails), {}, {
          totals: _objectSpread(_objectSpread({}, state.partnerDetails.totals), {}, {
            teamMembersUsed: parseInt(state.partnerDetails.totals.teamMembersUsed) + 1,
            teamMembersRemaining: parseInt(state.partnerDetails.totals.teamMembersRemaining) - 1
          })
        })
      });

    case SEND_INVITE_FAIL:
      return _objectSpread({}, state);

    case SET_ORDER_COLUMN:
      return _objectSpread(_objectSpread({}, state), {}, {
        sortColumnName: action.columnName,
        sortColumnDirection: action.direction
      });

    case SET_SEARCH_QUERY:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchQuery: action.query
      });

    case LOAD_TEAM_MEMBERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingTeamMembers: true
      });

    case LOAD_TEAM_MEMBERS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingTeamMembers: false,
        teamMembers: action.result.data.map(function (m) {
          return _objectSpread(_objectSpread({}, m), {}, {
            name: "".concat(m.firstName, " ").concat(m.lastName)
          });
        }).sort(function (a, b) {
          var aKey = a[state.teamSortColumnKey];
          var bKey = b[state.teamSortColumnKey];

          if (typeof a === "string") {
            aKey = aKey.toLowerCase();
          }

          if (typeof b === "string") {
            bKey = bKey.toLowerCase();
          }

          if (state.teamSortDirection === "ASC") {
            if (aKey < bKey) {
              return -1;
            } else if (bKey < aKey) {
              return 1;
            }

            return 0;
          }

          if (aKey < bKey) {
            return 1;
          } else if (bKey < aKey) {
            return -1;
          }

          return 0;
        })
      });

    case LOAD_TEAM_MEMBERS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoadingTeamMembers: false
      });

    case SET_USER_TABLE_FILTER:
      return _objectSpread(_objectSpread({}, state), {}, {
        filterUserId: action.userId
      });

    case SET_CLIENT_FILTER:
      var _selected = _toConsumableArray(state.selectedClientTableColumns);

      var fn = function fn(f) {
        if (_selected.indexOf(f) !== -1) {
          if (!action.state) {
            _selected.splice(_selected.indexOf(f), 1);
          }
        } else if (action.state) {
          _selected.push(f);
        }
      };

      if (_typeof(action.filter) === "object") {
        action.filter.forEach(fn);
      } else {
        fn(action.filter);
      }

      console.dir(_selected);
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedClientTableColumns: _toConsumableArray(_selected)
      });

    case SORT_TEAM_MEMBERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        teamSortColumnKey: action.columnKey,
        teamSortDirection: action.direction,
        teamMembers: _toConsumableArray(state.teamMembers).sort(function (a, b) {
          var aKey = a[action.columnKey];
          var bKey = b[action.columnKey];

          if (typeof a === "string" && a) {
            aKey = aKey.toLowerCase();
          }

          if (typeof b === "string" && a) {
            bKey = bKey.toLowerCase();
          }

          if (action.direction === "ASC") {
            if (aKey < bKey) {
              return -1;
            } else if (bKey < aKey) {
              return 1;
            }

            return 0;
          }

          if (aKey < bKey) {
            return 1;
          } else if (bKey < aKey) {
            return -1;
          }

          return 0;
        })
      });

    case SET_TEAM_STATUS_SELECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        teamListViewStatus: action.status
      });

    case RESEND_INVITE:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendingInvite: true
      });

    case RESEND_INVITE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendingInvite: false
      });

    case RESEND_INVITE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendingInvite: false
      });

    case GET_INVITE_SUCCESS:
      return function () {
        var options = {
          expires: 1.0 / 24.0
        };
        var loc = cookieLocation();
        cookie.set("inviteToken", action.result.data[0], _objectSpread(_objectSpread({}, options), loc));
        localStorage.set("inviteToken", action.result.data[0], _objectSpread(_objectSpread({}, options), loc));
        return _objectSpread(_objectSpread({}, state), {}, {
          invite: action.result.data[1]
        });
      }();

    case GET_ORG_PRODUCTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingOrgProducts: true
      });

    case GET_ORG_PRODUCTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingOrgProducts: false,
        products: action.result.data
      });

    case GET_ORG_PRODUCTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingOrgProducts: false
      });

    case TOGGLE_ORG_BILLING_VISIBILITY:
      return _objectSpread(_objectSpread({}, state), {}, {
        togglingOrgBillingVisibility: true
      });

    case TOGGLE_ORG_BILLING_VISIBILITY_SUCCESS:
      console.log("in TOGGLE_ORG_BILLING_VISIBILITY_SUCCESS");
      console.log(action.result.data);
      return _objectSpread(_objectSpread({}, state), {}, {
        orgBillingVisible: action.result.data.visible,
        togglingOrgBillingVisibility: false
      });

    case TOGGLE_ORG_BILLING_VISIBILITY_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        togglingOrgBillingVisibility: false
      });

    case CLIENT_TYPE_TOGGLE:
      return _objectSpread(_objectSpread({}, state), {}, {
        clientType: action.clientType
      });

    case UPSERT_AVAILABLE_FEATURE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        clientDetailById: _objectSpread(_objectSpread({}, state.clientDetailById), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, ((_state$clientDetailBy = state.clientDetailById) === null || _state$clientDetailBy === void 0 ? void 0 : _state$clientDetailBy["".concat(action.orgId)]) || {}), {}, {
          availableFeatures: action.result.data
        })))
      });

    default:
      return state;
  }
}

function saveAuth(response) {
  var _response$data = response.data,
      accessToken = _response$data.accessToken,
      user = _response$data.user;
  var storage = app.get("storage");

  if (accessToken) {
    storage.setItem("feathers-jwt", accessToken);
    storage.setItem("accessToken", accessToken);
    app.set("accessToken", accessToken);
    app.set("user", user);
    restApp.set("accessToken", accessToken);
    restApp.set("user", user);
  } else {
    storage.removeItem("feathers-jwt");
    storage.removeItem("accessToken");
  }

  var loc = cookieLocation();
  cookie.set("feathers-session", accessToken, _objectSpread({}, loc));
  cookie.set("accessToken", accessToken, _objectSpread({}, loc));
  return response;
}

export function editClientDetail(payload, contractId) {
  var endpoint = "/contract/".concat(contractId, "/contactPreferences");
  return {
    types: [EDIT_CLIENT, EDIT_CLIENT_SUCCESS, EDIT_CLIENT_FAIL],
    promise: function promise(client) {
      return client.put(endpoint, {
        data: payload
      });
    }
  };
}
export function getClient(orgId) {
  return {
    types: [GET_CLIENT, GET_CLIENT_SUCCESS, GET_CLIENT_FAIL],
    promise: function promise(client) {
      return client.post("/graphql", {
        data: {
          variables: {
            clientId: parseInt(orgId)
          },
          query: "query loadClient($clientId: Int!) {\n  Organization(id: $clientId) {\n    paidToDate\n    gracePeriodState\n    status\n    createdAt\n    name\n    organizationTypeId\n    parentOrganizationId\n    id\n    Type {\n      name\n    }\n    BillingConfig {\n      BillingType\n    }\n    ParentOrganization {\n      Subtype {\n        subtype\n      }\n    }\n    Contracts {\n      ParentContract {\n        id\n        PartnerContracts {\n          adsFeePercent\n        }\n      }\n      signedAt\n      uuid\n      paymentDetailEntryType\n      email\n      firstName\n      lastName\n      phone\n      id\n      Address {\n        address1\n        address2\n        locality\n        region\n        postcode\n        country\n      }\n      ContractProducts {\n        ProductId\n        monthlyAdsBudget\n        setupFee\n        monthlyFee\n        adsCommissionPercent\n      }\n      Products {\n        title\n        description\n        id\n      }\n      PaymentMethod {\n        name\n      }\n      ContactPreferences {\n        email\n        phone\n        firstName\n        lastName\n        businessName\n        Address {\n          address1\n        \taddress2\n        \tlocality\n        \tregion\n        \tpostcode\n        \tcountry\n        }\n      }\n    }\n    BillingVisibility {\n      visible\n    }\n    Queues {\n      id\n      type\n    }\n    TeamUsers {\n      id\n      firstName\n      lastName\n      nickname\n      nameSuffix\n      email\n      avatarUrl\n    }\n    SocialAccounts {\n      id\n      ownerUserId\n      platform\n      platformId\n      username\n      link\n      avatarImageUrl\n      valid\n      tokenValidUntil\n      FacebookAdAccount {\n        facebookPageId\n        adAccountId\n      }\n    }\n    AvailableFeatures {\n      id\n      featureType\n      state\n      cascade\n    }\n  }\n}"
        }
      });
    },
    orgId: orgId
  };
}
export function getPartnerDetails(orgId) {
  //  const endpoint = `/teamManagement/partner/${orgId}?fields=${fields}`;
  var endpoint = "/agreement/org/".concat(orgId, "/contractInfo");
  return {
    types: [GET_PARTNER_DETAILS, GET_PARTNER_DETAILS_SUCCESS, GET_PARTNER_DETAILS_FAIL],
    promise: function promise(client) {
      return client.get(endpoint);
    },
    orgId: orgId
  };
}
export function getPartnerDetailsByPartnerOrgId(orgId) {
  //  const endpoint = `/teamManagement/partner/${orgId}?fields=${fields}`;
  var endpoint = "/agreement/org/".concat(orgId, "/partnerContractInfo"); // NOTE THAT the constants below are same as above getPartnerDetails method

  return {
    types: [GET_PARTNER_DETAILS, GET_PARTNER_DETAILS_SUCCESS, GET_PARTNER_DETAILS_FAIL],
    promise: function promise(client) {
      return client.get(endpoint);
    },
    orgId: orgId
  };
}
export function getAllClients() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref2$orgState = _ref2.orgState,
      orgState = _ref2$orgState === void 0 ? "active" : _ref2$orgState,
      _ref2$orgTypeId = _ref2.orgTypeId,
      orgTypeId = _ref2$orgTypeId === void 0 ? 3 : _ref2$orgTypeId,
      _ref2$parentOrganizat = _ref2.parentOrganizationId,
      parentOrganizationId = _ref2$parentOrganizat === void 0 ? 0 : _ref2$parentOrganizat,
      _ref2$search = _ref2.search,
      search = _ref2$search === void 0 ? null : _ref2$search;

  return {
    types: [GET_ALL_CLIENTS, GET_ALL_CLIENTS_SUCCESS, GET_ALL_CLIENTS_FAIL],
    promise: function promise(client) {
      return client.post("/graphql", {
        data: {
          variables: _objectSpread(_objectSpread(_objectSpread({}, search ? {
            search: "".concat(search)
          } : {}), orgTypeId ? {
            type: parseInt(orgTypeId)
          } : {}), {}, {
            parentOrg: "".concat(parentOrganizationId),
            status: "".concat(orgState)
          }),
          query: "query getClientOrgs(\n  $search: String,\n  $status: String,\n  $type: Int,\n  $parentOrg: String\n) {\n  Organizations(\n    order: \"name\",\n    status: $status,\n  \tsearch: $search,\n    type: $type,\n    parentOrganizationId: $parentOrg\n  ) {\n    id\n    name\n    paidToDate\n    status\n    createdAt\n    Queues {\n      Categories {\n        tagName\n      }\n    }\n    ParentOrganization {\n      id\n      name\n    }\n    BillingAccounts {\n      PaymentMethod {\n        name\n      }\n    }\n    Type {\n      name\n    }\n    SocialAccounts {\n      id\n      platform\n      platformId\n      username\n      FacebookAdAccount {\n        adAccountId\n      }\n    }\n    Contracts {\n      id\n      uuid\n      businessName\n      email\n      phone\n      Address {\n        address1\n        address2\n        address3\n        address4\n        locality\n        region\n        postcode\n        country\n      }\n      Owner {\n        id\n        firstName\n        lastName\n      }\n      SalesUser {\n        id\n        firstName\n        lastName\n      }\n      Products {\n        title\n        retailMonthlyPrice\n        adsBudget\n      }\n    }\n  }\n}"
        }
      });
    }
  };
}
export function getTeamMembers(scopeOrgId) {
  return {
    types: [LOAD_TEAM_MEMBERS, LOAD_TEAM_MEMBERS_SUCCESS, LOAD_TEAM_MEMBERS_FAIL],
    promise: function promise(client) {
      return client.get("/teamManagement/teamMembers".concat(scopeOrgId ? "/org/".concat(scopeOrgId) : ""));
    }
  };
}
export function sendInvite(orgId, email, firstName, lastName) {
  return {
    types: [SEND_INVITE, SEND_INVITE_SUCCESS, SEND_INVITE_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/user/invite"), {
        data: {
          email: email,
          firstName: firstName,
          lastName: lastName
        }
      });
    }
  };
}
export function setOrderColumn(columnName, direction) {
  return {
    type: SET_ORDER_COLUMN,
    columnName: columnName,
    direction: direction
  };
}
export function toggleClientType(clientType) {
  return {
    type: CLIENT_TYPE_TOGGLE,
    clientType: clientType
  };
}
export function setSearchQuery(query) {
  return {
    type: SET_SEARCH_QUERY,
    query: query
  };
}
export function setUserTableFilter(userId) {
  return {
    type: SET_USER_TABLE_FILTER,
    userId: userId
  };
}
export function setClientFilter(filter, state) {
  console.log("setClientFilter");
  return {
    type: SET_CLIENT_FILTER,
    filter: _typeof(filter) !== "object" ? [filter] : filter,
    state: state
  };
}
export function sortTeamMembers(columnKey, direction) {
  return {
    type: SORT_TEAM_MEMBERS,
    columnKey: columnKey,
    direction: direction
  };
}
export function setTeamStatusSelection(status) {
  return {
    type: SET_TEAM_STATUS_SELECTION,
    status: status
  };
}
export function assignTeamMemberToOrganization(teamMemberUserId, organizationId) {
  return {
    types: [ASSIGN_TEAM_MEMBER, ASSIGN_TEAM_MEMBER_SUCCESS, ASSIGN_TEAM_MEMBER_FAIL],
    orgId: organizationId,
    promise: function promise(client) {
      return client.post("/org/".concat(organizationId, "/teamMember/assign"), {
        data: {
          userId: teamMemberUserId
        }
      });
    }
  };
}
export function assignOrganizationToTeamMember(organizationId, teamMemberUserId) {
  if (!(organizationId instanceof Array)) {
    organizationId = [organizationId];
  }

  return {
    types: [ASSIGN_TEAM_MEMBER_TO_ORG, ASSIGN_TEAM_MEMBER_TO_ORG_SUCCESS, ASSIGN_TEAM_MEMBER_TO_ORG_FAIL],
    promise: function promise(client) {
      return client.post("/user/".concat(teamMemberUserId, "/teamMember/assign"), {
        data: {
          orgId: organizationId
        }
      });
    }
  };
}
export function unassignOrganizationFromTeamMember(organizationId, teamMemberUserId) {
  if (!(organizationId instanceof Array)) {
    organizationId = [organizationId];
  }

  return {
    types: [UNASSIGN_ORG_FROM_TEAM_MEMBER, UNASSIGN_ORG_FROM_TEAM_MEMBER_SUCCESS, UNASSIGN_ORG_FROM_TEAM_MEMBER_FAIL],
    promise: function promise(client) {
      return client.put("/user/".concat(teamMemberUserId, "/teamMember/unassign"), {
        data: {
          orgId: organizationId
        }
      });
    }
  };
}
export function unassignTeamMemberFromOrganization(teamMemberUserId, organizationId, pageType) {
  if (!(teamMemberUserId instanceof Array)) {
    teamMemberUserId = [teamMemberUserId];
  }

  return {
    types: [UNASSIGN_TEAM_MEMBER, UNASSIGN_TEAM_MEMBER_SUCCESS, UNASSIGN_TEAM_MEMBER_FAIL],
    data: {
      pageType: pageType
    },
    promise: function promise(client) {
      return client.put("/org/".concat(organizationId, "/teamMember/unassign"), {
        data: {
          orgId: organizationId,
          userId: teamMemberUserId
        }
      });
    }
  };
}
export function getTeamMemberDetails(teamMemberUserId) {
  return {
    types: [GET_TEAM_MEMBER, GET_TEAM_MEMBER_SUCCESS, GET_TEAM_MEMBER_FAIL],
    promise: function promise(client) {
      return client.get("/teamManagement/teamMember/".concat(teamMemberUserId), {
        data: {}
      });
    }
  };
}
export function revokeUserInvite(invitedUserId, organizationId) {
  return {
    types: [REVOKE_INVITE, REVOKE_INVITE_SUCCESS, REVOKE_INVITE_FAIL],
    promise: function promise(client) {
      return client.del("/org/".concat(organizationId, "/user/invite/").concat(invitedUserId), {
        data: {}
      });
    }
  };
}
export function toggleAllSelected(ids, listName) {
  return {
    type: TOGGLE_ALL_SELECTED,
    data: {
      ids: ids,
      listName: listName
    }
  };
}
export function selectItem(itemId, list) {
  return {
    type: SELECT_ITEM,
    data: {
      id: itemId,
      list: list
    }
  };
}
export function deselectItem(itemId, list) {
  return {
    type: DESELECT_ITEM,
    data: {
      id: itemId,
      list: list
    }
  };
}
export function getInvite(uuid) {
  return {
    types: [GET_INVITE, GET_INVITE_SUCCESS, GET_INVITE_FAIL],
    promise: function promise(client) {
      return client.get("/teamManagement/invite?uuid=".concat(uuid));
    }
  };
}
export function finalizeTeamMemberCreation(payload, orgId) {
  return {
    types: [FINALIZE_INVITE, FINALIZE_INVITE_SUCCESS, FINALIZE_INVITE_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/finalizeUser"), {
        data: _objectSpread({}, payload)
      }).then(saveAuth);
    }
  };
}
export function resendInvite(orgId, userId) {
  return {
    types: [RESEND_INVITE, RESEND_INVITE_SUCCESS, RESEND_INVITE_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/user/invite/").concat(userId, "/resend"));
    }
  };
}

function cookieLocation() {
  var currentDomain = window.location.host;
  var domain = currentDomain;
  /*
  * Uncomment this to allow for *.current-domain.com cookies.
  if (subdomainFromUrl(currentDomain)) {
    domain = domain.replace(`${subdomainFromUrl(currentDomain)}.`, '');
  }
  */

  if (domain.indexOf(":") !== -1) {
    domain = domain.split(":")[0];
  }

  return {
    domain: domain,
    path: "/"
  };
}

export function activateTeamMember(userId, orgId) {
  return {
    types: [ACTIVATE_TEAM_MEMBER, ACTIVATE_TEAM_MEMBER_SUCCESS, ACTIVATE_TEAM_MEMBER_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/user/").concat(userId, "/activate"));
    },
    data: {
      userId: userId
    }
  };
}
export function deactivateTeamMember(userId, orgId) {
  return {
    types: [DEACTIVATE_TEAM_MEMBER, DEACTIVATE_TEAM_MEMBER_SUCCESS, DEACTIVATE_TEAM_MEMBER_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/user/").concat(userId, "/deactivate"));
    },
    data: {
      userId: userId
    }
  };
}
export function toggleOrgBillingVisibility(orgId, state) {
  return {
    types: [TOGGLE_ORG_BILLING_VISIBILITY, TOGGLE_ORG_BILLING_VISIBILITY_SUCCESS, TOGGLE_ORG_BILLING_VISIBILITY_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/billingVisibility"), {
        data: {
          state: state
        }
      });
    }
  };
}
export function getOrgProducts(orgId) {
  return {
    types: [GET_ORG_PRODUCTS, GET_ORG_PRODUCTS_SUCCESS, GET_ORG_PRODUCTS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/products"));
    }
  };
}
export function submitNewClientForm(orgId, data) {
  return {
    types: [SUBMIT_NEW_CLIENT, SUBMIT_NEW_CLIENT_SUCCESS, SUBMIT_NEW_CLIENT_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/new/org"), {
        data: data
      });
    },
    data: data
  };
}
export function adjustPartnerDetails(orgId, data) {
  return {
    types: [ADJUST_PARTNER_DETAILS, ADJUST_PARTNER_DETAILS_SUCCESS, ADJUST_PARTNER_DETAILS_FAIL],
    promise: function promise(client) {
      return client.post("/agreement/org/".concat(orgId, "/newRevision"), {
        data: data
      });
    }
  };
}
export function getWhitelabelInfo(orgId) {
  return {
    types: [GET_WHITELABEL, GET_WHITELABEL_SUCCESS, GET_WHITELABEL_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/whiteLabel"));
    },
    orgId: orgId
  };
}
export function getLikeableLocalTeamMembers() {
  return {
    types: [GET_LL_TEAM_MEMBERS, GET_LL_TEAM_MEMBERS_SUCCESS, GET_LL_TEAM_MEMBERS_FAIL],
    promise: function promise(client) {
      return client.get("/teamManagement/llTeamMembers");
    }
  };
}
export function setLLTeamMemberNotification(orgId, reminderType, enabled, userId) {
  return {
    types: [SET_LL_TEAM_MEMBER_NOTIFICATION, SET_LL_TEAM_MEMBER_NOTIFICATION_SUCCESS, SET_LL_TEAM_MEMBER_NOTIFICATION_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/notification/").concat(reminderType), {
        data: {
          enabled: enabled,
          userId: userId
        }
      });
    },
    orgId: orgId,
    userId: userId,
    enabled: enabled
  };
}