export var RESET_AD_BUILDER_STATE = 'likeablelocal/adBuilder/RESET_AD_BUILDER_STATE';
export var UPDATE_PREVIEW_STEP = 'likeablelocal/adBuilder/UPDATE_PREVIEW_STEP';
export var CLEAR_AUDIENCE = 'likeablelocal/adBuilder/CLEAR_AUDIENCE';
export var GET_AD_BUILDER_FORMAT = 'likeablelocal/GET_AD_BUILDER_FORMAT';
export var GET_AD_BUILDER_FORMAT_SUCCESS = 'likeablelocal/GET_AD_BUILDER_FORMAT_SUCCESS';
export var GET_AD_BUILDER_FORMAT_FAIL = 'likeablelocal/GET_AD_BUILDER_FORMAT_FAIL';
export var TARGETING_BROWSE = 'likeablelocal/TARGETING_BROWSE';
export var TARGETING_BROWSE_SUCCESS = 'likeablelocal/TARGETING_BROWSE_SUCCESS';
export var TARGETING_BROWSE_FAIL = 'likeablelocal/TARGETING_BROWSE_FAIL';
export var UPLOAD_IMAGE = 'likeablelocal/adBuilder/UPLOAD_IMAGE';
export var UPLOAD_IMAGE_SUCCESS = 'likeablelocal/adBuilder/UPLOAD_IMAGE_SUCCESS';
export var UPLOAD_IMAGE_FAIL = 'likeablelocal/adBuilder/UPLOAD_IMAGE_FAIL';
export var UPLOAD_FORM_IMAGE = 'likeablelocal/adBuilder/UPLOAD_FORM_IMAGE';
export var UPLOAD_FORM_IMAGE_SUCCESS = 'likeablelocal/adBuilder/UPLOAD_FORM_IMAGE_SUCCESS';
export var UPLOAD_FORM_IMAGE_FAIL = 'likeablelocal/adBuilder/UPLOAD_FORM_IMAGE_FAIL';
export var UPLOAD_BASE64_IMAGE = 'likeablelocal/adBuilder/UPLOAD_BASE64_IMAGE';
export var UPLOAD_BASE64_IMAGE_SUCCESS = 'likeablelocal/adBuilder/UPLOAD_BASE64_IMAGE_SUCCESS';
export var UPLOAD_BASE64_IMAGE_FAIL = 'likeablelocal/adBuilder/UPLOAD_BASE64_IMAGE_FAIL';
export var UPLOAD_CAROUSEL_ITEM_SUCCESS = 'likeablelocal/adBuilder/UPLOAD_CAROUSEL_ITEM_SUCCESS';
export var UPLOAD_CAROUSEL_ITEM_FAIL = 'likeablelocal/adBuilder/UPLOAD_CAROUSEL_ITEM_FAIL';
export var REMOVE_IMAGE_AD_IMAGE = 'likeablelocal/adBuilder/REMOVE_IMAGE_AD_IMAGE';
export var REMOVE_CAROUSEL_AD_ITEM = 'likeablelocal/adBuilder/REMOVE_CAROUSEL_AD_ITEM';
export var REMOVE_VIDEO_AD_VIDEO = 'likeablelocal/adBuilder/REMOVE_VIDEO_AD_VIDEO';
export var SUBMIT_AD = 'likeablelocal/adBuilder/SUBMIT_POST';
export var SUBMIT_AD_SUCCESS = 'likeablelocal/adBuilder/SUBMIT_POST_SUCCESS';
export var SUBMIT_AD_FAIL = 'likeablelocal/adBuilder/SUBMIT_POST_FAIL';
export var UPLOAD_VIDEO = 'likeablelocal/adBuilder/UPLOAD_VIDEO';
export var UPLOAD_VIDEO_SUCCESS = 'likeablelocal/adBuilder/UPLOAD_VIDEO_SUCCESS';
export var UPLOAD_VIDEO_FAIL = 'likeablelocal/adBuilder/UPLOAD_VIDEO_FAIL';
export var UPDATE_REACH_ESTIMATE = 'likeablelocal/adBuilder/UPDATE_REACH_ESTIMATE';
export var UPDATE_REACH_ESTIMATE_SUCCESS = 'likeablelocal/adBuilder/UPDATE_REACH_ESTIMATE_SUCCESS';
export var UPDATE_REACH_ESTIMATE_FAIL = 'likeablelocal/adBuilder/UPDATE_REACH_ESTIMATE_FAIL';
export var QUERY_INTERESTS = 'likeablelocal/adBuilder/QUERY_INTERESTS';
export var QUERY_INTERESTS_SUCCESS = 'likeablelocal/adBuilder/QUERY_INTERESTS_SUCCESS';
export var QUERY_INTERESTS_FAIL = 'likeablelocal/adBuilder/QUERY_INTERESTS_FAIL';
export var QUERY_JOB_TITLES = 'likeablelocal/adBuilder/QUERY_JOB_TITLES';
export var QUERY_JOB_TITLES_SUCCESS = 'likeablelocal/adBuilder/QUERY_JOB_TITLES_SUCCESS';
export var QUERY_JOB_TITLES_FAIL = 'likeablelocal/adBuilder/QUERY_JOB_TITLES_FAIL';
export var QUERY_MAJOR = 'likeablelocal/adBuilder/QUERY_MAJOR';
export var QUERY_MAJOR_SUCCESS = 'likeablelocal/adBuilder/QUERY_MAJOR_SUCCESS';
export var QUERY_MAJOR_FAIL = 'likeablelocal/adBuilder/QUERY_MAJOR_FAIL';
export var QUERY_EMPLOYER = 'likeablelocal/adBuilder/QUERY_EMPLOYER';
export var QUERY_EMPLOYER_SUCCESS = 'likeablelocal/adBuilder/QUERY_EMPLOYER_SUCCESS';
export var QUERY_EMPLOYER_FAIL = 'likeablelocal/adBuilder/QUERY_EMPLOYER_FAIL';
export var QUERY_SCHOOL = 'likeablelocal/adBuilder/QUERY_SCHOOL';
export var QUERY_SCHOOL_SUCCESS = 'likeablelocal/adBuilder/QUERY_SCHOOL_SUCCESS';
export var QUERY_SCHOOL_FAIL = 'likeablelocal/adBuilder/QUERY_SCHOOL_FAIL';
export var QUERY_LOCATIONS = 'likeablelocal/adBuilder/QUERY_LOCATIONS';
export var QUERY_LOCATIONS_SUCCESS = 'likeablelocal/adBuilder/QUERY_LOCATIONS_SUCCESS';
export var QUERY_LOCATIONS_FAIL = 'likeablelocal/adBuilder/QUERY_LOCATIONS_FAIL';
export var GET_CUSTOM_AUDIENCES = 'likeablelocal/adBuilder/GET_CUSTOM_AUDIENCES';
export var GET_CUSTOM_AUDIENCES_SUCCESS = 'likeablelocal/adBuilder/GET_CUSTOM_AUDIENCES_SUCCESS';
export var GET_CUSTOM_AUDIENCES_FAIL = 'likeablelocal/adBUilder/GET_CUSTOM_AUDIENCES_FAIL';
export var GET_CUSTOM_POST_PREVIEW = 'likeablelocal/GET_CUSTOM_POST_PREVIEW';
export var GET_CUSTOM_POST_PREVIEW_SUCCESS = 'likeablelocal/GET_CUSTOM_POST_PREVIEW_SUCCESS';
export var GET_CUSTOM_POST_PREVIEW_FAIL = 'likeablelocal/GET_CUSTOM_POST_PREVIEW_FAIL';
export var GET_FB_AD_PREVIEW = 'likeablelocal/adBuilder/GET_FB_AD_PREVIEW';
export var GET_FB_AD_PREVIEW_SUCCESS = 'likeablelocal/adBuilder/GET_FB_AD_PREVIEW_SUCCESS';
export var GET_FB_AD_PREVIEW_FAIL = 'likeablelocal/adBuilder/GET_FB_AD_PREVIEW_FAIL';
export var GET_FB_AD_PREVIEW_SRC = 'likeablelocal/adBuilder/GET_FB_AD_PREVIEW_SRC';
export var GET_FB_AD_PREVIEW_SRC_SUCCESS = 'likeablelocal/adBuilder/GET_FB_AD_PREVIEW_SRC_SUCCESS';
export var GET_FB_AD_PREVIEW_SRC_FAIL = 'likeablelocal/adBuilder/GET_FB_AD_PREVIEW_SRC_FAIL';
export var CLEAR_FB_AD_PREVIEW = 'likeablelocal/adBuilder/CLEAR_FB_AD_PREVIEW';
export var CREATE_PAGE_LIKE_AD = 'likeablelocal/ads/CREATE_PAGE_LIKE_AD';
export var CREATE_PAGE_LIKE_AD_SUCCESS = 'likeablelocal/ads/CREATE_PAGE_LIKE_AD_SUCCESS';
export var CREATE_PAGE_LIKE_AD_FAIL = 'likeablelocal/ads/CREATE_PAGE_LIKE_AD_FAIL';
export var CREATE_WEBSITE_CLICK_AD = 'likeablelocal/ads/CREATE_WEBSITE_CLICK_AD';
export var CREATE_WEBSITE_CLICK_AD_SUCCESS = 'likeablelocal/ads/CREATE_WEBSITE_CLICK_AD_SUCCESS';
export var CREATE_WEBSITE_CLICK_AD_FAIL = 'likeablelocal/ads/CREATE_WEBSITE_CLICK_AD_FAIL';
export var PARSE_LINK = 'likeablelocal/ads/PARSE_LINK';
export var PARSE_LINK_SUCCESS = 'likeablelocal/ads/PARSE_LINK_SUCCESS';
export var PARSE_LINK_FAIL = 'likeablelocal/ads/PARSE_LINK_FAIL';
export var SET_AD_TYPE = 'likeablelocal/adBuilder/SET_AD_TYPE';
export var SET_BACK_URL = 'likeablelocal/adBuilder/SET_BACK_URL';
export var CATEGORY_SEARCH = 'likeablelocal/adBuilder/CATEGORY_SEARCH';
export var CATEGORY_SEARCH_SUCCESS = 'likeablelocal/adBuilder/CATEGORY_SEARCH_SUCCESS';
export var CATEGORY_SEARCH_FAIL = 'likeablelocal/adBuilder/CATEGORY_SEARCH_FAIL';
export var SET_LEAD_AD_BUILDER_VALUE = 'likeablelocal/adBuilder/SET_LEAD_AD_BUILDER_VALUE';
export var SET_LEAD_AD_BUILDER_INTRO_BULLET = 'likeablelocal/adBuilder/SET_LEAD_AD_BUILDER_INTRO_BULLET';
export var SET_LEAD_AD_BUILDER_QUESTION_CHECK = 'likeablelocal/adBuilder/SET_LEAD_AD_BUILDER_QUESTION_CHECK';
export var INIT_LEAD_AD_BUILDER = 'likeablelocal/adBuilder/INIT_LEAD_AD_BUILDER';
export var ADD_CUSTOM_LEADFORM_QUESTION = 'likeablelocal/adBuilder/ADD_CUSTOM_LEADFORM_QUESTION';
export var REMOVE_CUSTOM_LEADFORM_QUESTION = 'likeablelocal/adBuilder/REMOVE_CUSTOM_LEADFORM_QUESTION';
export var SET_CUSTOM_LEADFORM_QUESTION = 'likeablelocal/adBuilder/SET_CUSTOM_LEADFORM_QUESTION';
export var ADD_LEADFORM_MULTI_ANSWER = 'likeablelocal/adBuilder/ADD_LEADFORM_MULTI_ANSWER';
export var REMOVE_LEADFORM_MULTI_ANSWER = 'likeablelocal/adBuilder/REMOVE_LEADFORM_MULTI_ANSWER';
export var SET_APPOINTMENT_CONFIRM_ENABLED = 'likeablelocal/adBuilder/SET_APPOINTMENT_CONFIRM_ENABLED';
export var SET_APPOINTMENT_CONFIRM_TEXT = 'likeablelocal/adBuilder/SET_APPOINTMENT_CONFIRM_TEXT';
export var ADD_NEW_CONSENT = 'likeablelocal/adBuilder/ADD_NEW_CONSENT';
export var SET_CONSENT_TEXT = 'likeablelocal/adBuilder/SET_CONSENT_TEXT';
export var SET_CONSENT_REQUIRED = 'likeablelocal/adBuilder/SET_CONSENT_REQUIRED';
export var REMOVE_CUSTOM_CONSENT = 'likeablelocal/adBuilder/REMOVE_CUSTOM_CONSENT';
export var FETCH_FORMS = 'likeablelocal/adBuilder/FETCH_FORMS';
export var FETCH_FORMS_SUCCESS = 'likeablelocal/adBuilder/FETCH_FORMS_SUCCESS';
export var FETCH_FORMS_FAIL = 'likeablelocal/adBuilder/FETCH_FORMS_FAIL';
export var FETCH_FORM_DETAIL = 'likeablelocal/adBuilder/FETCH_FORM_DETAIL';
export var FETCH_FORM_DETAIL_SUCCESS = 'likeablelocal/adBuilder/FETCH_FORM_DETAIL_SUCCESS';
export var FETCH_FORM_DETAIL_FAIL = 'likeablelocal/adBuilder/FETCH_FORM_DETAIL_FAIL';
export var SAVE_LEADFORM = 'likeablelocal/adBuilder/SAVE_LEADFORM';
export var SAVE_LEADFORM_SUCCESS = 'likeablelocal/adBuilder/SAVE_LEADFORM_SUCCESS';
export var SAVE_LEADFORM_FAIL = 'likeablelocal/adBuilder/SAVE_LEADFORM_FAIL';
export var CREATE_LEAD_GEN_AD = 'likeablelocal/ads/CREATE_LEAD_GEN_AD';
export var CREATE_LEAD_GEN_AD_SUCCESS = 'likeablelocal/ads/CREATE_LEAD_GEN_AD_SUCCESS';
export var CREATE_LEAD_GEN_AD_FAIL = 'likeablelocal/ads/CREATE_LEAD_GEN_AD_FAIL';
export var GET_PAGE_INSTA = 'likeablelocal/ads/GET_PAGE_INSTA';
export var GET_PAGE_INSTA_SUCCESS = 'likeablelocal/ads/GET_PAGE_INSTA_SUCCESS';
export var GET_PAGE_INSTA_FAIL = 'likeablelocal/ads/GET_PAGE_INSTA_FAIL';
export var INIT_ADBUILDER_STATE_FOR_ORG = 'likeablelocal/adBuilder/INIT_ADBUILDER_STATE_FOR_ORG';
export var CLEAR_LEAD_FORM_DATA = 'likeablelocal/adBuilder/CLEAR_LEAD_FORM_DATA';
export var CLEAR_AD_BUILDER_FOR_ORG = 'likeablelocal/adBuilder/CLEAR_AD_BUILDER_FOR_ORG';
export var GET_AVAILABLE_AD_BUDGET = 'likeablelocal/adBuilder/GET_AVAILABLE_AD_BUDGET';
export var GET_AVAILABLE_AD_BUDGET_SUCCESS = 'likeablelocal/adBuilder/GET_AVAILABLE_AD_BUDGET_SUCCESS';
export var GET_AVAILABLE_AD_BUDGET_FAIL = 'likeablelocal/adBuilder/GET_AVAILABLE_AD_BUDGET_FAIL';
export var CONVERT_AD_TO_REDUX = 'likeablelocal/adBuilder/CONVERT_AD_TO_REDUX';
export var CONVERT_AD_TO_REDUX_SUCCESS = 'likeablelocal/adBuilder/CONVERT_AD_TO_REDUX_SUCCESS';
export var CONVERT_AD_TO_REDUX_FAIL = 'likeablelocal/adBuilder/CONVERT_AD_TO_REDUX_FAIL';
export var ADBUILDER_CHANGE_VALUE = 'likeablelocal/adBuilder/ADBUILDER_CHANGE_VALUE';
export var ADBUILDER_DEEPLINK = 'likeablelocal/adBuilder/ADBUILDER_DEEPLINK';