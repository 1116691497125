import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
import cookies from 'js-cookie';
var SAVE = 'likeablelocal/socialConnection/SAVE';
export var SAVE_SUCCESS = 'likeablelocal/socialConnection/SAVE_SUCCESS';
var SAVE_FAIL = 'likeablelocal/socialConnection/SAVE_FAIL';
var DEL = 'likeablelocal/socialConnection/DEL';
export var DEL_SUCCESS = 'likeablelocal/socialConnection/DEL_SUCCESS';
var DEL_FAIL = 'likeablelocal/socialConnection/DEL_FAIL';
var LOAD_STAGED_SOCIAL_ACCOUNTS = 'likeablelocal/socialConnection/LOAD_STAGED_SOCIAL_ACCOUNTS';
var SET_ACCOUNT_TO_CONNECT = 'likeablelocal/socialConnection/SET_ACCOUNT_TO_CONNECT';
var REQ_BM_ACCESS = 'likeablelocal/socialConnection/REQ_BM_ACCESS';
var REQ_BM_ACCESS_SUCCESS = 'likeablelocal/socialConnection/REQ_BM_ACCESS_SUCCESS';
var REQ_BM_ACCESS_FAIL = 'likeablelocal/socialConnection/REQ_BM_ACCESS_FAIL';
var CHECK_BM_ACCESS = 'likeablelocal/socialConnection/CHECK_BM_ACCESS';
var CHECK_BM_ACCESS_SUCCESS = 'likeablelocal/socialConnection/CHECK_BM_ACCESS_SUCCESS';
var CHECK_BM_ACCESS_FAIL = 'likeablelocal/socialConnection/CHECK_BM_ACCESS_FAIL';
var REFRESH_FB_PAGE_TOKEN = 'likeablelocal/socialConnection/REFRESH_FB_PAGE_TOKEN';
var REFRESH_FB_PAGE_TOKEN_SUCCESS = 'likeablelocal/socialConnection/REFRESH_FB_PAGE_TOKEN_SUCCESS';
var REFRESH_FB_PAGE_TOKEN_FAIL = 'likeablelocal/socialConnection/REFRESH_FB_PAGE_TOKEN_FAIL';
var LOOKUP_INSTAGRAM = 'likeablelocal/socialConnection/LOOKUP_INSTAGRAM';
var LOOKUP_INSTAGRAM_SUCCESS = 'likeablelocal/socialConnection/LOOKUP_INSTAGRAM_SUCCESS';
var LOOKUP_INSTAGRAM_FAIL = 'likeablelocal/socialConnection/LOOKUP_INSTAGRAM_FAIL';
var initialState = {
  saveError: {},
  accountToConnect: null,
  sendingBMAccessRequest: false,
  checkingBmAccess: false,
  bmRequestError: null
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case SAVE:
      return state;
    // 'saving' flag handled by redux-form

    case SAVE_SUCCESS:
      return _objectSpread({}, state);

    case SAVE_FAIL:
      return typeof action.error === 'string' ? _objectSpread(_objectSpread({}, state), {}, {
        saveError: _objectSpread(_objectSpread({}, state.saveError), {}, _defineProperty({}, action.id, action.error))
      }) : state;

    case REQ_BM_ACCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendingBMAccessRequest: true,
        bmRequestError: null
      });

    case REQ_BM_ACCESS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendingBMAccessRequest: false
      });

    case REQ_BM_ACCESS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendingBMAccessRequest: false,
        bmRequestError: action.error
      });

    case CHECK_BM_ACCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        checkingBmAccess: true,
        bmAccessCheckError: null
      });

    case CHECK_BM_ACCESS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        checkingBmAccess: false
      });

    case CHECK_BM_ACCESS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        checkingBmAccess: false,
        bmAccessCheckError: action.error
      });

    case LOAD_STAGED_SOCIAL_ACCOUNTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        stagedSocialAccounts: action.stagedSocialAccounts
      });

    case SET_ACCOUNT_TO_CONNECT:
      return _objectSpread(_objectSpread({}, state), {}, {
        accountToConnect: action.account
      });

    case DEL_SUCCESS:
      return state;

    default:
      return state;
  }
}
export function createReminderOnlyAccount(orgId, platform, platformId, username, link, image) {
  var url = "/org/".concat(orgId, "/socialAccounts/reminderOnly");
  return {
    types: [LOOKUP_INSTAGRAM, LOOKUP_INSTAGRAM_SUCCESS, LOOKUP_INSTAGRAM_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: {
          platform: platform,
          platformId: platformId,
          username: username,
          link: link,
          image: image
        }
      });
    }
  };
}
export function lookupInstagram(account) {
  var url = "/socialAccounts/lookupInstagram".concat(account ? "?account=".concat(account) : '');
  return {
    types: [LOOKUP_INSTAGRAM, LOOKUP_INSTAGRAM_SUCCESS, LOOKUP_INSTAGRAM_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function save(organizationId, socialAccounts) {
  var url = "/org/".concat(organizationId, "/socialAccounts");
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: function promise(client) {
      return client.put(url, {
        data: socialAccounts
      });
    }
  };
}
export function del(organizationId, socialAccounts) {
  var socialAccountIds = Array.isArray(socialAccounts) ? socialAccounts.map(function (account) {
    return account.id;
  }) : [socialAccounts];
  var url = "/org/".concat(organizationId, "/socialAccounts");
  var data = {
    socialAccountIds: socialAccountIds
  };
  return {
    types: [DEL, DEL_SUCCESS, DEL_FAIL],
    promise: function promise(client) {
      return client.del(url, {
        data: data
      });
    }
  };
}
export function loadStagedSocialAccounts() {
  var allCookies = cookies.get();
  var stagedSocialAccounts = null;

  if (allCookies) {
    stagedSocialAccounts = allCookies.staging_social_accounts ? _objectSpread({}, allCookies.staging_social_accounts) : null;
  }

  cookies.remove('staging_social_accounts');
  return {
    type: LOAD_STAGED_SOCIAL_ACCOUNTS,
    stagedSocialAccounts: stagedSocialAccounts
  };
}
export function setAccountToConnect(account) {
  return {
    type: SET_ACCOUNT_TO_CONNECT,
    account: account
  };
}
export function requestBusinessManagerPageAccess(orgId, socialAccountId) {
  return {
    types: [REQ_BM_ACCESS, REQ_BM_ACCESS_SUCCESS, REQ_BM_ACCESS_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/account/").concat(socialAccountId, "/bmrequest"));
    }
  };
}
export function checkBusinessManagerPageAccess(orgId, socialAccountId) {
  return {
    types: [CHECK_BM_ACCESS, CHECK_BM_ACCESS_SUCCESS, CHECK_BM_ACCESS_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/account/").concat(socialAccountId, "/checkBusinessManagerPageAccess"));
    }
  };
}
export function refreshFacebookToken(orgId, socialAccountId) {
  var data = {
    socialAccountId: socialAccountId
  };
  return {
    types: [REFRESH_FB_PAGE_TOKEN, REFRESH_FB_PAGE_TOKEN_SUCCESS, REFRESH_FB_PAGE_TOKEN_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/refreshFacebookToken"), {
        data: data
      });
    }
  };
}