import getMuiTheme from "material-ui/styles/getMuiTheme";
export default function getTheme(theme) {
  var _theme$colors, _theme$colors2, _theme$colors3, _theme$colors4, _theme$colors5, _theme$colors6, _theme$colors7, _theme$colors8, _theme$colors9, _theme$colors10, _theme$colors11, _theme$colors12, _theme$colors13, _theme$colors14, _theme$colors15, _theme$colors16, _theme$colors17, _theme$colors18, _theme$colors19, _theme$colors20, _theme$colors21, _theme$colors22, _theme$colors23, _theme$colors24;

  return getMuiTheme({
    fontFamily: "Open Sans, serif",
    palette: {
      primary1Color: theme === null || theme === void 0 ? void 0 : (_theme$colors = theme.colors) === null || _theme$colors === void 0 ? void 0 : _theme$colors.secondaryColor,
      primary2Color: theme === null || theme === void 0 ? void 0 : (_theme$colors2 = theme.colors) === null || _theme$colors2 === void 0 ? void 0 : _theme$colors2.secondaryColor,
      primary3Color: theme === null || theme === void 0 ? void 0 : (_theme$colors3 = theme.colors) === null || _theme$colors3 === void 0 ? void 0 : _theme$colors3.mutedPrimaryColor,
      accent1Color: theme === null || theme === void 0 ? void 0 : (_theme$colors4 = theme.colors) === null || _theme$colors4 === void 0 ? void 0 : _theme$colors4.secondaryColor,
      textColor: theme === null || theme === void 0 ? void 0 : (_theme$colors5 = theme.colors) === null || _theme$colors5 === void 0 ? void 0 : _theme$colors5.textColor
    },
    textField: {
      textColor: theme === null || theme === void 0 ? void 0 : (_theme$colors6 = theme.colors) === null || _theme$colors6 === void 0 ? void 0 : _theme$colors6.black1,
      floatingLabelColor: theme === null || theme === void 0 ? void 0 : (_theme$colors7 = theme.colors) === null || _theme$colors7 === void 0 ? void 0 : _theme$colors7.gray2
    },
    button: {
      color: "white",
      primaryColor: theme === null || theme === void 0 ? void 0 : (_theme$colors8 = theme.colors) === null || _theme$colors8 === void 0 ? void 0 : _theme$colors8.primaryColor,
      primaryTextColor: theme === null || theme === void 0 ? void 0 : (_theme$colors9 = theme.colors) === null || _theme$colors9 === void 0 ? void 0 : _theme$colors9.white,
      secondaryColor: theme === null || theme === void 0 ? void 0 : (_theme$colors10 = theme.colors) === null || _theme$colors10 === void 0 ? void 0 : _theme$colors10.secondaryColor,
      secondaryTextColor: theme === null || theme === void 0 ? void 0 : (_theme$colors11 = theme.colors) === null || _theme$colors11 === void 0 ? void 0 : _theme$colors11.white,
      textColor: theme === null || theme === void 0 ? void 0 : (_theme$colors12 = theme.colors) === null || _theme$colors12 === void 0 ? void 0 : _theme$colors12.textColor,
      fontSize: 13,
      height: 32
    },
    checkbox: {
      boxColor: theme === null || theme === void 0 ? void 0 : (_theme$colors13 = theme.colors) === null || _theme$colors13 === void 0 ? void 0 : _theme$colors13.secondaryColor,
      checkedColor: theme === null || theme === void 0 ? void 0 : (_theme$colors14 = theme.colors) === null || _theme$colors14 === void 0 ? void 0 : _theme$colors14.secondaryColor
    },
    chip: {
      backgroundColor: theme === null || theme === void 0 ? void 0 : (_theme$colors15 = theme.colors) === null || _theme$colors15 === void 0 ? void 0 : _theme$colors15.gray3,
      fontSize: 13,
      fontWeight: 300,
      textColor: theme === null || theme === void 0 ? void 0 : (_theme$colors16 = theme.colors) === null || _theme$colors16 === void 0 ? void 0 : _theme$colors16.gray1
    },
    listItem: {
      nestedLevelDepth: 18,
      secondaryTextColor: theme === null || theme === void 0 ? void 0 : (_theme$colors17 = theme.colors) === null || _theme$colors17 === void 0 ? void 0 : _theme$colors17.textColor,
      leftIconColor: theme === null || theme === void 0 ? void 0 : (_theme$colors18 = theme.colors) === null || _theme$colors18 === void 0 ? void 0 : _theme$colors18.gray1,
      rightIconColor: theme === null || theme === void 0 ? void 0 : (_theme$colors19 = theme.colors) === null || _theme$colors19 === void 0 ? void 0 : _theme$colors19.gray1
    },
    raisedButton: {
      color: "white",
      primaryColor: theme === null || theme === void 0 ? void 0 : (_theme$colors20 = theme.colors) === null || _theme$colors20 === void 0 ? void 0 : _theme$colors20.primaryColor,
      primaryTextColor: theme === null || theme === void 0 ? void 0 : (_theme$colors21 = theme.colors) === null || _theme$colors21 === void 0 ? void 0 : _theme$colors21.white,
      secondaryColor: theme === null || theme === void 0 ? void 0 : (_theme$colors22 = theme.colors) === null || _theme$colors22 === void 0 ? void 0 : _theme$colors22.secondaryColor,
      secondaryTextColor: theme === null || theme === void 0 ? void 0 : (_theme$colors23 = theme.colors) === null || _theme$colors23 === void 0 ? void 0 : _theme$colors23.white,
      textColor: theme === null || theme === void 0 ? void 0 : (_theme$colors24 = theme.colors) === null || _theme$colors24 === void 0 ? void 0 : _theme$colors24.textColor,
      fontSize: 13,
      fontWeight: 300
    },
    snackbar: {
      actionColor: "white"
    }
  });
}