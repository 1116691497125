import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { CREATE_AUDIENCE_SUCCESS } from './audience';
import { UPDATE_AUDIENCE_SUCCESS } from './audience';
var ns = 'likeablelocal/adsAudiences';
var DELETE_AUDIENCE = "".concat(ns, "/DELETE_AUDIENCE");
var DELETE_AUDIENCE_SUCCESS = "".concat(ns, "/DELETE_AUDIENCE_SUCCESS");
var DELETE_AUDIENCE_FAIL = "".concat(ns, "/DELETE_AUDIENCE_FAIL");
var VALIDATE_TARGETING = 'likeablelocal/ads/VALIDATE_TARGETING';
var VALIDATE_TARGETING_SUCCESS = 'likeablelocal/ads/VALIDATE_TARGETING_SUCCESS';
var VALIDATE_TARGETING_FAIL = 'likeablelocal/ads/VALIDATE_TARGETING_FAIL';
var GET_TARGETING = 'likeablelocal/ads/GET_TARGETING';
var GET_TARGETING_SUCCESS = 'likeablelocal/ads/GET_TARGETING_SUCCESS';
var GET_TARGETING_FAIL = 'likeablelocal/ads/GET_TARGETING_FAIL';
var GET_SAVED_AUDIENCES = 'likeablelocal/ads/GET_SAVED_AUDIENCES';
var GET_SAVED_AUDIENCES_SUCCESS = 'likeablelocal/ads/GET_SAVED_AUDIENCES_SUCCESS';
var GET_SAVED_AUDIENCES_FAIL = 'likeablelocal/ads/GET_SAVED_AUDIENCES_FAIL';
var PUSH_SAVED_AUDIENCE = 'likeablelocal/ads/PUSH_SAVED_AUDIENCE';
var initialState = {
  creatingAudience: false,
  deletingAudience: false,
  gettingAudienceDefinition: false,
  gettingSavedAudiences: false,
  gettingTargeting: false,
  savedAudiences: {},
  targetingInfo: []
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case DELETE_AUDIENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        deletingAudience: true,
        savedAudiences: _objectSpread(_objectSpread({}, state.savedAudiences), {}, _defineProperty({}, action.orgId, state.savedAudiences[action.orgId].filter(function (a) {
          return parseInt(a.id) !== action.audienceId;
        })))
      });

    case DELETE_AUDIENCE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        deletingAudience: false
      });

    case DELETE_AUDIENCE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        deletingAudience: false
      });

    case CREATE_AUDIENCE_SUCCESS:
      console.log('in adsAudiences success reducer CREATE_AUDIENCE_SUCCESS');
      return _objectSpread(_objectSpread({}, state), {}, {
        creatingAudience: false,
        savedAudiences: _objectSpread(_objectSpread({}, state.savedAudiences), {}, _defineProperty({}, action.orgId, [].concat(_toConsumableArray(state.savedAudiences[action.orgId]), [_objectSpread(_objectSpread({}, action.result.data), {}, {
          reachEstimate: action.reachEstimate,
          targetingSentences: action.targetingSentences
        })])))
      });

    case UPDATE_AUDIENCE_SUCCESS:
      console.log('in adsAudiences success reducer CREATE_AUDIENCE_SUCCESS');
      return _objectSpread(_objectSpread({}, state), {}, {
        savedAudiences: _objectSpread(_objectSpread({}, state.savedAudiences), {}, _defineProperty({}, action.orgId, [].concat(_toConsumableArray(state.savedAudiences[action.orgId]), [_objectSpread(_objectSpread({}, action.result.data), {}, {
          reachEstimate: action.reachEstimate,
          targetingSentences: action.targetingSentences
        })])))
      });

    case GET_SAVED_AUDIENCES:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingSavedAudiences: true
      });

    case GET_SAVED_AUDIENCES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingSavedAudiences: false,
        savedAudiences: _objectSpread(_objectSpread({}, state.savedAudiences), {}, _defineProperty({}, action.orgId, action.result.data))
      });

    case GET_SAVED_AUDIENCES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingSavedAudiences: false
      });

    case PUSH_SAVED_AUDIENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        savedAudiences: _objectSpread(_objectSpread({}, state.savedAudiences), {}, _defineProperty({}, action.orgId, [].concat(_toConsumableArray(state[action.orgId].savedAudiences), [action.audience])))
      });

    case GET_TARGETING:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingTargeting: true
      });

    case GET_TARGETING_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        targetingInfo: action.result.data,
        gettingTargeting: false
      });

    case GET_TARGETING_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingTargeting: false
      });

    default:
      return state;
  }
}
export function getSavedAudiencesWithDefinition(orgId, adAccountId) {
  var placements = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var url = "/org/".concat(orgId, "/facebookAds/savedTargetingWithAudienceDefinition/").concat(adAccountId);
  return {
    types: [GET_SAVED_AUDIENCES, GET_SAVED_AUDIENCES_SUCCESS, GET_SAVED_AUDIENCES_FAIL],
    promise: function promise(client) {
      return client.get(url, {
        data: {
          placements: placements
        }
      });
    },
    orgId: orgId
  };
}
export function pushSavedAudience(audience, orgId) {
  return {
    type: PUSH_SAVED_AUDIENCE,
    audience: audience,
    orgId: orgId
  };
}
export function getTargeting(orgId, adId) {
  var url = "/org/".concat(orgId, "/ad/").concat(adId, "/targeting");
  return {
    types: [GET_TARGETING, GET_TARGETING_SUCCESS, GET_TARGETING_FAIL],
    promise: function promise(client) {
      return client.get(url);
    },
    orgId: orgId
  };
}
export function validateTargeting(targeting, adAccountId) {
  var url = "/facebook/adAccount/".concat(adAccountId, "/validateTargeting");
  return {
    types: [VALIDATE_TARGETING, VALIDATE_TARGETING_SUCCESS, VALIDATE_TARGETING_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: {
          targeting: targeting
        }
      });
    }
  };
}
export function deleteAudience(organizationId, audienceId) {
  return {
    types: [DELETE_AUDIENCE, DELETE_AUDIENCE_SUCCESS, DELETE_AUDIENCE_FAIL],
    promise: function promise(client) {
      return client.del("/org/".concat(organizationId, "/audience/").concat(audienceId));
    },
    orgId: organizationId,
    audienceId: audienceId
  };
}