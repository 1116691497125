import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import app, { restApp } from 'app';
import { SubmissionError } from 'redux-form';
import cookie from 'js-cookie';
import localStorage from 'local-storage';
import LogRocket from 'logrocket';
var CLEAR_ORGANIZATIONS = 'likeablelocal/auth/CLEAR_ORGANIZATIONS';
var LOAD = 'likeablelocal/auth/LOAD';
export var LOAD_SUCCESS = 'likeablelocal/auth/LOAD_SUCCESS';
var LOAD_FAIL = 'likeablelocal/auth/LOAD_FAIL';
var LOGIN = 'likeablelocal/auth/LOGIN';
var LOGIN_SUCCESS = 'likeablelocal/auth/LOGIN_SUCCESS';
var LOGIN_FAIL = 'likeablelocal/auth/LOGIN_FAIL';
var REGISTER = 'likeablelocal/auth/REGISTER';
var REGISTER_SUCCESS = 'likeablelocal/auth/REGISTER_SUCCESS';
var REGISTER_FAIL = 'likeablelocal/auth/REGISTER_FAIL';
var OAUTHLOGIN = 'likeablelocal/auth/OAUTHLOGIN';
var OAUTHLOGIN_SUCCESS = 'likeablelocal/auth/OAUTHLOGIN_SUCCESS';
var OAUTHLOGIN_FAIL = 'likeablelocal/auth/OAUTHLOGIN_FAIL';
var LOGOUT = 'likeablelocal/auth/LOGOUT';
export var LOGOUT_SUCCESS = 'likeablelocal/auth/LOGOUT_SUCCESS';
export var RESET_TO_INITIAL_STATE = 'likeablelocal/app/RESET_TO_INITIAL_STATE';
var LOGOUT_FAIL = 'likeablelocal/auth/LOGOUT_FAIL';
var PASSWORD_RESET_REQUEST = 'likeablelocal/auth/PASSWORD_RESET_REQUEST';
var PASSWORD_RESET_REQUEST_SUCCESS = 'likeablelocal/auth/PASSWORD_RESET_REQUEST_SUCCESS';
var PASSWORD_RESET_REQUEST_FAIL = 'likeablelocal/auth/PASSWORD_RESET_REQUEST_FAIL';
var LOAD_PASSWORD_RESET = 'likeablelocal/auth/LOAD_PASSWORD_RESET';
var LOAD_PASSWORD_RESET_SUCCESS = 'likeablelocal/auth/LOAD_PASSWORD_RESET_SUCCESS';
var LOAD_PASSWORD_RESET_FAIL = 'likeablelocal/auth/LOAD_PASSWORD_RESET_FAIL';
var RESET_PASSWORD = 'likeablelocal/auth/RESET_PASSWORD';
var RESET_PASSWORD_SUCCESS = 'likeablelocal/auth/RESET_PASSWORD_SUCCESS';
var RESET_PASSWORD_FAIL = 'likeablelocal/auth/RESET_PASSWORD_FAIL';
var GET_USER_SOCIAL_ACCOUNTS = 'likeablelocal/user/GET_USER_SOCIAL_ACCOUNTS';
var GET_USER_SOCIAL_ACCOUNTS_SUCCESS = 'likeablelocal/user/GET_USER_SOCIAL_ACCOUNTS_SUCCESS';
var GET_USER_SOCIAL_ACCOUNTS_FAIL = 'likeablelocal/user/GET_USER_SOCIAL_ACCOUNTS_FAIL';
var SEARCH_ORGANIZATIONS = 'likeablelocal/user/SEARCH_ORGANIZATIONS';
var SEARCH_ORGANIZATIONS_SUCCESS = 'likeablelocal/user/SEARCH_ORGANIZATIONS_SUCCESS';
var SEARCH_ORGANIZATIONS_FAIL = 'likeablelocal/user/SEARCH_ORGANIZATIONS_FAIL';
var UPDATE_USER = 'likeablelocal/user/UPDATE_USER';
var VERIFY_SIGNIN_LINK = 'likeablelocal/user/VERIFY_SIGNIN_LINK';
var VERIFY_SIGNIN_LINK_SUCCESS = 'likeablelocal/user/VERIFY_SIGNIN_LINK_SUCCESS';
var VERIFY_SIGNIN_LINK_FAIL = 'likeablelocal/user/VERIFY_SIGNIN_LINK_FAIL';
var userService = restApp.service('users');
var initialState = {
  loaded: false,
  reportBuilderOrgSearch: false,
  orgSearchResults: []
};

var catchValidation = function catchValidation(error) {
  if (error.message) {
    if (error.message === 'Validation failed' && error.data) {
      throw new SubmissionError(error.data);
    }

    throw new SubmissionError({
      _error: error.message
    });
  }

  return Promise.reject(error);
};

export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
      return initialState;

    case LOAD:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });

    case LOAD_SUCCESS:
      if (action.result && action.result.user) {
        LogRocket.identify(action.result.user.id, {
          name: "".concat(action.result.user.firstName, " ").concat(action.result.user.lastName),
          email: action.result.user.email
        });
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: true,
        accessToken: action.result ? action.result.accessToken : null,
        user: action.result ? _objectSpread({}, action.result.user) : null,
        userLoadedAt: function () {
          return new Date().getTime();
        }()
      });

    case LOAD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: false,
        error: action.error,
        userLoadedAt: null
      });

    case LOGIN:
    case OAUTHLOGIN:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingIn: true
      });

    case LOGIN_SUCCESS:
    case OAUTHLOGIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingIn: false,
        accessToken: action.result ? action.result.accessToken : null,
        user: action.result ? action.result.user : null
      });

    case LOGIN_FAIL:
    case OAUTHLOGIN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingIn: false,
        accessToken: null,
        loginError: action.error
      });

    case REGISTER:
      return _objectSpread(_objectSpread({}, state), {}, {
        registeringIn: true
      });

    case REGISTER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        registeringIn: false
      });

    case REGISTER_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        registeringIn: false,
        registerError: action.error
      });

    case LOGOUT:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingOut: true
      });

    case LOGOUT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingOut: false,
        user: null,
        accessToken: null,
        userLoadedAt: null
      });

    case LOGOUT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggingOut: false,
        logoutError: action.error,
        userLoadedAt: null
      });

    case LOAD_PASSWORD_RESET_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        resetCode: action.result.data ? action.result.data.uuid : null
      });

    case LOAD_PASSWORD_RESET_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        resetCodeError: action.error.message
      });

    case LOAD_PASSWORD_RESET:
      return state;

    case GET_USER_SOCIAL_ACCOUNTS:
      return state;

    case GET_USER_SOCIAL_ACCOUNTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        user: _objectSpread(_objectSpread({}, state.user), {}, {
          SocialAccounts: action.result.data
        })
      });

    case GET_USER_SOCIAL_ACCOUNTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        socialAccountLoadingError: action.error
      });

    case CLEAR_ORGANIZATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        orgSearchResults: []
      });

    case SEARCH_ORGANIZATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchingOrganizations: true,
        orgSearchError: null
      });

    case SEARCH_ORGANIZATIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchingOrganizations: false,
        orgSearchError: null,
        reportBuilderOrgSearch: action.reportBuilder,
        orgSearchResults: _objectSpread({}, action.result.data)
      });

    case SEARCH_ORGANIZATIONS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchingOrganizations: false,
        orgSearchError: action.result.error,
        orgSearchResults: null
      });

    case UPDATE_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        user: _objectSpread(_objectSpread({}, state.user), {}, {
          firstName: action.user.firstName,
          lastName: action.user.lastName,
          avatarUrl: action.user.avatarUrl
        })
      });

    case VERIFY_SIGNIN_LINK_SUCCESS:
      return function () {
        var opts = _objectSpread({}, cookieLocation(window.location.host));

        cookie.set('feathers-session', action.result.data, opts);
        cookie.set('accessToken', action.result.data, opts);
        return _objectSpread(_objectSpread({}, state), {}, {
          signinLinkOk: true
        });
      }();

    case VERIFY_SIGNIN_LINK_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        signinLinkOk: false
      });

    default:
      return state;
  }
}

function saveAuth(response) {
  var _response$data = response.data,
      accessToken = _response$data.accessToken,
      user = _response$data.user; // const storage = app.get('storage');
  // if (accessToken) {
  //   storage.setItem('feathers-jwt', accessToken);
  //   storage.setItem('accessToken', accessToken);
  //   app.set('accessToken', accessToken);
  //   app.set('user', user);
  //   restApp.set('accessToken', accessToken);
  //   restApp.set('user', user);
  // } else {
  //   storage.removeItem('feathers-jwt');
  //   storage.removeItem('accessToken');
  // }

  return response.data;
}

function subdomainFromUrl(url) {
  var parts = url.split('.');

  if (parts.length >= 3) {
    return parts[0];
  }

  return null;
}

function cookieLocation(domainHost) {
  var checkTeam = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var domain = domainHost;
  /*
  * Uncomment this to allow for *.current-domain.com cookies.
  if (subdomainFromUrl(currentDomain)) {
    domain = domain.replace(`${subdomainFromUrl(currentDomain)}.`, '');
  }
  */

  if (domain.indexOf(':') !== -1) {
    domain = domain.split(':')[0];
  }

  var cookieDomain = "".concat(domain);
  var changedDomain = false;

  if (checkTeam) {
    ['.reverbit.com', '.qtbit.com', '.likeablelocal.com'].forEach(function (host) {
      if (domain.indexOf(host) !== -1 && !changedDomain) {
        cookieDomain = domain.replace("".concat(subdomainFromUrl(domain), "."), '');
        changedDomain = true;
      }
    });
  }

  return {
    domain: cookieDomain,
    path: '/'
  };
}

function setCookie(result) {
  var options = result.expires ? {
    expires: result.expires
  } : undefined;
  var loc = cookieLocation(window.location.host); // cookie.set('feathers-session', result.accessToken, { ...options, ...loc });

  localStorage.set('accessToken', result.accessToken);
  localStorage.set('accessTokenTeam', result.accessToken, _objectSpread(_objectSpread({}, options), cookieLocation(window.location.host, true)));
  cookie.set('accessTokenTeam', result.accessToken, _objectSpread(_objectSpread({}, options), cookieLocation(window.location.host, true))); // cookie.set('accessToken', result.accessToken, { ...options, ...loc });

  return result;
}

export function isLoaded(globalState) {
  return globalState.auth && globalState.auth.loaded;
}
export function load() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: function promise(client) {
      return client.get('/auth/load');
    }
  };
}
export function register(data) {
  return {
    types: [REGISTER, REGISTER_SUCCESS, REGISTER_FAIL],
    promise: function promise() {
      return userService.create(data)["catch"](catchValidation);
    }
  };
}
export function login(data) {
  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: function promise() {
      return restApp.authenticate({
        type: 'local',
        email: data.email,
        password: data.password,
        doPersist: data.doPersist
      }).then(saveAuth).then(setCookie)["catch"](catchValidation);
    }
  };
}
export function oauthLogin(provider, data) {
  var validation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return {
    types: [OAUTHLOGIN, OAUTHLOGIN_SUCCESS, OAUTHLOGIN_FAIL],
    promise: function promise() {
      return restApp.service("/auth/".concat(provider)).create(_objectSpread({}, data)).then(saveAuth).then(setCookie)["catch"](validation ? catchValidation : function (error) {
        return Promise.reject(error);
      });
    }
  };
}
export function logout() {
  return {
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
    promise: function promise() {
      return restApp.logout().then(function () {
        console.dir(cookie.get('accessToken'));
        cookie.remove('feathers-session', _objectSpread({}, cookieLocation(window.location.host)));
        cookie.remove('accessToken', _objectSpread({}, cookieLocation(window.location.host)));
        cookie.remove('accessTokenTeam', _objectSpread({}, cookieLocation(window.location.host, true)));
        window.localStorage.clear();
        setTimeout(function () {
          window.location = '/';
        }, 1000);
      });
    }
  };
}
export function forceResetState() {
  return {
    type: RESET_TO_INITIAL_STATE
  };
}
export function requestPasswordReset(data) {
  return {
    types: [PASSWORD_RESET_REQUEST, PASSWORD_RESET_REQUEST_SUCCESS, PASSWORD_RESET_REQUEST_FAIL],
    promise: function promise(client) {
      return client.post('/reset/request', {
        data: data
      });
    }
  };
}
export function loadPasswordReset(resetCode) {
  return {
    types: [LOAD_PASSWORD_RESET, LOAD_PASSWORD_RESET_SUCCESS, LOAD_PASSWORD_RESET_FAIL],
    promise: function promise(client) {
      return client.get("/reset?c=".concat(resetCode));
    }
  };
}
export function resetPassword(data) {
  return {
    types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL],
    promise: function promise(client) {
      return client.post('/reset', {
        data: data
      });
    }
  };
}
export function getUserSocialAccounts() {
  return {
    types: [GET_USER_SOCIAL_ACCOUNTS, GET_USER_SOCIAL_ACCOUNTS_SUCCESS, GET_USER_SOCIAL_ACCOUNTS_FAIL],
    promise: function promise(client) {
      return client.get('/user/socialAccounts');
    }
  };
}
export function searchUserOrganizations(query) {
  var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var reportBuilder = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var urlQueryArray = [{
    key: 'q',
    value: encodeURIComponent(query)
  }, {
    key: 'p',
    value: page
  }];
  var urlQuery = urlQueryArray.filter(function (param) {
    return param.value;
  }).map(function (param) {
    return "".concat(param.key, "=").concat(param.value);
  }).join('&');
  var url = "/user/organizations/search?".concat(urlQuery).concat(reportBuilder ? '&includeCanceled=true' : '');
  return {
    types: [SEARCH_ORGANIZATIONS, SEARCH_ORGANIZATIONS_SUCCESS, SEARCH_ORGANIZATIONS_FAIL],
    promise: function promise(client) {
      return client.get(url);
    },
    reportBuilder: reportBuilder
  };
}
export function clearOrgSearchResults() {
  return {
    type: CLEAR_ORGANIZATIONS
  };
}
export function updateUser(user) {
  return {
    type: UPDATE_USER,
    user: user
  };
}
export function verifySigninLink(token, verifier) {
  return {
    types: [VERIFY_SIGNIN_LINK, VERIFY_SIGNIN_LINK_SUCCESS, VERIFY_SIGNIN_LINK_FAIL],
    promise: function promise(client) {
      return client.post('/signin/verify', {
        data: {
          token: token,
          verifier: verifier
        }
      });
    }
  };
}