import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
import cookie from 'js-cookie';

function cookieLocation() {
  var currentDomain = window.location.host;
  var domain = currentDomain;
  /*
  * Uncomment this to allow for *.current-domain.com cookies.
  if (subdomainFromUrl(currentDomain)) {
    domain = domain.replace(`${subdomainFromUrl(currentDomain)}.`, '');
  }
  */

  if (domain.indexOf(':') !== -1) {
    domain = domain.split(':')[0];
  }

  return {
    domain: domain,
    path: '/'
  };
}

function setCookieFromToken(token) {
  var options = undefined;
  var loc = cookieLocation();
  cookie.set('feathers-session', token, _objectSpread(_objectSpread({}, options), loc));
  cookie.set('accessToken', token, _objectSpread(_objectSpread({}, options), loc));
}

var SIGN_CONTRACT = 'likeablelocal/signContract/SIGN_CONTRACT';
var SIGN_CONTRACT_SUCCESS = 'likeablelocal/signContract/SIGN_CONTRACT_SUCCESS';
var SIGN_CONTRACT_FAIL = 'likeablelocal/signContract/SIGN_CONTRACT_FAIL';
var initialState = {
  saveError: null
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case SIGN_CONTRACT:
      return state;

    case SIGN_CONTRACT_SUCCESS:
      // const validationData = [...state.data];
      // validationData[action.result.id - 1] = action.result;
      // after signing contract, log the user in!
      if (action.result.data.contract.paymentDetailEntryType != 2 && !action.affiliateSign) {
        console.log('setCookieFromToken');
        setCookieFromToken(action.result.data.token);
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        // [validationData]: validationData,
        saveError: null
      });

    case SIGN_CONTRACT_FAIL:
      console.log('why you fail?');
      return action.error ? _objectSpread(_objectSpread({}, state), {}, {
        saveError: action.error
      }) : state;

    default:
      return state;
  }
}
export function signContract(data) {
  var affiliateSign = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return {
    types: [SIGN_CONTRACT, SIGN_CONTRACT_SUCCESS, SIGN_CONTRACT_FAIL],
    promise: function promise(client) {
      return client.post('/signContract', {
        data: data
      });
    },
    affiliateSign: affiliateSign
  };
}