import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var UPDATE_SMB_PARTNER_BUTTONBAR = 'likeablelocal/agreement/UPDATE_SMB_PARTNER_BUTTONBAR';
var UPDATE_SELECTED_SALES_USER = 'likeablelocal/agreement/UPDATE_SELECTED_SALES_USER';
var initialState = {
  selectedView: 'PARTNER',
  selectedSalesUserId: ''
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case UPDATE_SMB_PARTNER_BUTTONBAR:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedView: action.selectedView
      });

    case UPDATE_SELECTED_SALES_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedSalesUserId: action.selectedSalesUserId
      });

    default:
      return state;
  }
}
export function updateView(selectedView) {
  return {
    type: UPDATE_SMB_PARTNER_BUTTONBAR,
    selectedView: selectedView
  };
}
export function updateSelectedSalesUser(selectedSalesUserId) {
  return {
    type: UPDATE_SELECTED_SALES_USER,
    selectedSalesUserId: selectedSalesUserId
  };
}