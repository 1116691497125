import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var ns = 'likeablelocal/audienceDemographics';
var TARGETING_BROWSE = "".concat(ns, "/TARGETING_BROWSE");
var TARGETING_BROWSE_SUCCESS = "".concat(ns, "/TARGETING_BROWSE_SUCCESS");
var TARGETING_BROWSE_FAIL = "".concat(ns, "/TARGETING_BROWSE_FAIL");
var CATEGORY_SEARCH = "".concat(ns, "/CATEGORY_SEARCH");
var CATEGORY_SEARCH_SUCCESS = "".concat(ns, "/CATEGORY_SEARCH_SUCCESS");
var CATEGORY_SEARCH_FAIL = "".concat(ns, "/CATEGORY_SEARCH_FAIL");
var QUERY_JOB_TITLES = "".concat(ns, "/QUERY_JOB_TITLES");
var QUERY_JOB_TITLES_SUCCESS = "".concat(ns, "/QUERY_JOB_TITLES_SUCCESS");
var QUERY_JOB_TITLES_FAIL = "".concat(ns, "/QUERY_JOB_TITLES_FAIL");
var QUERY_MAJOR = "".concat(ns, "/QUERY_MAJOR");
var QUERY_MAJOR_SUCCESS = "".concat(ns, "/QUERY_MAJOR_SUCCESS");
var QUERY_MAJOR_FAIL = "".concat(ns, "/QUERY_MAJOR_FAIL");
var QUERY_EMPLOYER = "".concat(ns, "/QUERY_EMPLOYER");
var QUERY_EMPLOYER_SUCCESS = "".concat(ns, "/QUERY_EMPLOYER_SUCCESS");
var QUERY_EMPLOYER_FAIL = "".concat(ns, "/QUERY_EMPLOYER_FAIL");
var QUERY_SCHOOL = "".concat(ns, "/QUERY_SCHOOL");
var QUERY_SCHOOL_SUCCESS = "".concat(ns, "/QUERY_SCHOOL_SUCCESS");
var QUERY_SCHOOL_FAIL = "".concat(ns, "/QUERY_SCHOOL_FAIL");
var initialState = {
  loadingCategory: false,
  dataSource: []
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case CATEGORY_SEARCH:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCategory: true
      });

    case CATEGORY_SEARCH_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCategory: false
      });

    case CATEGORY_SEARCH_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCategory: false
      });

    default:
      return state;
  }
}
export function queryJobTitles(query) {
  var url = "/facebook/ad/jobTitles?q=".concat(encodeURIComponent(query));
  return {
    types: [QUERY_JOB_TITLES, QUERY_JOB_TITLES_SUCCESS, QUERY_JOB_TITLES_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function queryEmployer(query) {
  var url = "/facebook/ad/employer?q=".concat(encodeURIComponent(query));
  return {
    types: [QUERY_EMPLOYER, QUERY_EMPLOYER_SUCCESS, QUERY_EMPLOYER_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function querySchool(query) {
  var url = "/facebook/ad/school?q=".concat(encodeURIComponent(query));
  return {
    types: [QUERY_SCHOOL, QUERY_SCHOOL_SUCCESS, QUERY_SCHOOL_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function queryMajor(query) {
  var url = "/facebook/ad/major?q=".concat(encodeURIComponent(query));
  return {
    types: [QUERY_MAJOR, QUERY_MAJOR_SUCCESS, QUERY_MAJOR_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function categorySearch(category) {
  var url = "/facebook/ad/categorySearch?category=".concat(category);
  return {
    types: [CATEGORY_SEARCH, CATEGORY_SEARCH_SUCCESS, CATEGORY_SEARCH_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function getTargetingInfo(adAccountId, type) {
  var url = "/facebook/ad/".concat(adAccountId, "/targetingbrowse/").concat(type);
  return {
    types: [TARGETING_BROWSE, TARGETING_BROWSE_SUCCESS, TARGETING_BROWSE_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}