import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import moment from 'moment';
import validator from 'validator';
var ns = 'likeablelocal/newClient';
var SET_FORM_VALUE = "".concat(ns, "/SET_FORM_VALUE");
var CHANGE_CC_VALIDATION = "".concat(ns, "/CHANGE_CC_VALIDATION");
var VALIDATE = "".concat(ns, "/VALIDATE");
var CLEAR = "".concat(ns, "/CLEAR");
var CREATE_NEW_CLIENT = "".concat(ns, "/CREATE_NEW_CLIENT");
export var CREATE_NEW_CLIENT_SUCCESS = "".concat(ns, "/CREATE_NEW_CLIENT_SUCCESS");
var CREATE_NEW_CLIENT_FAIL = "".concat(ns, "/CREATE_NEW_CLIENT_FAIL");
var initialState = {
  gettingProducts: false,
  products: [],
  orgConfig: {
    billingMethod: 'end_to_end',
    managementMethod: 'partnerManaged',
    managementFee: 200,
    adsBudget: 200,
    adsCommissionPercent: '25'
  },
  orgDetails: {
    firstName: '',
    lastName: '',
    email: '',
    businessName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    phone: ''
  },
  cc: {
    cardNumber: '',
    expMonth: '',
    expYear: '',
    cvv: '',
    stripeTokenId: '',
    cardDetails: null,
    nextInvoice: {
      billingDate: moment('2019-01-14'),
      proratedEndDate: moment('2019-01-13'),
      proratedStartDate: moment('2019-12-14'),
      proratedCharge: 48.24
    }
  },
  validation: {
    step2FirstPass: true,
    step2Valid: true,
    step1Valid: true
  },
  locationBillingInfo: {
    licenseFeeBillTo: 'location',
    adsBillTo: 'location'
  },
  validationFields: ['businessName', 'email', 'cardNumber', 'expMonth', 'expYear', 'cvv'],
  invalidFields: [],
  orgDetailsValid: false,
  affiliateConfig: {
    affiliateProductId: null
  }
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case CLEAR:
      {
        return initialState;
      }

    case VALIDATE:
      {
        var stateBeforeValidation = Object.assign({}, state); // validate all fields

        var stateAfterValidation = validateStep2Fields(stateBeforeValidation);
        return stateAfterValidation;
      }

    case CREATE_NEW_CLIENT_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          cardChargeError: null
        });
      }

    case CHANGE_CC_VALIDATION:
      {
        var validationFields = action.includeCC ? ['businessName', 'email', 'cardNumber', 'expMonth', 'expYear', 'cvv'] : ['businessName', 'email'];
        return _objectSpread(_objectSpread({}, state), {}, {
          validationFields: validationFields
        });
      }

    case SET_FORM_VALUE:
      {
        var _stateAfterValidation;

        var _stateBeforeValidation = Object.assign({}, _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.sectionKey, _objectSpread(_objectSpread({}, state[action.sectionKey]), {}, _defineProperty({}, action.key, action.value)))));

        var needToValidate = !state.step2FirstPass && state.validationFields.includes(action.key);

        if (needToValidate) {
          // validate all fields
          _stateAfterValidation = validateStep2Fields(_stateBeforeValidation);
        }

        return needToValidate ? _stateAfterValidation : _stateBeforeValidation;
      }

    case CREATE_NEW_CLIENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        cardChargeError: action.error.error.message
      });

    case CREATE_NEW_CLIENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        cardChargeError: null
      });

    default:
      return state;
  }
}
export function setFormValue(key, sectionKey, value) {
  return {
    type: SET_FORM_VALUE,
    key: key,
    sectionKey: sectionKey,
    value: value
  };
}
export function changeCCValidation() {
  var includeCC = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    type: CHANGE_CC_VALIDATION,
    includeCC: includeCC
  };
}
export function wipeNewClientState() {
  return {
    type: CLEAR
  };
}
export function validateStep2() {
  return {
    type: VALIDATE
  };
}
export function createNewPartnerClient(orgId, data) {
  return {
    types: [CREATE_NEW_CLIENT, CREATE_NEW_CLIENT_SUCCESS, CREATE_NEW_CLIENT_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/new/partnerClient"), {
        data: {
          orgConfig: data.orgConfig,
          orgDetails: data.orgDetails,
          stripeTokenId: data.cc.stripeTokenId,
          totals: data.totals
        }
      });
    },
    data: data
  };
}
export function createNewPartnerLocation(orgId, data) {
  return {
    types: [CREATE_NEW_CLIENT, CREATE_NEW_CLIENT_SUCCESS, CREATE_NEW_CLIENT_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/new/partnerClient"), {
        data: {
          orgConfig: {
            billingMethod: 'location'
          },
          orgDetails: data.orgDetails,
          stripeTokenId: data.cc.stripeTokenId,
          locationBillingInfo: {
            licenseFee: {
              billTo: data.locationBillingInfo.licenseFeeBillTo
            },
            ads: {
              billTo: data.locationBillingInfo.adsBillTo,
              amountInCents: data.orgConfig.adsBudget * 100
            }
          }
        }
      });
    },
    data: data
  };
}
export function createNewPartnerAffiliate(orgId, data) {
  return {
    types: [CREATE_NEW_CLIENT, CREATE_NEW_CLIENT_SUCCESS, CREATE_NEW_CLIENT_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/new/partnerClient"), {
        data: {
          orgConfig: {
            billingMethod: 'affiliate'
          },
          orgDetails: data.orgDetails,
          stripeTokenId: data.cc.stripeTokenId,
          productId: 1
        }
      });
    },
    data: data
  };
}

function validateStep2Fields(stateCopy) {
  stateCopy.invalidFields = [];
  stateCopy.validationFields.forEach(function (field) {
    if (stateCopy.orgDetails[field] === '') {
      stateCopy.invalidFields.push(field);
    } else if (field === 'email' && !validator.isEmail(stateCopy.orgDetails[field])) {
      stateCopy.invalidFields.push(field);
    }

    if (stateCopy.cc[field] === '') {
      stateCopy.invalidFields.push(field);
    }
  }); // we have errors

  if (stateCopy.invalidFields.length) {
    stateCopy.validation.step2Valid = false;
  } else {
    stateCopy.validation.step2Valid = true;
  }

  return stateCopy;
}