import { startCase } from 'lodash';
export default function adTypeFromPost(post) {
  if (post.adDetails) {
    var objective = post.adDetails.campaign.objective;
    var val = prettifyAdObjective(objective);
    return val === 'Post Engagement' ? 'Boosted Post' : val;
  }

  return 'Ad';
}
export function prettifyAdObjective(objective) {
  if (objective === 'POST_ENGAGEMENT_AD') {
    return 'Boosted Post';
  }

  return "".concat(startCase(objective.toLowerCase()), " Ad");
}