import validator from 'validator';
export default function leadFormValidator(builder) {
  var errors = {
    form: [],
    intro: [],
    questions: [],
    privacyPolicy: [],
    thankYou: [],
    settings: []
  };

  if (!builder.formName) {
    errors.form.push('Form name is required.');
  }

  if (builder.introEnabled) {
    if (!builder.introHeadline) {
      errors.intro.push('Intro Headline is required.');
    } else if (builder.introHeadline.length > 60) {
      errors.intro.push('Intro Headline cannot be longer than 60 characters.');
    }

    if (builder.introLayoutType === 0) {
      if (!builder.introParagraph) {
        errors.intro.push('Intro description is required.');
      }
    } else {
      var hasAnyBullets = false;
      builder.introBullets.forEach(function (b, idx) {
        if (b.length > 80) {
          errors.intro.push("Intro bullet ".concat(idx + 1, " cannot be longer than 80 characters."));
        }

        if (b.length > 0) {
          hasAnyBullets = true;
        }
      });

      if (!hasAnyBullets) {
        errors.intro.push('At least one Intro bullet is required.');
      }
    }

    if (builder.introImageType === 'uploaded' && (!builder.imageUrl || builder.imageUrl === 'http://bit.ly/2k0Ds2T')) {
      errors.intro.push('A cover image is required.');
    }
  }

  if (!builder.questionsHeadline) {
    errors.questions.push('Questions Headline is required.');
  }

  if (!builder.infoQuestions || builder.infoQuestions.length === 0) {
    errors.questions.push('At least one question is required.');
  }

  if (builder.customQuestions && builder.customQuestions.length > 0) {
    builder.customQuestions.forEach(function (cq) {
      if (!cq.question) {
        errors.questions.push("A question is required in your ".concat(cq.type === 'short_answer' ? 'Short Answer' : 'Multiple Choice', " Custom Question."));
      }

      if (cq.type === 'multiple_choice' && (!cq.answers || cq.answers.length === 0)) {
        errors.questions.push('At least one Answer is required in your Multiple Choice Custom Question');
      }
    });
  }

  if (builder.infoQuestions && builder.infoQuestions.length === 0) {
    errors.questions.push('At least one non-custom question is required.');
  }

  if (!builder.privacyPolicyLinkText) {
    errors.privacyPolicy.push('Privacy Policy link text is required.');
  } else if (builder.privacyPolicyLinkText.length > 70) {
    errors.privacyPolicy.push('Privacy Policy link text cannot be longer than 70 characters.');
  }

  if (!builder.privacyPolicyURL) {
    errors.privacyPolicy.push('Privacy Policy URL is required.');
  } else if (!validator.isURL(builder.privacyPolicyURL)) {
    errors.privacyPolicy.push('Privacy Policy URL is not valid.');
  }

  if (builder.customDisclaimerEnabled) {
    if (!builder.customDisclaimerTitle) {
      errors.privacyPolicy.push('Custom Disclaimer Title is required.');
    }

    if (!builder.customDisclaimerText) {
      errors.privacyPolicy.push('Custom Disclaimer Text is required.');
    }

    if (builder.customDisclaimerConsents && builder.customDisclaimerConsents.length > 0) {
      builder.customDisclaimerConsents.forEach(function (dis, idx) {
        if (!dis.text) {
          errors.privacyPolicy.push("Consent ".concat(idx + 1, " Question is required."));
        }
      });
    }
  }

  if (!builder.thankYouHeadline) {
    errors.thankYou.push('Headline is required.');
  }

  if (!builder.thankYouDescription) {
    errors.thankYou.push('Description is required.');
  }

  if (!builder.thankYouButtonText) {
    errors.thankYou.push('Button Text is required.');
  }

  if (!builder.thankYouButtonURL) {
    errors.thankYou.push('Website Link is required.');
  } else if (!validator.isURL(builder.thankYouButtonURL)) {
    errors.thankYou.push('Website Link is invalid.');
  }

  return errors;
}