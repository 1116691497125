import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
var UPDATE_INNER_WIDTH = 'likeablelocal/organization/UPDATE_INNER_WIDTH';
var UPDATE_OUTER_WIDTH = 'likeablelocal/organization/UPDATE_OUTER_WIDTH';
var UPDATE_OFFSET = 'likeablelocal/organization/UPDATE_OFFSET';
var initialState = {
  offset: 0,
  innerWidth: 0,
  outerWidth: 0
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case UPDATE_INNER_WIDTH:
      return _objectSpread(_objectSpread({}, state), {}, {
        innerWidth: action.innerWidth
      });

    case UPDATE_OUTER_WIDTH:
      return _objectSpread(_objectSpread({}, state), {}, {
        outerWidth: action.outerWidth
      });

    case UPDATE_OFFSET:
      return _objectSpread(_objectSpread({}, state), {}, {
        offset: action.offset
      });

    default:
      return state;
  }
}
export function setOffset(offset) {
  return {
    type: UPDATE_OFFSET,
    offset: offset
  };
}
export function setInnerWidth(innerWidth) {
  return {
    type: UPDATE_INNER_WIDTH,
    innerWidth: innerWidth
  };
}
export function setOuterWidth(outerWidth) {
  return {
    type: UPDATE_OUTER_WIDTH,
    outerWidth: outerWidth
  };
}