var getAdsCommissionPercent = function getAdsCommissionPercent(val) {
  if (parseFloat(val) < 0 || parseFloat(val) > 25 || isNaN(parseFloat(val)) || !"".concat(val).match(/^[-+]?[0-9]*\.?[0-9]+$/)) {
    return 0;
  }

  return parseFloat(val);
};

export default function tallyClientTotals(contractInfo, orgConfig) {
  var commissionPercent = getAdsCommissionPercent(orgConfig.adsCommissionPercent) / 100.0;
  var totals = {}; // contract derived values

  totals.llManagedFee = parseFloat(contractInfo.terms.llManagedMonthlyFee);
  totals.partnerManagedMonthlyFee = parseFloat(contractInfo.terms.partnerManagedMonthlyFee); // new client config values

  totals.managementFee = parseFloat(orgConfig.managementFee);
  totals.adsBudget = parseFloat(orgConfig.adsBudget); // calculated values

  totals.licenseFee = orgConfig.managementMethod === 'likeableManaged' ? totals.llManagedFee : totals.partnerManagedMonthlyFee;
  totals.totalBilledToClient = orgConfig.billingMethod === 'end_to_end' ? totals.managementFee + totals.adsBudget : totals.adsBudget;
  totals.adsCommission = totals.adsBudget * commissionPercent;
  totals.totalBilledToPartner = orgConfig.billingMethod === 'end_to_end' ? 0 : totals.licenseFee;
  totals.transactionFee = orgConfig.billingMethod === 'end_to_end' ? totals.totalBilledToClient * 0.029 + 0.30 : 0;
  totals.monthlyNet = orgConfig.billingMethod === 'end_to_end' ? totals.managementFee + totals.adsCommission - totals.licenseFee - totals.transactionFee : totals.adsCommission;
  totals.partnerPaymentSubtotal = totals.managementFee - totals.licenseFee - totals.transactionFee;
  return totals;
}