import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as actions from "./actions";
import cookie from "js-cookie";
import moment from "moment";
import * as _ from "lodash";
import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from "../auth";
var initialState = {
  hasBillingAccount: true,
  hasCommissionsAccount: false,
  hasAdPaymentMethod: false,
  loadingCommissionAccount: false,
  loadingHasCommissionsAccount: false,
  loadingAdsPaymentMethod: true,
  loadingBillingHistory: true,
  commissionsAccount: null,
  billingHistory: [],
  billingHistoryByOrgId: {},
  subscriptionBillingHistory: [],
  loadingSubscriptionBillingHistory: false,
  addFundsInProgress: false,
  currentAdsBudgetRemaining: 0,
  invoice: null,
  loadingInvoice: false,
  changingAdsPlan: false,
  cc: null,
  fetchingStripeConnectVerificationFields: false
};
export default function reducer() {
  var _action$error, _action$error$error;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case actions.GET_WHITELABEL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        whiteLabelInfo: action.result.data
      });

    case actions.SET_INVOICE:
      return _objectSpread(_objectSpread({}, state), {}, {
        invoice: action.invoice
      });

    case actions.CLEAR_CREDIT_CARD_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        cc: null
      });

    case actions.CHANGE_ADS_PLAN:
      return _objectSpread(_objectSpread({}, state), {}, {
        changingAdsPlan: true
      });

    case actions.CHANGE_ADS_PLAN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        changingAdsPlan: false
      });

    case actions.CHANGE_ADS_PLAN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        changingAdsPlan: false
      });

    case actions.CANCEL_CLIENT:
      return _objectSpread({}, state);

    case actions.CANCEL_CLIENT_SUCCESS:
      return _objectSpread({}, state);

    case actions.CANCEL_CLIENT_FAIL:
      return _objectSpread({}, state);

    case actions.GET_PARTNER_BILLING_HISTORY:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSubscriptionBillingHistory: true
      });

    case actions.GET_PARTNER_BILLING_HISTORY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        subscriptionBillingHistory: action.result.data.map(function (h) {
          return {
            amount: h.amountDue.display,
            createdDate: "".concat(moment(h.dateRange.start).format("M/D/YY"), " - ").concat(moment(h.dateRange.end).format("M/D/YY")),
            status: h.paid ? "Paid" : "Not Paid"
          };
        }),
        loadingSubscriptionBillingHistory: false
      });

    case actions.GET_PARTNER_BILLING_HISTORY_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        subscriptionBillingHistory: [],
        loadingSubscriptionBillingHistory: false
      });

    case actions.GET_BILLING_HISTORY:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingBillingHistory: true
      });

    case actions.GET_BILLING_HISTORY_SUCCESS:
      return function () {
        var billingHistory = action.result.data.map(function (h) {
          var oneTimeCharge = h.stripeId && h.stripeId.indexOf("ch_") === 0;
          return {
            biller: {
              org: h.parentOrganization,
              address: h.parentOrganizationAddress
            },
            billee: {
              org: h.childOrganization,
              address: h.childOrganizationAddress
            },
            stripeId: h.stripeId,
            cardBrand: h.chargedCard ? h.chargedCard.brand : "",
            amount: h.amountDue ? h.amountDue.display : "",
            balanceDue: h.amountRemaining ? h.amountRemaining.display : "",
            amountPaid: h.amountPaid ? h.amountPaid.display : "",
            amountRefunded: h.amountRefunded && h.amountRefunded.value > 0 ? h.amountRefunded.display : null,
            createdDate: moment(h.date).format("MM/DD/YYYY"),
            last4: h.chargedCard ? h.chargedCard.last4 : "",
            paid: h.paid,
            invoiceStartDate: h.dateRange ? moment(h.dateRange.end).format("MM/DD/YYYY") : null,
            invoiceEndDate: h.dateRange ? moment(h.dateRange.end).add(1, "month").subtract(1, "day").format("MM/DD/YYYY") : null,
            invoiceNumber: h.number,
            oneTimeCharge: oneTimeCharge,
            lineItems: h.lineItems ? h.lineItems : [],
            description: h.description
          };
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          billingHistory: billingHistory,
          billingHistoryByOrgId: _objectSpread(_objectSpread({}, state.billingHistoryByOrgId), {}, _defineProperty({}, action.orgId, billingHistory)),
          loadingBillingHistory: false
        });
      }();

    case actions.GET_BILLING_HISTORY_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        billingHistory: [],
        loadingBillingHistory: false
      });

    case actions.CLEAR_BILLING_HISTORY:
      return _objectSpread(_objectSpread({}, state), {}, {
        billingHistory: []
      });

    case actions.GET_INVOICE:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingInvoice: true
      });

    case actions.GET_INVOICE_SUCCESS:
      return function () {
        var h = action.result.data;
        var oneTimeCharge = h.dateRange.start === null;
        var invoice = {
          biller: {
            org: h.parentOrganization,
            address: h.parentOrganizationAddress
          },
          billee: {
            org: h.childOrganization,
            address: h.childOrganizationAddress
          },
          stripeId: h.stripeId,
          cardBrand: h.chargedCard ? h.chargedCard.brand : "",
          amount: h.amountDue.display,
          balanceDue: h.amountRemaining.display,
          amountPaid: h.amountPaid.display,
          amountRefunded: h.amountRefunded && h.amountRefunded.value > 0 ? h.amountRefunded.display : null,
          createdDate: moment(h.date).format("MM/DD/YYYY"),
          last4: h.chargedCard ? h.chargedCard.last4 : "",
          paid: h.paid,
          invoiceStartDate: moment(h.dateRange.end).format("MM/DD/YYYY"),
          invoiceEndDate: moment(h.dateRange.end).add(1, "month").subtract(1, "day").format("MM/DD/YYYY"),
          invoiceNumber: h.number,
          oneTimeCharge: oneTimeCharge,
          lineItems: h.lineItems
        };
        return _objectSpread(_objectSpread({}, state), {}, {
          invoice: invoice,
          loadingInvoice: false
        });
      }();

    case actions.GET_INVOICE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        invoice: null,
        loadingBillingHistory: false
      });

    case actions.CLEAR_INVOICE_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        invoice: null
      });

    case actions.GET_HAS_COMMISSIONS_ACCOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingHasCommissionsAccount: true
      });

    case actions.GET_HAS_COMMISSIONS_ACCOUNT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingHasCommissionsAccount: false,
        hasCommissionsAccount: true
      });

    case actions.GET_HAS_COMMISSIONS_ACCOUNT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingHasCommissionsAccount: false
      });

    case actions.ADD_FUNDS:
      return _objectSpread(_objectSpread({}, state), {}, {
        addFundsInProgress: true,
        billingError: null
      });

    case actions.ADD_FUNDS_SUCCESS:
      // Stripe returns amounts in cents
      // assuming here that all dollar amounts are in cents
      var currentAdsBudgetRemaining = action.result.data.charge.amount + state.currentAdsBudgetRemaining;
      return _objectSpread(_objectSpread({}, state), {}, {
        addFundsInProgress: false,
        currentAdsBudgetRemaining: currentAdsBudgetRemaining,
        billingError: null
      });

    case actions.ADD_FUNDS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        addFundsInProgress: false,
        billingError: action.error.error ? action.error.error : action.error
      });

    case actions.SET_ADS_PAYMENT_METHOD:
      return _objectSpread(_objectSpread({}, state), {}, {
        settingAdsPaymentMethod: true,
        billingError: null
      });

    case actions.SET_ADS_PAYMENT_METHOD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        hasAdPaymentMethod: true,
        settingAdsPaymentMethod: false,
        cc: action.result.data.card,
        billingError: null
      });

    case actions.SET_ADS_PAYMENT_METHOD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        hasAdPaymentMethod: false,
        settingAdsPaymentMethod: false,
        billingError: action.error.error ? action.error.error : action.error
      });

    case actions.GET_ADS_PAYMENT_METHOD:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAdsPaymentMethod: true
      });

    case actions.GET_ADS_PAYMENT_METHOD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAdsPaymentMethod: false,
        hasAdPaymentMethod: true,
        cc: action.result.data
      });

    case actions.GET_ADS_PAYMENT_METHOD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAdsPaymentMethod: false,
        hasAdPaymentMethod: false,
        hasBillingAccount: false
      });

    case actions.UNMOUNT_AD_BUDGET:
      return _objectSpread(_objectSpread({}, state), {}, {
        hasAdPaymentMethod: false,
        hasBillingAccount: false,
        loadingAdsPaymentMethod: true,
        loadingBillingHistory: true,
        cc: null
      });

    case actions.INITIAL_SUBSCRIPTION_SUCCESS:
      return function () {
        if (action.result.data.contract.paymentDetailEntryType === 1 && !action.affiliateSign) {
          var domain = action.result.data.whiteLabel ? action.result.data.whiteLabel.domain : action.result.data.domain;
          window.location.href = "//".concat(domain, "/signin?secret=").concat(encodeURIComponent(action.result.data.token), "&verifier=").concat(encodeURIComponent(action.result.data.tokenHash));
        }

        return _objectSpread({}, state);
      }();

    case actions.PROCESS_PAYMENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        billingError: action.result.error
      });

    case actions.SHOW_STRIPE_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        billingError: action.error,
        checkoutInProgress: false
      });

    case actions.CLEAR_STRIPE_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        billingError: action.error
      });

    case actions.GET_CONNECT_VERIFICATION_FIELDS:
      return _objectSpread(_objectSpread({}, state), {}, {
        fetchingStripeConnectVerificationFields: true
      });

    case actions.GET_CONNECT_VERIFICATION_FIELDS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        stripeConnectVerificationFields: action.result.data.verification_fields,
        fetchingStripeConnectVerificationFields: false,
        stripeCountry: action.result.data
      });

    case actions.GET_CONNECT_VERIFICATION_FIELDS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        fetchingStripeConnectVerificationFields: false
      });

    case actions.CREATE_STRIPE_BANK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        createdStripeBankAccount: action.result
      });

    case actions.LOAD_COMMISSION_ACCOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCommissionAccount: true
      });

    case actions.LOAD_COMMISSION_ACCOUNT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCommissionAccount: false,
        commissionsAccount: action.result.data
      });

    case actions.LOAD_COMMISSION_ACCOUNT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCommissionAccount: false,
        commissionsAccount: null,
        commissionsAccountError: action === null || action === void 0 ? void 0 : (_action$error = action.error) === null || _action$error === void 0 ? void 0 : (_action$error$error = _action$error.error) === null || _action$error$error === void 0 ? void 0 : _action$error$error.message
      });

    case actions.CREATE_COMMISSION_ACCOUNT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        commissionsAccount: action.result.data
      });

    case actions.LIST_ACCOUNT_TRANFERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAccountTransfers: true
      });

    case actions.LIST_ACCOUNT_TRANFERS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        connectAccountTransfers: action.result.data,
        loadingAccountTransfers: false
      });

    case actions.LIST_ACCOUNT_TRANFERS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAccountTransfers: false
      });

    case actions.GET_CONNECT_ACCOUNT_BALANCE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        commissionsBalance: action.result.data
      });

    case actions.GET_ORG_OWNER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        organizationOwnerUser: action.result.data
      });

    case actions.GET_ORG_AD_SPEND_SUMMARY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        adSpendHeartbeat: _objectSpread(_objectSpread({}, state.adSpendHeartbeat), {}, _defineProperty({}, action.orgId, action.result.data))
      });

    case actions.CLEAR_BILLING_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        billingError: null
      });

    case actions.GET_FB_AD_CONFIG:
      return _objectSpread({}, state);

    case actions.GET_FB_AD_CONFIG_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        fbAdConfig: action.result.data,
        fbAdConfigError: null
      });

    case actions.GET_FB_AD_CONFIG_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        fbAdConfigError: action.error.error
      });

    case actions.UPSERT_FB_AD_CONFIG_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        fbAdConfig: action.result.data,
        fbAdConfigError: null
      });

    case actions.GET_PARTNER_DEFAULT_PAYMENT_METHOD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        partnerBillingDetails: action.result.data
      });

    case actions.INITIAL_SUBSCRIPTION_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        billingError: action.error && action.error.error ? action.error.error.message : "Unable to charge your account",
        checkoutInProgress: false
      });

    case actions.INITIAL_SUBSCRIPTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        billingError: "",
        checkoutInProgress: true
      });

    default:
      return state;
  }
}