import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import moment from 'moment';
import { snakeCase } from 'lodash';
import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth'; // this is from billing

var CHANGE_ADS_PLAN_SUCCESS = 'likeablelocal/billing/CHANGE_ADS_PLAN_SUCCESS';
var ns = 'likeablelocal/organization';
var CLEAR_ORG_BILLING_CONFIG = "".concat(ns, "/CLEAR_ORG_BILLING_CONFIG");
var LOAD_CURRENT = "".concat(ns, "/LOAD_CURRENT");
var TAB_ADDED = "".concat(ns, "/TAB_ADDED");
var TAB_REMOVED = "".concat(ns, "/TAB_REMOVED");
var GET_SOCIAL_ACCOUNTS = "".concat(ns, "/GET_SOCIAL_ACCOUNTS");
var GET_SOCIAL_ACCOUNTS_SUCCESS = "".concat(ns, "/GET_SOCIAL_ACCOUNTS_SUCCESS");
var GET_SOCIAL_ACCOUNTS_FAIL = "".concat(ns, "/GET_SOCIAL_ACCOUNTS_FAIL");
var GET_ORG_BILLING_CONFIG = "".concat(ns, "/GET_ORG_BILLING_CONFIG");
var GET_ORG_BILLING_CONFIG_SUCCESS = "".concat(ns, "/GET_ORG_BILLING_CONFIG_SUCCESS");
var GET_ORG_BILLING_CONFIG_FAIL = "".concat(ns, "/GET_ORG_BILLING_CONFIG_FAIL");
var LOAD_POST_STATS = "".concat(ns, "/schedule/LOAD_POST_STATS");
var LOAD_POST_STATS_SUCCESS = "".concat(ns, "/schedule/LOAD_POST_STATS_SUCCESS");
var LOAD_POST_STATS_FAIL = "".concat(ns, "/schedule/LOAD_POST_STATS_FAIL");
var FETCH_ORGANIZATION = "".concat(ns, "/FETCH_ORGANIZATION");
var FETCH_ORGANIZATION_SUCCESS = "".concat(ns, "/FETCH_ORGANIZATION_SUCCESS");
var FETCH_ORGANIZATION_FAIL = "".concat(ns, "/FETCH_ORGANIZATION_FAIL");
var GET_ORG_USERS = "".concat(ns, "/GET_ORG_USERS");
var GET_ORG_USERS_SUCCESS = "".concat(ns, "/GET_ORG_USERS_SUCCESS");
var GET_ORG_USERS_FAIL = "".concat(ns, "/GET_ORG_USERS_FAIL");
var SET_USER_STATUS_SELECTION = "".concat(ns, "/SET_USER_STATUS_SELECTION");
var SAVE_ORG_STATE = "".concat(ns, "/SAVE_ORG_STATE");
var PARSE_LINK = 'likeablelocal/organization/PARSE_LINK';
var PARSE_LINK_SUCCESS = 'likeablelocal/organization/PARSE_LINK_SUCCESS';
var PARSE_LINK_FAIL = 'likeablelocal/organization/PARSE_LINK_FAIL';
var CLEAR_SAVED_ORG_STATE = "".concat(ns, "/CLEAR_SAVED_ORG_STATE");
var SAVE_TIMEZONE = "".concat(ns, "/SAVE_TIMEZONE");
export var SAVE_TIMEZONE_SUCCESS = "".concat(ns, "/SAVE_TIMEZONE_SUCCESS");
var SAVE_TIMEZONE_FAIL = "".concat(ns, "/SAVE_TIMEZONE_FAIL");
var GET_ORG_USER_DETAIL = "".concat(ns, "/GET_ORG_USER_DETAIL");
var GET_ORG_USER_DETAIL_SUCCESS = "".concat(ns, "/GET_ORG_USER_DETAIL_SUCCESS");
var GET_ORG_USER_DETAIL_FAIL = "".concat(ns, "/GET_ORG_USER_DETAIL_FAIL");
var FETCH_ORG_PRIVACY_POLICY = "".concat(ns, "/FETCH_ORG_PRIVACY_POLICY");
var FETCH_ORG_PRIVACY_POLICY_SUCCESS = "".concat(ns, "/FETCH_ORG_PRIVACY_POLICY_SUCCESS");
var FETCH_ORG_PRIVACY_POLICY_FAIL = "".concat(ns, "/FETCH_ORG_PRIVACY_POLICY_FAIL");
var GET_ORG_NOTIFICATION_SUBS = "".concat(ns, "/GET_ORG_NOTIFICATION_SUBS");
var GET_ORG_NOTIFICATION_SUBS_SUCCESS = "".concat(ns, "/GET_ORG_NOTIFICATION_SUBS_SUCCESS");
var GET_ORG_NOTIFICATION_SUBS_FAIL = "".concat(ns, "/GET_ORG_NOTIFICATION_SUBS_FAIL");
var SET_ORG_NOTIFICATION_SUB = "".concat(ns, "/SET_ORG_NOTIFICATION_SUB");
var SET_ORG_NOTIFICATION_SUB_SUCCESS = "".concat(ns, "/SET_ORG_NOTIFICATION_SUB_SUCCESS");
var SET_ORG_NOTIFICATION_SUB_FAIL = "".concat(ns, "/SET_ORG_NOTIFICATION_SUB_FAIL");
var GET_ORG_SOCIAL_ACCOUNT_FEATURES = "".concat(ns, "/GET_ORG_SOCIAL_ACCOUNT_FEATURES");
var GET_ORG_SOCIAL_ACCOUNT_FEATURES_SUCCESS = "".concat(ns, "/GET_ORG_SOCIAL_ACCOUNT_FEATURES_SUCCESS");
var GET_ORG_SOCIAL_ACCOUNT_FEATURES_FAIL = "".concat(ns, "/GET_ORG_SOCIAL_ACCOUNT_FEATURES_FAIL");
var SUB_ACCOUNT_TO_FB_WEBHOOKS = "".concat(ns, "/SUB_ACCOUNT_TO_FB_WEBHOOKS");
var SUB_ACCOUNT_TO_FB_WEBHOOKS_SUCCESS = "".concat(ns, "/SUB_ACCOUNT_TO_FB_WEBHOOKS_SUCCESS");
var SUB_ACCOUNT_TO_FB_WEBHOOKS_FAIL = "".concat(ns, "/SUB_ACCOUNT_TO_FB_WEBHOOKS_FAIL");
var DELETE_LIVE_POST = "".concat(ns, "/DELETE_LIVE_POST");
var DELETE_LIVE_POST_SUCCESS = "".concat(ns, "/DELETE_LIVE_POST_SUCCESS");
var DELETE_LIVE_POST_FAIL = "".concat(ns, "/DELETE_LIVE_POST_FAIL");
var GET_NOTIFIABLE_CONTACTS = "".concat(ns, "/GET_NOTIFIABLE_CONTACTS");
var GET_NOTIFIABLE_CONTACTS_SUCCESS = "".concat(ns, "/GET_NOTIFIABLE_CONTACTS_SUCCESS");
var GET_NOTIFIABLE_CONTACTS_FAIL = "".concat(ns, "/GET_NOTIFIABLE_CONTACTS_FAIL");
var UPSERT_POSTREMINDER_SUB = "".concat(ns, "/UPSERT_POSTREMINDER_SUB");
var UPSERT_POSTREMINDER_SUB_SUCCESS = "".concat(ns, "/UPSERT_POSTREMINDER_SUB_SUCCESS");
var UPSERT_POSTREMINDER_SUB_FAIL = "".concat(ns, "/UPSERT_POSTREMINDER_SUB_FAIL");
var UPSERT_AVAILABLE_FEATURE = "".concat(ns, "/UPSERT_AVAILABLE_FEATURE");
export var UPSERT_AVAILABLE_FEATURE_SUCCESS = "".concat(ns, "/UPSERT_AVAILABLE_FEATURE_SUCCESS");
var UPSERT_AVAILABLE_FEATURE_FAIL = "".concat(ns, "/UPSERT_AVAILABLE_FEATURE_FAIL");
var initialState = {
  selectedOrgTabs: [],
  selectedOrgDetails: {},
  loadingPostStatsIds: [],
  loadingPostsAnywhere: false,
  savingTimezone: false,
  current: {
    loadingUsers: true,
    users: [],
    userListViewStatus: 'ACTIVE',
    allPartners: [],
    gettingAllPartners: false,
    partnerClients: [],
    gettingPartnerClients: false,
    orgBillingConfig: null,
    orgBillingConfigById: {},
    gettingOrgBillingConfig: false
  }
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case CHANGE_ADS_PLAN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        orgBillingConfig: _objectSpread(_objectSpread({}, state.orgBillingConfig), {}, {
          adsBudget: action.result.data.AdsBudget
        }),
        orgBillingConfigById: _objectSpread(_objectSpread({}, state.orgBillingConfigById), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state.orgBillingConfigById[action.orgId]), {}, {
          adsBudget: action.result.data.AdsBudget
        })))
      });

    case SET_USER_STATUS_SELECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        userListViewStatus: action.status
      });

    case CLEAR_ORG_BILLING_CONFIG:
      return _objectSpread(_objectSpread({}, state), {}, {
        orgBillingConfig: null
      });

    case GET_ORG_USERS:
      if (action.organizationId != state.current.id) {
        return state;
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        loadingUsers: true
      });

    case GET_ORG_USERS_SUCCESS:
      if (action.organizationId != state.current.id) {
        return state;
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        loadingUsers: false,
        users: action.result.data
      });

    case GET_ORG_USERS_FAIL:
      if (action.organizationId != state.current.id) {
        return state;
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        loadingUsers: false
      });

    case GET_ORG_BILLING_CONFIG:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingOrgBillingConfig: true
      });

    case GET_ORG_BILLING_CONFIG_SUCCESS:
      return function () {
        var orgConfigById = state.orgBillingConfigById ? _objectSpread({}, state.orgBillingConfigById) : {};
        var orgConfigForId = orgConfigById && orgConfigById[action.orgId] ? orgConfigById[action.orgId] : {};
        return _objectSpread(_objectSpread({}, state), {}, {
          gettingOrgBillingConfig: false,
          orgBillingConfig: action.result.data,
          orgBillingConfigById: _objectSpread(_objectSpread({}, orgConfigById), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, orgConfigForId), action.result.data)))
        });
      }();

    case GET_ORG_BILLING_CONFIG_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingOrgBillingConfig: false
      });

    case LOAD_CURRENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        current: _objectSpread(_objectSpread({}, state.current), action.organization)
      });

    case TAB_ADDED:
      // console.log(('added tab,': action.organization));
      var selectedOrgTabs = state.selectedOrgTabs.filter(function (org) {
        return org && org.id !== action.organization.id;
      }).concat({
        id: action.organization.id,
        name: action.organization.name
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedOrgTabs: selectedOrgTabs
      });

    case TAB_REMOVED:
      // Get an updated list of the organizations that weren't deleted
      var remainingOrganizations = state.selectedOrgTabs.filter(function (org) {
        return org.id !== action.organizationId;
      }); // Get the index of the selected organization if we need to change the selected org

      var selectedOrgTabIndex = 0;
      state.selectedOrgTabs.forEach(function (org, index) {
        if (org.id === state.current.id) {
          selectedOrgTabIndex = index;
        }
      }); // Update the current organization if it was the removed organization

      var current = remainingOrganizations.filter(function (org) {
        return org.id === state.current.id;
      }).length ? state.current : remainingOrganizations[Math.min(remainingOrganizations.length - 1, selectedOrgTabIndex)];
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedOrgTabs: remainingOrganizations,
        current: current
      });

    case PARSE_LINK:
      return _objectSpread(_objectSpread({}, state), {}, {
        parsingLink: true
      });

    case PARSE_LINK_SUCCESS:
      var post = action.post;
      post.linkUrl = action.result.data.url;
      post.imageUrl = action.result.data.imageUrl;
      post.displayMessage = post.message ? post.message : action.result.data.description;
      return _objectSpread(_objectSpread({}, state), {}, {
        parsingLink: false,
        current: _objectSpread({}, state.current)
      });

    case PARSE_LINK_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        parsingLink: false
      });

    case GET_SOCIAL_ACCOUNTS:
      if (action.organizationId != state.current.id) {
        return state;
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSocialAccounts: true
      });

    case GET_SOCIAL_ACCOUNTS_SUCCESS:
      if ("".concat(action.orgId) !== "".concat(state.current.id)) {
        return state;
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSocialAccounts: false,
        current: _objectSpread(_objectSpread({}, state.current), {}, {
          SocialAccounts: _toConsumableArray(action.result.data)
        })
      });

    case GET_SOCIAL_ACCOUNTS_FAIL:
      if (action.organizationId != state.current.id) {
        return state;
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSocialAccounts: false,
        loadingSocialAccountsError: action.error,
        current: _objectSpread(_objectSpread({}, state.current), {}, {
          SocialAccounts: []
        })
      });

    case LOAD_POST_STATS:
      var existingIds = state.loadingPostStatsIds || [];
      var newloadingPostStatsIds = [].concat(_toConsumableArray(existingIds), [action.postId]);
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPostStatsIds: newloadingPostStatsIds
      });

    case LOAD_POST_STATS_SUCCESS:
      var _action$result$data = action.result.data,
          statsPostId = _action$result$data.postId,
          stats = _action$result$data.stats;
      var successNewPostIds = state.loadingPostStatsIds.filter(function (loadingPostId) {
        return loadingPostId !== statsPostId;
      });
      /*      const currentPosts = state.current.schedule.posts.slice();
      const postToUpdate = currentPosts.find(post => post.post.id === statsPostId);
      if (postToUpdate) {
        postToUpdate.post.stats = stats;
      }
      */

      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPostStatsIds: successNewPostIds,
        current: _objectSpread({}, state.current)
      });

    case LOAD_POST_STATS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPostStatsIds: []
      });

    case SAVE_ORG_STATE:
      var updatedOrg = _objectSpread({}, state.selectedOrgTabs.find(function (o) {
        return o.id === action.orgId;
      }));

      var updatedOrgIndex = state.selectedOrgTabs.findIndex(function (o) {
        return o.id === action.orgId;
      });

      if (action.savedStateTrees) {
        updatedOrg.savedState = action.savedStateTrees;
      }

      if (action.path) {
        updatedOrg.savedPath = action.path;
      }

      var newSelectedOrgs = _toConsumableArray(state.selectedOrgTabs);

      newSelectedOrgs[updatedOrgIndex] = updatedOrg;
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedOrgTabs: newSelectedOrgs
      });

    case CLEAR_SAVED_ORG_STATE:
      var clearUpdatedOrg = _objectSpread({}, state.selectedOrgTabs.find(function (o) {
        return o.id === action.orgId;
      }));

      var clearUpdatedOrgIndex = state.selectedOrgTabs.findIndex(function (o) {
        return o.id === action.orgId;
      });

      if (action.savedStateTrees) {
        clearUpdatedOrg.savedState = action.savedStateTrees;
      }

      var clearNewSelectedOrgs = _toConsumableArray(state.selectedOrgTabs);

      clearNewSelectedOrgs[clearUpdatedOrgIndex] = clearUpdatedOrg;
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedOrgTabs: clearNewSelectedOrgs
      });

    case SAVE_TIMEZONE:
      return _objectSpread(_objectSpread({}, state), {}, {
        savingTimezone: true
      });

    case SAVE_TIMEZONE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        savingTimezone: false,
        current: _objectSpread(_objectSpread({}, state.current), {}, {
          OrganizationTimezone: action.result.data
        })
      });

    case SAVE_TIMEZONE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        savingTimezone: false
      });

    case FETCH_ORGANIZATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        fetchingOrganization: true
      });

    case FETCH_ORGANIZATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        fetchingOrganization: false,
        selectedOrgDetails: _objectSpread(_objectSpread({}, state.selectedOrgDetails), {}, _defineProperty({}, action.orgId, action.result.data)),
        selectedOrgTabs: !state.selectedOrgTabs.length ? [{
          id: action.result.data.id,
          name: action.result.data.name
        }] : state.selectedOrgTabs
      });

    case FETCH_ORGANIZATION_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        fetchingOrganization: false
      });

    case GET_ORG_USER_DETAIL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        orgUserDetail: action.result.data
      });

    case SUB_ACCOUNT_TO_FB_WEBHOOKS:
    case GET_ORG_SOCIAL_ACCOUNT_FEATURES:
      return function () {
        if ("".concat(action.orgId) !== "".concat(state.current.id)) {
          return state;
        }

        var saIdx = state.current.SocialAccounts.findIndex(function (sa) {
          return "".concat(sa.id) === "".concat(action.socialAccountId);
        });

        if (saIdx === -1) {
          return state;
        }

        var accounts = _toConsumableArray(state.current.SocialAccounts);

        accounts[saIdx] = _objectSpread(_objectSpread({}, state.current.SocialAccounts[saIdx]), {}, {
          features: {
            loading: true
          }
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loadingSocialAccounts: false,
          current: _objectSpread(_objectSpread({}, state.current), {}, {
            SocialAccounts: accounts
          })
        });
      }();

    case SUB_ACCOUNT_TO_FB_WEBHOOKS_FAIL:
    case GET_ORG_SOCIAL_ACCOUNT_FEATURES_FAIL:
      return function () {
        if ("".concat(action.orgId) !== "".concat(state.current.id)) {
          return state;
        }

        var saIdx = state.current.SocialAccounts.findIndex(function (sa) {
          return "".concat(sa.id) === "".concat(action.socialAccountId);
        });

        if (saIdx === -1) {
          return state;
        }

        var accounts = _toConsumableArray(state.current.SocialAccounts);

        accounts[saIdx] = _objectSpread(_objectSpread({}, state.current.SocialAccounts[saIdx]), {}, {
          features: {
            loading: false
          }
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loadingSocialAccounts: false,
          current: _objectSpread(_objectSpread({}, state.current), {}, {
            SocialAccounts: accounts
          })
        });
      }();

    case SUB_ACCOUNT_TO_FB_WEBHOOKS_SUCCESS:
    case GET_ORG_SOCIAL_ACCOUNT_FEATURES_SUCCESS:
      return function () {
        if ("".concat(action.orgId) !== "".concat(state.current.id)) {
          return state;
        }

        var saIdx = state.current.SocialAccounts.findIndex(function (sa) {
          return "".concat(sa.id) === "".concat(action.socialAccountId);
        });

        if (saIdx === -1) {
          return state;
        }

        var accounts = _toConsumableArray(state.current.SocialAccounts);

        accounts[saIdx] = _objectSpread(_objectSpread({}, state.current.SocialAccounts[saIdx]), {}, {
          features: _objectSpread(_objectSpread({}, action.result.data), {}, {
            loading: false
          })
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loadingSocialAccounts: false,
          current: _objectSpread(_objectSpread({}, state.current), {}, {
            SocialAccounts: accounts
          })
        });
      }();

    case GET_NOTIFIABLE_CONTACTS_SUCCESS:
      return function () {
        if ("".concat(action.orgId) !== "".concat(state.current.id)) {
          return state;
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          current: _objectSpread(_objectSpread({}, state.current), {}, {
            notifiableContacts: action.result.data
          })
        });
      }();

    default:
      return state;
  }
}
export function setCurrentOrganization(organization) {
  return {
    type: LOAD_CURRENT,
    organization: organization
  };
}
export function addOrganizationTab(organization) {
  return {
    type: TAB_ADDED,
    organization: organization
  };
}
export function removeOrganizationTab(organizationId) {
  return {
    type: TAB_REMOVED,
    organizationId: organizationId
  };
}
export function getSocialAccounts(organizationId) {
  var url = "/org/".concat(organizationId, "/socialAccounts");
  return {
    types: [GET_SOCIAL_ACCOUNTS, GET_SOCIAL_ACCOUNTS_SUCCESS, GET_SOCIAL_ACCOUNTS_FAIL],
    promise: function promise(client) {
      return client.get(url);
    },
    orgId: organizationId
  };
}
export function loadPostStats(postId) {
  var url = "/post/".concat(postId, "/basicStats");
  return {
    types: [LOAD_POST_STATS, LOAD_POST_STATS_SUCCESS, LOAD_POST_STATS_FAIL],
    promise: function promise(client) {
      return client.get(url);
    },
    postId: postId
  };
}
export function fetchOrganization(orgId) {
  return {
    types: [FETCH_ORGANIZATION, FETCH_ORGANIZATION_SUCCESS, FETCH_ORGANIZATION_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId));
    },
    orgId: orgId
  };
}
export function fetchOrganizationHostedPrivacyPolicy(orgId) {
  return {
    types: [FETCH_ORG_PRIVACY_POLICY, FETCH_ORG_PRIVACY_POLICY_SUCCESS, FETCH_ORG_PRIVACY_POLICY_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/hostedPrivacyPolicy"));
    },
    orgId: orgId
  };
}
export function getOrgUsers(orgId) {
  return {
    types: [GET_ORG_USERS, GET_ORG_USERS_SUCCESS, GET_ORG_USERS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/users"));
    },
    organizationId: orgId
  };
}
export function saveOrgState(orgId, path, savedStateTrees) {
  return {
    type: SAVE_ORG_STATE,
    savedStateTrees: savedStateTrees,
    path: path,
    orgId: orgId
  };
}
export function clearSavedOrgState() {
  return {
    type: CLEAR_SAVED_ORG_STATE
  };
}
export function setUserStatusSelection(status) {
  return {
    type: SET_USER_STATUS_SELECTION,
    status: status
  };
}
export function parseLink(post) {
  return {
    types: [PARSE_LINK, PARSE_LINK_SUCCESS, PARSE_LINK_FAIL],
    post: post,
    promise: function promise(client) {
      return client.post('/parseLink', {
        data: {
          link: post.linkUrl
        }
      });
    }
  };
}
export function saveTimezone(orgId, timezoneName) {
  return {
    types: [SAVE_TIMEZONE, SAVE_TIMEZONE_SUCCESS, SAVE_TIMEZONE_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/timezones"), {
        data: {
          name: timezoneName
        }
      });
    }
  };
}
export function getOrganizationUserDetails(orgId, userId) {
  return {
    types: [GET_ORG_USER_DETAIL, GET_ORG_USER_DETAIL_SUCCESS, GET_ORG_USER_DETAIL_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/user/").concat(userId));
    }
  };
}
export function getOrgBillingConfig(orgId) {
  return {
    types: [GET_ORG_BILLING_CONFIG, GET_ORG_BILLING_CONFIG_SUCCESS, GET_ORG_BILLING_CONFIG_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/orgBillingConfig"));
    },
    orgId: orgId
  };
}
export function clearOrgBillingConfig() {
  return {
    type: CLEAR_ORG_BILLING_CONFIG
  };
}
export function getOrgNotificationSubscriptions(orgId) {
  return {
    types: [GET_ORG_NOTIFICATION_SUBS, GET_ORG_NOTIFICATION_SUBS_SUCCESS, GET_ORG_NOTIFICATION_SUBS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/notificationSubscriptions"));
    },
    orgId: orgId
  };
}
export function setOrgNotificationSubscription(orgId, notificationType, enabled) {
  return {
    types: [SET_ORG_NOTIFICATION_SUB, SET_ORG_NOTIFICATION_SUB_SUCCESS, SET_ORG_NOTIFICATION_SUB_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/notification/").concat(notificationType), {
        data: {
          enabled: enabled
        }
      });
    },
    orgId: orgId
  };
}
export function getOrgSocialAccountFeatures(orgId, socialAccountId) {
  return {
    types: [GET_ORG_SOCIAL_ACCOUNT_FEATURES, GET_ORG_SOCIAL_ACCOUNT_FEATURES_SUCCESS, GET_ORG_SOCIAL_ACCOUNT_FEATURES_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/socialAccount/").concat(socialAccountId, "/features"));
    },
    orgId: orgId,
    socialAccountId: socialAccountId
  };
}
export function subscribeOrgSocialAccountToWebhooks(orgId, socialAccountId) {
  return {
    types: [SUB_ACCOUNT_TO_FB_WEBHOOKS, SUB_ACCOUNT_TO_FB_WEBHOOKS_SUCCESS, SUB_ACCOUNT_TO_FB_WEBHOOKS_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/socialAccount/").concat(socialAccountId, "/webhookSubscribe"));
    },
    orgId: orgId,
    socialAccountId: socialAccountId
  };
}
export function deleteLivePost(orgId, postId, socialAccountId) {
  return {
    types: [DELETE_LIVE_POST, DELETE_LIVE_POST_SUCCESS, DELETE_LIVE_POST_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/deleteLivePost"), {
        data: {
          postId: postId,
          socialAccountId: socialAccountId
        }
      });
    },
    orgId: orgId,
    socialAccountId: socialAccountId,
    postId: postId
  };
}
export function getNotifiableContacts(orgId) {
  return {
    types: [GET_NOTIFIABLE_CONTACTS, GET_NOTIFIABLE_CONTACTS_SUCCESS, GET_NOTIFIABLE_CONTACTS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/notifiableContacts"));
    },
    orgId: orgId
  };
}
export function upsertPostReminderSubscription(orgId, postReminderId, users) {
  return {
    types: [UPSERT_POSTREMINDER_SUB, UPSERT_POSTREMINDER_SUB_SUCCESS, UPSERT_POSTREMINDER_SUB_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/posts/reminder/").concat(postReminderId, "/subscriptions"), {
        data: {
          users: users
        }
      });
    },
    orgId: orgId,
    postReminderId: postReminderId
  };
}
export function upsertAvailableFeatures(orgId, feature, state) {
  return {
    types: [UPSERT_AVAILABLE_FEATURE, UPSERT_AVAILABLE_FEATURE_SUCCESS, UPSERT_AVAILABLE_FEATURE_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/availableFeatures"), {
        data: {
          feature: feature,
          state: state
        }
      });
    },
    orgId: orgId,
    feature: feature,
    state: state
  };
}