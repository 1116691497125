import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as _ from 'lodash';
var ns = 'likeablelocal/audience';
var QUERY_LOCATIONS = "".concat(ns, "/QUERY_LOCATIONS");
var QUERY_LOCATIONS_SUCCESS = "".concat(ns, "/QUERY_LOCATIONS_SUCCESS");
var QUERY_LOCATIONS_FAIL = "".concat(ns, "/QUERY_LOCATIONS_FAIL");
var UPDATE_AUDIENCE = "".concat(ns, "/UPDATE_AUDIENCE");
export var UPDATE_AUDIENCE_SUCCESS = "".concat(ns, "/UPDATE_AUDIENCE_SUCCESS");
var UPDATE_AUDIENCE_FAIL = "".concat(ns, "/UPDATE_AUDIENCE_FAIL");
var GET_AUDIENCE = "".concat(ns, "/GET_AUDIENCE");
var GET_AUDIENCE_SUCCESS = "".concat(ns, "/GET_AUDIENCE_SUCCESS");
var GET_AUDIENCE_FAIL = "".concat(ns, "/GET_AUDIENCE_FAIL");
var CREATE_AUDIENCE = "".concat(ns, "/CREATE_AUDIENCE");
export var CREATE_AUDIENCE_SUCCESS = "".concat(ns, "/CREATE_AUDIENCE_SUCCESS");
var CREATE_AUDIENCE_FAIL = "".concat(ns, "/CREATE_AUDIENCE_FAIL");
var GET_REACH_ESTIMATE = "".concat(ns, "/GET_REACH_ESTIMATE");
var GET_REACH_ESTIMATE_SUCCESS = "".concat(ns, "/GET_REACH_ESTIMATE_SUCCESS");
var GET_REACH_ESTIMATE_FAIL = "".concat(ns, "/GET_REACH_ESTIMATE_FAIL");
var ADD_LOCATION = "".concat(ns, "/ADD_LOCATION");
var REMOVE_FLEXIBLE_SPEC = "".concat(ns, "/REMOVE_FLEXIBLE_SPEC");
var CHANGE_CONNECTION_VALUE = "".concat(ns, "/CHANGE_CONNECTION_VALUE");
var CHANGE_CONFIG_DATA_VALUE = "".concat(ns, "/CHANGE_CONFIG_DATA_VALUE");
var CHANGE_GENDER_VALUE = "".concat(ns, "/CHANGE_GENDER_VALUE");
var REMOVE_EXCLUSION_ITEM = "".concat(ns, "/REMOVE_EXCLUSION_ITEM");
var REMOVE_LOCATION_ITEM = "".concat(ns, "/REMOVE_LOCATION_ITEM");
var REMOVE_SPEC_ITEM = "".concat(ns, "/REMOVE_SPEC_ITEM");
var ADD_FLEX_SPEC = "".concat(ns, "/ADD_FLEX_SPEC");
var CLEAR_AUDIENCE = "".concat(ns, "/CLEAR_AUDIENCE");
var POPULATE_AUDIENCE = "".concat(ns, "/POPULATE_AUDIENCE");
var CHANGE_FLEXIBLE_SPEC_VALUE = "".concat(ns, "/CHANGE_FLEXIBLE_SPEC_VALUE");
var CHANGE_AUDIENCE_VALUE = "".concat(ns, "/CHANGE_AUDIENCE_VALUE");
var UPDATE_REACH_ESTIMATE = "".concat(ns, "/UPDATE_REACH_ESTIMATE");
var UPDATE_REACH_ESTIMATE_SUCCESS = "".concat(ns, "/UPDATE_REACH_ESTIMATE_SUCCESS");
var UPDATE_REACH_ESTIMATE_FAIL = "".concat(ns, "/UPDATE_REACH_ESTIMATE_FAIL");
var DUPLICATE_AUDIENCE = "".concat(ns, "/DUPLICATE_AUDIENCE");
var DUPLICATE_AUDIENCE_SUCCESS = "".concat(ns, "/DUPLICATE_AUDIENCE_SUCCESS");
var DUPLICATE_AUDIENCE_FAIL = "".concat(ns, "/DUPLICATE_AUDIENCE_FAIL");
var flexibleSpec = {
  connections: [],
  friends_of_connections: [],
  interests: [],
  user_adclusters: [],
  behaviors: [],
  college_years: [],
  education_majors: [],
  education_schools: [],
  education_statuses: [],
  family_statuses: [],
  generation: [],
  home_type: [],
  home_ownership: [],
  home_value: [],
  household_composition: [],
  interested_in: [],
  income: [],
  industries: [],
  life_events: [],
  moms: [],
  net_worth: [],
  office_type: [],
  politics: [],
  work_positions: [],
  work_employers: []
};
var initialState = {
  saveError: null,
  customAudiencesError: null,
  creatingAudience: false,
  updatingAudience: false,
  gettingAudience: false,
  id: null,
  name: '',
  FacebookTargetingConfigId: null,
  OrganizationId: null,
  FacebookTargetingConfig: {
    id: null,
    data: {
      excluded_custom_audiences: [],
      custom_audiences: [],
      user_device: [],
      excluded_connections: [],
      excluded_user_device: [],
      genders: [1, 2],
      device_platforms: ['mobile', 'desktop'],
      facebook_positions: ['feed'],
      instagram_positions: [],
      audience_network_positions: [],
      messenger_positions: [],
      publisher_platforms: ['facebook'],
      geo_locations: {
        cities: [],
        countries: [],
        regions: [],
        zips: []
      },
      excluded_geo_locations: {
        cities: [],
        countries: [],
        regions: [],
        zips: []
      },
      age_min: 21,
      age_max: 65,
      flexible_spec: [_.cloneDeep(flexibleSpec)],
      exclusions: {}
    }
  },
  gettingReachEstimate: false,
  reachEstimate: null,
  targetingSentences: null
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  function pluralizeLocationType(type) {
    switch (type) {
      case 'city':
        return 'cities';

      case 'zip':
        return 'zips';

      case 'country':
        return 'countries';

      case 'region':
        return 'regions';
    }
  }

  switch (action.type) {
    case REMOVE_FLEXIBLE_SPEC:
      return _objectSpread(_objectSpread({}, state), {}, {
        FacebookTargetingConfig: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig), {}, {
          data: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig.data), {}, {
            flexible_spec: state.FacebookTargetingConfig.data.flexible_spec.filter(function (spec, index) {
              return index !== action.index;
            })
          })
        })
      });

    case GET_AUDIENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAudience: true
      });

    case GET_AUDIENCE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isEdit: true
      }, action.result.data);

    case GET_AUDIENCE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAudience: false
      });

    case UPDATE_AUDIENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        updatingAudience: true
      });

    case UPDATE_AUDIENCE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        updatingAudience: false
      });

    case UPDATE_AUDIENCE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        updatingAudience: false
      });

    case CREATE_AUDIENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        creatingAudience: true
      });

    case CREATE_AUDIENCE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        creatingAudience: false
      });

    case CREATE_AUDIENCE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        creatingAudience: false
      });

    case GET_REACH_ESTIMATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingReachEstimate: true
      });

    case GET_REACH_ESTIMATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingReachEstimate: false,
        reachEstimate: action.result.data.reachEstimate,
        targetingSentences: action.result.data.targetingSentences
      });

    case GET_REACH_ESTIMATE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingReachEstimate: false
      });

    case POPULATE_AUDIENCE:
      console.log('in POPULATE_AUDIENCE');
      console.log('in POPULATE_AUDIENCE');
      console.log('in POPULATE_AUDIENCE');
      console.log(action.audience);
      return Object.assign({
        isEdit: true
      }, action.audience);

    case ADD_LOCATION:
      {
        var typeName = pluralizeLocationType(action.locationType);
        return _objectSpread(_objectSpread({}, state), {}, {
          FacebookTargetingConfig: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig), {}, {
            data: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig.data), {}, _defineProperty({}, action.geoType, _objectSpread(_objectSpread({}, state.FacebookTargetingConfig.data[action.geoType]), {}, _defineProperty({}, typeName, [].concat(_toConsumableArray(state.FacebookTargetingConfig.data[action.geoType][typeName]), [action.location])))))
          })
        });
      }

    case CHANGE_GENDER_VALUE:
      return _objectSpread(_objectSpread({}, state), {}, {
        FacebookTargetingConfig: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig), {}, {
          data: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig.data), {}, {
            genders: action.value === 'Everyone' ? [1, 2] : action.value === 'Men' ? [1] : [2]
          })
        })
      });

    case CHANGE_CONNECTION_VALUE:
      var target = [{
        id: action.facebookPageId
      }];
      var connections = action.value === 'fans' ? target : [];
      var friends_of_connections = action.value === 'friends+fans' ? target : [];
      var excluded_connections = action.value === 'nofans' ? target : [];
      return _objectSpread(_objectSpread({}, state), {}, {
        FacebookTargetingConfig: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig), {}, {
          data: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig.data), {}, {
            excluded_connections: excluded_connections,
            flexible_spec: state.FacebookTargetingConfig.data.flexible_spec.map(function (spec) {
              var specCopy = Object.assign({}, spec);
              specCopy.connections = connections;
              specCopy.friends_of_connections = friends_of_connections;
              return specCopy;
            })
          })
        })
      });

    case CHANGE_CONFIG_DATA_VALUE:
      return _objectSpread(_objectSpread({}, state), {}, {
        FacebookTargetingConfig: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig), {}, {
          data: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig.data), {}, _defineProperty({}, action.key, action.value))
        })
      });

    case CHANGE_FLEXIBLE_SPEC_VALUE:
      var flexible_spec = state.FacebookTargetingConfig.data.flexible_spec.map(function (s, index) {
        // don't mutate...
        var spec = Object.assign({}, s); // we only need to grab the flexSpec with matching index, also make sure newValue has id and name

        if (index === action.index && action.newValue.id && action.newValue.name) {
          var type = action.newValue.type; // get rid of the other fields or Facebook will error when we load the audiences later

          if (type === 'education_statuses' || type === 'relationship_statuses') {
            spec[type] = _.union(spec[type], [parseInt(action.newValue.id)]);
          } else {
            var strippedNewValue = {
              id: action.newValue.id,
              name: action.newValue.name
            }; // update spec, removing dups

            spec[type] = _.unionBy(spec[type], [strippedNewValue], 'id');
          }
        }

        return spec;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        FacebookTargetingConfig: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig), {}, {
          data: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig.data), {}, {
            flexible_spec: flexible_spec
          })
        })
      });

    case ADD_FLEX_SPEC:
      return _objectSpread(_objectSpread({}, state), {}, {
        FacebookTargetingConfig: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig), {}, {
          data: action.isExclusion ? _objectSpread(_objectSpread({}, state.FacebookTargetingConfig.data), {}, {
            exclusions: _.cloneDeep(flexibleSpec)
          }) : _objectSpread(_objectSpread({}, state.FacebookTargetingConfig.data), {}, {
            flexible_spec: [].concat(_toConsumableArray(state.FacebookTargetingConfig.data.flexible_spec), [_.cloneDeep(flexibleSpec)])
          })
        })
      });

    case REMOVE_SPEC_ITEM:
      // export function removeSpecItem(key, item, index) {
      var remove_flexible_spec = state.FacebookTargetingConfig.data.flexible_spec.map(function (spec, index) {
        if (index === action.index) {
          // filter out spec thing
          // we don't know the type, so we have to go over all of the arrays and check for the id
          // fresh copy to mutate
          var removedSpec = Object.assign({}, spec);
          Object.keys(removedSpec).forEach(function (specKey) {
            removedSpec[specKey] = removedSpec[specKey].filter(function (s) {
              if (action.item.id) {
                return s.id !== action.item.id;
              } // else there is no id, so it's a flat array of ids - not objects


              return parseInt(s) !== parseInt(action.item);
            });
          });
          spec = removedSpec;
        }

        return spec;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        FacebookTargetingConfig: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig), {}, {
          data: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig.data), {}, {
            flexible_spec: remove_flexible_spec
          })
        })
      });

    case REMOVE_EXCLUSION_ITEM:
      // fresh copy to mutate
      var removedExclusions = Object.assign({}, state.FacebookTargetingConfig.data.exclusions);
      Object.keys(removedExclusions).forEach(function (key) {
        removedExclusions[key] = removedExclusions[key].filter(function (s) {
          return s.id !== action.item.id;
        });
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        FacebookTargetingConfig: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig), {}, {
          data: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig.data), {}, {
            exclusions: removedExclusions
          })
        })
      });

    case REMOVE_LOCATION_ITEM:
      var pluralTypeName = typeof action.item === 'string' ? 'countries' : pluralizeLocationType(action.item.type);
      return _objectSpread(_objectSpread({}, state), {}, {
        FacebookTargetingConfig: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig), {}, {
          data: _objectSpread(_objectSpread({}, state.FacebookTargetingConfig.data), {}, _defineProperty({}, action.key, _objectSpread(_objectSpread({}, state.FacebookTargetingConfig.data[action.key]), {}, _defineProperty({}, pluralTypeName, state.FacebookTargetingConfig.data[action.key][pluralTypeName].filter(pluralTypeName === 'countries' ? function (i) {
            return i !== action.item;
          } : function (i) {
            return i.key !== action.item.key;
          })))))
        })
      });

    case CHANGE_AUDIENCE_VALUE:
      return _objectSpread(_objectSpread({}, state), action.keyValues);

    case UPDATE_REACH_ESTIMATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        reachEstimate: action.result.data
      });

    case CLEAR_AUDIENCE:
      return _.cloneDeep(initialState);

    default:
      return state;
  }
}
export function updateReachEstimate(adAccountId, data, orgId) {
  var url = "/facebook/adAccount/".concat(adAccountId, "/reachEstimate");
  return {
    types: [UPDATE_REACH_ESTIMATE, UPDATE_REACH_ESTIMATE_SUCCESS, UPDATE_REACH_ESTIMATE_FAIL],
    promise: function promise(client) {
      return client.post(url, data);
    },
    orgId: orgId
  };
}
export function changeAudienceValue(keyValues) {
  return {
    type: CHANGE_AUDIENCE_VALUE,
    keyValues: keyValues
  };
}
export function getTargetingInfo(adAccountId, type) {
  var url = "/facebook/ad/".concat(adAccountId, "/targetingbrowse/").concat(type);
  return {
    types: [TARGETING_BROWSE, TARGETING_BROWSE_SUCCESS, TARGETING_BROWSE_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function populateAudience(audience) {
  return {
    type: POPULATE_AUDIENCE,
    audience: audience
  };
}
export function changeFlexibleSpecValue(index, newValue) {
  return {
    type: CHANGE_FLEXIBLE_SPEC_VALUE,
    index: index,
    newValue: newValue
  };
}
export function addFlexibleSpec() {
  var isExclusion = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    type: ADD_FLEX_SPEC,
    isExclusion: isExclusion
  };
}
export function removeSpecItem(key, item, index) {
  return {
    type: REMOVE_SPEC_ITEM,
    key: key,
    item: item,
    index: index
  };
}
export function removeLocationItem(key, item) {
  return {
    type: REMOVE_LOCATION_ITEM,
    key: key,
    item: item
  };
}
export function removeExclusionItem(item) {
  return {
    type: REMOVE_EXCLUSION_ITEM,
    item: item
  };
}
export function changeGenderValue(value) {
  return {
    type: CHANGE_GENDER_VALUE,
    value: value
  };
}
export function changeConfigDataValue(key, value) {
  return {
    type: CHANGE_CONFIG_DATA_VALUE,
    key: key,
    value: value
  };
}
export function clearAudience() {
  return {
    type: CLEAR_AUDIENCE
  };
}
export function removeFlexibleSpecChunk(index) {
  return {
    type: REMOVE_FLEXIBLE_SPEC,
    index: index
  };
}
export function addLocation(geoType, location, locationType) {
  return {
    type: ADD_LOCATION,
    geoType: geoType,
    location: location,
    locationType: locationType
  };
}
export function changeConnectionValue(value, facebookPageId) {
  return {
    type: CHANGE_CONNECTION_VALUE,
    value: value,
    facebookPageId: facebookPageId
  };
}
export function getReachEstimate(orgId, adAccountId, targetingSpec) {
  var url = "/org/".concat(orgId, "/facebookAds/").concat(adAccountId, "/reachEstimate");
  return {
    types: [GET_REACH_ESTIMATE, GET_REACH_ESTIMATE_SUCCESS, GET_REACH_ESTIMATE_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: {
          targetingData: targetingSpec
        }
      });
    },
    orgId: orgId
  };
}
export function createAudience(organizationId, newAudienceName, targeting, reachEstimate, targetingSentences) {
  var url = "/org/".concat(organizationId, "/ads/createAudience");
  return {
    types: [CREATE_AUDIENCE, CREATE_AUDIENCE_SUCCESS, CREATE_AUDIENCE_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: {
          newAudienceName: newAudienceName,
          targeting: targeting
        }
      });
    },
    orgId: organizationId,
    reachEstimate: reachEstimate,
    targetingSentences: targetingSentences
  };
}
export function getAudience(fbTargetingConfigId) {
  var url = "/savedAudience/".concat(fbTargetingConfigId);
  return {
    types: [GET_AUDIENCE, GET_AUDIENCE_SUCCESS, GET_AUDIENCE_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function duplicateAudience(audienceId, orgId) {
  var url = "/org/".concat(orgId, "/savedAudience/duplicate");
  return {
    types: [DUPLICATE_AUDIENCE, DUPLICATE_AUDIENCE_SUCCESS, DUPLICATE_AUDIENCE_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: {
          audienceId: audienceId
        }
      });
    }
  };
}
export function updateAudience(organizationId, newAudienceName, targeting, targetingId, reachEstimate, targetingSentences) {
  var url = "/org/".concat(organizationId, "/ads/updateAudience/").concat(targetingId);
  return {
    types: [UPDATE_AUDIENCE, UPDATE_AUDIENCE_SUCCESS, UPDATE_AUDIENCE_FAIL],
    promise: function promise(client) {
      return client.put(url, {
        data: {
          newAudienceName: newAudienceName,
          targeting: targeting
        }
      });
    },
    orgId: organizationId,
    reachEstimate: reachEstimate,
    targetingSentences: targetingSentences
  };
}
export function queryLocations(query) {
  var url = "/facebook/ad/locations?q=".concat(encodeURIComponent(query));
  return {
    types: [QUERY_LOCATIONS, QUERY_LOCATIONS_SUCCESS, QUERY_LOCATIONS_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}