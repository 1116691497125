import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
var UPDATE_SETTINGS = 'likeablelocal/user/settings/UPDATE_SETTINGS';
var UPDATE_SETTINGS_SUCCESS = 'likeablelocal/user/settings/UPDATE_SETTINGS_SUCCESS';
var UPDATE_SETTINGS_FAIL = 'likeablelocal/user/settings/UPDATE_SETTINGS_FAIL';
var UPLOAD_AVATAR = 'likeablelocal/user/settings/UPLOAD_AVATAR';
var UPLOAD_AVATAR_SUCCESS = 'likeablelocal/user/settings/UPLOAD_AVATAR_SUCCESS';
var UPLOAD_AVATAR_FAIL = 'likeablelocal/user/settings/UPLOAD_AVATAR_FAIL';
var initialState = {
  uploadingAvatar: false,
  avatarUploadError: null,
  updatingSettings: false,
  updatingError: null
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case UPLOAD_AVATAR:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadingAvatar: true
      });

    case UPLOAD_AVATAR_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadingAvatar: false,
        uploadedAvatar: action.result.data[0]
      });

    case UPLOAD_AVATAR_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadingAvatar: true,
        avatarUploadError: action.result.error
      });

    case UPDATE_SETTINGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        updatingSettings: true
      });

    case UPDATE_SETTINGS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        updatingSettings: false
      });

    case UPDATE_SETTINGS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        updatingSettings: false,
        updatingError: action.error
      });

    default:
      return state;
  }
}
export function uploadAvatar(files) {
  return {
    types: [UPLOAD_AVATAR, UPLOAD_AVATAR_SUCCESS, UPLOAD_AVATAR_FAIL],
    promise: function promise(client) {
      return client.post('/imageUpload', {
        files: files
      });
    }
  };
}
export function updateUserSettings(settings) {
  return {
    types: [UPDATE_SETTINGS, UPDATE_SETTINGS_SUCCESS, UPDATE_SETTINGS_FAIL],
    promise: function promise(client) {
      return client.put('/user/settings', {
        data: settings
      });
    }
  };
}