import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import moment from 'moment';

var getUrls = require('get-urls');

export default function convertPost(post) {
  var isNew = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var timezoneOffset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    utc_offset: {
      hours: new Date().getTimezoneOffset() / 60
    }
  };
  var postType = 'textPost';

  if (post.PostTemplate) {
    // queued posts - click edit on HorizontalPostCard in the queue come here
    var hasImages = post.S3Files && post.S3Files.length ? [post.S3Files[0].fullyQualifiedUrl] : null;
    var link = {};

    if (!hasImages) {
      var urls = getUrls(post.message);

      if (urls) {
        var l = Array.from(urls)[0];

        if (l) {
          link.url = l;
          link.title = post.linkTitle;
          postType = 'linkPost';
        }
      }
    } else {
      postType = 'imagePost';
    }
  } else {
    // posts from schedule view come here
    // console.dir(post);
    if (post.videoUrl) {
      postType = 'videoPost';
    } else if (post.linkUrl && post.linkTitle) {
      postType = 'linkPost';
    } else if (post.S3Files.length) {
      if (post.S3Files.length === 1 && post.S3Files[0].fullyQualifiedUrl.indexOf('.mp4') !== -1) {
        postType = 'videoPost';
        post.videoUrl = post.S3Files[0].fullyQualifiedUrl;
      } else {
        postType = 'imagePost';
      }
    }
  }

  var selectedAccounts;

  if (post.QueueId) {
    // is a queued post
    // get the selectedAccounts from the queue information
    selectedAccounts = {
      facebookProfile: post.Queue.type === 'Facebook profile',
      facebookPage: post.Queue.type === 'Facebook page',
      twitter: post.Queue.type === 'Twitter',
      linkedinProfile: post.Queue.type === 'LinkedIn profile',
      linkedinCompany: post.Queue.type === 'LinkedIn company',
      instagram: post.Queue.type === 'Instagram',
      googleMybusiness: post.Queue.type === 'Google my business'
    };
  } else {
    // otherwise, use the SocialAccountPost object
    selectedAccounts = {
      facebookProfile: post.SocialAccountPosts.some(function (p) {
        return p.SocialAccount && p.SocialAccount.platform === 'Facebook profile';
      }),
      facebookPage: post.SocialAccountPosts.some(function (p) {
        return p.SocialAccount && p.SocialAccount.platform === 'Facebook page';
      }),
      twitter: post.SocialAccountPosts.some(function (p) {
        return p.SocialAccount && p.SocialAccount.platform === 'Twitter';
      }),
      linkedinProfile: post.SocialAccountPosts.some(function (p) {
        return p.SocialAccount && p.SocialAccount.platform === 'LinkedIn profile';
      }),
      linkedinCompany: post.SocialAccountPosts.some(function (p) {
        return p.SocialAccount && p.SocialAccount.platform === 'LinkedIn company';
      }),
      instagram: post.SocialAccountPosts.some(function (p) {
        return p.SocialAccount && p.SocialAccount.platform === 'Instagram';
      }),
      googleMybusiness: post.SocialAccountPosts.some(function (p) {
        return p.SocialAccount && p.SocialAccount.platform === 'Google my business';
      })
    };
  }

  var messages = {
    global: post.message,
    facebook: post.SocialAccountPosts.filter(function (p) {
      return p.SocialAccount && p.SocialAccount.platform.indexOf('Facebook') === 0;
    }).length ? post.SocialAccountPosts.filter(function (p) {
      return p.SocialAccount && p.SocialAccount.platform.indexOf('Facebook') === 0;
    })[0].message : undefined,
    twitter: post.SocialAccountPosts.filter(function (p) {
      return p.SocialAccount && p.SocialAccount.platform.indexOf('Twitter') === 0;
    }).length ? post.SocialAccountPosts.filter(function (p) {
      return p.SocialAccount && p.SocialAccount.platform.indexOf('Twitter') === 0;
    })[0].message : undefined,
    linkedin: post.SocialAccountPosts.filter(function (p) {
      return p.SocialAccount && p.SocialAccount.platform.indexOf('LinkedIn') === 0;
    }).length ? post.SocialAccountPosts.filter(function (p) {
      return p.SocialAccount && p.SocialAccount.platform.indexOf('LinkedIn') === 0;
    })[0].message : undefined,
    instagram: post.SocialAccountPosts.filter(function (p) {
      return p.SocialAccount && p.SocialAccount.platform === 'Instagram';
    }).length ? post.SocialAccountPosts.filter(function (p) {
      return p.SocialAccount && p.SocialAccount.platform === 'Instagram';
    })[0].message : undefined,
    googleMybusiness: post.SocialAccountPosts.filter(function (p) {
      return p.SocialAccount && p.SocialAccount.platform === 'Google my business';
    }).length ? post.SocialAccountPosts.filter(function (p) {
      return p.SocialAccount && p.SocialAccount.platform === 'Google my business';
    })[0].message : undefined
  };
  post.S3Files.sort(compareByPlacement);

  var initialState = _objectSpread(_objectSpread({
    advertising: null,
    convertQueuedPost: false,
    existingPostId: isNew === false ? post.id : undefined,
    existingPostQueueId: isNew === false ? post.QueueId : undefined,
    images: post.S3Files.map(function (file) {
      return file.fullyQualifiedUrl;
    }),
    linkData: {
      link: post.linkUrl
    },
    messages: messages,
    postType: postType,
    publishAt: isNew ? new Date().getTime() : moment.utc(post.publishAt).valueOf(),
    selectedAccounts: selectedAccounts,
    videoUrl: post.videoUrl
  }, post.DraftPost && post.DraftPost.id ? {
    DraftPost: _objectSpread({}, post.DraftPost)
  } : {}), {}, {
    postTimeType: post.DraftPost && post.DraftPost.id ? 'draft' : 'publishNow'
  }); // console.log('converted Post will be:');
  // console.log(initialState);


  return initialState;
}
export function compareByPlacement(a, b) {
  if (a.PostS3File.placement > b.PostS3File.placement) {
    return 1;
  } else if (a.PostS3File.placement < b.PostS3File.placement) {
    return -1;
  } else {
    return 0;
  }
}