import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
var ns = 'likeablelocal/adminDashboard';
var GET_DEPOSIT_V_SPEND = "".concat(ns, "/GET_DEPOSIT_V_SPEND");
var GET_DEPOSIT_V_SPEND_SUCCESS = "".concat(ns, "/GET_DEPOSIT_V_SPEND_SUCCESS");
var GET_DEPOSIT_V_SPEND_FAIL = "".concat(ns, "/GET_DEPOSIT_V_SPEND_FAIL");
var GET_PENDING_DRAFT_APPROVALS = "".concat(ns, "/GET_PENDING_DRAFT_APPROVALS");
var GET_PENDING_DRAFT_APPROVALS_SUCCESS = "".concat(ns, "/GET_PENDING_DRAFT_APPROVALS_SUCCESS");
var GET_PENDING_DRAFT_APPROVALS_FAIL = "".concat(ns, "/GET_PENDING_DRAFT_APPROVALS_FAIL");
var GET_FB_STATUS = "".concat(ns, "/GET_FB_STATUS");
var GET_FB_STATUS_SUCCESS = "".concat(ns, "/GET_FB_STATUS_SUCCESS");
var GET_FB_STATUS_FAIL = "".concat(ns, "/GET_FB_STATUS_FAIL");
var GET_STRIPE_STATUS = "".concat(ns, "/GET_STRIPE_STATUS");
var GET_STRIPE_STATUS_SUCCESS = "".concat(ns, "/GET_STRIPE_STATUS_SUCCESS");
var GET_STRIPE_STATUS_FAIL = "".concat(ns, "/GET_STRIPE_STATUS_FAIL");
var GET_BUSINESS_MANAGER_PAGES = "".concat(ns, "/GET_BUSINESS_MANAGER_PAGES");
var GET_BUSINESS_MANAGER_PAGES_SUCCESS = "".concat(ns, "/GET_BUSINESS_MANAGER_PAGES_SUCCESS");
var GET_BUSINESS_MANAGER_PAGES_FAIL = "".concat(ns, "/GET_BUSINESS_MANAGER_PAGES_FAIL");
var ASSIGN_SYSUSER_TO_PAGE = "".concat(ns, "/ASSIGN_SYSUSER_TO_PAGE");
var ASSIGN_SYSUSER_TO_PAGE_SUCCESS = "".concat(ns, "/ASSIGN_SYSUSER_TO_PAGE_SUCCESS");
var ASSIGN_SYSUSER_TO_PAGE_FAIL = "".concat(ns, "/ASSIGN_SYSUSER_TO_PAGE_FAIL");
var CONNECT_BM_PAGE = "".concat(ns, "/CONNECT_BM_PAGE");
var CONNECT_BM_PAGE_SUCCESS = "".concat(ns, "/CONNECT_BM_PAGE_SUCCESS");
var CONNECT_BM_PAGE_FAIL = "".concat(ns, "/CONNECT_BM_PAGE_FAIL");
var GET_INVALID_FBS = "".concat(ns, "/GET_INVALID_FBS");
var GET_INVALID_FBS_SUCCESS = "".concat(ns, "/GET_INVALID_FBS_SUCCESS");
var GET_INVALID_FBS_FAIL = "".concat(ns, "/GET_INVALID_FBS_FAIL");
var initialState = {
  gettingDepositVsSpend: false,
  depositVsSpendData: [],
  gettingPendingDraftApprovals: false,
  pendingDraftApprovalsData: [],
  gettingStripeStatus: false,
  stripeStatus: {
    allCount: 0,
    errorCount: 0
  },
  fbStatus: {
    allCalls: 0,
    errorCount: 0
  },
  gettingFBStatus: false,
  loading: true,
  loadingBMPages: false,
  businessManagerPages: [],
  assignSysUserError: false,
  isAssigningSystemUsers: false,
  isConnectingBmPage: false,
  isFetchingInvalidFbPages: false,
  invalidFacebookPages: []
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case GET_DEPOSIT_V_SPEND:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingDepositVsSpend: true
      });

    case GET_DEPOSIT_V_SPEND_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingDepositVsSpend: false,
        depositVsSpendData: action.result && action.result.data && !!action.result.data.length ? action.result.data[0] : []
      });

    case GET_DEPOSIT_V_SPEND_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingDepositVsSpend: false
      });

    case GET_PENDING_DRAFT_APPROVALS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPendingDraftApprovals: true
      });

    case GET_PENDING_DRAFT_APPROVALS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPendingDraftApprovals: false,
        pendingDraftApprovalsData: action.result && action.result.data && !!action.result.data.length ? action.result.data[0] : []
      });

    case GET_PENDING_DRAFT_APPROVALS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPendingDraftApprovals: false
      });

    case GET_STRIPE_STATUS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingStripeStatus: true
      });

    case GET_STRIPE_STATUS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingStripeStatus: false,
        stripeStatus: action.result.data
      });

    case GET_STRIPE_STATUS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingStripeStatus: false
      });

    case GET_FB_STATUS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingFBStatus: true
      });

    case GET_FB_STATUS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingFBStatus: false,
        fbStatus: action.result.data
      });

    case GET_FB_STATUS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingFBStatus: false
      });

    case GET_BUSINESS_MANAGER_PAGES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        businessManagerPages: action.result.data,
        loadingBMPages: false
      });

    case GET_BUSINESS_MANAGER_PAGES:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingBMPages: true
      });

    case GET_BUSINESS_MANAGER_PAGES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingBMPages: false
      });

    case ASSIGN_SYSUSER_TO_PAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        assignSysUserError: false,
        isAssigningSystemUsers: true
      });

    case ASSIGN_SYSUSER_TO_PAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        assignSysUserError: false,
        isAssigningSystemUsers: false
      });

    case ASSIGN_SYSUSER_TO_PAGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        assignSysUserError: action.error.error.message,
        isAssigningSystemUsers: false
      });

    case CONNECT_BM_PAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isConnectingBmPage: true
      });

    case CONNECT_BM_PAGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isConnectingBmPage: false
      });

    case CONNECT_BM_PAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isConnectingBmPage: false
      });

    case GET_INVALID_FBS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingInvalidFbPages: true
      });

    case GET_INVALID_FBS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingInvalidFbPages: false
      });

    case GET_INVALID_FBS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFetchingInvalidFbPages: false,
        invalidFacebookPages: action.result.data
      });

    default:
      return state;
  }
}
export function getDepositVsSpendData() {
  return {
    types: [GET_DEPOSIT_V_SPEND, GET_DEPOSIT_V_SPEND_SUCCESS, GET_DEPOSIT_V_SPEND_FAIL],
    promise: function promise(client) {
      return client.get('/adminDashboard/depositVsSpend');
    }
  };
}
export function getPendingDraftApprovals() {
  return {
    types: [GET_PENDING_DRAFT_APPROVALS, GET_PENDING_DRAFT_APPROVALS_SUCCESS, GET_PENDING_DRAFT_APPROVALS_FAIL],
    promise: function promise(client) {
      return client.get('/adminDashboard/pendingDraftApprovals');
    }
  };
}
export function getStripeStatus() {
  var hours = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 24;
  return {
    types: [GET_STRIPE_STATUS, GET_STRIPE_STATUS_SUCCESS, GET_STRIPE_STATUS_FAIL],
    promise: function promise(client) {
      return client.get("/adminDashboard/logEvents/stripe_webhook/summary?hours=".concat(hours));
    }
  };
}
export function getFBStatus() {
  var hours = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 48;
  return {
    types: [GET_FB_STATUS, GET_FB_STATUS_SUCCESS, GET_FB_STATUS_FAIL],
    promise: function promise(client) {
      return client.get("/adminDashboard/facebookCalls/summary?hours=".concat(hours));
    }
  };
}
export function getBusinessManagerPages() {
  return {
    types: [GET_BUSINESS_MANAGER_PAGES, GET_BUSINESS_MANAGER_PAGES_SUCCESS, GET_BUSINESS_MANAGER_PAGES_FAIL],
    promise: function promise(client) {
      return client.get('/admin/businessManagerPages');
    }
  };
}
export function getInvalidFacebookPages() {
  return {
    types: [GET_INVALID_FBS, GET_INVALID_FBS_SUCCESS, GET_INVALID_FBS_FAIL],
    promise: function promise(client) {
      return client.get('/adminDashboard/invalidFacebookPages');
    }
  };
}
export function assignSystemUsersToPage(facebookPageId) {
  return {
    types: [ASSIGN_SYSUSER_TO_PAGE, ASSIGN_SYSUSER_TO_PAGE_SUCCESS, ASSIGN_SYSUSER_TO_PAGE_FAIL],
    promise: function promise(client) {
      return client.post('/admin/assignSystemUsersToPage', {
        data: {
          facebookPageId: facebookPageId
        }
      });
    }
  };
}
export function connectBusinessManagerPage(clientOrganizationId, accountData) {
  return {
    types: [CONNECT_BM_PAGE, CONNECT_BM_PAGE_SUCCESS, CONNECT_BM_PAGE_FAIL],
    promise: function promise(client) {
      return client.post('/admin/connectBusinessManagerPage', {
        data: {
          clientOrganizationId: clientOrganizationId,
          accountData: accountData
        }
      });
    }
  };
}