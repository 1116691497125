import feathers from 'feathers/client';
import hooks from 'feathers-hooks';
import rest from 'feathers-rest/client';
import socketio from 'feathers-socketio/client';
import authentication from 'feathers-authentication/client';
import superagent from 'superagent';
import config from './config';
import io from 'socket.io-client';
var serverVal = null;

try {
  serverVal = __SERVER__;
} catch (e) {
  serverVal = true;
}

var socketConfig = function socketConfig(path) {
  return {
    location: '',
    config: {
      path: path,
      autoConnect: true
    }
  };
};

var storage = serverVal ? require('localstorage-memory') : window.localStorage;
var socket = io(config.apiEndPoint, {
  path: '/ws',
  autoConnect: true
});

var host = function host(clientUrl) {
  if (serverVal) {
    if (config.apiPort && !isNaN(parseInt(config.apiPort))) {
      return "http".concat(__DEVELOPMENT__ ? '' : 's', "://").concat(config.apiHost, ":").concat(config.apiPort);
    }

    return "http".concat(__DEVELOPMENT__ ? '' : 's', "://").concat(config.apiHost);
  }

  return clientUrl;
};

var configureApp = function configureApp(app, transport) {
  return app.configure(transport).configure(hooks()).configure(authentication({
    storage: storage
  }));
};

var app = feathers();
export default app;
export var restApp = feathers();
export var init = function init() {
  console.log(process.env.NODE_ENV);
  console.log(process.env);
  console.log(config);
  configureApp(app, socketio(socket));
  configureApp(restApp, rest("".concat(config.apiEndPoint)).superagent(superagent));
};