import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
import { LOAD_WHITELABEL_SUCCESS } from './config';
var SUBMIT = 'likeablelocal/whiteLabel/SUBMIT';
var SUBMIT_SUCCESS = 'likeablelocal/whiteLabel/SUBMIT_SUCCESS';
var SUBMIT_FAIL = 'likeablelocal/whiteLabel/SUBMIT_FAIL';
var GET_SETTINGS = 'likeablelocal/whiteLabel/GET_SETTINGS';
var GET_SETTINGS_SUCCESS = 'likeablelocal/whiteLabel/GET_SETTINGS_SUCCESS';
var GET_SETTINGS_FAIL = 'likeablelocal/whiteLabel/GET_SETTINGS_FAIL';
var initialState = {};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
      return initialState;

    case LOGOUT_SUCCESS:
      return initialState;

    case SUBMIT:
      return state;

    case SUBMIT_SUCCESS:
      return state;

    case SUBMIT_FAIL:
      return state;

    case GET_SETTINGS:
      return state;

    case LOAD_WHITELABEL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), action.result.data);

    default:
      return state;
  }
}
export function submit(orgId, data) {
  var payload = {
    primaryColor: data.primaryColor,
    secondaryColor: data.secondaryColor,
    logoUrl: [data.logoUrl]
  };
  return {
    types: [SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/whiteLabel"), {
        data: payload
      });
    }
  };
}