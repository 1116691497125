import moment from 'moment';
export default {
  formatTwitterTime: formatTwitterTime
};
export function formatTwitterTime(time) {
  var momentTime = moment(time);
  var now = moment();

  if (Math.abs(momentTime.diff(now)) < 60000) {
    // last minute, so show seconds
    var seconds = Math.abs(momentTime.diff(now, 'seconds'));
    return "".concat(seconds, "s");
  } else if (Math.abs(momentTime.diff(now)) < 3600000) {
    // last hour, so show minutes
    var minutes = Math.abs(momentTime.diff(now, 'minutes'));
    return "".concat(minutes, "m");
  } else if (Math.abs(momentTime.diff(now)) < 86400000) {
    // last day, so show hours
    var hours = Math.abs(momentTime.diff(now, 'hours'));
    return "".concat(hours, "h");
  } // in current calendar year


  if (momentTime.format('YYYY') === now.format('YYYY')) {
    return momentTime.format('MMM D');
  } // from last calendar year


  return momentTime.format('D MMM YYYY');
}