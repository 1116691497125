import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var ns = 'likeablelocal/adsDashboard';
var GET_INSIGHTS = "".concat(ns, "/GET_INSIGHTS");
var GET_INSIGHTS_SUCCESS = "".concat(ns, "/GET_INSIGHTS_SUCCESS");
var GET_INSIGHTS_FAIL = "".concat(ns, "/GET_INSIGHTS_FAIL");
var GET_LOCATION_AND_DEMOGRAPHICS = "".concat(ns, "/GET_LOCATION_AND_DEMOGRAPHICS");
var GET_LOCATION_AND_DEMOGRAPHICS_SUCCESS = "".concat(ns, "/GET_LOCATION_AND_DEMOGRAPHICS_SUCCESS");
var GET_LOCATION_AND_DEMOGRAPHICS_FAIL = "".concat(ns, "/GET_LOCATION_AND_DEMOGRAPHICS_FAIL");
var GET_ADS_IN_RANGE = "".concat(ns, "/GET_ADS_IN_RANGE");
var GET_ADS_IN_RANGE_SUCCESS = "".concat(ns, "/GET_ADS_IN_RANGE_SUCCESS");
var GET_ADS_IN_RANGE_FAIL = "".concat(ns, "/GET_ADS_IN_RANGE_FAIL");
var initialState = {
  accountInsights: {},
  adsInRange: [],
  gettingAdsInRange: false,
  locationAndDemographics: {},
  gettingLocationAndDemographics: false
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case GET_ADS_IN_RANGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAdsInRange: true
      });

    case GET_ADS_IN_RANGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        adsInRange: action.result.data,
        gettingAdsInRange: false
      });

    case GET_ADS_IN_RANGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAdsInRange: false
      });

    case GET_LOCATION_AND_DEMOGRAPHICS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingLocationAndDemographics: true
      });

    case GET_LOCATION_AND_DEMOGRAPHICS_SUCCESS:
      var data2 = action.result.data && action.result.data.results ? action.result.data.results : null;
      var initState = [{
        key: 'page_fans_gender_age',
        label: 'Age and Gender',
        value: true,
        data: null
      }, {
        key: 'page_fans_city',
        label: 'Locations',
        value: true,
        data: null
      }];
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingLocationAndDemographics: false,
        locationAndDemographics: _objectSpread(_objectSpread({}, state.locationAndDemographics), {}, _defineProperty({}, action.orgId, initState.map(function (insight) {
          insight.data = data2 ? data2[insight.key].results : null;
          return insight;
        })))
      });

    case GET_LOCATION_AND_DEMOGRAPHICS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingLocationAndDemographics: false,
        locationAndDemographics: [{
          key: 'page_fans_gender_age',
          label: 'Age and Gender',
          value: true,
          data: null
        }, {
          key: 'page_fans_city',
          label: 'Locations',
          value: true,
          data: null
        }]
      });

    case GET_LOCATION_AND_DEMOGRAPHICS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingLocationAndDemographics: false
      });

    case GET_INSIGHTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingInsights: true
      });

    case GET_INSIGHTS_SUCCESS:
      console.log('GET_INSIGHTS');
      console.log(action);
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingInsights: false,
        accountInsights: _objectSpread(_objectSpread({}, state.accountInsights), {}, _defineProperty({}, action.orgId, action.result.data))
      });

    case GET_INSIGHTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingInsights: false
      });

    default:
      return state;
  }
}
export function getAdsInRange(orgId, startDate, endDate) {
  console.log('in getAdsInRage');
  console.log(orgId);
  return {
    types: [GET_ADS_IN_RANGE, GET_ADS_IN_RANGE_SUCCESS, GET_ADS_IN_RANGE_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/reporting/adsInRange?start=").concat(startDate, "&end=").concat(endDate));
    }
  };
}
export function getAdAccountOverviewData(orgId, adAccountId, startDate, endDate) {
  return {
    types: [GET_INSIGHTS, GET_INSIGHTS_SUCCESS, GET_INSIGHTS_FAIL],
    promise: function promise(client) {
      return client.get("/facebook/adAccount/".concat(adAccountId, "/insights?since=").concat(startDate, "&until=").concat(endDate), {
        data: {
          startDate: startDate,
          endDate: endDate
        }
      });
    },
    orgId: orgId
  };
}
export function getLocationAndDemographics(orgId, startDate, endDate) {
  var metrics = 'page_fans_city,page_fans_gender_age';
  return {
    types: [GET_LOCATION_AND_DEMOGRAPHICS, GET_LOCATION_AND_DEMOGRAPHICS_SUCCESS, GET_LOCATION_AND_DEMOGRAPHICS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/reporting/pageStats?since=").concat(startDate, "&until=").concat(endDate, "&metrics=").concat(metrics));
    },
    orgId: orgId
  };
}