import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var ns = 'likeablelocal/audienceLocations';
var QUERY_LOCATIONS = "".concat(ns, "/QUERY_LOCATIONS");
var QUERY_LOCATIONS_SUCCESS = "".concat(ns, "/QUERY_LOCATIONS_SUCCESS");
var QUERY_LOCATIONS_FAIL = "".concat(ns, "/QUERY_LOCATIONS_FAIL");
var initialState = {
  searching: false
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case QUERY_LOCATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        searching: true
      });

    case QUERY_LOCATIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        searching: false
      });

    case QUERY_LOCATIONS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        searching: false
      });

    default:
      return state;
  }
}
export function queryLocations(query) {
  var url = "/facebook/ad/locations?q=".concat(encodeURIComponent(query));
  return {
    types: [QUERY_LOCATIONS, QUERY_LOCATIONS_SUCCESS, QUERY_LOCATIONS_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}