import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import moment from 'moment';
import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
var ns = 'likeablelocal/queues';
var REMOVE_CATEGORY = "".concat(ns, "/REMOVE_CATEGORY");
export var REMOVE_CATEGORY_SUCCESS = "".concat(ns, "/REMOVE_CATEGORY_SUCCESS");
var REMOVE_CATEGORY_FAIL = "".concat(ns, "/REMOVE_CATEGORY_FAIL");
var LOAD_QUEUES = "".concat(ns, "/LOAD_QUEUES");
var LOAD_QUEUES_SUCCESS = "".concat(ns, "/LOAD_QUEUES_SUCCESS");
var LOAD_QUEUES_FAIL = "".concat(ns, "/LOAD_QUEUES_FAIL");
var UPDATE_QUEUE = "".concat(ns, "/UPDATE_QUEUE");
export var UPDATE_QUEUE_SUCCESS = "".concat(ns, "/UPDATE_QUEUE_SUCCESS");
var UPDATE_QUEUE_FAIL = "".concat(ns, "/UPDATE_QUEUE_FAIL");
var REMOVE_QUEUE_ITEM = "".concat(ns, "/REMOVE_QUEUE_ITEM");
export var REMOVE_QUEUE_ITEM_SUCCESS = "".concat(ns, "/REMOVE_QUEUE_ITEM_SUCCESS");
var REMOVE_QUEUE_ITEM_FAIL = "".concat(ns, "/REMOVE_QUEUE_ITEM_FAIL");
var initialState = {
  queues: [],
  removingQueueItem: false,
  queuesLoaded: false,
  removingCategory: false
};
export default function reducer() {
  var _objectSpread3, _objectSpread5;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case REMOVE_CATEGORY:
      return _objectSpread(_objectSpread({}, state), {}, {
        removingCategory: true
      });

    case REMOVE_CATEGORY_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({
        removingCategory: false
      }, action.orgId, state[action.orgId].map(function (q) {
        if (q.id === parseInt(action.queueId)) {
          q.PostTemplateTags = q.PostTemplateTags.filter(function (tag) {
            return tag.id !== parseInt(action.tagId);
          });
        }

        return q;
      })));

    case REMOVE_CATEGORY_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        removingCategory: false
      });

    case LOAD_QUEUES:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingQueues: true
      });

    case LOAD_QUEUES_SUCCESS:
      var queues = action.result.data.filter(function (q) {
        return q.SocialAccounts && !!q.SocialAccounts.length && q.type !== 'Facebook profile';
      });
      return _objectSpread(_objectSpread({}, state), {}, (_objectSpread3 = {}, _defineProperty(_objectSpread3, action.orgId, queues), _defineProperty(_objectSpread3, "loadingQueues", false), _defineProperty(_objectSpread3, "queuesLoaded", true), _objectSpread3));

    case LOAD_QUEUES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingQueues: false
      });

    case REMOVE_QUEUE_ITEM:
      return _objectSpread(_objectSpread({}, state), {}, {
        removingQueueItem: true
      });

    case REMOVE_QUEUE_ITEM_SUCCESS:
      console.log("queueId = ".concat(action.queueId));
      console.log("postId = ".concat(action.postId));
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({
        removingQueueItem: false
      }, action.orgId, state[action.orgId].map(function (queue) {
        if (parseInt(action.queueId) === parseInt(queue.id)) {
          return _objectSpread(_objectSpread({}, queue), {}, {
            Posts: queue.Posts.filter(function (post) {
              return parseInt(action.postId) !== parseInt(post.id);
            })
          });
        }

        return queue;
      })));

    case REMOVE_QUEUE_ITEM_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        removingQueueItem: false
      });

    case UPDATE_QUEUE:
      return _objectSpread(_objectSpread({}, state), {}, {
        updatingQueues: true
      });

    case UPDATE_QUEUE_SUCCESS:
      var queueIndex = state.queues[action.orgId].findIndex(function (queue) {
        return queue.id === action.result.data.id;
      });
      var newQueues = state.queues[action.orgId].slice(); // Either add or update the queue

      if (queueIndex === -1) {
        newQueues.push(action.result.data);
      } else {
        newQueues[queueIndex] = action.result.data;
      }

      return _objectSpread(_objectSpread({}, state), {}, (_objectSpread5 = {}, _defineProperty(_objectSpread5, action.orgId, newQueues), _defineProperty(_objectSpread5, "updatingQueues", false), _objectSpread5));

    case UPDATE_QUEUE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        updatingQueues: false
      });

    default:
      return state;
  }
}
export function updateQueue(orgId, queueId, action) {
  var actionData = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  console.log('in updateQueue');
  console.log(orgId);
  console.log(queueId);
  console.log(action);
  console.log(actionData);
  var url = "/org/".concat(orgId, "/queue/").concat(queueId);

  var body = _objectSpread(_objectSpread({}, actionData), {}, {
    action: action
  });

  return {
    types: [UPDATE_QUEUE, UPDATE_QUEUE_SUCCESS, UPDATE_QUEUE_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: body
      });
    },
    orgId: orgId
  };
}
export function removeQueueItem(orgId, queueId, postId) {
  var url = "/org/".concat(orgId, "/queue/").concat(queueId);
  var body = {
    postIds: postId,
    action: 'remove'
  };
  return {
    types: [REMOVE_QUEUE_ITEM, REMOVE_QUEUE_ITEM_SUCCESS, REMOVE_QUEUE_ITEM_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: body
      });
    },
    orgId: orgId,
    queueId: queueId,
    postId: postId
  };
}
export function loadOrganizationQueues(orgId) {
  var url = "/org/".concat(orgId, "/queues");
  return {
    types: [LOAD_QUEUES, LOAD_QUEUES_SUCCESS, LOAD_QUEUES_FAIL],
    promise: function promise(client) {
      return client.get(url);
    },
    orgId: orgId
  };
}
export function removeCategoryFromQueue(orgId, socialAccountIds, tagId, queueId) {
  return {
    types: [REMOVE_CATEGORY, REMOVE_CATEGORY_SUCCESS, REMOVE_CATEGORY_FAIL],
    promise: function promise(client) {
      return client.del("/org/".concat(orgId, "/channel/").concat(tagId), {
        data: {
          socialAccountIds: socialAccountIds
        }
      });
    },
    orgId: orgId,
    tagId: tagId,
    removedSocialAccountId: socialAccountIds[0],
    queueId: queueId
  };
}