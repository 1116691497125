import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as actions from './actions';
import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from '../auth';
import moment from 'moment';
import * as leadFormConverter from '../../../helpers/leadFormConverter';
import leadFormValidator from '../../../helpers/leadFormValidator';
var initialState = {};
var orgInitialState = {
  DESKTOP_FEED_STANDARD: null,
  MOBILE_FEED_STANDARD: null,
  RIGHT_COLUMN_STANDARD: null,
  loadingCategory: false,
  saveError: null,
  imageUploadError: null,
  videoUploadError: null,
  adAccountId: '',
  adImage: [],
  // These two fields are arrays to simplify porting from the compose screen
  adVideo: [],
  carouselItems: [],
  uploadingFile: false,
  loadingFBAdPreview: true,
  type: 'pageLikes',
  uploadingBase64Image: false,
  step: 0,
  adName: '',
  adStart: moment().toDate(),
  adEnd: moment().add(7, 'days').toDate(),
  selectedAudienceTabKey: 'saved',
  adFormatTabKey: 'image',
  'slideshow-duration': {
    id: 2,
    title: '2 seconds',
    val: 2000
  },
  'slideshow-transition': {
    id: 1,
    title: 'Fast',
    val: 100
  }
};
var maxCarouselItems = 10;
export default function reducer() {
  var _objectSpread4, _objectSpread11, _action$error, _action$error$error, _action$error2, _action$error2$error, _action$error3, _action$error3$error, _action$error3$error$;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
      return initialState;

    case LOGOUT_SUCCESS:
      return initialState;

    case actions.TARGETING_BROWSE:
      console.log('calling getTargetingInfo');
      return _objectSpread({}, state);

    case actions.CREATE_PAGE_LIKE_AD_FAIL:
    case actions.CREATE_WEBSITE_CLICK_AD_FAIL:
    case actions.CREATE_LEAD_GEN_AD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        saveError: action.error && action.error.error && action.error.error.message ? action.error.error.message : 'An unknown error occurred.'
      })));

    case actions.CREATE_PAGE_LIKE_AD:
    case actions.CREATE_WEBSITE_CLICK_AD:
    case actions.CREATE_LEAD_GEN_AD:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        saveError: null
      })));

    case actions.TARGETING_BROWSE_SUCCESS:
      console.log('getTargetingInfo() success');
      console.log(action.result);
      return _objectSpread({}, state);

    case actions.TARGETING_BROWSE_FAIL:
      console.log('getTargetingInfo() fail');
      return _objectSpread({}, state);

    case actions.RESET_AD_BUILDER_STATE:
      return _objectSpread(_objectSpread({}, state), {}, (_objectSpread4 = {}, _defineProperty(_objectSpread4, "".concat(action.orgId), _objectSpread({}, orgInitialState)), _defineProperty(_objectSpread4, "leadBuilder_".concat(action.orgId), null), _objectSpread4));

    case actions.INIT_ADBUILDER_STATE_FOR_ORG:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, orgInitialState), {}, {
        adStart: moment().toDate(),
        adEnd: moment().add(7, 'days').toDate(),
        step: 1
      })));

    case actions.UPDATE_PREVIEW_STEP:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
        previewStep: action.previewStep
      })));

    case actions.UPLOAD_FORM_IMAGE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
        uploadingImage: true
      })));

    case actions.UPLOAD_FORM_IMAGE_SUCCESS:
      return function () {
        var builderState = _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
          imageUrl: action.result.data[0].fullyQualifiedUrl,
          uploadingImage: false
        });

        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, builderState), {}, {
          validationErrors: _objectSpread({}, leadFormValidator(builderState))
        })));
      }();

    case actions.UPLOAD_FORM_IMAGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
        uploadingImage: false
      })));

    case actions.GET_FB_AD_PREVIEW:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        loadingFBAdPreview: true,
        fbAdPreviewError: null
      })));

    case actions.GET_FB_AD_PREVIEW_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, (_objectSpread11 = {}, _defineProperty(_objectSpread11, action.data.adType, action.result.data), _defineProperty(_objectSpread11, "loadingFBAdPreview", false), _defineProperty(_objectSpread11, "fbAdPreviewError", null), _objectSpread11))));

    case actions.GET_FB_AD_PREVIEW_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        fbAdPreviewError: typeof action.error.error.message === 'string' ? action.error.error.message : action.error.error.message.message,
        loadingFBAdPreview: false
      })));

    case actions.GET_CUSTOM_POST_PREVIEW:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        loadingFBAdPreview: true,
        fbAdPreviewError: null
      })));

    case actions.GET_CUSTOM_POST_PREVIEW_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        DESKTOP_FEED_STANDARD: action.result.data.result,
        loadingFBAdPreview: false,
        fbAdPreviewError: null
      })));

    case actions.GET_CUSTOM_POST_PREVIEW_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        fbAdPreviewError: typeof ((_action$error = action.error) === null || _action$error === void 0 ? void 0 : (_action$error$error = _action$error.error) === null || _action$error$error === void 0 ? void 0 : _action$error$error.message) === 'string' ? (_action$error2 = action.error) === null || _action$error2 === void 0 ? void 0 : (_action$error2$error = _action$error2.error) === null || _action$error2$error === void 0 ? void 0 : _action$error2$error.message : (_action$error3 = action.error) === null || _action$error3 === void 0 ? void 0 : (_action$error3$error = _action$error3.error) === null || _action$error3$error === void 0 ? void 0 : (_action$error3$error$ = _action$error3$error.message) === null || _action$error3$error$ === void 0 ? void 0 : _action$error3$error$.message,
        loadingFBAdPreview: false
      })));

    case actions.CLEAR_FB_AD_PREVIEW:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        DESKTOP_FEED_STANDARD: null,
        MOBILE_FEED_STANDARD: null,
        RIGHT_COLUMN_STANDARD: null
      })));

    case actions.UPLOAD_IMAGE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        uploadingFile: true
      })));

    case actions.UPLOAD_IMAGE_SUCCESS:
      var imgData = action.result.data;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        uploadingFile: false,
        adImage: imgData
      })));

    case actions.UPLOAD_IMAGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        uploadingFile: false,
        imageUploadError: action.error
      })));

    case actions.UPLOAD_BASE64_IMAGE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        uploadingBase64Image: true
      })));

    case actions.UPLOAD_BASE64_IMAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        uploadingBase64Image: false
      })));

    case actions.UPLOAD_BASE64_IMAGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        uploadingBase64Image: false
      })));

    case actions.UPLOAD_CAROUSEL_ITEM_SUCCESS:
      var newItems = (Array.isArray(action.result.data) ? action.result.data : [action.result.data]).map(function (item) {
        return {
          fullyQualifiedUrl: item.fullyQualifiedUrl
        };
      });
      var compiledItems = state["".concat(action.orgId)].carouselItems ? state["".concat(action.orgId)].carouselItems : [];
      compiledItems.push.apply(compiledItems, _toConsumableArray(newItems));
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        uploadingFile: false,
        // truncate the array if they somehow uploaded more than we allow.
        carouselItems: compiledItems.slice(0, maxCarouselItems)
      })));

    case actions.REMOVE_IMAGE_AD_IMAGE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        adImage: []
      })));

    case actions.REMOVE_VIDEO_AD_VIDEO:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        adVideo: []
      })));

    case actions.REMOVE_CAROUSEL_AD_ITEM:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        carouselItems: state["".concat(action.orgId)].carouselItems ? _toConsumableArray(state["".concat(action.orgId)].carouselItems.filter(function (item) {
          return item.fullyQualifiedUrl !== action.item;
        })) : []
      })));

    case actions.SUBMIT_AD:
      return state;

    case actions.SUBMIT_AD_SUCCESS:
      return state;

    case actions.SUBMIT_AD_FAIL:
      return state;

    case actions.UPLOAD_VIDEO:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        uploadingFile: true
      })));

    case actions.UPLOAD_VIDEO_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        uploadingFile: false
      })));

    case actions.UPLOAD_VIDEO_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        uploadingFile: false,
        videoUploadError: action.error
      })));

    case actions.UPDATE_REACH_ESTIMATE:
      return state;

    case actions.UPDATE_REACH_ESTIMATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        reachEstimate: action.result.data
      })));

    case actions.UPDATE_REACH_ESTIMATE_FAIL:
      return state;

    case actions.GET_CUSTOM_AUDIENCES:
      return state;

    case actions.GET_CUSTOM_AUDIENCES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        customAudiences: action.result.data
      })));

    case actions.GET_CUSTOM_AUDIENCES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        customAudiencesError: action.result.error
      })));

    case actions.PARSE_LINK:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        parsingLinkData: true
      })));

    case actions.PARSE_LINK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        parsedLinkData: action.result.data,
        parsingLinkData: false
      })));

    case actions.PARSE_LINK_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        parsingLinkData: false
      })));

    case actions.SET_AD_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        type: action.adType
      })));

    case actions.SET_BACK_URL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        backUrl: action.url
      })));

    case actions.SET_LEAD_AD_BUILDER_VALUE:
      return function () {
        var builderState = _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, _defineProperty({}, action.key, action.value));

        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, builderState), {}, {
          validationErrors: _objectSpread({}, leadFormValidator(builderState))
        })));
      }();

    case actions.INIT_LEAD_AD_BUILDER:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread({}, leadFormConverter.initialLeadBuilderState(action.orgId))));

    case actions.SET_LEAD_AD_BUILDER_INTRO_BULLET:
      return function () {
        var builderState = _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
          introBullets: _toConsumableArray(state["leadBuilder_".concat(action.orgId)].introBullets.map(function (val, idx) {
            if (action.bulletIdx !== idx) {
              return val;
            }

            return action.value;
          }))
        });

        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, builderState), {}, {
          validationErrors: _objectSpread({}, leadFormValidator(builderState))
        })));
      }();

    case actions.SET_LEAD_AD_BUILDER_QUESTION_CHECK:
      return function () {
        var builderState = _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
          infoQuestions: _toConsumableArray(action.enabled ? [action.key].concat(state["leadBuilder_".concat(action.orgId)].infoQuestions) : state["leadBuilder_".concat(action.orgId)].infoQuestions.filter(function (q) {
            return q !== action.key;
          }))
        });

        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, builderState), {}, {
          validationErrors: _objectSpread({}, leadFormValidator(builderState))
        })));
      }();

    case actions.ADD_CUSTOM_LEADFORM_QUESTION:
      return function () {
        var builderState = _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
          customQuestions: [].concat(_toConsumableArray(state["leadBuilder_".concat(action.orgId)].customQuestions), [{
            type: action.questionType,
            question: '',
            answers: _toConsumableArray(action.questionType === 'appointment_scheduling' ? [true, ''] : [])
          }])
        });

        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, builderState), {}, {
          validationErrors: _objectSpread({}, leadFormValidator(builderState))
        })));
      }();

    case actions.REMOVE_CUSTOM_LEADFORM_QUESTION:
      return function () {
        var a = _toConsumableArray(state["leadBuilder_".concat(action.orgId)].customQuestions);

        a.splice(action.index, 1);

        var builderState = _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
          customQuestions: _toConsumableArray(a)
        });

        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, builderState), {}, {
          validationErrors: _objectSpread({}, leadFormValidator(builderState))
        })));
      }();

    case actions.SET_CUSTOM_LEADFORM_QUESTION:
      return function () {
        var builderState = _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
          customQuestions: _toConsumableArray(state["leadBuilder_".concat(action.orgId)].customQuestions.map(function (q, idx) {
            if (idx === action.index) {
              return _objectSpread(_objectSpread({}, q), {}, {
                question: action.value
              });
            }

            return q;
          }))
        });

        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, builderState), {}, {
          validationErrors: _objectSpread({}, leadFormValidator(builderState))
        })));
      }();

    case actions.ADD_LEADFORM_MULTI_ANSWER:
      return function () {
        var builderState = _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
          customQuestions: _toConsumableArray(state["leadBuilder_".concat(action.orgId)].customQuestions.map(function (q, idx) {
            if (idx === action.index) {
              return _objectSpread(_objectSpread({}, q), {}, {
                answers: [].concat(_toConsumableArray(q.answers), [action.value])
              });
            }

            return q;
          }))
        });

        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, builderState), {}, {
          validationErrors: _objectSpread({}, leadFormValidator(builderState))
        })));
      }();

    case actions.REMOVE_LEADFORM_MULTI_ANSWER:
      return function () {
        var answers = _toConsumableArray(state["leadBuilder_".concat(action.orgId)].customQuestions[action.customQuestionIndex].answers);

        answers.splice(action.answerIndex, 1);

        var builderState = _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
          customQuestions: _toConsumableArray(state["leadBuilder_".concat(action.orgId)].customQuestions.map(function (q, idx) {
            if (idx === action.customQuestionIndex) {
              return _objectSpread(_objectSpread({}, q), {}, {
                answers: _toConsumableArray(answers)
              });
            }

            return q;
          }))
        });

        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, builderState), {}, {
          validationErrors: _objectSpread({}, leadFormValidator(builderState))
        })));
      }();

    case actions.SET_APPOINTMENT_CONFIRM_ENABLED:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
        customQuestions: _toConsumableArray(state["leadBuilder_".concat(action.orgId)].customQuestions.map(function (q, idx) {
          if (idx === action.customQuestionIndex) {
            return _objectSpread(_objectSpread({}, q), {}, {
              answers: _toConsumableArray(q.answers.map(function (an, aidx) {
                if (aidx === 0) {
                  return action.value;
                }

                return an;
              }))
            });
          }

          return q;
        }))
      })));

    case actions.SET_APPOINTMENT_CONFIRM_TEXT:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
        customQuestions: _toConsumableArray(state["leadBuilder_".concat(action.orgId)].customQuestions.map(function (q, idx) {
          if (idx === action.customQuestionIndex) {
            return _objectSpread(_objectSpread({}, q), {}, {
              answers: _toConsumableArray(q.answers.map(function (an, aidx) {
                if (aidx === 1) {
                  return action.value;
                }

                return an;
              }))
            });
          }

          return q;
        }))
      })));

    case actions.ADD_NEW_CONSENT:
      return function () {
        var builderState = _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
          customDisclaimerConsents: [].concat(_toConsumableArray(state["leadBuilder_".concat(action.orgId)].customDisclaimerConsents), [{
            text: '',
            isRequired: true
          }])
        });

        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, builderState), {}, {
          validationErrors: _objectSpread({}, leadFormValidator(builderState))
        })));
      }();

    case actions.SET_CONSENT_TEXT:
      return function () {
        var builderState = _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
          customDisclaimerConsents: _toConsumableArray(state["leadBuilder_".concat(action.orgId)].customDisclaimerConsents.map(function (consent, idx) {
            if (idx === action.consentIdx) {
              return _objectSpread(_objectSpread({}, consent), {}, {
                text: action.value
              });
            }

            return consent;
          }))
        });

        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, builderState), {}, {
          validationErrors: _objectSpread({}, leadFormValidator(builderState))
        })));
      }();

    case actions.SET_CONSENT_REQUIRED:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
        customDisclaimerConsents: _toConsumableArray(state["leadBuilder_".concat(action.orgId)].customDisclaimerConsents.map(function (consent, idx) {
          if (idx === action.consentIdx) {
            return _objectSpread(_objectSpread({}, consent), {}, {
              isRequired: action.value
            });
          }

          return consent;
        }))
      })));

    case actions.REMOVE_CUSTOM_CONSENT:
      return function () {
        var consents = _toConsumableArray(state["leadBuilder_".concat(action.orgId)].customDisclaimerConsents);

        consents.splice(action.consentIdx, 1);

        var builderState = _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
          customDisclaimerConsents: _toConsumableArray(consents)
        });

        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, builderState), {}, {
          validationErrors: _objectSpread({}, leadFormValidator(builderState))
        })));
      }();

    case actions.FETCH_FORMS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
        isLoadingExistingForms: true
      })));

    case actions.FETCH_FORMS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
        isLoadingExistingForms: false
      })));

    case actions.FETCH_FORM_DETAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
        isLoadingFormDetail: true,
        selectedExistingFormId: action.formId
      })));

    case actions.FETCH_FORM_DETAIL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
        isLoadingFormDetail: false,
        selectedExistingFormId: -1
      })));

    case actions.FETCH_FORM_DETAIL_SUCCESS:
      return function () {
        var data = action.result.data;
        var obj = leadFormConverter.toRedux(data, action.orgId);
        return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
          isLoadingFormDetail: false
        }, obj)));
      }();

    case actions.SAVE_LEADFORM:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
        isSavingForm: true
      })));

    case actions.SAVE_LEADFORM_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
        isSavingForm: false,
        saveError: action.error.error.message
      })));

    case actions.CLEAR_LEAD_FORM_DATA:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread({}, leadFormConverter.initialLeadBuilderState(action.orgId))));

    case actions.SAVE_LEADFORM_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "leadBuilder_".concat(action.orgId), _objectSpread(_objectSpread({}, state["leadBuilder_".concat(action.orgId)]), {}, {
        isSavingForm: false,
        createdFormId: action.result.data.id
      })));

    case actions.GET_PAGE_INSTA_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        adAccountConnectedInstagramId: action.result.data
      })));

    case actions.CLEAR_AD_BUILDER_FOR_ORG:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), undefined));

    case actions.GET_AVAILABLE_AD_BUDGET_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        availableAdsFunds: action.result.data
      })));

    case actions.CONVERT_AD_TO_REDUX_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, orgInitialState), action.result.data.redux)));

    case actions.ADBUILDER_CHANGE_VALUE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), action.keyValues)));

    case actions.UPLOAD_CAROUSEL_ITEM_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        uploadingFile: false
      })));

    case actions.UPLOAD_CAROUSEL_ITEM_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        uploadingFile: false
      })));

    case actions.ADBUILDER_DEEPLINK:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, orgInitialState), {}, {
        adStart: moment().toDate(),
        adEnd: moment().add(7, 'days').toDate(),
        step: 1
      }, action.keyValues)));

    case actions.CLEAR_AUDIENCE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        audienceName: '',
        reachEstimate: null,
        deviceSelectValue: 'all',
        includedBehaviors: [],
        excludedBehaviors: [],
        includedDemographics: [],
        excludedDemographics: [],
        includedInterests: [],
        excludedInterests: [],
        includedLocations: [],
        excludedLocations: [],
        includedCustomAudiences: [],
        excludedCustomAudiences: [],
        selectedGender: 'Everyone',
        selectedStartAge: '21',
        selectedEndAge: '65'
      })));

    default:
      return state;
  }
}