import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { uniqBy, sortBy, startCase } from 'lodash';
import moment from 'moment';
var ns = 'likeablelocal/organization';
var GET_AFFILIATE_PARTNERS = "".concat(ns, "/GET_AFFILIATE_PARTNERS");
var GET_AFFILIATE_PARTNERS_SUCCESS = "".concat(ns, "/GET_AFFILIATE_PARTNERS_SUCCESS");
var GET_AFFILIATE_PARTNERS_FAIL = "".concat(ns, "/GET_AFFILIATE_PARTNERS_FAIL");
var GET_MULTILOCATION_PARTNERS = "".concat(ns, "/GET_MULTILOCATION_PARTNERS");
var GET_MULTILOCATION_PARTNERS_SUCCESS = "".concat(ns, "/GET_MULTILOCATION_PARTNERS_SUCCESS");
var GET_MULTILOCATION_PARTNERS_FAIL = "".concat(ns, "/GET_MULTILOCATION_PARTNERS_FAIL");
var GET_ALL_PARTNERS = "".concat(ns, "/GET_ALL_PARTNERS");
var GET_ALL_PARTNERS_SUCCESS = "".concat(ns, "/GET_ALL_PARTNERS_SUCCESS");
var GET_ALL_PARTNERS_FAIL = "".concat(ns, "/GET_ALL_PARTNERS_FAIL");
var SEARCH_PARTNERS = "".concat(ns, "/SEARCH_PARTNERS");
var SEARCH_PARTNERS_SUCCESS = "".concat(ns, "/SEARCH_PARTNERS_SUCCESS");
var SEARCH_PARTNERS_FAIL = "".concat(ns, "/SEARCH_PARTNERS_FAIL");
var CLEAR_PARTNER_SEARCH = "".concat(ns, "/CLEAR_PARTNER_SEARCH");
var GET_PARTNER_CLIENTS = "".concat(ns, "/GET_PARTNER_CLIENTS");
var GET_PARTNER_CLIENTS_SUCCESS = "".concat(ns, "/GET_PARTNER_CLIENTS_SUCCESS");
var GET_PARTNER_CLIENTS_FAIL = "".concat(ns, "/GET_PARTNER_CLIENTS_FAIL");
var initialState = {
  allPartners: [],
  multilocationPartners: [],
  gettingMultilocationPartners: false,
  affiliatePartners: [],
  gettingAffiliatePartners: false,
  gettingAllPartners: false,
  partnerClients: [],
  gettingPartnerClients: false,
  partnerSearchResults: []
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case GET_MULTILOCATION_PARTNERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingMultilocationPartners: true
      });

    case GET_MULTILOCATION_PARTNERS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingMultilocationPartners: false,
        multilocationPartners: action.result.data
      });

    case GET_MULTILOCATION_PARTNERS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingMultilocationPartners: false
      });

    case GET_AFFILIATE_PARTNERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAffiliatePartners: true
      });

    case GET_AFFILIATE_PARTNERS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAffiliatePartners: false,
        affiliatePartners: action.result.data
      });

    case GET_AFFILIATE_PARTNERS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAffiliatePartners: false
      });

    case GET_ALL_PARTNERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAllPartners: true
      });

    case GET_ALL_PARTNERS_SUCCESS:
      return function () {
        var allPartners = action.result.data.Organizations.map(function (partner) {
          var contract = partner.Contracts && partner.Contracts.length > 0 ? partner.Contracts[0] : {
            firstName: 'Unknown',
            lastName: '',
            email: 'Unknown',
            SalesUser: {
              firstName: 'Unknown',
              lastName: ''
            }
          };
          return {
            orgId: partner.id,
            name: partner.name,
            ownerFullName: "".concat(contract.firstName, " ").concat(contract.lastName),
            email: contract.email,
            partnerType: startCase(function () {
              try {
                return contract.PartnerContracts[0].partnerType || 'regular';
              } catch (e) {
                return 'regular';
              }
            }()),
            createdAt: moment(partner.createdAt).format('L'),
            status: startCase(partner.status),
            salesRepName: "".concat(contract.SalesUser.firstName, " ").concat(contract.SalesUser.lastName)
          };
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          allPartners: sortBy(uniqBy([].concat(_toConsumableArray(state.allPartners), _toConsumableArray(allPartners)), 'orgId'), 'name'),
          gettingAllPartners: false
        });
      }();

    case GET_ALL_PARTNERS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAllPartners: false
      });

    case GET_PARTNER_CLIENTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPartnerClients: true
      });

    case GET_PARTNER_CLIENTS_SUCCESS:
      var partnerClients = action.result.data.map(function (client) {
        return {
          orgId: client.id,
          name: client.name,
          email: client.Contracts[0].email,
          fullName: "".concat(client.Contracts[0].firstName, " ").concat(client.Contracts[0].lastName)
        };
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPartnerClients: false,
        partnerClients: _objectSpread(_objectSpread({}, state.partnerClients), {}, _defineProperty({}, action.orgId, partnerClients))
      });

    case GET_PARTNER_CLIENTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPartnerClients: false
      });

    case SEARCH_PARTNERS_SUCCESS:
      return function () {
        var partnerSearchResults = action.result.data.Organizations.map(function (partner) {
          var contract = partner.Contracts && partner.Contracts.length > 0 ? partner.Contracts[0] : {
            firstName: 'Unknown',
            lastName: '',
            email: 'Unknown',
            SalesUser: {
              firstName: 'Unknown',
              lastName: ''
            }
          };
          return {
            orgId: partner.id,
            name: partner.name,
            ownerFullName: "".concat(contract.firstName, " ").concat(contract.lastName),
            email: contract.email,
            partnerType: startCase(function () {
              try {
                return contract.PartnerContracts[0].partnerType || 'regular';
              } catch (e) {
                return 'regular';
              }
            }()),
            createdAt: moment(partner.createdAt).format('L'),
            status: startCase(partner.status),
            salesRepName: "".concat(contract.SalesUser.firstName, " ").concat(contract.SalesUser.lastName)
          };
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          partnerSearchResults: partnerSearchResults
        });
      }();

    case CLEAR_PARTNER_SEARCH:
      return _objectSpread(_objectSpread({}, state), {}, {
        partnerSearchResults: []
      });

    default:
      return state;
  }
}
export function clearPartnerSearch() {
  return {
    type: CLEAR_PARTNER_SEARCH
  };
}
export function searchPartners(search) {
  return {
    types: [SEARCH_PARTNERS, SEARCH_PARTNERS_SUCCESS, SEARCH_PARTNERS_FAIL],
    promise: function promise(client) {
      return client.post('/graphql', {
        data: {
          query: "query getPartnerOrgs(\n  $search:String\n) {\n  Organizations(\n    type: 2,\n    order: \"name\",\n  \tsearch: $search,\n  ) {\n    id\n    name\n    status\n    createdAt\n    Contracts {\n      firstName\n      lastName\n      email\n      PartnerContracts {\n        partnerType\n      }\n      SalesUser {\n        firstName\n        lastName\n      }\n    }\n\t}\n}",
          variables: {
            search: search
          }
        }
      });
    }
  };
}
export function getAllPartners() {
  var limit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 25;
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return {
    types: [GET_ALL_PARTNERS, GET_ALL_PARTNERS_SUCCESS, GET_ALL_PARTNERS_FAIL],
    promise: function promise(client) {
      return client.post('/graphql', {
        data: {
          query: "query getPartnerOrgs(\n  $limit:Int,\n  $offset:Int\n) {\n  Organizations(\n    type: 2,\n    order: \"name\",\n    limit: $limit,\n    offset: $offset\n  ) {\n    id\n    name\n    status\n    createdAt\n    Contracts {\n      firstName\n      lastName\n      email\n      PartnerContracts {\n        partnerType\n      }\n      SalesUser {\n        firstName\n        lastName\n      }\n    }\n\t}\n}",
          variables: {
            limit: limit,
            offset: offset
          }
        }
      });
    }
  };
}
export function getAffiliatePartners() {
  return {
    types: [GET_AFFILIATE_PARTNERS, GET_AFFILIATE_PARTNERS_SUCCESS, GET_AFFILIATE_PARTNERS_FAIL],
    promise: function promise(client) {
      return client.get('/organizations/partners?subtype=affiliate');
    }
  };
}
export function getMultilocationPartners() {
  return {
    types: [GET_MULTILOCATION_PARTNERS, GET_MULTILOCATION_PARTNERS_SUCCESS, GET_MULTILOCATION_PARTNERS_FAIL],
    promise: function promise(client) {
      return client.get('/organizations/partners?subtype=multilocation');
    }
  };
}
export function getPartnerClients(orgId) {
  return {
    types: [GET_PARTNER_CLIENTS, GET_PARTNER_CLIENTS_SUCCESS, GET_PARTNER_CLIENTS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/partnerClients"));
    },
    orgId: orgId
  };
}