import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as _ from 'lodash';
import moment from 'moment';
var ns = 'likeablelocal/feedback';
var GET_ALL_FEEDBACK_THREADS = "".concat(ns, "/GET_ALL_FEEDBACK_THREADS");
var GET_ALL_FEEDBACK_THREADS_SUCCESS = "".concat(ns, "/GET_ALL_FEEDBACK_THREADS_SUCCESS");
var GET_ALL_FEEDBACK_THREADS_FAIL = "".concat(ns, "/GET_ALL_FEEDBACK_THREADS_FAIL");
var GET_FEEDBACK_BY_SUBJECT_ID = "".concat(ns, "/GET_FEEDBACK_BY_SUBJECT_ID");
var GET_FEEDBACK_BY_SUBJECT_ID_SUCCESS = "".concat(ns, "/GET_FEEDBACK_BY_SUBJECT_ID_SUCCESS");
var GET_FEEDBACK_BY_SUBJECT_ID_FAIL = "".concat(ns, "/GET_FEEDBACK_BY_SUBJECT_ID_FAIL");
var GET_FEEDBACK_THREAD = "".concat(ns, "/GET_FEEDBACK_THREAD");
var GET_FEEDBACK_THREAD_SUCCESS = "".concat(ns, "/GET_FEEDBACK_THREAD_SUCCESS");
var GET_FEEDBACK_THREAD_FAIL = "".concat(ns, "/GET_FEEDBACK_THREAD_FAIL");
var POST_FEEDBACK = "".concat(ns, "/POST_FEEDBACK");
var POST_FEEDBACK_SUCCESS = "".concat(ns, "/POST_FEEDBACK_SUCCESS");
var POST_FEEDBACK_FAIL = "".concat(ns, "/POST_FEEDBACK_FAIL");
var initialState = {
  gettingAllFeedbackThreads: false,
  gettingFeedbackThread: false,
  feedbackThreadsByDraftPostId: {},
  orgFeedbackThreads: {},
  postingFeedback: false
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _.cloneDeep(initialState);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case GET_FEEDBACK_BY_SUBJECT_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingFeedbackBySubjectId: true
      });

    case GET_FEEDBACK_BY_SUBJECT_ID_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingFeedbackBySubjectId: false,
        feedbackThreadsByDraftPostId: _objectSpread(_objectSpread({}, state.feedbackThreadsByDraftPostId), {}, _defineProperty({}, action.subjectId, action.result.data))
      });

    case GET_FEEDBACK_BY_SUBJECT_ID_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingFeedbackBySubjectId: false
      });

    case GET_FEEDBACK_THREAD:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingFeedbackThread: true
      });

    case GET_FEEDBACK_THREAD_SUCCESS:
      // TEST needed for this
      var draftPostId = parseInt(action.result.data.subjectId);
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingFeedbackThread: false,
        feedbackThreadsByDraftPostId: _objectSpread(_objectSpread({}, state.feedbackThreadsByDraftPostId), {}, _defineProperty({}, draftPostId, action.result.data))
      });

    case GET_FEEDBACK_THREAD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingFeedbackThread: false
      });

    case GET_ALL_FEEDBACK_THREADS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAllFeedbackThreads: true
      });

    case GET_ALL_FEEDBACK_THREADS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        orgFeedbackThreads: _objectSpread(_objectSpread({}, state.orgFeedbackThreads), {}, _defineProperty({}, action.orgId, action.result.data.filter(function (d) {
          return d.subjectType !== action.type;
        }))),
        gettingAllFeedbackThreads: false
      });

    case GET_ALL_FEEDBACK_THREADS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAllFeedbackThreads: false
      });

    case POST_FEEDBACK:
      return _objectSpread(_objectSpread({}, state), {}, {
        postingFeedback: true
      });

    case POST_FEEDBACK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        postingFeedback: false,
        feedbackThreadsByDraftPostId: _objectSpread(_objectSpread({}, state.feedbackThreadsByDraftPostId), {}, _defineProperty({}, action.subjectId, action.result.data))
      });

    case POST_FEEDBACK_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        postingFeedback: false
      });

    default:
      return state;
  }
}
export function getAllFeedbackThreads(orgId, type) {
  return {
    types: [GET_ALL_FEEDBACK_THREADS, GET_ALL_FEEDBACK_THREADS_SUCCESS, GET_ALL_FEEDBACK_THREADS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/feedback/threads"));
    },
    orgId: orgId,
    type: type
  };
}
export function getFeedbackThread(orgId, threadId) {
  return {
    types: [GET_FEEDBACK_THREAD, GET_FEEDBACK_THREAD_SUCCESS, GET_FEEDBACK_THREAD_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/feedback/thread/").concat(threadId));
    },
    orgId: orgId,
    threadId: threadId
  };
}
export function getFeedbackBySubjectId(orgId, subjectId) {
  var subjectType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'DraftPost';
  return {
    types: [GET_FEEDBACK_BY_SUBJECT_ID, GET_FEEDBACK_BY_SUBJECT_ID_SUCCESS, GET_FEEDBACK_BY_SUBJECT_ID_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/feedback/type/").concat(subjectType, "/id/").concat(subjectId));
    },
    orgId: orgId,
    subjectId: subjectId,
    subjectType: subjectType
  };
}
export function postFeedback(_ref) {
  var linkUrl = _ref.linkUrl,
      orgId = _ref.orgId,
      orgName = _ref.orgName,
      message = _ref.message,
      subjectType = _ref.subjectType,
      subjectId = _ref.subjectId,
      _ref$token = _ref.token,
      token = _ref$token === void 0 ? null : _ref$token,
      _ref$attachments = _ref.attachments,
      attachments = _ref$attachments === void 0 ? null : _ref$attachments;
  return {
    types: [POST_FEEDBACK, POST_FEEDBACK_SUCCESS, POST_FEEDBACK_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/feedback/thread/message"), {
        data: {
          linkUrl: linkUrl,
          orgName: orgName,
          message: message,
          subjectType: subjectType,
          subjectId: parseInt(subjectId),
          token: token,
          attachments: attachments
        }
      });
    },
    subjectId: subjectId
  };
}