import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import moment from 'moment';
export function toFacebook(builder) {
  return {
    name: builder.formName,
    question_page_custom_headline: builder.questionsHeadline,
    questions: [].concat(_toConsumableArray(builder.customQuestions && builder.customQuestions.length ? _toConsumableArray(builder.customQuestions).filter(function (q) {
      return q && q.question;
    }).map(function (q) {
      return {
        type: 'CUSTOM',
        key: q.question.replace(/\W+/g, '_'),
        label: q.question,
        options: q.type === 'multiple_choice' ? _toConsumableArray(q.answers.map(function (a) {
          return {
            key: a.replace(/\W+/g, '_'),
            value: a
          };
        })) : undefined
      };
    }) : []), _toConsumableArray(builder.infoQuestions.filter(function (q) {
      return q;
    }).map(function (question) {
      return _objectSpread({}, question ? {
        type: "".concat(question).toUpperCase()
      } : {});
    }))),
    thank_you_page: {
      title: builder.thankYouHeadline,
      body: builder.thankYouDescription,
      button_text: builder.thankYouButtonText,
      button_type: 'VIEW_WEBSITE',
      website_url: builder.thankYouButtonURL
    },
    context_card: builder.introEnabled ? {
      cover_photo: builder.imageUrl,
      title: builder.introHeadline,
      content: builder.introLayoutType === 0 ? [builder.introParagraph] : builder.introBullets,
      style: builder.introLayoutType === 0 ? 'PARAGRAPH_STYLE' : 'LIST_STYLE'
    } : null,
    legal_content: {
      privacy_policy: {
        url: builder.privacyPolicyURL,
        link_text: builder.privacyPolicyLinkText
      },
      custom_disclaimer: builder.customDisclaimerEnabled ? {
        title: builder.customDisclaimerTitle,
        body: {
          text: builder.customDisclaimerText
        },
        checkboxes: _toConsumableArray(builder.customDisclaimerConsents && builder.customDisclaimerConsents.length ? builder.customDisclaimerConsents.map(function (consent) {
          return {
            is_required: consent.isRequired,
            text: consent.text,
            key: consent.text.replace(/\W+/g, '_')
          };
        }) : [])
      } : undefined
    },
    locale: builder.formLanguage,
    block_display_for_non_targeted_viewer: builder.blockDisplayForNonTargetedViewer
  };
}
export function toRedux(data, organizationId) {
  var obj = initialLeadBuilderState(organizationId);
  obj.formName = "".concat(data.name, "-copy");
  obj.selectedExistingFormId = -1;
  obj.isCreatingNewForm = true;
  obj.blockDisplayForNonTargetedViewer = data.block_display_for_non_targeted_viewer;
  obj.formLanguage = data.locale;

  if (data.context_card) {
    obj.introLayoutType = data.context_card.style === 'PARAGRAPH_STYLE' ? 0 : 1;
    obj.introHeadline = data.context_card.title;

    if (data.context_card.style === 'PARAGRAPH_STYLE') {
      obj.introParagraph = data.context_card.content[0];
    } else {
      obj.introBullets = data.context_card.content;
    }

    if (data.context_card.cover_photo && data.context_card.cover_photo.images && data.context_card.cover_photo.images.length) {
      obj.imageUrl = data.context_card.cover_photo.images[0].source;
      obj.introImageType = 'uploaded';
    }
  } else {
    data.introEnabled = false;
  }

  if (data.questions) {
    obj.infoQuestions = _toConsumableArray(data.questions.filter(function (q) {
      return q.type !== 'CUSTOM';
    }).map(function (q) {
      return q.type.toLowerCase();
    }));
    obj.customQuestions = _toConsumableArray(data.questions.filter(function (q) {
      return q.type === 'CUSTOM';
    }).map(function (q) {
      var type;

      if (q.options && q.options.length) {
        type = 'multiple_choice';
      } else {
        type = 'short_answer';
      }

      return {
        type: type,
        question: q.label,
        answers: _toConsumableArray(q.options ? q.options.map(function (q) {
          return q.value;
        }) : [])
      };
    }));
  }

  if (data.question_page_custom_headline) {
    obj.questionsHeadline = data.question_page_custom_headline;
  }

  if (data.legal_content) {
    if (data.legal_content.privacy_policy) {
      obj.privacyPolicyLinkText = data.legal_content.privacy_policy.link_text;
      obj.privacyPolicyURL = data.legal_content.privacy_policy.url;
    }

    if (data.legal_content.custom_disclaimer) {
      obj.customDisclaimerEnabled = true;
      obj.customDisclaimerTitle = data.legal_content.custom_disclaimer.title;
      obj.customDisclaimerText = data.legal_content.custom_disclaimer.body ? data.legal_content.custom_disclaimer.body.text : '';
      obj.customDisclaimerConsents = _toConsumableArray(data.legal_content.custom_disclaimer.checkboxes && data.legal_content.custom_disclaimer.checkboxes.length ? data.legal_content.custom_disclaimer.checkboxes.map(function (checkbox) {
        return {
          text: checkbox.text,
          isRequired: checkbox.is_required
        };
      }) : []);
    }
  }

  if (data.thank_you_page) {
    obj.thankYouHeadline = data.thank_you_page.title;
    obj.thankYouDescription = data.thank_you_page.body;
    obj.thankYouButtonText = data.thank_you_page.button_text;
    obj.thankYouButtonURL = data.thank_you_page.website_url;
  }

  return obj;
}
export function initialLeadBuilderState(orgId) {
  var currentHostname = global.window ? global.window.location.hostname : 'app.likeablelocal.com';
  return {
    formName: "Untitled form ".concat(moment().format('YYYY-MM-DD')),
    formLanguage: 'en_US',
    blockDisplayForNonTargetedViewer: true,
    previewStep: 'intro',
    introEnabled: true,
    uploadingImage: false,
    imageUrl: 'http://bit.ly/2k0Ds2T',
    introLayoutType: 0,
    introHeadline: 'Headline Text',
    introImageType: 'fromAd',
    introParagraph: '',
    introBullets: ['', '', '', '', ''],
    contentAccordionTab: 0,
    questionsHeadline: 'Sign up by providing your info below.',
    infoQuestions: ['email', 'full_name'],
    customQuestions: [],
    privacyPolicyLinkText: '',
    privacyPolicyURL: "https://".concat(currentHostname, "/privacy-policy/").concat(orgId),
    customDisclaimerEnabled: false,
    customDisclaimerTitle: '',
    customDisclaimerText: '',
    customDisclaimerConsents: [],
    thankYouHeadline: "Thanks, you're all set.",
    thankYouDescription: 'Your info has been sent to us. Tap below to visit our website.',
    thankYouButtonType: 'view_website',
    thankYouButtonText: 'View Website',
    thankYouButtonURL: '',
    existingForms: [],
    isCreatingNewForm: false,
    selectedExistingFormId: -1,
    isSavingForm: false
  };
}