import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import moment from 'moment';
import { get as getField, set as setField } from 'lodash';
import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
import { CLOSE_MODAL } from './modalLayout';
var ADD_IMAGE = 'likeablelocal/composeboxForm/ADD_IMAGE';
var ADD_VIDEO = 'likeablelocal/composeboxForm/ADD_VIDEO';
var GET_VALUES = 'likeablelocal/composeboxForm/GET_VALUES';
var NULLIFY_PUBLISH_AT = 'likeablelocal/composeboxForm/NULLIFY_PUBLISH_AT';
var REMOVE_IMAGE = 'likeablelocal/composeboxForm/REMOVE_IMAGE';
var SET_BACK_URL = 'likeablelocal/composeboxForm/SET_BACK_URL';
var TOGGLE_QUEUED_POST = 'likeablelocal/composeboxForm/TOGGLE_QUEUED_POST';
var UPDATE_IMAGE_ORDER = 'likeablelocal/composeboxForm/UPDATE_IMAGE_ORDER';
var UPDATE_MESSAGE = 'likeablelocal/composeboxForm/UPDATE_MESSAGE';
var UPDATE_PLATFORM_CHECKBOX = 'likeablelocal/composeboxForm/UPDATE_PLATFORM_CHECKBOX';
var UPDATE_POST_TYPE = 'likeablelocal/composeboxForm/UPDATE_POST_TYPE';
var UPDATE_PUBLISH_AT = 'likeablelocal/composeboxForm/UPDATE_PUBLISH_AT';
var UPDATE_LINK_DATA = 'likeablelocal/composeboxForm/UPDATE_LINK_DATA';
var INIT = 'likeablelocal/composeboxForm/INIT';
var RESET_COMPOSEBOX = 'likeablelocal/composeboxForm/RESET_COMPOSEBOX';
var REMOVE_ALL_IMAGES = 'likeablelocal/composeboxForm/REMOVE_ALL_IMAGES';
var REMOVE_VIDEO = 'likeablelocal/composeboxForm/REMOVE_VIDEO';
var WIPE_LINK_DATA = 'likeablelocal/composeboxForm/WIPE_LINK_DATA';
var LOAD_LINK_PARSE = 'likeablelocal/composeboxForm/LOAD_LINK_PARSE';
var LOAD_LINK_PARSE_SUCCESS = 'likeablelocal/composeboxForm/LOAD_LINK_PARSE_SUCCESS';
var LOAD_LINK_PARSE_FAIL = 'likeablelocal/composeboxForm/LOAD_PARSE_FAIL';
var SUBMIT_POST = 'likeablelocal/composeboxForm/SUBMIT_POST';
export var SUBMIT_POST_SUCCESS = 'likeablelocal/composeboxForm/SUBMIT_POST_SUCCESS';
var SUBMIT_POST_FAIL = 'likeablelocal/composeboxForm/SUBMIT_POST_FAIL';
var SET_SELECTED_PREVIEW_TAB = 'likeablelocal/composeboxForm/SET_SELECTED_PREVIEW_TAB';
var SET_SELECTED_LINKEDIN_PREVIEW_TYPE = 'likeablelocal/composeboxForm/SET_SELECTED_LINKEDIN_PREVIEW_TYPE';
var SET_POST_TIME_TYPE = 'likeablelocal/composeboxForm/SET_POST_TIME_TYPE';
var SET_DRAFT_AUTO_SCHEDULE_TOGGLE = 'likeablelocal/composeboxForm/SET_DRAFT_AUTO_SCHEDULE_TOGGLE';
var SET_DRAFT_AUTO_PUBLISH = 'likeablelocal/composeboxForm/SET_DRAFT_AUTO_PUBLISH';
export var orgInitialState = {
  advertising: null,
  convertQueuedPost: false,
  DraftPost: null,
  existingPostId: null,
  existingPostQueueId: null,
  images: [],
  linkData: {
    caption: null,
    description: null,
    image: null,
    link: null,
    title: null
  },
  messages: {
    global: '',
    facebook: null,
    twitter: null,
    linkedin: null,
    instagram: null
  },
  postType: 'textPost',
  publishAt: null,
  selectedAccounts: {
    facebookPage: true,
    facebookProfile: true,
    twitter: true,
    linkedinProfile: true,
    linkedinCompany: true,
    instagram: true,
    googleMybusiness: true
  },
  videoUrl: null,
  selectedPreviewTab: 'Facebook',
  selectedLinkedinPreviewType: 'profile',
  postTimeType: 'draft',
  draftPostToggleState: false
};
var initialState = {
  submittingPost: false,
  parsingLink: false
};

function defaultKeyResolver(action) {
  return action.meta ? action.meta.controlInstanceKey : undefined;
}

export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case ADD_IMAGE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        images: state["".concat(action.orgId)].images.length ? [].concat(_toConsumableArray(state["".concat(action.orgId)].images), [action.imageUrl]) : [action.imageUrl]
      })));

    case ADD_VIDEO:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        videoUrl: action.videoUrl
      })));

    case INIT:
      var publishAt = action.post.publishAt ? action.post.publishAt : orgInitialState.publishAt;

      if (action.extra && action.extra.minDate) {
        publishAt = action.extra.minDate;
      }

      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread(_objectSpread({}, state["".concat(action.orgId)]), action.extra), {}, {
        DraftPost: action.post.DraftPost ? action.post.DraftPost : orgInitialState.DraftPost,
        selectedPreviewTab: 'Facebook',
        selectedLinkedinPreviewType: 'profile',
        advertising: action.post.advertising ? action.post.advertising : orgInitialState.advertising,
        convertQueuedPost: action.post.convertQueuedPost ? action.post.convertQueuedPost : orgInitialState.convertQueuedPost,
        existingPostId: action.post.existingPostId ? action.post.existingPostId : orgInitialState.existingPostId,
        existingPostQueueId: action.post.existingPostId ? action.post.existingPostQueueId : orgInitialState.existingPostQueueId,
        images: action.post.images ? action.post.images : orgInitialState.images,
        linkData: {
          caption: !!action.post.linkData && !!action.post.linkData.caption ? action.post.linkData.caption : orgInitialState.linkData.caption,
          description: !!action.post.linkData && !!action.post.linkData.description ? action.post.linkData.description : orgInitialState.linkData.description,
          image: !!action.post.linkData && !!action.post.linkData.image ? action.post.linkData.image : orgInitialState.linkData.image,
          link: !!action.post.linkData && !!action.post.linkData.link ? action.post.linkData.link : orgInitialState.linkData.link,
          title: !!action.post.linkData && !!action.post.linkData.title ? action.post.linkData.title : orgInitialState.linkData.title
        },
        messages: {
          global: !!action.post.messages && !!action.post.messages.global ? action.post.messages.global : orgInitialState.messages.global,
          facebook: !!action.post.messages && !!action.post.messages.facebook ? action.post.messages.facebook : orgInitialState.messages.facebook,
          twitter: !!action.post.messages && !!action.post.messages.twitter ? action.post.messages.twitter : orgInitialState.messages.twitter,
          linkedin: !!action.post.messages && !!action.post.messages.linkedin ? action.post.messages.linkedin : orgInitialState.messages.linkedin,
          instagram: !!action.post.messages && !!action.post.messages.instagram ? action.post.messages.instagram : orgInitialState.messages.instagram,
          googleMybusiness: !!action.post.messages && !!action.post.messages.googleMybusiness ? action.post.messages.googleMybusiness : orgInitialState.messages.googleMybusiness
        },
        organizationId: action.orgId,
        postType: action.post.postType ? action.post.postType : orgInitialState.postType,
        publishAt: publishAt,
        selectedAccounts: {
          facebookPage: action.post && action.post.selectedAccounts ? action.post.selectedAccounts.facebookPage : orgInitialState.selectedAccounts.facebookPage,
          facebookProfile: action.post.selectedAccounts ? action.post && action.post.selectedAccounts.facebookProfile : orgInitialState.selectedAccounts.facebookProfile,
          twitter: action.post && action.post.selectedAccounts ? action.post.selectedAccounts.twitter : orgInitialState.selectedAccounts.twitter,
          linkedinProfile: action.post && action.post.selectedAccounts ? action.post.selectedAccounts.linkedinProfile : orgInitialState.selectedAccounts.linkedinProfile,
          linkedinCompany: action.post && action.post.selectedAccounts ? action.post.selectedAccounts.linkedinCompany : orgInitialState.selectedAccounts.linkedinCompany,
          instagram: action.post && action.post.selectedAccounts ? action.post.selectedAccounts.instagram : orgInitialState.selectedAccounts.instagram,
          googleMybusiness: action.post && action.post.selectedAccounts ? action.post.selectedAccounts.googleMybusiness : orgInitialState.selectedAccounts.googleMybusiness
        },
        videoUrl: action.post.videoUrl ? action.post.videoUrl : orgInitialState.videoUrl,
        postTimeType: function () {
          if (action.post.DraftPost && action.post.DraftPost.id && !action.post.publishAt) {
            return 'draft';
          }

          if (action.post.publishAt && moment(action.post.publishAt).isAfter(moment())) {
            return 'schedule';
          }

          return orgInitialState.postTimeType;
        }(),
        draftPostToggleState: action.post.DraftPost && action.post.DraftPost.id && !action.post.publishAt && action.post.DraftPost.suggestedPublishAt
      })));

    case LOGOUT_SUCCESS:
      return {};

    case NULLIFY_PUBLISH_AT:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        publishAt: null
      })));

    case RESET_TO_INITIAL_STATE:
      return {};

    case RESET_COMPOSEBOX:
      var stateCopy = Object.assign({}, state);
      delete stateCopy[action.orgId];
      return _objectSpread({}, stateCopy);

    case REMOVE_IMAGE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        images: [].concat(_toConsumableArray(state["".concat(action.orgId)].images.slice(0, action.index)), _toConsumableArray(state["".concat(action.orgId)].images.slice(action.index + 1)))
      })));

    case REMOVE_ALL_IMAGES:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        images: []
      })));

    case REMOVE_VIDEO:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        videoUrl: null
      })));

    case TOGGLE_QUEUED_POST:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        convertQueuedPost: !state["".concat(action.orgId)].convertQueuedPost
      })));

    case UPDATE_IMAGE_ORDER:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        images: action.values
      })));

    case UPDATE_LINK_DATA:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        linkData: _objectSpread(_objectSpread({}, state["".concat(action.orgId)].linkData), {}, _defineProperty({}, action.field, action.value))
      })));

    case UPDATE_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        messages: _objectSpread(_objectSpread({}, state["".concat(action.orgId)].messages ? state["".concat(action.orgId)].messages : _objectSpread({}, orgInitialState.messages)), {}, _defineProperty({}, action.messageType, action.value))
      })));

    case UPDATE_PLATFORM_CHECKBOX:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        selectedAccounts: _objectSpread(_objectSpread({}, state["".concat(action.orgId)] ? state["".concat(action.orgId)].selectedAccounts : _objectSpread({}, orgInitialState.selectedAccounts)), {}, _defineProperty({}, action.platformName, action.checked))
      })));

    case UPDATE_POST_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        postType: action.value
      })));

    case UPDATE_PUBLISH_AT:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        publishAt: action.publishAt
      })));

    case WIPE_LINK_DATA:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        linkData: {
          link: null,
          caption: null,
          description: null,
          image: null,
          title: null
        }
      })));

    case LOAD_LINK_PARSE:
      return _objectSpread(_objectSpread({}, state), {}, {
        parsingLink: true
      });

    case LOAD_LINK_PARSE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({
        parsingLink: false
      }, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        linkData: {
          link: action.result.data.url,
          caption: action.result.data.siteName,
          description: action.result.data.description,
          image: action.result.data.imageUrl,
          title: action.result.data.title
        }
      })));

    case LOAD_LINK_PARSE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        parsingLink: false
      });

    case SUBMIT_POST:
      return _objectSpread(_objectSpread({}, state), {}, {
        submittingPost: true
      });

    case SUBMIT_POST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        submittingPost: false
      });

    case SUBMIT_POST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        submittingPost: false
      });

    case SET_SELECTED_PREVIEW_TAB:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        selectedPreviewTab: action.tab
      })));

    case SET_SELECTED_LINKEDIN_PREVIEW_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        selectedLinkedinPreviewType: action.liType
      })));

    case SET_POST_TIME_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        postTimeType: action.postTimeType
      })));

    case SET_DRAFT_AUTO_SCHEDULE_TOGGLE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        draftPostToggleState: action.toggle,
        DraftPost: _objectSpread(_objectSpread({}, state["".concat(action.orgId)].DraftPost ? state["".concat(action.orgId)].DraftPost : {}), {}, {
          suggestedPublishAt: action.toggle ? new Date() : null
        })
      })));

    case SET_DRAFT_AUTO_PUBLISH:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state["".concat(action.orgId)]), {}, {
        DraftPost: _objectSpread(_objectSpread({}, state["".concat(action.orgId)].DraftPost ? state["".concat(action.orgId)].DraftPost : {}), {}, {
          suggestedPublishAt: action.publish
        })
      })));

    default:
      return state;
  }
}

function instanceMapReducerEnhancer(composeboxFormReducer) {
  var controlInstanceKeyResolver = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultKeyResolver;
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments.length > 1 ? arguments[1] : undefined;
    var instanceKey = controlInstanceKeyResolver(action);

    if (typeof instanceKey === 'string') {
      var instanceState = reducer(state[instanceKey], action);
      var newState = Object.assign({}, state, _defineProperty({}, instanceKey, instanceState));
      return newState;
    }

    return state;
  };
}

export function setSelectedPreviewTab(tab, orgId) {
  return {
    type: SET_SELECTED_PREVIEW_TAB,
    tab: tab,
    orgId: orgId
  };
}
export function setSelectedLinkedinPreviewType(liType, orgId) {
  return {
    type: SET_SELECTED_LINKEDIN_PREVIEW_TYPE,
    liType: liType,
    orgId: orgId
  };
}
export function setBackUrl(url, orgId) {
  return {
    type: SET_BACK_URL,
    url: url,
    orgId: orgId
  };
}
export function getComposeboxFormValues(orgId) {
  return {
    type: GET_VALUES,
    meta: {
      controlInstanceKeyResolver: orgId
    }
  };
}
export function updateImageOrder(values, orgId) {
  return {
    type: UPDATE_IMAGE_ORDER,
    values: values,
    orgId: orgId
  };
}
export function updateMessage(messageType, value, orgId) {
  return {
    type: UPDATE_MESSAGE,
    messageType: messageType,
    value: value,
    orgId: orgId
  };
}
export function updatePlatformCheckbox(platformName, checked, orgId) {
  return {
    type: UPDATE_PLATFORM_CHECKBOX,
    platformName: platformName,
    checked: checked,
    orgId: orgId
  };
}
export function updatePostType(value, orgId) {
  return {
    type: UPDATE_POST_TYPE,
    value: value,
    orgId: orgId
  };
}
export function updatePublishAt(publishAt, orgId) {
  return {
    type: UPDATE_PUBLISH_AT,
    publishAt: publishAt,
    orgId: orgId
  };
}
export function toggleQueuedPost(orgId) {
  return {
    type: TOGGLE_QUEUED_POST,
    orgId: orgId
  };
}
export function populateComposebox(post, orgId) {
  var extra = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return {
    type: INIT,
    post: post,
    orgId: orgId,
    extra: extra
  };
}
export function resetComposebox(orgId) {
  return {
    type: RESET_COMPOSEBOX,
    orgId: orgId
  };
}
export function updateLinkData(field, value, orgId) {
  return {
    type: UPDATE_LINK_DATA,
    field: field,
    value: value,
    orgId: orgId
  };
}
export function wipeLinkData(orgId) {
  return {
    type: WIPE_LINK_DATA,
    orgId: orgId
  };
}
export function addImage(imageUrl, orgId) {
  return {
    type: ADD_IMAGE,
    imageUrl: imageUrl,
    orgId: orgId
  };
}
export function addVideo(videoUrl, orgId) {
  return {
    type: ADD_VIDEO,
    videoUrl: videoUrl,
    orgId: orgId
  };
}
export function removeAllImages(orgId) {
  return {
    type: REMOVE_ALL_IMAGES,
    orgId: orgId
  };
}
export function removeVideo(orgId) {
  return {
    type: REMOVE_VIDEO,
    orgId: orgId
  };
}
export function removeImage(index, orgId) {
  return {
    type: REMOVE_IMAGE,
    index: index,
    orgId: orgId
  };
}
export function parseLink(link, orgId) {
  return {
    types: [LOAD_LINK_PARSE, LOAD_LINK_PARSE_SUCCESS, LOAD_LINK_PARSE_FAIL],
    orgId: orgId,
    promise: function promise(client) {
      return client.post('/parseLink', {
        data: {
          link: link
        }
      });
    }
  };
}
export function nullifyPublishAt(orgId) {
  return {
    type: NULLIFY_PUBLISH_AT,
    orgId: orgId
  };
}
export function setPostTimeType(orgId, postTimeType) {
  return {
    type: SET_POST_TIME_TYPE,
    orgId: orgId,
    postTimeType: postTimeType
  };
}
export function setDraftPostAutoScheduleState(orgId, toggle) {
  return {
    type: SET_DRAFT_AUTO_SCHEDULE_TOGGLE,
    orgId: orgId,
    toggle: toggle
  };
}
export function setDraftPostAutoPublishAt(publish, orgId) {
  return {
    type: SET_DRAFT_AUTO_PUBLISH,
    orgId: orgId,
    publish: publish
  };
}
export function submitPost(payload, organizationId) {
  var post = _objectSpread({}, payload);

  var wipedLink = {
    caption: null,
    description: null,
    image: null,
    link: null,
    title: null
  };

  if (post.postType === 'linkPost') {
    post.images = [];
    post.videoUrl = null;
  } else if (post.postType === 'imagePost') {
    post.videoUrl = null;
    post.linkData = wipedLink;
  } else if (post.postType === 'videoPost') {
    post.images = [];
    post.linkData = wipedLink;
  } else if (post.postType === 'textPost') {
    post.images = [];
    post.linkData = wipedLink;
    post.videoUrl = null;
  }

  if (!!post.existingPostQueueId && !post.convertQueuedPost) {
    // need to get rid of the publishAt for queued posts that are staying in the queue
    post.publishAt = null;
  } else if (post.postTimeType) {
    if (post.postTimeType === 'draft') {
      post.publishAt = null;
      post.DraftPost = _objectSpread(_objectSpread({}, post.DraftPost && post.DraftPost.id ? {
        id: post.DraftPost.id
      } : {}), {}, {
        suggestedPublishAt: post.DraftPost ? post.DraftPost.suggestedPublishAt : null
      });
    } else if (post.postTimeType === 'publishNow') {
      post.DraftPost = null;
      post.publishAt = new Date().getTime();
    } else if (post.postTimeType === 'schedule' && !post.publishAt) {
      post.DraftPost = null;
      post.publishAt = new Date().getTime();
    }
  }

  if (!!post.existingPostId && post.existingPostId.includes('group_post')) {
    post.existingPostId = null;
  } // not actually pushing these changes into the redux state
  // about to get wiped anyway once we submit


  return {
    types: [SUBMIT_POST, SUBMIT_POST_SUCCESS, SUBMIT_POST_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(organizationId, "/createPost"), {
        data: post
      });
    },
    post: post,
    orgId: organizationId
  };
}