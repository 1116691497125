export default function getTheme(whiteLabel, organizationId) {
  /*
  if (!whiteLabel && !organizationId && !(organizationId === 0)) {
    console.log('organizationId');
    console.log(organizationId);
    // throw new Error('Unable to provide a theme - no white label settings and no organization id provided');
  }
  */
  // console.log(whiteLabel ? whiteLabel.organization : '');

  /* console.log('in getTheme, organizationId is:');
  console.log(organizationId);
  console.log('in getTheme, whiteLabel is:');
  console.log(whiteLabel);*/
  var retValue = {
    org: whiteLabel ? whiteLabel.OrganizationId : organizationId,
    businessName: whiteLabel && whiteLabel.Organization ? whiteLabel.Organization.name : 'Likeable Local',
    businessInfo: whiteLabel && whiteLabel.Organization ? whiteLabel.Organization : null,
    logo: whiteLabel && whiteLabel.details && whiteLabel.details.logoUrl ? whiteLabel.details.logoUrl : 'https://likeabucket.s3.amazonaws.com/76q7m7c40g_1480436487184.png',
    colors: {
      primaryColor: whiteLabel && whiteLabel.details && whiteLabel.details.primaryColor ? whiteLabel.details.primaryColor : '#ef7521',
      secondaryColor: whiteLabel && whiteLabel.details && whiteLabel.details.secondaryColor ? whiteLabel.details.secondaryColor : '#1F5180',
      loggedOutBgColor: '#091927',
      textColor: '#616161',
      warningColor: '#c71212',
      black: '#000',
      black1: '#333',
      black2: '#555',
      black3: '#252C32',
      black4: '#4a4a4a',
      black5: 'rgba(0,0,0,.2)',
      black6: 'rgba(0,0,0,.07)',
      white: '#ffffff',
      gray1: '#616161',
      gray2: '#8b8b8b',
      gray3: '#dfdfdf',
      gray4: '#f1f1f1',
      sidebarIconGray: '#93979e',
      facebook: '#3b5998',
      twitter: '#55acee',
      linkedin: '#007bb5',
      pinterest: '#cb2027',
      instagram: '#cd486b',
      instagramGradient: 'radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)'
    }
  };
  return retValue;
}