import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20;

import styled from 'styled-components';
export var CalendarWrap = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  transition: opacity 300ms ease;\n"])));
export var CalendarHeader = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: black;\n  font-weight: bold;\n  height: 44px;\n  width: 100%;\n\n  & * {\n    user-select: none;\n  }\n"])));
export var CalendarHeaderTitle = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  & * {\n    font-size: 20px;\n  }\n"])));
export var CalendarHeaderChevron = styled.button(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  font-size: 32px;\n  cursor: pointer;\n  user-select: none;\n  width: 32px;\n  height: 32px;\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 50%;\n  background: white;\n  border: none;\n  padding: 0;\n  font-weight: normal;\n  background-position: center;\n  transition: background 0.8s;\n\n  &:hover {\n    background: white radial-gradient(circle, transparent 1%, white 1%) center/15000%;\n  }\n\n  &:active {\n    background-color: #ececec;\n    background-size: 100%;\n    transition: background 0s;\n  }\n"])));
export var CalendarMonthWrap = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n\n  & > div {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    overflow: hidden;\n\n    & > div {\n      display: flex;\n      width: 100%;\n\n      & > div {\n        width: 100%;\n      }\n    }\n    & > section {\n      display: flex;\n      width: 100%;\n      padding-bottom: 100%;\n\n      & > div {\n        width: 100%;\n      }\n    }\n  }\n"])));
export var CalendarDayWrap = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  position: absolute;\n  top: 0;\n  padding-top: 12.5%;\n  transition: transform 300ms ease-in, opacity 300ms ease;\n"])));
export var CalendarDay = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  background: none;\n  transition: background 200ms ease-in-out;\n  margin: 3px;\n  padding: 3px;\n  width: calc(14.28% - 6px);\n  overflow: hidden;\n\n  &:hover {\n    background: rgba(31, 81, 128, 0.3);\n    transition: background 200ms ease-in-out;\n  }\n\n  &.today {\n  }\n  &.selected {\n    border: 2px solid #1f5180;\n    border-radius: 2px;\n  }\n  &.outsideMonth {\n    background: #f2f2f2;\n  }\n  &.event {\n    background: red;\n  }\n"])));
export var CalendarDayEventDot = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  position: relative;\n\n  & > div {\n    position: absolute;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    width: 5px;\n    height: 5px;\n    background: red;\n    margin-left: auto;\n    margin-right: auto;\n    border-radius: 50%;\n  }\n"])));
export var CalendarDayInner = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  padding-top: 100%;\n  position: relative;\n"])));
export var CalendarDayContent = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #000;\n  cursor: pointer;\n  user-select: none;\n\n  &.header {\n    color: rgba(97, 97, 97, 0.6);\n    cursor: default;\n    border-bottom: 1px solid #ccc;\n  }\n\n  &.selected {\n    background: red;\n    color: white;\n  }\n  &.disabled {\n    color: rgba(97, 97, 97, 0.6);\n    cursor: not-allowed;\n  }\n  &.today {\n    color: #ff9a9a;\n    font-weight: bold;\n  }\n"])));
export var CalendarDayDetailWrap = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  transition: opacity 300ms ease;\n"])));
export var CalendarDayDetailHeader = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  margin-bottom: 32px;\n"])));
export var CalendarDayDetailHourWrap = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  width: 44px;\n\n  & > div {\n    width: 100%;\n    height: 44px;\n    display: flex;\n    justify-content: flex-end;\n    align-items: flex-start;\n    & > span {\n      color: #939393;\n      font-size: 12px;\n      margin-top: -6px;\n    }\n  }\n"])));
export var CalendarDayDetailTimeline = styled.div(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  flex-direction: column;\n  flex: 1;\n\n  & > section {\n    height: 44px;\n    border-top: 1px solid #d7d7d7;\n    border-right: 1px solid #d7d7d7;\n    width: 100%;\n    padding-left: 12px;\n\n    &:last-child {\n      border-bottom: 1px solid #d7d7d7;\n    }\n\n    & > div {\n      border-left: 1px solid #d7d7d7;\n      width: 100%;\n      height: 100%;\n      padding: 3px;\n    }\n  }\n"])));
export var CalendarDetailTimelineWrap = styled.div(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  max-height: 512px;\n  overflow: scroll;\n  display: flex;\n  position: relative;\n  cursor: all-scroll;\n"])));
export var CalendarTimeWrap = styled.div(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  position: absolute;\n  height: 512px;\n  width: 512px;\n  padding-left: 56px;\n"])));
export var CalendarTimelineEvent = styled.article(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  position: absolute;\n  padding: 0 10px;\n  left: 56px;\n  right: 0;\n  text-shadow: 0px 0px 4px #3c3c3c;\n"])));
export var CalendarTimeLine = styled.div(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n  z-index: 1;\n  border-top: 2px solid #ea4335;\n  position: relative;\n  cursor: all-scroll;\n"])));
export var CalendarTimeBall = styled.div(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n  z-index: 1;\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  background: #ea4335;\n  margin-left: -6px;\n  margin-top: -6px;\n  position: relative;\n  cursor: all-scroll;\n"])));
export var CalendarTimelineTimeLabel = styled.span(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n  z-index: 1;\n  position: relative;\n  right: 0;\n  width: 100%;\n  display: block;\n  text-align: right;\n  padding-right: 20px;\n  color: #ea4335;\n  font-size: 15px;\n  user-select: none;\n  height: 0;\n"])));