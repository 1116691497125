import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export var LOAD_LOCATION_INFO = "likeablelocal/googleMyBusiness/LOAD_LOCATION_INFO";
export var LOAD_LOCATION_INFO_FAIL = "likeablelocal/googleMyBusiness/LOAD_LOCATION_INFO_FAIL";
export var LOAD_LOCATION_INFO_SUCCESS = "likeablelocal/googleMyBusiness/LOAD_LOCATION_INFO_SUCCESS";
var initialState = {
  businessInfoByLocation: {}
};
export default function reducer() {
  var _action$result, _action$result$data, _action$result$data$l;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case LOAD_LOCATION_INFO_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        businessInfoByLocation: _objectSpread(_objectSpread({}, state.businessInfoByLocation), {}, _defineProperty({}, (_action$result = action.result) === null || _action$result === void 0 ? void 0 : (_action$result$data = _action$result.data) === null || _action$result$data === void 0 ? void 0 : (_action$result$data$l = _action$result$data.locationInfo) === null || _action$result$data$l === void 0 ? void 0 : _action$result$data$l.name, _objectSpread({}, action.result.data.locationInfo)))
      });

    default:
      return state;
  }
}
export function loadBusinessInfo(orgId) {
  return {
    types: [LOAD_LOCATION_INFO, LOAD_LOCATION_INFO_SUCCESS, LOAD_LOCATION_INFO_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/socialAccounts/gmb/businessInfo"));
    }
  };
}