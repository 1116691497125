import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var ns = 'likeablelocal/customAudiences';
var CLEAR_CA_BUILDER = "".concat(ns, "/CLEAR_CA_BUILDER");
var SET_VALUE = "".concat(ns, "/SET_VALUE");
var CREATE_CUSTOM_AUDIENCE = "".concat(ns, "/CREATE_CUSTOM_AUDIENCE");
var CREATE_CUSTOM_AUDIENCE_SUCCESS = "".concat(ns, "/CREATE_CUSTOM_AUDIENCE_SUCCESS");
var CREATE_CUSTOM_AUDIENCE_FAIL = "".concat(ns, "/CREATE_CUSTOM_AUDIENCE_FAIL");
var GET_FB_PIXELS = "".concat(ns, "/GET_FB_PIXELS");
var GET_FB_PIXELS_SUCCESS = "".concat(ns, "/GET_FB_PIXELS_SUCCESS");
var GET_FB_PIXELS_FAIL = "".concat(ns, "/GET_FB_PIXELS_FAIL");
var GET_CUSTOM_AUDIENCES = "".concat(ns, "/GET_CUSTOM_AUDIENCES");
var GET_CUSTOM_AUDIENCES_SUCCESS = "".concat(ns, "/GET_CUSTOM_AUDIENCES_SUCCESS");
var GET_CUSTOM_AUDIENCES_FAIL = "".concat(ns, "/GET_CUSTOM_AUDIENCES_FAIL");
var initialState = {
  isCreatingCustomAudience: false,
  gettingCustomAudiences: false,
  customAudiences: {},
  gettingFBPixels: false,
  facebookPixels: {},
  audienceType: null,
  audienceName: ''
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case CLEAR_CA_BUILDER:
      return _objectSpread(_objectSpread({}, state), {}, {
        audienceType: null,
        audienceName: '',
        customAudienceError: null
      });

    case CREATE_CUSTOM_AUDIENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isCreatingCustomAudience: true
      });

    case CREATE_CUSTOM_AUDIENCE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isCreatingCustomAudience: false,
        audienceType: null,
        audienceName: '',
        customAudiences: _objectSpread(_objectSpread({}, state.customAudiences), {}, _defineProperty({}, action.orgId, [action.result.data].concat(_toConsumableArray(state.customAudiences[action.orgId]))))
      });

    case CREATE_CUSTOM_AUDIENCE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isCreatingCustomAudience: false
      });

    case GET_FB_PIXELS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingFBPixels: true
      });

    case GET_FB_PIXELS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingFBPixels: false,
        facebookPixels: _objectSpread(_objectSpread({}, state.facebookPixels), {}, _defineProperty({}, action.orgId, _toConsumableArray(action.result.data)))
      });

    case GET_FB_PIXELS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingFBPixels: false
      });

    case SET_VALUE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.key, action.value));

    case GET_CUSTOM_AUDIENCES:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingCustomAudiences: true
      });

    case GET_CUSTOM_AUDIENCES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingCustomAudiences: false,
        customAudiences: _objectSpread(_objectSpread({}, state.customAudiences), {}, _defineProperty({}, action.orgId, action.result.data))
      });

    case GET_CUSTOM_AUDIENCES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingCustomAudiences: false
      });

    default:
      return state;
  }
}
export function setValue(key, value) {
  return {
    type: SET_VALUE,
    key: key,
    value: value
  };
}
export function getCustomAudiences(orgId, adAccountId) {
  console.log('in getCustomAudiences');
  console.log(orgId);
  var url = "/org/".concat(orgId, "/adAccountId/").concat(adAccountId, "/customAudiences");
  return {
    types: [GET_CUSTOM_AUDIENCES, GET_CUSTOM_AUDIENCES_SUCCESS, GET_CUSTOM_AUDIENCES_FAIL],
    promise: function promise(client) {
      return client.get(url);
    },
    orgId: orgId,
    adAccountId: adAccountId
  };
}
export function getFacebookPixels(orgId, adAccountId) {
  var url = "/org/".concat(orgId, "/adAccount/").concat(adAccountId, "/facebookPixels");
  return {
    types: [GET_FB_PIXELS, GET_FB_PIXELS_SUCCESS, GET_FB_PIXELS_FAIL],
    promise: function promise(client) {
      return client.get(url);
    },
    orgId: orgId
  };
}
export function clearCABuilder() {
  return {
    type: CLEAR_CA_BUILDER
  };
}
export function createCustomAudience(type, orgId) {
  var payload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var url = "/org/".concat(orgId, "/customAudience/").concat(type);
  return {
    types: [CREATE_CUSTOM_AUDIENCE, CREATE_CUSTOM_AUDIENCE_SUCCESS, CREATE_CUSTOM_AUDIENCE_FAIL],
    promise: function promise(client) {
      return client.post(url, payload);
    },
    orgId: orgId
  };
}