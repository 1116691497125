import _typeof from "@babel/runtime/helpers/typeof";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import moment from 'moment';
import * as _ from 'lodash';
import { LOAD_REPORT_SUCCESS } from './reportBuilder';
var ns = 'likeablelocal/reportFacebookChecklist';
var PREPOPULATE_FB_CHECKLIST = "".concat(ns, "/PREPOPULATE_FB_CHECKLIST");
export var CLEAR_FB = "".concat(ns, "/CLEAR_FB");
var UPDATE_NON_SECTION_FIELD = "".concat(ns, "/UPDATE_NON_SECTION_FIELD");
var UPDATE_FIELD = "".concat(ns, "/UPDATE_FIELD");
var GET_PAGE_FIELDS = "".concat(ns, "/GET_PAGE_FIELDS");
export var GET_PAGE_FIELDS_SUCCESS = "".concat(ns, "/GET_PAGE_FIELDS_SUCCESS");
var GET_PAGE_FIELDS_FAIL = "".concat(ns, "/GET_PAGE_FIELDS_FAIL");
var initialState = {
  gettingPageFields: false,
  lastReportId: null,
  lastReportScore: 0,
  lastReportDate: moment().format('MM/DD/YYYY'),
  sections: [{
    key: 'username',
    label: 'Username',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    data: null,
    manualField: false,
    comments: '',
    dataValue: 'No username found.',
    score: 0
  }, {
    key: 'name',
    label: 'Display Name',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    data: null,
    comments: '',
    manualField: false,
    dataValue: 'No display name found.',
    score: 0
  }, {
    key: 'callToAction',
    label: 'Call To Action',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    score: 0,
    comments: '',
    data: null,
    dataValue: '',
    manualField: true
  }, {
    key: 'about',
    label: 'Description',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    score: 0,
    comments: '',
    manualField: false,
    data: null,
    dataValue: 'No description found.'
  }, {
    key: 'page_about_story',
    label: 'Story',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    comments: '',
    data: null,
    dataValue: 'No story found.',
    manualField: false,
    score: 0
  }, {
    key: 'phone',
    label: 'Phone Number',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    score: 0,
    comments: '',
    data: null,
    manualField: false,
    dataValue: 'No phone number found.'
  }, {
    key: 'emails',
    label: 'Email',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    comments: '',
    data: null,
    manualField: false,
    dataValue: 'No email found.',
    score: 0
  }, {
    key: 'website',
    label: 'Website',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    comments: '',
    data: null,
    manualField: false,
    dataValue: 'No website found.',
    score: 0
  }, {
    key: 'single_line_address',
    label: 'Address',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    score: 0,
    comments: '',
    data: null,
    manualField: false,
    dataValue: 'No address found.'
  }, {
    key: 'hours',
    label: 'Business Hours',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    comments: '',
    data: null,
    manualField: false,
    dataValue: 'No hours found.',
    score: 0
  }, {
    key: 'picture',
    label: 'Profile Picture',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    score: 0,
    comments: '',
    data: null,
    manualField: false,
    dataValue: 'No profile picture found.'
  }, {
    key: 'cover',
    label: 'Cover Photo',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    comments: '',
    data: null,
    dataValue: 'No cover photo found.',
    manualField: false,
    score: 0
  }, {
    key: 'category',
    label: 'Page Category',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    comments: '',
    data: null,
    manualField: false,
    dataValue: 'No page category found.',
    score: 0
  }, {
    key: 'price_range',
    label: 'Price Range',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    score: 0,
    manualField: false,
    comments: '',
    data: null,
    dataValue: 'No price range found.'
  }, {
    key: 'parking',
    label: 'Parking Info',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    score: 0,
    manualField: false,
    comments: '',
    data: null,
    dataValue: 'No parking information found.'
  }, {
    key: 'founded',
    label: 'Business Start Date',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    score: 0,
    manualField: false,
    comments: '',
    data: null,
    dataValue: 'No date found.'
  }, {
    key: 'page_backed_instagram_accounts',
    label: 'Connected Instagram for Ads',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    score: 0,
    manualField: false,
    comments: '',
    data: null,
    dataValue: 'Instagram not connected.'
  }, {
    key: 'verification_status',
    label: 'Page Verification',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    score: 0,
    comments: '',
    data: null,
    manualField: false,
    dataValue: 'Page not verified.'
  }, {
    key: 'products',
    label: 'Products And/Or Services',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    score: 0,
    manualField: false,
    comments: '',
    data: null,
    dataValue: 'No products found.'
  }, {
    key: 'instantReply',
    label: 'Instant Reply for Messages',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    score: 0,
    comments: '',
    data: null,
    manualField: true,
    dataValue: ''
  }, {
    key: 'posting_history',
    label: 'Review Posting History',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    score: 0,
    comments: '',
    data: null,
    manualField: true,
    dataValue: ''
  }, {
    key: 'ratings',
    label: 'Monitor Reviews',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    score: 0,
    manualField: false,
    comments: '',
    data: null,
    dataValue: 'No reviews found.'
  }, {
    key: 'pinnedPost',
    label: 'Pinned Post',
    feedback: '',
    inspectedBy: null,
    rawUpdatedAt: null,
    updatedAt: null,
    comments: '',
    data: null,
    manualField: true,
    dataValue: '',
    score: 0
  }].map(function (val, idx, arr) {
    return _objectSpread(_objectSpread({}, val), {}, {
      value: 100.0 / parseFloat(arr.length)
    });
  }),
  checklistOptions: [{
    label: 'Verified',
    key: 'verified'
  }, {
    label: 'Updated and\nVerified',
    key: 'updatedAndVerified'
  }, {
    label: 'N/A',
    key: 'notApplicable'
  }, {
    label: 'Need Client\nFeedback',
    key: 'needFeedback'
  }, {
    label: 'Incomplete',
    key: 'incomplete'
  }]
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _.cloneDeep(initialState);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case CLEAR_FB:
      return _.cloneDeep(initialState);

    case LOAD_REPORT_SUCCESS:
      return function () {
        if (action.result.data.reportType === 'facebookChecklist') {
          return _objectSpread({}, action.result.data.details);
        }

        return state;
      }();

    case GET_PAGE_FIELDS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPageFields: true
      });

    case GET_PAGE_FIELDS_SUCCESS:
      return function () {
        var scoreVal = state.sections && state.sections.length > 0 ? 100.0 / parseFloat(state.sections.length) : 4.34;
        var rating_count = action.result.data.rating_count;

        if (action.result.data.ratings) {
          action.result.data.ratings.count = rating_count || 0;
        } else if (action.result.data.hasOwnProperty('rating_count')) {
          action.result.data.ratings = {
            count: 0
          };
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          gettingPageFields: false,
          sections: state.sections.map(function (section) {
            Object.keys(action.result.data).forEach(function (key) {
              if (key === section.key) {
                section.data = action.result.data[key];
                section.dataValue = fieldDataToString(key, action.result.data[key]);
                section.score = section.score && section.score > scoreVal / 2.0 ? section.score : scoreVal / 2.0;
              }
            });
            return section;
          })
        });
      }();

    case PREPOPULATE_FB_CHECKLIST:
      var sections = state.sections.map(function (section, index) {
        section = action.lastReportSections[index];
        return section;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        sections: sections
      });

    case GET_PAGE_FIELDS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPageFields: false
      });

    case UPDATE_NON_SECTION_FIELD:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.field, action.value));

    case UPDATE_FIELD:
      return function () {
        var scoreVal = state.sections && state.sections.length > 0 ? 100.0 / parseFloat(state.sections.length) : 4.34; // scoreMap human readable = [updated, updatedAndVerified, notApplicable, needFeedback, incomplete]

        var scoreMap = [scoreVal, scoreVal, scoreVal, 0, 0];
        return _objectSpread(_objectSpread({}, state), {}, {
          sections: state.sections.map(function (section) {
            if (section.key === action.sectionKey) {
              section[action.field] = action.value;
              section.score = action.field === 'value' ? scoreMap[action.value] : section.score;
              section.inspectedBy = action.user;
              section.updatedAt = action.updatedAt.format('MM/D/YYYY - h:MM A');
              section.rawUpdatedAt = action.rawUpdatedAt;
            }

            return section;
          })
        });
      }();

    default:
      return state;
  }
}
export function updateFBChecklist(field, value) {
  return {
    type: UPDATE_NON_SECTION_FIELD,
    field: field,
    value: value
  };
}
export function updateFBChecklistField(sectionKey, field, value) {
  var user = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var updatedAt = moment();
  var rawUpdatedAt = new Date();
  return {
    type: UPDATE_FIELD,
    sectionKey: sectionKey,
    value: value,
    field: field,
    user: user,
    updatedAt: updatedAt,
    rawUpdatedAt: rawUpdatedAt
  };
}
export function prePopulateChecklist() {
  var lastReportSections = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  return lastReportSections ? {
    type: PREPOPULATE_FB_CHECKLIST,
    lastReportSections: lastReportSections
  } : null;
}
export function clearFBChecklistTemplate() {
  return {
    type: CLEAR_FB
  };
}
export function getPageFields(orgId, pageId) {
  var fields = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'about';
  return {
    types: [GET_PAGE_FIELDS, GET_PAGE_FIELDS_SUCCESS, GET_PAGE_FIELDS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/socialAccount/").concat(pageId, "/pageFields?fields=").concat(fields));
    }
  };
}

var fieldDataToString = function fieldDataToString(key, data) {
  var pageInfo = '-';

  if (data && typeof data === 'string') {
    pageInfo = data;
  } else if (data && _typeof(data) === 'object') {
    switch (key) {
      case 'hours':
        pageInfo = formatHours(data);
        break;

      case 'parking':
        pageInfo = Object.keys(data).map(function (k) {
          return parseInt(data[k]) ? _.startCase(k) : null;
        }).filter(function (v) {
          return v !== null;
        }).toString();
        break;

      case 'page_about_story':
        var storyText = '';

        try {
          storyText = data.composed_text && !!data.composed_text.length && data.composed_text.text ? "".concat(data.composed_text[0].text.slice(0, 200), "...") : '';
        } catch (e) {
          console.log(e);
        }

        var title = data.title ? data.title : '';
        pageInfo = "".concat(title, "\n\n").concat(storyText);
        break;

      case 'emails':
        pageInfo = data.toString();
        break;

      case 'picture':
        pageInfo = data.data.url;
        break;

      case 'ratings':
        pageInfo = formatRatings(data);
        break;

      case 'cover':
        pageInfo = data.source;
        break;

      case 'page_backed_instagram_accounts':
        pageInfo = data.data.length ? 'Yes' : 'No';
        break;

      default:
        pageInfo = 'cannot read data';
    }
  }

  return pageInfo;
};

var formatHours = function formatHours(hours) {
  var hoursObj = {
    mon: {
      open: '-',
      close: '-'
    },
    tue: {
      open: '-',
      close: '-'
    },
    wed: {
      open: '-',
      close: '-'
    },
    thu: {
      open: '-',
      close: '-'
    },
    fri: {
      open: '-',
      close: '-'
    },
    sat: {
      open: '-',
      close: '-'
    },
    sun: {
      open: '-',
      close: '-'
    }
  };
  Object.keys(hours).forEach(function (key) {
    var dow = key.split('_')[0];
    var openClose = key.split('_')[2];
    var date = new Date();
    var setDate = date.setHours(hours[key].split(':')[0], hours[key].split(':')[1]);
    hoursObj[dow][openClose] = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  });
  var hoursString = "Monday: ".concat(hoursObj.mon.open, " to ").concat(hoursObj.mon.close, "\n Tuesday: ").concat(hoursObj.tue.open, " to ").concat(hoursObj.tue.close, "\n Wednesday: ").concat(hoursObj.wed.open, " to ").concat(hoursObj.wed.close, "\n Thursday: ").concat(hoursObj.thu.open, " to ").concat(hoursObj.thu.close, "\n Friday: ").concat(hoursObj.fri.open, " to ").concat(hoursObj.fri.close, "\n Saturday: ").concat(hoursObj.sat.open, " to ").concat(hoursObj.sat.close, "\n Sunday: ").concat(hoursObj.sun.open, " to ").concat(hoursObj.sun.close);
  return hoursString.replace(/- to -/g, 'Closed');
};

var formatRatings = function formatRatings(ratings) {
  var count = {
    positive: 0,
    negative: 0
  };

  if (ratings.data) {
    ratings.data.forEach(function (rating) {
      count[rating.recommendation_type] = count[rating.recommendation_type] + 1;
    });
  }

  return ratings.data ? "".concat(ratings.count, " Ratings\n\nMost recent: ").concat(count.positive, " Positive, ").concat(count.negative, " Negative") : "".concat(ratings.count, " Ratings");
};