import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { get as lodashGet, set as lodashSet, unset as lodashUnset } from 'lodash';
export var CREATE_FORM = 'forms/CREATE_FORM';
export var changeFieldAction = function changeFieldAction(formName) {
  return "forms/".concat(formName, "/CHANGE_FIELD");
};
export var clearFormAction = function clearFormAction(formName) {
  return "forms/".concat(formName, "/CLEAR_FORM");
};
export var mergeFormAction = function mergeFormAction(formName) {
  return "forms/".concat(formName, "/MERGE_FORM");
};
export var asyncChangeFieldActions = function asyncChangeFieldActions(formName) {
  return ["forms/".concat(formName, "/ASYNC_CHANGE_FIELD"), changeFieldAction(formName), "forms/".concat(formName, "/ASYNC_CHANGE_FIELD_FAIL")];
};
var initialState = {};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (/^forms\/(.*)\/CHANGE_FIELD$/.test("".concat(action.type))) {
    return updateField(state, action.formName, action.keyPath, action.value);
  }

  if (/^forms\/(.*)\/CLEAR_FORM$/.test("".concat(action.type))) {
    var obj = _objectSpread({}, state);

    delete obj[action.formName];
    return obj;
  }

  if (/^forms\/(.*)\/MERGE_FORM$/.test("".concat(action.type))) {
    return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.formName, _objectSpread(_objectSpread({}, state[action.formName] ? state[action.formName] : {}), action.data)));
  }

  if (/^forms\/(.*)\/ASYNC_CHANGE_FIELD_FAIL$/.test("".concat(action.type))) {
    return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.formName, _objectSpread(_objectSpread({}, state[action.formName] ? state[action.formName] : {}), {}, {
      asyncErrors: _objectSpread(_objectSpread({}, state[action.formName].asyncErrors ? state[action.formName].asyncErrors : {}), {}, _defineProperty({}, action.keyPath, action.error.error ? action.error.error : action.error))
    })));
  }

  if (/^forms\/(.*)\/ASYNC_CHANGE_FIELD$/.test("".concat(action.type))) {
    return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.formName, _objectSpread(_objectSpread({}, state[action.formName] ? state[action.formName] : {}), {}, {
      asyncErrors: _objectSpread(_objectSpread({}, state[action.formName].asyncErrors ? state[action.formName].asyncErrors : {}), {}, _defineProperty({}, action.keyPath, undefined))
    })));
  }

  switch (action.type) {
    case CREATE_FORM:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.name, _objectSpread({}, action.defaultState ? action.defaultState : {})));

    default:
      return state;
  }
}
export function create(name) {
  var defaultState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    type: CREATE_FORM,
    name: name,
    defaultState: defaultState
  };
}
export function changeField(formName, keyPath, value) {
  return {
    type: changeFieldAction(formName),
    formName: formName,
    keyPath: keyPath,
    value: value
  };
}
export function asyncChangeField(formName, keyPath, promise) {
  return {
    types: asyncChangeFieldActions(formName),
    formName: formName,
    keyPath: keyPath,
    promise: promise,
    asyncResultKeyName: 'value'
  };
}
export function clearForm(formName) {
  return {
    type: clearFormAction(formName),
    formName: formName
  };
}
export function mergeForm(formName, data) {
  return {
    type: mergeFormAction(formName),
    formName: formName,
    data: data
  };
}

function updateField(obj, formName, keyPath, value) {
  var objClone = _objectSpread({}, obj);

  var path = typeof keyPath === 'string' ? "".concat(formName, ".").concat(keyPath) : [formName].concat(_toConsumableArray(keyPath));

  if (value === undefined) {
    console.log('SHOULD UNSET');
    lodashUnset(objClone, path);
    return objClone;
  }

  return lodashSet(objClone, path, value);
}