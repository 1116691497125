import { change } from 'redux-form';
import { clearSavedOrgState } from 'redux/modules/organization';
export default function restoreOrgState(store, savedState) {
  var dispatch = store.dispatch;

  if (!savedState) {
    return;
  } // Removed because we moved to unique form names for orgs
  // Restore redux forms if they are saved
  // if (savedState.form && Object.keys(savedState.form).length) {
  //   const forms = Object.keys(savedState.form);
  //   forms.forEach(form => {
  //     const fields = savedState.form[form].values ? Object.keys(savedState.form[form].values) : [];
  //     fields.forEach(field => {
  //       console.log('Updating ', form, field, savedState.form[form].values[field]);
  //       dispatch(change(form, field, savedState.form[form].values[field]));
  //     });
  //   });
  // }
  // Restore any other state trees we have saved and clear the saved state
  // The logic for each state tree needs to be handled in the individual reducer with the following action type


  dispatch({
    type: 'likeablelocal/RESTORE_STATE',
    savedState: savedState
  }); // dispatch(clearSavedOrgState());
}