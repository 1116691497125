export default function checkNet() {
  var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '/favicon.ico';
  // Handle IE and more capable browsers
  var xhr = new (window.ActiveXObject || XMLHttpRequest)('Microsoft.XMLHTTP'); // Open new request as a HEAD to the root hostname with a random param to bust the cache

  xhr.open('HEAD', "//".concat(window.location.host).concat(path, "?rand=").concat(Math.floor((1 + Math.random()) * 0x10000)), true); // Issue request and handle response

  return new Promise(function (resolve) {
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status >= 200 && (xhr.status < 300 || xhr.status === 304)) {
          return resolve(true);
        }

        resolve(false);
      }
    };

    xhr.send(null);
  });
}