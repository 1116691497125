import * as actions from './actions';
export function getOrgOwnerUser() {
  return {
    types: [actions.GET_ORG_OWNER, actions.GET_ORG_OWNER_SUCCESS, actions.GET_ORG_OWNER_FAIL],
    promise: function promise(client) {
      return client.get('/billing/ownerUser');
    }
  };
}
export function getCommissionsAccountBalance() {
  return {
    types: [actions.GET_CONNECT_ACCOUNT_BALANCE, actions.GET_CONNECT_ACCOUNT_BALANCE_SUCCESS, actions.GET_CONNECT_ACCOUNT_BALANCE_FAIL],
    promise: function promise(client) {
      return client.get('/billing/commissionsAccount/balance');
    }
  };
}
export function listAccountTransfers() {
  return {
    types: [actions.LIST_ACCOUNT_TRANFERS, actions.LIST_ACCOUNT_TRANFERS_SUCCESS, actions.LIST_ACCOUNT_TRANFERS_FAIL],
    promise: function promise(client) {
      return client.get('/billing/listAccountTransfers');
    }
  };
}
export function initialSubscription(data, contractUuid) {
  var affiliateSign = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return {
    types: [actions.INITIAL_SUBSCRIPTION, actions.INITIAL_SUBSCRIPTION_SUCCESS, actions.INITIAL_SUBSCRIPTION_FAIL],
    promise: function promise(client) {
      return client.post("/billing/".concat(contractUuid, "/initialSubscription"), {
        data: data
      });
    },
    affiliateSign: affiliateSign
  };
}
export function processPayment(data, contractUuid) {
  return {
    types: [actions.PROCESS_PAYMENT, actions.PROCESS_PAYMENT_SUCCESS, actions.PROCESS_PAYMENT_FAIL],
    promise: function promise(client) {
      return client.post("/billing/".concat(contractUuid), {
        data: data
      });
    }
  };
}
export function sendCancellationEmail(cancelledClientData, partnerDetails) {
  var orgBillingConfig = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return {
    types: [actions.SEND_CANCELLATION_EMAIL, actions.SEND_CANCELLATION_EMAIL_SUCCESS, actions.SEND_CANCELLATION_EMAIL_FAIL],
    promise: function promise(client) {
      return client.post('/sendPartnerCancellationEmail', {
        data: {
          cancelledClientData: cancelledClientData,
          partnerDetails: partnerDetails,
          orgBillingConfig: orgBillingConfig
        }
      });
    }
  };
}
export function sendBudgetChangeEmail(data) {
  return {
    types: [actions.SEND_BUDGET_CHANGE_EMAIL, actions.SEND_BUDGET_CHANGE_EMAIL_SUCCESS, actions.SEND_BUDGET_CHANGE_EMAIL_FAIL],
    promise: function promise(client) {
      return client.post('/sendBudgetChangeEmail', {
        data: {
          data: data
        }
      });
    }
  };
}
export function sendOneTimeAdChargeEmail(clientName, amount, partnerDetails) {
  var chargeAmount = "$".concat(parseInt(amount, 10) / 100);
  return {
    types: [actions.SEND_ONE_TIME_CHARGE_EMAIL, actions.SEND_ONE_TIME_CHARGE_EMAIL_SUCCESS, actions.SEND_ONE_TIME_CHARGE_EMAIL_FAIL],
    promise: function promise(client) {
      return client.post('/sendOneTimeAdChargeEmail', {
        data: {
          clientName: clientName,
          chargeAmount: chargeAmount,
          partnerDetails: partnerDetails
        }
      });
    }
  };
}
export function showStripeError(error) {
  return {
    type: actions.SHOW_STRIPE_ERROR,
    error: "".concat(error.message, " (Error code \"").concat(error.code, "\")")
  };
}
export function clearStripeError() {
  return {
    type: actions.CLEAR_STRIPE_ERROR,
    error: ''
  };
}
export function clearInvoiceInfo() {
  return {
    type: actions.CLEAR_INVOICE_INFO
  };
}
export function getCountryVerificationFields(countryCode) {
  return {
    types: [actions.GET_CONNECT_VERIFICATION_FIELDS, actions.GET_CONNECT_VERIFICATION_FIELDS_SUCCESS, actions.GET_CONNECT_VERIFICATION_FIELDS_FAIL],
    promise: function promise(client) {
      return client.get("/billing/countryVerificationFields?countryCode=".concat(countryCode));
    }
  };
}
export function createStripeBankToken(country, currency, routing_number, account_number, account_holder_name, account_holder_type, stripeKey) {
  Stripe.setPublishableKey(stripeKey);
  return {
    types: [actions.CREATE_STRIPE_BANK, actions.CREATE_STRIPE_BANK_SUCCESS, actions.CREATE_STRIPE_BANK_FAIL],
    promise: function promise() {
      return new Promise(function (resolve, reject) {
        Stripe.bankAccount.createToken({
          country: country,
          currency: currency,
          routing_number: routing_number,
          account_number: account_number,
          account_holder_name: account_holder_name,
          account_holder_type: account_holder_type
        }, function (status, response) {
          if (response.error) {
            return reject(new Error(JSON.stringify(response.error)));
          }

          resolve(response);
        });
      });
    }
  };
}
export function createCommissionAccount(data) {
  return {
    types: [actions.CREATE_COMMISSION_ACCOUNT, actions.CREATE_COMMISSION_ACCOUNT_SUCCESS, actions.CREATE_COMMISSION_ACCOUNT_FAIL],
    promise: function promise(client) {
      return client.post('/billing/setupCommissions', {
        data: data
      });
    }
  };
}
export function cancelClient(partnerOrgId, clientOrgId) {
  return {
    types: [actions.CANCEL_CLIENT, actions.CANCEL_CLIENT_SUCCESS, actions.CANCEL_CLIENT_FAIL],
    promise: function promise(client) {
      return client.del("/org/".concat(partnerOrgId, "/cancel/partnerClient/").concat(clientOrgId));
    }
  };
}
export function changeAdsPlan(orgId, adsAmount, adsCommissionPercent) {
  return {
    types: [actions.CHANGE_ADS_PLAN, actions.CHANGE_ADS_PLAN_SUCCESS, actions.CHANGE_ADS_PLAN_FAIL],
    promise: function promise(client) {
      return client.put("/billing/org/".concat(orgId, "/changeAdsPlan"), {
        data: {
          adsAmount: parseInt(adsAmount),
          adsCommissionPercent: adsCommissionPercent
        }
      });
    },
    orgId: orgId
  };
}
export function updatePartnerClientBilling(orgId, data) {
  return {
    types: [actions.UPDATE_PARTNER_CLIENT_BILLING, actions.UPDATE_PARTNER_CLIENT_BILLING_SUCCESS, actions.UPDATE_PARTNER_CLIENT_BILLING_FAIL],
    promise: function promise(client) {
      return client.put("/billing/org/".concat(orgId, "/partnerClient/billing"), {
        data: data
      });
    },
    orgId: orgId
  };
}
export function loadCommissionsAccount() {
  return {
    types: [actions.LOAD_COMMISSION_ACCOUNT, actions.LOAD_COMMISSION_ACCOUNT_SUCCESS, actions.LOAD_COMMISSION_ACCOUNT_FAIL],
    promise: function promise(client) {
      return client.get('/billing/commissionsAccount');
    }
  };
}
export function getAdsPaymentMethod(orgId) {
  return {
    types: [actions.GET_ADS_PAYMENT_METHOD, actions.GET_ADS_PAYMENT_METHOD_SUCCESS, actions.GET_ADS_PAYMENT_METHOD_FAIL],
    promise: function promise(client) {
      return client.get("/billing/org/".concat(orgId, "/adsPaymentMethod"));
    }
  };
}
export function getBillingHistory(orgId) {
  return {
    types: [actions.GET_BILLING_HISTORY, actions.GET_BILLING_HISTORY_SUCCESS, actions.GET_BILLING_HISTORY_FAIL],
    promise: function promise(client) {
      return client.get("/billing/org/".concat(orgId, "/billingHistory"));
    },
    orgId: orgId
  };
}
export function getPartnerBillingHistory(orgId) {
  return {
    types: [actions.GET_PARTNER_BILLING_HISTORY, actions.GET_PARTNER_BILLING_HISTORY_SUCCESS, actions.GET_PARTNER_BILLING_HISTORY_FAIL],
    promise: function promise(client) {
      return client.get("/billing/org/".concat(orgId, "/billingHistory"));
    }
  };
}
export function setAdsPaymentMethod(data, orgId, cc) {
  return {
    types: [actions.SET_ADS_PAYMENT_METHOD, actions.SET_ADS_PAYMENT_METHOD_SUCCESS, actions.SET_ADS_PAYMENT_METHOD_FAIL],
    promise: function promise(client) {
      if (cc) {
        return client.put("/billing/org/".concat(orgId, "/adsPaymentMethod"), {
          data: data
        });
      }

      return client.post("/billing/org/".concat(orgId, "/adsPaymentMethod"), {
        data: data
      });
    }
  };
}
export function depositAdsFunds(amount, paymentSourceToken, orgId) {
  var data = {};
  data.amount = parseInt(amount) * 100;
  data.paymentSourceToken = paymentSourceToken;
  return {
    types: [actions.ADD_FUNDS, actions.ADD_FUNDS_SUCCESS, actions.ADD_FUNDS_FAIL],
    promise: function promise(client) {
      return client.post("/billing/org/".concat(orgId, "/depositAdsFunds"), {
        data: data
      });
    }
  };
}
export function unmountAdBudget() {
  return {
    type: actions.UNMOUNT_AD_BUDGET
  };
}
export function clearBillingError() {
  return {
    type: actions.CLEAR_BILLING_ERROR
  };
}
export function clearBillingHistory() {
  return {
    type: actions.CLEAR_BILLING_HISTORY
  };
}
export function getAdSpendSummary() {
  return {
    types: [actions.GET_AD_SPEND_SUMMARY, actions.GET_AD_SPEND_SUMMARY_SUCCESS, actions.GET_AD_SPEND_SUMMARY_FAIL],
    promise: function promise(client) {
      return client.get('/billing/partnerHeartbeatStats');
    }
  };
}
export function getAdSpendSummaryForOrganization(orgId) {
  return {
    types: [actions.GET_ORG_AD_SPEND_SUMMARY, actions.GET_ORG_AD_SPEND_SUMMARY_SUCCESS, actions.GET_ORG_AD_SPEND_SUMMARY_FAIL],
    promise: function promise(client) {
      return client.get("/billing/org/".concat(orgId, "/heartbeatStats"));
    },
    orgId: orgId
  };
}
export function getFacebookAdConfig(orgId) {
  return {
    types: [actions.GET_FB_AD_CONFIG, actions.GET_FB_AD_CONFIG_SUCCESS, actions.GET_FB_AD_CONFIG_FAIL],
    promise: function promise(client) {
      return client.get("/billing/org/".concat(orgId, "/facebookAdConfig"));
    }
  };
}
export function upsertFacebookAdConfig(orgId, socialAccountId, budget) {
  return {
    types: [actions.UPSERT_FB_AD_CONFIG, actions.UPSERT_FB_AD_CONFIG_SUCCESS, actions.UPSERT_FB_AD_CONFIG_FAIL],
    promise: function promise(client) {
      return client.put("/billing/org/".concat(orgId, "/socialAccount/").concat(socialAccountId, "/facebookAdConfig"), {
        data: {
          budget: budget
        }
      });
    }
  };
}
export function getPartnerDefaultPaymentMethod() {
  return {
    types: [actions.GET_PARTNER_DEFAULT_PAYMENT_METHOD, actions.GET_PARTNER_DEFAULT_PAYMENT_METHOD_SUCCESS, actions.GET_PARTNER_DEFAULT_PAYMENT_METHOD_FAIL],
    promise: function promise(client) {
      return client.get('/billing/defaultPartnerPaymentMethod');
    }
  };
}
export function setInvoice(invoice) {
  return {
    type: actions.SET_INVOICE,
    invoice: invoice
  };
}
export function getHasCommissionsAccount(orgId) {
  return {
    types: [actions.GET_HAS_COMMISSIONS_ACCOUNT, actions.GET_HAS_COMMISSIONS_ACCOUNT_SUCCESS, actions.GET_HAS_COMMISSIONS_ACCOUNT_FAIL],
    promise: function promise(client) {
      return client.get("/billing/org/".concat(orgId, "/hasCommissionsAccount"));
    }
  };
}
export function getInvoice(orgId, stripeId) {
  return {
    types: [actions.GET_INVOICE, actions.GET_INVOICE_SUCCESS, actions.GET_INVOICE_FAIL],
    promise: function promise(client) {
      return client.get("/billing/org/".concat(orgId, "/billingHistory/").concat(stripeId));
    }
  };
}
export function clearCreditCardInfo() {
  return {
    type: actions.CLEAR_CREDIT_CARD_INFO
  };
}
export function updatePartnerDefaultPaymentMethod(data) {
  return {
    types: [actions.UPDATE_PARTNER_DEFAULT_PAYMENT_METHOD, actions.UPDATE_PARTNER_DEFAULT_PAYMENT_METHOD_SUCCESS, actions.UPDATE_PARTNER_DEFAULT_PAYMENT_METHOD_FAIL],
    promise: function promise(client) {
      return client.put('/billing/defaultPartnerPaymentMethod', {
        data: data
      });
    }
  };
}
export function getWhiteLabelInfo(orgId) {
  return {
    types: [actions.GET_WHITELABEL, actions.GET_WHITELABEL_SUCCESS, actions.GET_WHITELABEL_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/whiteLabel"));
    }
  };
}