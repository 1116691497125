import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import moment from 'moment';
import * as _ from 'lodash';
import { LOAD_REPORT_SUCCESS } from './reportBuilder';
var ns = 'likeablelocal/reportUpcomingContent';
export var CLEAR_UC_TEMPLATE = "".concat(ns, "/CLEAR_UC_TEMPLATE");
var UPDATE_NON_SECTION_FIELD = "".concat(ns, "/UPDATE_NON_SECTION_FIELD");
var UPDATE_FIELD = "".concat(ns, "/UPDATE_FIELD");
var UPDATE_RADIO = "".concat(ns, "/UPDATE_RADIO");
var GET_POSTS = "".concat(ns, "/GET_POSTS");
var GET_POSTS_SUCCESS = "".concat(ns, "/GET_POSTS_SUCCESS");
var GET_POSTS_FAIL = "".concat(ns, "/GET_POSTS_FAIL");
var initialState = {
  gettingPageFields: false,
  lastReportId: null,
  lastReportScore: 0,
  lastReportDate: moment().format('MM/DD/YYYY'),
  posts: [],
  sections: [{
    key: 'previewType',
    label: 'Preview Type',
    value: 0
  }, {
    key: 'postTypesToShow',
    label: 'Post Types To Show'
  }, {
    key: 'posts',
    label: 'Posts To Show'
  }],
  previewType: [{
    key: 'listPreview',
    label: 'List Preview'
  }, {
    key: 'platformPreview',
    label: 'Large Preview'
  }, {
    key: 'textOnly',
    label: 'Text Only'
  }],
  postTypesToShow: [{
    key: 'customPosts',
    label: 'Custom Posts',
    value: true
  }, {
    key: 'queuePosts',
    label: 'Queued Posts',
    value: true
  }]
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _.cloneDeep(initialState);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case CLEAR_UC_TEMPLATE:
      return _.cloneDeep(initialState);

    case GET_POSTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPosts: true
      });

    case GET_POSTS_SUCCESS:
      var posts = action.result.data.filter(function (post) {
        if (moment(post.publishAt).isAfter(action.startMoment)) return post;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        posts: posts.map(function (post) {
          post.value = true;
          post.key = post.id;
          return post;
        }),
        gettingPosts: false
      });

    case GET_POSTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPosts: false
      });

    case LOAD_REPORT_SUCCESS:
      return function () {
        if (action.result.data.reportType === 'upcomingContent') {
          return _objectSpread({}, action.result.data.details);
        }

        return state;
      }();

    case UPDATE_NON_SECTION_FIELD:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.field, action.value));

    case UPDATE_RADIO:
      return _objectSpread(_objectSpread({}, state), {}, {
        sections: state.sections.map(function (section) {
          if (section.key === action.sectionKey) {
            section.value = action.value;
          }

          return section;
        })
      });

    case UPDATE_FIELD:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.sectionKey, state[action.sectionKey].map(function (field) {
        if (field.key === action.field) {
          field.value = action.value;
        }

        return field;
      })));

    default:
      return state;
  }
}
export function updateFBChecklist(field, value) {
  return {
    type: UPDATE_NON_SECTION_FIELD,
    field: field,
    value: value
  };
}
export function updateContentSectionRadio(sectionKey, value) {
  return {
    type: UPDATE_RADIO,
    sectionKey: sectionKey,
    value: value
  };
}
export function updateContentField(sectionKey, field, value) {
  return {
    type: UPDATE_FIELD,
    sectionKey: sectionKey,
    value: value,
    field: field
  };
}
export function clearUpcomingContentTemplate() {
  return {
    type: CLEAR_UC_TEMPLATE
  };
}
export function getUpcomingPosts(orgId, startMoment, endMoment) {
  return {
    types: [GET_POSTS, GET_POSTS_SUCCESS, GET_POSTS_FAIL],
    startMoment: startMoment,
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/posts?from=").concat(startMoment.valueOf(), "&to=").concat(endMoment.valueOf(), "&noAds=true&queuedPostsRange=true"));
    }
  };
}