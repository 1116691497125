export var CLOSE_MODAL = 'likeablelocal/modalLayout/CLOSE_MODAL';
export var OPEN_MODAL = 'likeablelocal/modalLayout/OPEN_MODAL';
var initialState = {
  open: false
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case CLOSE_MODAL:
      return initialState;

    case OPEN_MODAL:
      return {
        open: true
      };

    default:
      return state;
  }
}
export function closeModal() {
  return {
    type: CLOSE_MODAL
  };
}
export function openModal() {
  return {
    type: OPEN_MODAL
  };
}