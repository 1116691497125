import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { createStore as _createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { autoRehydrate, createPersistor } from 'redux-persist';
import createMiddleware from './middleware/clientMiddleware';
import { routerMiddleware as gaRouterMiddleware, eventsTracking as gaEventMiddleware } from './middleware/googleAnalytics';
import createReducer, { injectAsyncReducer } from './reducer';
import conf from '../config';
export default function createStore(history, client, data) {
  var online = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var persistConfig = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  var moreMiddleware = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : [];
  var reduxRouterMiddleware = routerMiddleware(history);
  var middleware = [createMiddleware(client), reduxRouterMiddleware, gaRouterMiddleware, gaEventMiddleware].concat(_toConsumableArray(moreMiddleware));
  var finalCreateStore;
  console.log(__DEVTOOLS__, '__DEVTOOLS__', process.env, conf.isDevelopment);

  if (conf.isDevelopment === "true") {
    var _require = require('redux-devtools'),
        persistState = _require.persistState;

    var DevTools = require('../containers/DevTools/DevTools');

    console.log('window.devToolsExtension', window.devToolsExtension);
    var enhancers = [autoRehydrate({
      log: true
    })].concat([applyMiddleware.apply(void 0, _toConsumableArray(middleware)), persistState(window.location.href.match(/[?&]debug_session=([^&]+)\b/)), window.devToolsExtension ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : DevTools.instrument()]);
    finalCreateStore = compose.apply(void 0, _toConsumableArray(enhancers))(_createStore);
  } else {
    var _enhancers = [autoRehydrate()].concat(applyMiddleware.apply(void 0, _toConsumableArray(middleware)));

    finalCreateStore = compose.apply(void 0, _toConsumableArray(_enhancers))(_createStore);
  }

  var store = finalCreateStore(createReducer(), data);
  store.asyncReducers = {};
  store.injectAsyncReducer = injectAsyncReducer.bind(null, store);
  if (persistConfig) createPersistor(store, persistConfig);
  store.dispatch({
    type: 'PERSIST'
  });

  if (__DEVELOPMENT__ && module.hot) {
    module.hot.accept('./reducer', function () {
      store.replaceReducer(require('./reducer')["default"]());
    });
  }

  return store;
}