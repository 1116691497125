import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as _ from 'lodash';
var UNLINK_GROUP_POST = 'likeablelocal/groupPosts/UNLINK_GROUP_POST';
export var UNLINK_GROUP_POST_SUCCESS = 'likeablelocal/groupPosts/UNLINK_GROUP_POST_SUCCESS';
var UNLINK_GROUP_POST_FAIL = 'likeablelocal/groupPosts/UNLINK_GROUP_POST_FAIL';
var DELETE_GROUP_POST = 'likeablelocal/groupPosts/DELETE_GROUP_POST';
export var DELETE_GROUP_POST_SUCCESS = 'likeablelocal/groupPosts/DELETE_GROUP_POST_SUCCESS';
var DELETE_GROUP_POST_FAIL = 'likeablelocal/groupPosts/DELETE_GROUP_POST_FAIL';
var PARSE_LINK = 'likeablelocal/groupPosts/PARSE_LINK';
var PARSE_LINK_SUCCESS = 'likeablelocal/groupPosts/PARSE_LINK_SUCCESS';
var PARSE_LINK_FAIL = 'likeablelocal/groupPosts/PARSE_LINK_FAIL';
var GET_GROUP_POSTS = 'likeablelocal/groupPosts/GET_GROUP_POSTS';
var GET_GROUP_POSTS_SUCCESS = 'likeablelocal/groupPosts/GET_GROUP_POSTS_SUCCESS';
var GET_GROUP_POSTS_FAIL = 'likeablelocal/groupPosts/GET_GROUP_POSTS_FAIL';
export var initialState = {
  upcomingPosts: [],
  pastPosts: [],
  gettingGroupPosts: false,
  parsedLinks: {},
  upcomingCount: null,
  pastCount: null,
  upcomingPage: 1,
  pastPage: 1
};
export default function reducer() {
  var _objectSpread2;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _.cloneDeep(initialState);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case DELETE_GROUP_POST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        upcomingPosts: state.upcomingPosts.filter(function (post) {
          return post.id !== action.postId;
        })
      });

    case GET_GROUP_POSTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingGroupPosts: true
      });

    case GET_GROUP_POSTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, (_objectSpread2 = {
        gettingGroupPosts: false
      }, _defineProperty(_objectSpread2, action.pastPosts ? 'pastPosts' : 'upcomingPosts', action.result.data.data), _defineProperty(_objectSpread2, action.pastPosts ? 'pastCount' : 'upcomingCount', action.result.data.count), _defineProperty(_objectSpread2, action.pastPosts ? 'pastPage' : 'upcomingPage', action.result.data.page), _objectSpread2));

    case GET_GROUP_POSTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingGroupPosts: false
      });

    case PARSE_LINK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        parsingLink: false,
        parsedLinks: _objectSpread(_objectSpread({}, state.parsedLinks), {}, _defineProperty({}, action.postId, action.result.data))
      });

    case UNLINK_GROUP_POST_SUCCESS:
      var upcomingPosts = state.upcomingPosts.map(function (post) {
        if (parseInt(post.id, 10) === parseInt(action.postId, 10)) {//          post.GroupPostOrganizations = post
        }
      });
      return _objectSpread({}, state);

    default:
      return state;
  }
}
export function parseLink(link, postId) {
  return {
    types: [PARSE_LINK, PARSE_LINK_SUCCESS, PARSE_LINK_FAIL],
    promise: function promise(client) {
      return client.post('/parseLink', {
        data: {
          link: link
        }
      });
    },
    postId: postId
  };
}
export function getGroupPosts() {
  var pastPosts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return {
    types: [GET_GROUP_POSTS, GET_GROUP_POSTS_SUCCESS, GET_GROUP_POSTS_FAIL],
    promise: function promise(client) {
      return client.get("/groupPosts?past=".concat(pastPosts, "&page=").concat(page));
    },
    pastPosts: pastPosts
  };
}
export function deleteGroupPost(postId) {
  return {
    types: [DELETE_GROUP_POST, DELETE_GROUP_POST_SUCCESS, DELETE_GROUP_POST_FAIL],
    promise: function promise(client) {
      return client.del("/groupPost/".concat(postId));
    },
    postId: postId
  };
}
export function unlinkGroupPost(postId, orgId) {
  return {
    types: [UNLINK_GROUP_POST, UNLINK_GROUP_POST_SUCCESS, UNLINK_GROUP_POST_FAIL],
    promise: function promise(client) {
      return client.put("/unlinkGroupPost/".concat(postId, "/org/").concat(orgId));
    },
    postId: postId,
    orgId: orgId
  };
}