import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as _ from 'lodash';
var ns = 'likeablelocal/imagePicker';
var UPDATE_URL = "".concat(ns, "/UPDATE_URL");
var INIT = "".concat(ns, "/INIT");
var ADD_IMAGE = "".concat(ns, "/ADD_IMAGE");
var GET_RECENT_IMAGES = "".concat(ns, "/GET_RECENT_IMAGES");
var GET_RECENT_IMAGES_SUCCESS = "".concat(ns, "/GET_RECENT_IMAGES_SUCCESS");
var GET_RECENT_IMAGES_FAIL = "".concat(ns, "/GET_RECENT_IMAGES_FAIL");
var UPLOAD_IMAGES = "".concat(ns, "/UPLOAD_IMAGES");
var UPLOAD_IMAGES_SUCCESS = "".concat(ns, "/UPLOAD_IMAGES_SUCCESS");
var UPLOAD_IMAGES_FAIL = "".concat(ns, "/UPLOAD_IMAGES_FAIL");
var UPLOAD_IMAGE_URL = "".concat(ns, "/UPLOAD_IMAGE_URL");
var UPLOAD_IMAGE_URL_SUCCESS = "".concat(ns, "/UPLOAD_IMAGE_URL_SUCCESS");
var UPLOAD_IMAGE_URL_FAIL = "".concat(ns, "/UPLOAD_IMAGE_URL_FAIL");
var initialState = {
  urlImageUploadError: null,
  fileUploadError: null,
  uploadingImage: false,
  selectedstockimages: [],
  recentImages: [],
  gettingRecentImages: false,
  selectedsavedimages: [],
  uploadedImages: [],
  uploadedURLImages: [],
  hasValidUrlLink: false,
  urlString: ''
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case INIT:
      return _.cloneDeep(initialState);

    case UPDATE_URL:
      return _objectSpread(_objectSpread({}, state), {}, {
        urlString: action.url,
        hasValidUrlLink: isValidURL(action.url)
      });

    case ADD_IMAGE:
      var key = "selected".concat(action.tab, "images");
      var images;

      if (_.some(state[key], action.image)) {
        // clicking again, so remove
        images = state[key].filter(function (i) {
          return i.id !== action.image.id;
        });
      } else if (state[key].length && state[key].length < action.maxImageCount) {
        // already images, so append
        images = [].concat(_toConsumableArray(state[key]), [action.image]);
      } else if (!state[key].length) {
        // first image added
        images = [action.image];
      } else {
        images = state[key];
        alert("You can only add ".concat(action.maxImageCount, " image").concat(action.maxImageCount > 1 ? 's' : '', "."));
      }

      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, key, images));

    case GET_RECENT_IMAGES:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingRecentImages: true
      });

    case GET_RECENT_IMAGES_SUCCESS:
      var fromOrganization = action.result.data[0];
      var mine = action.result.data[1];
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingRecentImages: false,
        recentImages: {
          org: fromOrganization,
          user: mine
        }
      });

    case GET_RECENT_IMAGES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingRecentImages: false
      });

    case UPLOAD_IMAGES:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadingImage: true,
        fileUploadError: null
      });

    case UPLOAD_IMAGES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadedImages: state.uploadedImages.length ? [].concat(_toConsumableArray(state.uploadedImages), [action.result.data]) : [action.result.data],
        uploadingImage: false,
        fileUploadError: null
      });

    case UPLOAD_IMAGES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadingImage: false,
        fileUploadError: action.error.error.message
      });

    case UPLOAD_IMAGE_URL:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadingImage: true
      });

    case UPLOAD_IMAGE_URL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadedURLImages: state.uploadedURLImages.length ? [].concat(_toConsumableArray(state.uploadedURLImages), [action.result.data]) : [action.result.data],
        uploadingImage: false
      });

    case UPLOAD_IMAGE_URL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadingImage: false,
        urlImageUploadError: true
      });

    default:
      return state;
  }
}
export function uploadImages(files, orgId) {
  return {
    types: [UPLOAD_IMAGES, UPLOAD_IMAGES_SUCCESS, UPLOAD_IMAGES_FAIL],
    promise: function promise(client) {
      return client.post("/imageUpload?forOrganization=".concat(orgId), {
        files: files
      });
    }
  };
}
export function uploadImageURL(url, orgId) {
  var mediaId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  console.log('url = ', url);
  return {
    types: [UPLOAD_IMAGE_URL, UPLOAD_IMAGE_URL_SUCCESS, UPLOAD_IMAGE_URL_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/imageUpload/url"), {
        data: {
          imageUrl: url,
          mediaId: mediaId
        }
      });
    }
  };
}
export function addImage(image, tab, maxImageCount) {
  return {
    type: ADD_IMAGE,
    image: image,
    tab: tab,
    maxImageCount: maxImageCount
  };
}
export function updateURL(url) {
  return {
    type: UPDATE_URL,
    url: url
  };
}
export function init() {
  return {
    type: INIT
  };
}

function isValidURL(url) {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}

export function getRecentImages(orgId) {
  return {
    types: [GET_RECENT_IMAGES, GET_RECENT_IMAGES_SUCCESS, GET_RECENT_IMAGES_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/recentImages"));
    },
    orgId: orgId
  };
}
;