import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
export default function calculateContract(contract, contractProducts) {
  var isAffiliateClientContract = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var obj = {};
  var products = contract.ContractProducts ? contract.ContractProducts : contractProducts;

  if (!products) {
    console.error('Attempted to calculate contract with null or empty products');
    return {};
  }

  if (!contract.isPartner && products) {
    var _products = _slicedToArray(products, 1),
        product = _products[0];

    obj.licenseFee = product.monthlyFee ? parseInt(parseFloat(product.monthlyFee) * 100.0) : 0;
    obj.adsFee = product.monthlyAdsBudget ? parseInt(parseFloat(product.monthlyAdsBudget) * 100.0) : 0;
    obj.customFee = product.monthlyCustomFee ? parseInt(parseFloat(product.monthlyCustomFee) * 100.0) : 0;
    obj.setupFee = product.setupFee ? parseInt(parseFloat(product.setupFee) * 100.0) : 0;
    obj.monthlyFee = obj.licenseFee + obj.adsFee + obj.customFee;
  } else {
    obj.licenseFee = getTotalDueTodayPartner(contract);
    obj.monthlyFee = getTotalDueTodayPartner(contract);
  }

  obj.totalDueToday = isAffiliateClientContract ? getTotalDueTodayAffiliateClient(contract) : contract.isPartner ? obj.licenseFee : getTotalDueToday(contract, products);

  if (isAffiliateClientContract) {
    obj.monthlyFee = obj.licenseFee + obj.adsFee;
  }

  return obj;
}

function getTotalDueTodayPartner(contract) {
  var cp = contract.ContractPartners[0];
  var initialTeamMemberCost = parseInt(cp.initialTeamMembers) * parseInt(cp.initialTeamMembersMonthlyPrice);
  var initialClientCost = parseInt(cp.initialClients) * parseInt(cp.initialClientsMonthlyPrice);
  return parseInt((initialTeamMemberCost + initialClientCost + parseInt(cp.monthlyFee)) * 100.0);
}

function getTotalDueToday(contract, contractProducts) {
  var val = contractProducts.reduce(function (prev, curr) {
    var quantity = parseFloat(curr.quantity);
    var setupFee = parseFloat(curr.setupFee);
    var monthlyFee = parseFloat(curr.monthlyFee);
    var adsFee = parseFloat(curr.monthlyAdsBudget);
    var customFee = curr.monthlyCustomFee ? parseFloat(curr.monthlyCustomFee) : 0;
    var total = (monthlyFee + customFee + setupFee + adsFee) * quantity;
    return prev + total;
  }, 0);
  return parseInt(val * 100.0);
}

function getTotalDueTodayAffiliateClient(contract) {
  var p = contract.Products[0];
  var total = parseFloat(p.retailSetupFee) + parseFloat(p.retailMonthlyPrice) + parseFloat(p.adsBudget);
  return parseInt(total);
}

export function formatDollars(value) {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });
  return formatter.format(parseFloat(value));
}