import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import moment from 'moment';
import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
var ns = 'likeablelocal/reporting';
var ARCHIVE_REPORT = "".concat(ns, "/ARCHIVE_REPORT");
var ARCHIVE_REPORT_SUCCESS = "".concat(ns, "/ARCHIVE_REPORT_SUCCESS");
var ARCHIVE_REPORT_FAIL = "".concat(ns, "/ARCHIVE_REPORT_FAIL");
var GET_REPORTS = "".concat(ns, "/GET_REPORTS");
var GET_REPORTS_SUCCESS = "".concat(ns, "/GET_REPORTS_SUCCESS");
var GET_REPORTS_FAIL = "".concat(ns, "/GET_REPORTS_FAIL");
var RECENT_POSTS = "".concat(ns, "/RECENT_POSTS");
var RECENT_POSTS_SUCCESS = "".concat(ns, "/RECENT_POSTS_SUCCESS");
var RECENT_POSTS_FAIL = "".concat(ns, "/RECENT_POSTS_FAIL");
var CLEAR_PAGE_INSIGHTS = "".concat(ns, "/CLEAR_PAGE_INSIGHTS");
var PAGE_INSIGHTS = "".concat(ns, "/PAGE_INSIGHTS");
var PAGE_INSIGHTS_SUCCESS = "".concat(ns, "/PAGE_INSIGHTS_SUCCESS");
var PAGE_INSIGHTS_FAIL = "".concat(ns, "/PAGE_INSIGHTS_FAIL");
var GET_RECENT_FB_POSTS = "".concat(ns, "/GET_RECENT_FB_POSTS");
var GET_RECENT_FB_POSTS_SUCCESS = "".concat(ns, "/GET_RECENT_FB_POSTS_SUCCESS");
var GET_RECENT_FB_POSTS_FAIL = "".concat(ns, "/GET_RECENT_FB_POSTS_FAIL");
var CLEAR_RECENT_FB_POSTS = "".concat(ns, "/CLEAR_RECENT_FB_POSTS");
var initialState = {
  recentFBPosts: [],
  recentPostsPaging: {},
  loadingRecentFBPosts: false,
  loadingMorePosts: false,
  pageInsightStats: {},
  pageInsightPaging: {},
  reports: {},
  gettingReports: false,
  selectedInsights: [{
    metric: 'page_fans',
    label: 'Total Page Likes'
  }, {
    metric: 'page_views_total',
    label: 'Total Page Views'
  }, {
    metric: 'page_impressions',
    label: 'Page Impressions'
  }, {
    metric: 'page_engaged_users',
    label: 'Engaged Users'
  }],
  insightPeriod: 'LAST_7_DAYS'
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case ARCHIVE_REPORT:
      return _objectSpread(_objectSpread({}, state), {}, {
        archivingReport: true
      });

    case ARCHIVE_REPORT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        archiveReport: false,
        reports: _objectSpread(_objectSpread({}, state.reports), {}, _defineProperty({}, action.orgId, state.reports[action.orgId].map(function (report) {
          if (parseInt(report.id, 10) === parseInt(action.reportId, 10)) {
            report.isArchived = true;
          }

          return report;
        })))
      });

    case ARCHIVE_REPORT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        archivingReport: false
      });

    case GET_REPORTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingReports: true
      });

    case GET_REPORTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingReports: false,
        reports: _objectSpread(_objectSpread({}, state.reports), {}, _defineProperty({}, action.orgId, _.sortBy(action.result.data, function (r) {
          return new moment(r.createdAt);
        }).reverse()))
      });

    case GET_REPORTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingReports: false
      });

    case CLEAR_PAGE_INSIGHTS:
      return function () {
        return _objectSpread(_objectSpread({}, state), {}, {
          pageInsightStats: {},
          pageInsightPaging: {}
        });
      }();

    case RECENT_POSTS_SUCCESS:
      return function () {
        return _objectSpread(_objectSpread({}, state), {}, {
          current: _objectSpread(_objectSpread({}, state.current), {}, {
            recentPosts: _toConsumableArray(action.result.data.data),
            recentPostsPaging: _objectSpread({}, action.result.data && action.result.data.paging ? action.result.data.paging.cursors : {})
          })
        });
      }();

    case PAGE_INSIGHTS_SUCCESS:
      return function () {
        var insights = Object.assign({}, state.pageInsightStats, action.result.data.data);
        return _objectSpread(_objectSpread({}, state), {}, {
          pageInsightStats: insights,
          pageInsightPaging: _objectSpread({}, action.result.data.paging.cursors)
        });
      }();

    case GET_RECENT_FB_POSTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingRecentFBPosts: true,
        loadingMorePosts: !!state.recentFBPosts.length,
        recentFbPostsLoadingError: null
      });

    case GET_RECENT_FB_POSTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingRecentFBPosts: false,
        loadingMorePosts: false,
        recentFBPosts: mergeRecentPosts(state.recentFBPosts, action.result.data.data),
        recentPostsPaging: _objectSpread(_objectSpread({}, state.recentPostsPaging), {}, {
          after: action.result.data && action.result.data.paging ? action.result.data.paging.cursors.after : null
        }),
        recentFbPostsLoadingError: null
      });

    case GET_RECENT_FB_POSTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingRecentFBPosts: false,
        loadingMorePosts: false,
        recentFbPostsLoadingError: true
      });

    case CLEAR_RECENT_FB_POSTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        recentFBPosts: []
      });

    default:
      return state;
  }
}
export function getReports(orgId) {
  return {
    types: [GET_REPORTS, GET_REPORTS_SUCCESS, GET_REPORTS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/reports"));
    },
    orgId: orgId
  };
}
export function archiveReport(orgId, reportId) {
  return {
    types: [ARCHIVE_REPORT, ARCHIVE_REPORT_SUCCESS, ARCHIVE_REPORT_FAIL],
    promise: function promise(client) {
      return client.del("/org/".concat(orgId, "/report/").concat(reportId));
    },
    orgId: orgId,
    reportId: reportId
  };
}
export function getRecentFBPagePostStats(orgId, socialAccountId) {
  var cursors = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var url = "/org/".concat(orgId, "/socialAccount/").concat(socialAccountId, "/recentPosts?");
  url += cursors.before ? "&before=".concat(cursors.before) : '';
  url += cursors.after ? "&after=".concat(cursors.after) : '';
  return {
    types: [GET_RECENT_FB_POSTS, GET_RECENT_FB_POSTS_SUCCESS, GET_RECENT_FB_POSTS_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function clearRecentFBPosts() {
  return {
    type: CLEAR_RECENT_FB_POSTS
  };
}
export function clearPageInsights() {
  return {
    type: CLEAR_PAGE_INSIGHTS
  };
}
export function getPageInsights(orgId, socialAccountId, metrics) {
  var aggregation = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'WEEK';
  var dateSpan = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'THIS_MONTH';
  var cursors = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
  return {
    types: [PAGE_INSIGHTS, PAGE_INSIGHTS_SUCCESS, PAGE_INSIGHTS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/socialAccount/").concat(socialAccountId, "/pageInsights?metrics=").concat(metrics.join(','), "&aggregation=").concat(aggregation, "&dateSpan=").concat(dateSpan, "&before=").concat(cursors.before || '', "&after=").concat(cursors.after || ''));
    }
  };
}

function mergeRecentPosts(oldPosts, newPosts) {
  var mergedList = _toConsumableArray(oldPosts);

  newPosts.forEach(function (p) {
    var existingPostIndex = mergedList.findIndex(function (oldPost) {
      return oldPost.id === p.id;
    });

    if (existingPostIndex !== -1) {
      mergedList[existingPostIndex] = p;
    } else {
      mergedList.push(p);
    }
  });
  return mergedList;
}