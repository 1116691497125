import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var initialState = {
  fetchingAccountLink: false
};
export var FETCH_STRIPE_LINK = 'likeablelocal/partnerOnboarding/FETCH_STRIPE_LINK';
export var FETCH_STRIPE_LINK_SUCCESS = 'likeablelocal/partnerOnboarding/FETCH_STRIPE_LINK_SUCCESS';
export var FETCH_STRIPE_LINK_FAIL = 'likeablelocal/partnerOnboarding/FETCH_STRIPE_LINK_FAIL';
export var CREATE_INITIAL_CONNECT_ACCOUNT = 'likeablelocal/partnerOnboarding/CREATE_INITIAL_CONNECT_ACCOUNT';
export var CREATE_INITIAL_CONNECT_ACCOUNT_SUCCESS = 'likeablelocal/partnerOnboarding/CREATE_INITIAL_CONNECT_ACCOUNT_SUCCESS';
export var CREATE_INITIAL_CONNECT_ACCOUNT_FAIL = 'likeablelocal/partnerOnboarding/CREATE_INITIAL_CONNECT_ACCOUNT_FAIL';
export var CONNECT_BANK = 'likeablelocal/partnerOnboarding/CONNECT_BANK';
export var CONNECT_BANK_SUCCESS = 'likeablelocal/partnerOnboarding/CONNECT_BANK_SUCCESS';
export var CONNECT_BANK_FAIL = 'likeablelocal/partnerOnboarding/CONNECT_BANK_FAIL';
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case FETCH_STRIPE_LINK:
      return _objectSpread(_objectSpread({}, state), {}, {
        fetchingAccountLink: true
      });

    case FETCH_STRIPE_LINK_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        fetchingAccountLink: false
      });

    case FETCH_STRIPE_LINK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        fetchingAccountLink: false,
        accountLink: action.result.data.url
      });

    default:
      return state;
  }
}
export function getStripeAccountLink(orgId) {
  return {
    types: [FETCH_STRIPE_LINK, FETCH_STRIPE_LINK_SUCCESS, FETCH_STRIPE_LINK_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/partnerOnboarding/stripeLink"));
    }
  };
}
export function createInitialAccount(orgId) {
  return {
    types: [CREATE_INITIAL_CONNECT_ACCOUNT, CREATE_INITIAL_CONNECT_ACCOUNT_SUCCESS, CREATE_INITIAL_CONNECT_ACCOUNT_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/partnerOnboarding/initialAccount"));
    }
  };
}
export function connectBankAccount(orgId, bankToken) {
  return {
    types: [CONNECT_BANK, CONNECT_BANK_SUCCESS, CONNECT_BANK_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/partnerOnboarding/bankAccount"), {
        data: {
          bankToken: bankToken
        }
      });
    }
  };
}