import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import ReactGA from 'react-ga';
var options = {};

var trackPage = function trackPage(page) {
  ReactGA.set(_objectSpread({
    page: page
  }, options));
  ReactGA.pageview(page);
};

var currentPage = '';

var routerMiddleware = function routerMiddleware(store) {
  return function (next) {
    return function (action) {
      if (action.type === '@@router/LOCATION_CHANGE') {
        var _action$payload = action.payload,
            pathname = _action$payload.pathname,
            search = _action$payload.search;
        var nextPage = "".concat(pathname).concat(search);

        if (currentPage !== nextPage) {
          currentPage = nextPage;
          trackPage(nextPage);
        }
      }

      return next(action);
    };
  };
};

export default routerMiddleware;