import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
var INCREMENT = 'redux-example/counter/INCREMENT';
var initialState = {
  count: 0
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case INCREMENT:
      var count = state.count;
      return {
        count: count + 1
      };

    default:
      return state;
  }
}
export function increment() {
  return {
    type: INCREMENT
  };
}