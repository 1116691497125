import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
import { GET_FACEBOOK_ADS_FOR_PLAN, GET_FACEBOOK_ADS_FOR_PLAN_SUCCESS } from './adsPlanner';
var CREATE_AUDIENCE = 'likeablelocal/ads/CREATE_AUDIENCE';
var CREATE_AUDIENCE_SUCCESS = 'likeablelocal/ads/CREATE_AUDIENCE_SUCCESS';
var CREATE_AUDIENCE_FAIL = 'likeablelocal/ads/CREATE_AUDIENCE_FAIL';
var LOAD_ALL_ADS = 'likeablelocal/ads/LOAD_ALL_ADS';
var LOAD_ALL_ADS_SUCCESS = 'likeablelocal/ads/LOAD_ALL_ADS_SUCCESS';
var LOAD_ALL_ADS_FAIL = 'likeablelocal/ads/LOAD_ALL_ADS_FAIL';
var LOAD_STATUS = 'likeablelocal/ads/LOAD_STATUS';
var LOAD_STATUS_SUCCESS = 'likeablelocal/ads/LOAD_STATUS_SUCCESS';
var LOAD_STATUS_FAIL = 'likeablelocal/ads/LOAD_STATUS_FAIL';
var VALIDATE_TARGETING = 'likeablelocal/ads/VALIDATE_TARGETING';
var VALIDATE_TARGETING_SUCCESS = 'likeablelocal/ads/VALIDATE_TARGETING_SUCCESS';
var VALIDATE_TARGETING_FAIL = 'likeablelocal/ads/VALIDATE_TARGETING_FAIL';
var LOAD_ADS = 'likeablelocal/ads/LOAD_ADS';
var LOAD_ADS_SUCCESS = 'likeablelocal/ads/LOAD_ADS_SUCCESS';
var LOAD_ADS_FAIL = 'likeablelocal/ads/LOAD_ADS_FAIL';
var LOAD_ADS_DASHBOARD = 'likeablelocal/ads/LOAD_ADS_DASHBOARD';
var LOAD_ADS_DASHBOARD_SUCCESS = 'likeablelocal/ads/LOAD_ADS_DASHBOARD_SUCCESS';
var LOAD_ADS_DASHBOARD_FAIL = 'likeablelocal/ads/LOAD_ADS_DASHBOARD_FAIL';
var LOAD_FACEBOOK_AD_ACCOUNT = 'likeablelocal/ads/LOAD_FACEBOOK_AD_ACCOUNT';
var LOAD_FACEBOOK_AD_ACCOUNT_SUCCESS = 'likeablelocal/ads/LOAD_FACEBOOK_AD_ACCOUNT_SUCCESS';
var LOAD_FACEBOOK_AD_ACCOUNT_FAIL = 'likeablelocal/ads/LOAD_FACEBOOK_AD_ACCOUNT_FAIL';
var GET_AUDIENCE_DEFINITION = 'likeablelocal/ads/GET_AUDIENCE_DEFINITION';
var GET_AUDIENCE_DEFINITION_SUCCESS = 'likeablelocal/ads/GET_AUDIENCE_DEFINITION_SUCCESS';
var GET_AUDIENCE_DEFINITION_FAIL = 'likeablelocal/ads/GET_AUDIENCE_DEFINITION_FAIL';
var GET_LEAD_AD_RESULTS = 'likeablelocal/ads/GET_LEAD_AD_RESULTS';
var GET_LEAD_AD_RESULTS_SUCCESS = 'likeablelocal/ads/GET_LEAD_AD_RESULTS_SUCCESS';
var GET_LEAD_AD_RESULTS_FAIL = 'likeablelocal/ads/GET_LEAD_AD_RESULTS_FAIL';
var CLEAR_LEAD_AD_RESULTS = 'likeablelocal/ads/CLEAR_LEAD_AD_RESULTS';
var GET_TARGETING = 'likeablelocal/ads/GET_TARGETING';
var GET_TARGETING_SUCCESS = 'likeablelocal/ads/GET_TARGETING_SUCCESS';
var GET_TARGETING_FAIL = 'likeablelocal/ads/GET_TARGETING_FAIL';
var LOAD_FB_PIXELS = 'likeablelocal/ads/LOAD_FB_PIXELS';
var LOAD_FB_PIXELS_SUCCESS = 'likeablelocal/ads/LOAD_FB_PIXELS_SUCCESS';
var LOAD_FB_PIXELS_FAIL = 'likeablelocal/ads/LOAD_FB_PIXELS_FAIL';
var VERIFY_PIXEL = 'likeablelocal/ads/VERIFY_PIXEL';
var VERIFY_PIXEL_SUCCESS = 'likeablelocal/ads/VERIFY_PIXEL_SUCCESS';
var VERIFY_PIXEL_FAIL = 'likeablelocal/ads/VERIFY_PIXEL_FAIL';
var GENERATE_SLIDESHOW_VIDEO = 'likeablelocal/ads/GENERATE_SLIDESHOW_VIDEO';
var GENERATE_SLIDESHOW_VIDEO_SUCCESS = 'likeablelocal/ads/GENERATE_SLIDESHOW_VIDEO_SUCCESS';
var GENERATE_SLIDESHOW_VIDEO_FAIL = 'likeablelocal/ads/GENERATE_SLIDESHOW_VIDEO_FAIL';
var GET_SAVED_AUDIENCES = 'likeablelocal/ads/GET_SAVED_AUDIENCES';
var GET_SAVED_AUDIENCES_SUCCESS = 'likeablelocal/ads/GET_SAVED_AUDIENCES_SUCCESS';
var GET_SAVED_AUDIENCES_FAIL = 'likeablelocal/ads/GET_SAVED_AUDIENCES_FAIL';
var UPLOAD_VIDEO_TO_FACEBOOK = 'likeablelocal/ads/UPLOAD_VIDEO_TO_FACEBOOK';
var UPLOAD_VIDEO_TO_FACEBOOK_SUCCESS = 'likeablelocal/ads/UPLOAD_VIDEO_TO_FACEBOOK_SUCCESS';
var UPLOAD_VIDEO_TO_FACEBOOK_FAIL = 'likeablelocal/ads/UPLOAD_VIDEO_TO_FACEBOOK_FAIL';
var SET_AD_STATUS = 'likeablelocal/ads/SET_AD_STATUS';
var SET_AD_STATUS_SUCCESS = 'likeablelocal/ads/SET_AD_STATUS_SUCCESS';
var SET_AD_STATUS_FAIL = 'likeablelocal/ads/SET_AD_STATUS_FAIL';
var CHANGE_FB_PIXEL_VERIFY_URL = 'likeablelocal/ads/CHANGE_FB_PIXEL_VERIFY_URL';
var UNMOUNT_DASHBOARD = 'likeablelocal/ads/UNMOUNT_DASHBOARD';
var GET_AD_RESULTS_CSV = 'likeablelocal/ads/GET_AD_RESULTS_CSV';
var GET_AD_RESULTS_CSV_SUCCESS = 'likeablelocal/ads/GET_AD_RESULTS_CSV_SUCCESS';
var GET_AD_RESULTS_CSV_FAIL = 'likeablelocal/ads/GET_AD_RESULTS_CSV_FAIL';
var PUSH_SAVED_AUDIENCE = 'likeablelocal/ads/PUSH_SAVED_AUDIENCE';
var initialState = {
  creatingAudience: false,
  loadingStatus: true,
  loadingAllAds: false,
  loadingPlanAds: false,
  loadingAds: true,
  loadingAdsDashboard: false,
  loadingLeadAdResults: false,
  loadingTargeting: false,
  targetingInfo: [],
  leadAdResults: [],
  ads: [],
  adAccount: {},
  status: {
    inProgress: 0,
    completed: 0,
    upcoming: 0,
    drafts: 0
  }
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case CREATE_AUDIENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        creatingAudience: true
      });

    case CREATE_AUDIENCE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        creatingAudience: false,
        savedAudiences: [].concat(_toConsumableArray(state.savedAudiences), [action.result.data])
      });

    case CREATE_AUDIENCE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        creatingAudience: false
      });

    case GENERATE_SLIDESHOW_VIDEO_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        slideshowVideoID: action.result.data,
        slideshowUploadKey: action.uploadKey
      });

    case GET_FACEBOOK_ADS_FOR_PLAN:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingPlanAds: true
      });

    case LOAD_STATUS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingStatus: true
      });

    case LOAD_STATUS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingStatus: false,
        status: action.result.data
      });

    case LOAD_STATUS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingStatus: false
      });

    case LOAD_ADS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAds: true
      });

    case LOAD_ADS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        ads: action.result.data,
        loadingAds: false,
        adsLoadError: null
      });

    case LOAD_ADS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAds: false,
        adsLoadError: action.error
      });

    case LOAD_FACEBOOK_AD_ACCOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAds: true
      });

    case LOAD_FACEBOOK_AD_ACCOUNT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAds: false,
        adAccount: _objectSpread(_objectSpread({}, state.adAccount), {}, _defineProperty({}, action.organizationId, action.result.data))
      });

    case LOAD_FACEBOOK_AD_ACCOUNT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        adAccount: null,
        loadingAds: false
      });

    case GET_AUDIENCE_DEFINITION:
      return _objectSpread(_objectSpread({}, state), {}, {
        fetchingAudienceData: true
      });

    case GET_AUDIENCE_DEFINITION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        audienceDefinitionError: '',
        audienceDefinition: action.result ? action.result.data : {},
        fetchingAudienceData: false
      });

    case GET_AUDIENCE_DEFINITION_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        audienceDefinition: {},
        audienceDefinitionError: action.error && action.error.error && action.error.error.data && action.error.error.data.error ? action.error.error.data.error.error_user_msg : action.error.error && action.error.error.message ? action.error.error.message : 'Unable to get the estimated audience.',
        fetchingAudienceData: false
      });

    case LOAD_FB_PIXELS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        facebookPixels: _toConsumableArray(action.result.data)
      });

    case CHANGE_FB_PIXEL_VERIFY_URL:
      return _objectSpread(_objectSpread({}, state), {}, {
        facebookPixelVerifyUrl: action.data.url
      });

    case VERIFY_PIXEL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isVerifyingPixel: true
      });

    case VERIFY_PIXEL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isVerifyingPixel: false
      });

    case VERIFY_PIXEL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isVerifyingPixel: false
      });

    case LOAD_ADS_DASHBOARD:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAds: true
      });

    case LOAD_ADS_DASHBOARD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        adsDashboard: action.result.data,
        loadingAllAds: false
      });

    case LOAD_ALL_ADS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingAllAds: true
      });

    case LOAD_ALL_ADS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        allAds: action.result.data,
        loadingAllAds: false
      });

    case GET_FACEBOOK_ADS_FOR_PLAN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        planAds: {
          ads: action.result.data.facebookAds
        },
        loadingPlanAds: false
      });

    case LOAD_ADS_DASHBOARD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({
        adsDashboard: {
          ads: []
        },
        loadingAds: false
      }, "loadingAds", false));

    case UNMOUNT_DASHBOARD:
      return _objectSpread(_objectSpread({}, state), {}, {
        adsDashboard: {
          ads: []
        },
        loadingAds: true
      });

    case GET_SAVED_AUDIENCES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        savedAudiences: action.result.data
      });

    case PUSH_SAVED_AUDIENCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        savedAudiences: [].concat(_toConsumableArray(state.savedAudiences), [action.audience])
      });

    case GET_LEAD_AD_RESULTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingLeadAdResults: true
      });

    case GET_LEAD_AD_RESULTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        leadAdResults: action.result.data,
        loadingLeadAdResults: false
      });

    case GET_LEAD_AD_RESULTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingLeadAdResults: false
      });

    case CLEAR_LEAD_AD_RESULTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        leadAdResults: []
      });

    case GET_TARGETING:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingTargeting: true
      });

    case GET_TARGETING_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        targetingInfo: action.result.data,
        loadingTargeting: false
      });

    case GET_TARGETING_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingTargeting: false
      });

    case UPLOAD_VIDEO_TO_FACEBOOK:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUploadingFacebookVideo: true
      });

    case UPLOAD_VIDEO_TO_FACEBOOK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadedFacebookVideo: _objectSpread({}, action.result.data.details),
        isUploadingFacebookVideo: false
      });

    case UPLOAD_VIDEO_TO_FACEBOOK_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isUploadingFacebookVideo: false
      });

    case SET_AD_STATUS:
      return _objectSpread(_objectSpread({}, state), {}, {
        adsDashboard: _objectSpread(_objectSpread({}, state.adsDashboard), {}, {
          ads: _toConsumableArray(state.adsDashboard.ads.map(function (ad) {
            if (ad.campaign.id === action.campaignId) {
              return _objectSpread(_objectSpread({}, ad), {}, {
                campaign: _objectSpread(_objectSpread({}, ad.campaign), {}, {
                  effective_status: action.status ? 'ACTIVE' : 'PAUSED'
                })
              });
            }

            return ad;
          }))
        })
      });

    case SET_AD_STATUS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        adsDashboard: _objectSpread(_objectSpread({}, state.adsDashboard), {}, {
          ads: _toConsumableArray(state.adsDashboard.ads.map(function (ad) {
            if (action.campaignId && ad.campaign.id === action.campaignId) {
              return _objectSpread(_objectSpread({}, ad), {}, {
                campaign: _objectSpread(_objectSpread({}, ad.campaign), {}, {
                  effective_status: action.status ? 'ACTIVE' : 'PAUSED'
                })
              });
            } else if (action.adId && ad.id === action.adId) {
              return _objectSpread(_objectSpread({}, ad), {}, {
                effective_status: action.status ? 'ACTIVE' : 'PAUSED'
              });
            }

            return ad;
          }))
        })
      });

    default:
      return state;
  }
}
export function generateSlideshowVideo(organizationId, socialAccountId, imageUrls, duration, transition) {
  var url = "/org/".concat(organizationId, "/socialAccount/").concat(socialAccountId, "/facebookSlideshowVideo");
  return {
    types: [GENERATE_SLIDESHOW_VIDEO, GENERATE_SLIDESHOW_VIDEO_SUCCESS, GENERATE_SLIDESHOW_VIDEO_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: {
          slideshowSpec: {
            images_urls: _toConsumableArray(imageUrls),
            duration_ms: parseInt(duration),
            transition_ms: parseInt(transition)
          }
        }
      });
    },
    uploadKey: "".concat(imageUrls.join('')).concat(duration).concat(transition)
  };
}
export function verifyFacebookPixelInstallation(organizationId, targetUrl, pixelId) {
  var url = "/org/".concat(organizationId, "/verifyFacebookPixel");
  return {
    types: [VERIFY_PIXEL, VERIFY_PIXEL_SUCCESS, VERIFY_PIXEL_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: {
          pixelId: pixelId,
          url: targetUrl
        }
      });
    }
  };
}
export function unmountDashboard() {
  return {
    type: UNMOUNT_DASHBOARD
  };
}
export function changeFacebookPixelVerifyUrl(url) {
  return {
    type: CHANGE_FB_PIXEL_VERIFY_URL,
    data: {
      url: url
    }
  };
}
export function getAudienceDefinition(organizationId, socialAccountId, targetingSpec) {
  var url = "/org/".concat(organizationId, "/socialAccount/").concat(socialAccountId, "/audienceDefinition");
  return {
    types: [GET_AUDIENCE_DEFINITION, GET_AUDIENCE_DEFINITION_SUCCESS, GET_AUDIENCE_DEFINITION_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: {
          targeting: targetingSpec
        }
      });
    }
  };
}
export function loadAdsStatus(organizationId) {
  var url = "/org/".concat(organizationId, "/ads/status");
  return {
    types: [LOAD_STATUS, LOAD_STATUS_SUCCESS, LOAD_STATUS_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function loadFacebookAdAccount(organizationId) {
  var url = "/org/".concat(organizationId, "/facebook/adAccount");
  return {
    types: [LOAD_FACEBOOK_AD_ACCOUNT, LOAD_FACEBOOK_AD_ACCOUNT_SUCCESS, LOAD_FACEBOOK_AD_ACCOUNT_FAIL],
    promise: function promise(client) {
      return client.get(url);
    },
    organizationId: organizationId
  };
}
export function loadAds(organizationId) {
  var url = "/org/".concat(organizationId, "/ads?insights=true");
  return {
    types: [LOAD_ADS, LOAD_ADS_SUCCESS, LOAD_ADS_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function loadAdsDashboard(organizationId, socialAccountId) {
  var url = "/org/".concat(organizationId, "/socialAccount/").concat(socialAccountId, "/adsDashboard");
  return {
    types: [LOAD_ADS_DASHBOARD, LOAD_ADS_DASHBOARD_SUCCESS, LOAD_ADS_DASHBOARD_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function loadAllAds(organizationId, socialAccountId) {
  var url = "/org/".concat(organizationId, "/socialAccount/").concat(socialAccountId, "/adsDashboard");
  return {
    types: [LOAD_ALL_ADS, LOAD_ALL_ADS_SUCCESS, LOAD_ALL_ADS_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function loadFacebookPixels(organizationId, socialAccountId) {
  var url = "/org/".concat(organizationId, "/socialAccount/").concat(socialAccountId, "/facebookPixels");
  return {
    types: [LOAD_FB_PIXELS, LOAD_FB_PIXELS_SUCCESS, LOAD_FB_PIXELS_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function loadSavedFacebookAudiences(organizationId, adAccountId) {
  var url = "/org/".concat(organizationId, "/facebookAds/savedTargeting/").concat(adAccountId);
  return {
    types: [GET_SAVED_AUDIENCES, GET_SAVED_AUDIENCES_SUCCESS, GET_SAVED_AUDIENCES_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function pushSavedAudience(audience) {
  return {
    type: PUSH_SAVED_AUDIENCE,
    audience: audience
  };
}
export function getLeadAdResults(orgId, adId) {
  var url = "/org/".concat(orgId, "/ad/").concat(adId, "/leads");
  return {
    types: [GET_LEAD_AD_RESULTS, GET_LEAD_AD_RESULTS_SUCCESS, GET_LEAD_AD_RESULTS_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function getTargeting(orgId, adId) {
  var url = "/org/".concat(orgId, "/ad/").concat(adId, "/targeting");
  return {
    types: [GET_TARGETING, GET_TARGETING_SUCCESS, GET_TARGETING_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function clearLeadAdResults() {
  return {
    type: CLEAR_LEAD_AD_RESULTS
  };
}
export function uploadVideoToFacebook(orgId, socialAccountId, videoUrl) {
  var url = "/org/".concat(orgId, "/socialAccount/").concat(socialAccountId, "/facebookVideo");
  return {
    types: [UPLOAD_VIDEO_TO_FACEBOOK, UPLOAD_VIDEO_TO_FACEBOOK_SUCCESS, UPLOAD_VIDEO_TO_FACEBOOK_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: {
          videoUrl: videoUrl
        }
      });
    }
  };
}
export function setAdCampaignStatus(orgId, campaignId, status) {
  var url = "/org/".concat(orgId, "/campaign/").concat(campaignId, "/status");
  return {
    types: [SET_AD_STATUS, SET_AD_STATUS_SUCCESS, SET_AD_STATUS_FAIL],
    campaignId: campaignId,
    status: status,
    promise: function promise(client) {
      return client.put(url, {
        data: {
          status: status
        }
      });
    }
  };
}
export function validateTargeting(targeting, adAccountId) {
  var url = "/facebook/adAccount/".concat(adAccountId, "/validateTargeting");
  return {
    types: [VALIDATE_TARGETING, VALIDATE_TARGETING_SUCCESS, VALIDATE_TARGETING_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: {
          targeting: targeting
        }
      });
    }
  };
}
export function setAdStatus(orgId, adId, status) {
  var url = "/org/".concat(orgId, "/ad/").concat(adId, "/status");
  return {
    types: [SET_AD_STATUS, SET_AD_STATUS_SUCCESS, SET_AD_STATUS_FAIL],
    adId: adId,
    status: status,
    promise: function promise(client) {
      return client.put(url, {
        data: {
          status: status
        }
      });
    }
  };
}
export function getAdResultsCSV(orgId, adId) {
  var url = "/org/".concat(orgId, "/ad/").concat(adId, "/download.csv");
  return {
    types: [GET_AD_RESULTS_CSV, GET_AD_RESULTS_CSV_SUCCESS, GET_AD_RESULTS_CSV_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function createAudience(organizationId, newAudienceName, targeting) {
  var url = "/org/".concat(organizationId, "/ads/createAudience");
  return {
    types: [CREATE_AUDIENCE, CREATE_AUDIENCE_SUCCESS, CREATE_AUDIENCE_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: {
          newAudienceName: newAudienceName,
          targeting: targeting
        }
      });
    }
  };
}