import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as _ from 'lodash';
import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
import { CLOSE_MODAL } from './modalLayout';
var SELECT_ALL = 'likeablelocal/groupPost/SELECT_ALL';
var ADD_IMAGE = 'likeablelocal/groupPost/ADD_IMAGE';
var ADD_VIDEO = 'likeablelocal/groupPost/ADD_VIDEO';
var GET_VALUES = 'likeablelocal/groupPost/GET_VALUES';
var NULLIFY_PUBLISH_AT = 'likeablelocal/groupPost/NULLIFY_PUBLISH_AT';
var REMOVE_IMAGE = 'likeablelocal/groupPost/REMOVE_IMAGE';
var SET_BACK_URL = 'likeablelocal/groupPost/SET_BACK_URL';
var TOGGLE_QUEUED_POST = 'likeablelocal/groupPost/TOGGLE_QUEUED_POST';
var UPDATE_MESSAGE = 'likeablelocal/groupPost/UPDATE_MESSAGE';
var UPDATE_PLATFORM_CHECKBOX = 'likeablelocal/groupPost/UPDATE_PLATFORM_CHECKBOX';
var UPDATE_POST_TYPE = 'likeablelocal/groupPost/UPDATE_POST_TYPE';
var UPDATE_PUBLISH_AT = 'likeablelocal/groupPost/UPDATE_PUBLISH_AT';
var UPDATE_LINK_DATA = 'likeablelocal/groupPost/UPDATE_LINK_DATA';
var INIT = 'likeablelocal/groupPost/INIT';
var RESET_COMPOSEBOX = 'likeablelocal/groupPost/RESET_COMPOSEBOX';
var REMOVE_ALL_IMAGES = 'likeablelocal/groupPost/REMOVE_ALL_IMAGES';
var REMOVE_VIDEO = 'likeablelocal/groupPost/REMOVE_VIDEO';
var WIPE_LINK_DATA = 'likeablelocal/groupPost/WIPE_LINK_DATA';
var LOAD_LINK_PARSE = 'likeablelocal/groupPost/LOAD_LINK_PARSE';
var LOAD_LINK_PARSE_SUCCESS = 'likeablelocal/groupPost/LOAD_LINK_PARSE_SUCCESS';
var LOAD_LINK_PARSE_FAIL = 'likeablelocal/groupPost/LOAD_PARSE_FAIL';
var SUBMIT_POST = 'likeablelocal/groupPost/SUBMIT_POST';
export var SUBMIT_POST_SUCCESS = 'likeablelocal/groupPost/SUBMIT_POST_SUCCESS';
var SUBMIT_POST_FAIL = 'likeablelocal/groupPost/SUBMIT_POST_FAIL';
var SET_SELECTED_PREVIEW_TAB = 'likeablelocal/groupPost/SET_SELECTED_PREVIEW_TAB';
var SET_SELECTED_LINKEDIN_PREVIEW_TYPE = 'likeablelocal/groupPost/SET_SELECTED_LINKEDIN_PREVIEW_TYPE';
export var orgInitialState = {
  allSelected: false,
  existingPostId: null,
  images: [],
  linkData: {
    caption: null,
    description: null,
    image: null,
    link: null,
    title: null
  },
  messages: {
    global: '',
    facebook: null,
    twitter: null,
    linkedin: null
  },
  postType: 'textPost',
  publishAt: new Date().getTime(),
  selectedAccounts: {},
  videoUrl: null,
  selectedPreviewTab: 'Facebook',
  selectedLinkedinPreviewType: 'profile',
  posts: [],
  gettingGroupPosts: false
};
var initialState = {
  submittingPost: false,
  parsingLink: false
};

function defaultKeyResolver(action) {
  return action.meta ? action.meta.controlInstanceKey : undefined;
}

export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _.cloneDeep(orgInitialState);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case ADD_IMAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        images: [].concat(_toConsumableArray(state.images), [action.imageUrl])
      });

    case ADD_VIDEO:
      return _objectSpread(_objectSpread({}, state), {}, {
        videoUrl: action.videoUrl
      });

    case INIT:
      var publishAt = action.post.publishAt ? action.post.publishAt : new Date().getTime();

      if (action.extra && action.extra.minDate) {
        publishAt = action.extra.minDate;
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        selectedPreviewTab: 'Facebook',
        selectedLinkedinPreviewType: 'profile',
        existingPostId: action.post.existingPostId ? action.post.existingPostId : orgInitialState.existingPostId,
        images: action.post.images ? action.post.images : orgInitialState.images,
        linkData: {
          caption: !!action.post.linkData && !!action.post.linkData.caption ? action.post.linkData.caption : orgInitialState.linkData.caption,
          description: !!action.post.linkData && !!action.post.linkData.description ? action.post.linkData.description : orgInitialState.linkData.description,
          image: !!action.post.linkData && !!action.post.linkData.image ? action.post.linkData.image : orgInitialState.linkData.image,
          link: !!action.post.linkData && !!action.post.linkData.link ? action.post.linkData.link : orgInitialState.linkData.link,
          title: !!action.post.linkData && !!action.post.linkData.title ? action.post.linkData.title : orgInitialState.linkData.title
        },
        messages: {
          global: !!action.post.messages && !!action.post.messages.global ? action.post.messages.global : orgInitialState.messages.global,
          facebook: !!action.post.messages && !!action.post.messages.facebook ? action.post.messages.facebook : orgInitialState.messages.facebook,
          twitter: !!action.post.messages && !!action.post.messages.twitter ? action.post.messages.twitter : orgInitialState.messages.twitter,
          linkedin: !!action.post.messages && !!action.post.messages.linkedin ? action.post.messages.linkedin : orgInitialState.messages.linkedin
        },
        postType: action.post.postType ? action.post.postType : orgInitialState.postType,
        publishAt: publishAt,
        selectedAccounts: action.post.selectedAccounts ? action.post.selectedAccounts : {},
        videoUrl: action.post.videoUrl ? action.post.videoUrl : orgInitialState.videoUrl
      });

    case LOGOUT_SUCCESS:
      return {};

    case NULLIFY_PUBLISH_AT:
      return _objectSpread(_objectSpread({}, state), {}, {
        publishAt: null
      });

    case RESET_TO_INITIAL_STATE:
      return {};

    case RESET_COMPOSEBOX:
      return _.cloneDeep(orgInitialState);

    case REMOVE_IMAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        images: [].concat(_toConsumableArray(state.images.slice(0, action.index)), _toConsumableArray(state.images.slice(action.index + 1)))
      });

    case REMOVE_ALL_IMAGES:
      return _objectSpread(_objectSpread({}, state), {}, {
        images: []
      });

    case REMOVE_VIDEO:
      return _objectSpread(_objectSpread({}, state), {}, {
        videoUrl: null
      });

    case UPDATE_LINK_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        linkData: _objectSpread(_objectSpread({}, state.linkData), {}, _defineProperty({}, action.field, action.value))
      });

    case UPDATE_MESSAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        messages: _objectSpread(_objectSpread({}, state.messages ? state.messages : _objectSpread({}, orgInitialState.messages)), {}, _defineProperty({}, action.messageType, action.value))
      });

    case UPDATE_PLATFORM_CHECKBOX:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedAccounts: _objectSpread(_objectSpread({}, state.selectedAccounts), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state.selectedAccounts[action.orgId]), {}, _defineProperty({}, action.platformName, action.checked)))),
        allSelected: !action.checked ? false : state.allSelected
      });

    case SELECT_ALL:
      var selectedAccounts = {};
      action.clientsWithSocialAccounts.forEach(function (client) {
        selectedAccounts[client.id] = {};
        client.SocialAccounts.forEach(function (sa) {
          selectedAccounts[client.id][_.camelCase(sa.platform)] = action.select;
        });
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedAccounts: selectedAccounts,
        allSelected: action.select
      });

    case UPDATE_POST_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        postType: action.value
      });

    case UPDATE_PUBLISH_AT:
      return _objectSpread(_objectSpread({}, state), {}, {
        publishAt: action.publishAt
      });

    case WIPE_LINK_DATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        linkData: {
          link: null,
          caption: null,
          description: null,
          image: null,
          title: null
        }
      });

    case LOAD_LINK_PARSE:
      return _objectSpread(_objectSpread({}, state), {}, {
        parsingLink: true
      });

    case LOAD_LINK_PARSE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        parsingLink: false,
        linkData: {
          link: action.result.data.url,
          caption: action.result.data.siteName,
          description: action.result.data.description,
          image: action.result.data.imageUrl,
          title: action.result.data.title
        }
      });

    case LOAD_LINK_PARSE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        parsingLink: false
      });

    case SUBMIT_POST:
      return _objectSpread(_objectSpread({}, state), {}, {
        submittingPost: true
      });

    case SUBMIT_POST_SUCCESS:
      return _.cloneDeep(orgInitialState);

    case SUBMIT_POST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        submittingPost: false
      });

    case SET_SELECTED_PREVIEW_TAB:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedPreviewTab: action.tab
      });

    case SET_SELECTED_LINKEDIN_PREVIEW_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedLinkedinPreviewType: action.liType
      });

    default:
      return state;
  }
}

function instanceMapReducerEnhancer(groupPostReducer) {
  var controlInstanceKeyResolver = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultKeyResolver;
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var action = arguments.length > 1 ? arguments[1] : undefined;
    var instanceKey = controlInstanceKeyResolver(action);

    if (typeof instanceKey === 'string') {
      var instanceState = reducer(state[instanceKey], action);
      var newState = Object.assign({}, state, _defineProperty({}, instanceKey, instanceState));
      return newState;
    }

    return state;
  };
}

export function setSelectedPreviewTab(tab, orgId) {
  return {
    type: SET_SELECTED_PREVIEW_TAB,
    tab: tab,
    orgId: orgId
  };
}
export function setSelectedLinkedinPreviewType(liType, orgId) {
  return {
    type: SET_SELECTED_LINKEDIN_PREVIEW_TYPE,
    liType: liType,
    orgId: orgId
  };
}
export function setBackUrl(url, orgId) {
  return {
    type: SET_BACK_URL,
    url: url,
    orgId: orgId
  };
}
export function getComposeboxFormValues(orgId) {
  return {
    type: GET_VALUES,
    meta: {
      controlInstanceKeyResolver: orgId
    }
  };
}
export function updateMessage(messageType, value, orgId) {
  return {
    type: UPDATE_MESSAGE,
    messageType: messageType,
    value: value,
    orgId: orgId
  };
}
export function updatePlatformCheckbox(platformName, checked, orgId) {
  return {
    type: UPDATE_PLATFORM_CHECKBOX,
    platformName: platformName,
    checked: checked,
    orgId: orgId
  };
}
export function selectAll(clientsWithSocialAccounts) {
  var select = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return {
    type: SELECT_ALL,
    clientsWithSocialAccounts: clientsWithSocialAccounts,
    select: select
  };
}
export function updatePostType(value, orgId) {
  return {
    type: UPDATE_POST_TYPE,
    value: value,
    orgId: orgId
  };
}
export function updatePublishAt(publishAt, orgId) {
  return {
    type: UPDATE_PUBLISH_AT,
    publishAt: publishAt,
    orgId: orgId
  };
}
export function populateGroupPostComposebox(post) {
  var extra = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    type: INIT,
    post: post,
    extra: extra
  };
}
export function resetComposebox() {
  return {
    type: RESET_COMPOSEBOX
  };
}
export function updateLinkData(field, value, orgId) {
  return {
    type: UPDATE_LINK_DATA,
    field: field,
    value: value,
    orgId: orgId
  };
}
export function wipeLinkData(orgId) {
  return {
    type: WIPE_LINK_DATA,
    orgId: orgId
  };
}
export function addImage(imageUrl, orgId) {
  return {
    type: ADD_IMAGE,
    imageUrl: imageUrl,
    orgId: orgId
  };
}
export function addVideo(videoUrl, orgId) {
  return {
    type: ADD_VIDEO,
    videoUrl: videoUrl,
    orgId: orgId
  };
}
export function removeAllImages(orgId) {
  return {
    type: REMOVE_ALL_IMAGES,
    orgId: orgId
  };
}
export function removeVideo(orgId) {
  return {
    type: REMOVE_VIDEO,
    orgId: orgId
  };
}
export function removeImage(index, orgId) {
  return {
    type: REMOVE_IMAGE,
    index: index,
    orgId: orgId
  };
}
export function nullifyPublishAt(orgId) {
  return {
    type: NULLIFY_PUBLISH_AT,
    orgId: orgId
  };
}
export function submitPost(payload, organizationId) {
  var post = payload;
  var wipedLink = {
    caption: null,
    description: null,
    image: null,
    link: null,
    title: null
  };

  if (post.postType === 'linkPost') {
    post.images = [];
    post.videoUrl = null;
  } else if (post.postType === 'imagePost') {
    post.videoUrl = null;
    post.linkData = wipedLink;
  } else if (post.postType === 'videoPost') {
    post.images = [];
    post.linkData = wipedLink;
  } else if (post.postType === 'textPost') {
    post.images = [];
    post.linkData = wipedLink;
    post.videoUrl = null;
  } // not actually pushing these changes into the redux state
  // about to get wiped anyway once we submit


  return {
    types: [SUBMIT_POST, SUBMIT_POST_SUCCESS, SUBMIT_POST_FAIL],
    post: post,
    promise: post.existingPostId ? function (client) {
      return client.put("/groupPost/".concat(post.existingPostId), {
        data: post
      });
    } : function (client) {
      return client.post('/post/createGroupPost', {
        data: post
      });
    },
    orgId: organizationId
  };
}
export function parseLink(link, orgId) {
  return {
    types: [LOAD_LINK_PARSE, LOAD_LINK_PARSE_SUCCESS, LOAD_LINK_PARSE_FAIL],
    orgId: orgId,
    promise: function promise(client) {
      return client.post('/parseLink', {
        data: {
          link: link
        }
      });
    }
  };
}