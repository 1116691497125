import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as _ from 'lodash';
export function generateFacebookTargetingSpec(formValues, facebookPageId) {
  var adName = formValues.adName,
      adStart = formValues.adStart,
      adEnd = formValues.adEnd,
      adBudget = formValues.adBudget,
      deviceSelectValue = formValues.deviceSelectValue,
      includedLocations = formValues.includedLocations,
      excludedLocations = formValues.excludedLocations,
      includedDemographics = formValues.includedDemographics,
      excludedDemographics = formValues.excludedDemographics,
      includedBehaviors = formValues.includedBehaviors,
      excludedBehaviors = formValues.excludedBehaviors,
      includedInterests = formValues.includedInterests,
      excludedInterests = formValues.excludedInterests,
      saveCheckbox = formValues.saveCheckbox,
      audienceName = formValues.audienceName,
      description = formValues.description,
      headline = formValues.headline,
      adFormatTabKey = formValues.adFormatTabKey,
      adType = formValues.adType,
      includedCustomAudiences = formValues.includedCustomAudiences,
      excludedCustomAudiences = formValues.excludedCustomAudiences,
      selectedGender = formValues.selectedGender,
      selectedStartAge = formValues.selectedStartAge,
      selectedEndAge = formValues.selectedEndAge,
      pixelId = formValues.pixelId;
  var genders = [];

  if (selectedGender === 'Men') {
    genders.push(1);
  } else if (selectedGender === 'Women') {
    genders.push(2);
  } else {
    genders.push(1);
    genders.push(2);
  }

  var includedEducationAndWork = {
    education_schools: [],
    education_majors: [],
    work_employers: [],
    work_positions: []
  };
  var includedFlexSpecDemos = {
    family_statuses: [],
    home_type: [],
    household_composition: [],
    income: [],
    industries: [],
    life_events: [],
    net_worth: [],
    politics: [],
    relationship_statuses: [],
    education_statuses: []
  };
  var excludedEducationAndWork = {
    education_schools: [],
    education_majors: [],
    work_employers: [],
    work_positions: []
  };
  var excludedFlexSpecDemos = {
    family_statuses: [],
    home_type: [],
    household_composition: [],
    income: [],
    industries: [],
    life_events: [],
    net_worth: [],
    politics: [],
    relationship_statuses: [],
    education_statuses: []
  };
  var excluded_user_device = [];
  var user_device = [];
  excludedDemographics.forEach(function (obj) {
    if (obj.category === 'user_device') {
      excluded_user_device.push(obj.name);
    }

    var arr = null;

    if (Object.keys(excludedEducationAndWork).indexOf(obj.category) !== -1) {
      arr = excludedEducationAndWork[obj.category];
    } else if (Object.keys(excludedFlexSpecDemos).indexOf(obj.category) !== -1) {
      arr = excludedFlexSpecDemos[obj.category];
    }

    if (!arr) {
      return;
    }

    if (obj.category === 'relationship_statuses' || obj.category === 'education_statuses') {
      arr.push(parseInt(obj.id));
    } else {
      arr.push({
        id: obj.id
      });
    }
  });
  includedDemographics.forEach(function (obj) {
    if (obj.category === 'user_device') {
      user_device.push(obj.name);
    }

    var arr = null;

    if (Object.keys(includedEducationAndWork).indexOf(obj.category) !== -1) {
      arr = includedEducationAndWork[obj.category];
    } else if (Object.keys(includedFlexSpecDemos).indexOf(obj.category) !== -1) {
      arr = includedFlexSpecDemos[obj.category];
    }

    if (!arr) {
      return;
    }

    if (obj.category === 'relationship_statuses' || obj.category === 'education_statuses') {
      arr.push(parseInt(obj.id));
    } else {
      arr.push({
        id: obj.id
      });
    }
  }); //
  // get placement options
  //

  var positions = Object.keys(formValues).filter(function (k) {
    return k.indexOf('placement-option-') === 0;
  });
  var facebook_positions = positions.map(function (key) {
    if (formValues[key] === false) {
      return null;
    }

    var type = key.replace('placement-option-', '');

    switch (type) {
      case 'facebook-feed':
        return 'feed';

      case 'facebook-right-bar':
        return 'right_hand_column';

      case 'facebook-instant-articles':
        return 'instant_article';

      case 'facebook-messenger-home':
        return null;

      default:
        return null;
    }
  }).filter(function (k) {
    return k;
  });
  var instagram_positions = positions.map(function (key) {
    if (formValues[key] === false) {
      return null;
    }

    var type = key.replace('placement-option-', '');

    switch (type) {
      case 'instagram-stream':
        return 'stream';

      case 'instagram-stories':
        return 'story';

      default:
        return null;
    }
  }).filter(function (k) {
    return k;
  });
  var audience_network_positions = positions.map(function (key) {
    if (formValues[key] === false) {
      return null;
    }

    var type = key.replace('placement-option-', '');

    switch (type) {
      case 'audience-network-classic':
        return 'classic';

      case 'audience-network-rewarded-video':
        return 'rewarded_video';

      default:
        return null;
    }
  }).filter(function (k) {
    return k;
  });
  var messenger_positions = positions.map(function (key) {
    if (formValues[key] === false) {
      return null;
    }

    var type = key.replace('placement-option-', '');

    switch (type) {
      case 'facebook-messenger-home':
        return 'messenger_home';

      default:
        return null;
    }
  }).filter(function (k) {
    return k;
  }); //
  // END get placement options
  //

  var publisher_platforms_raw = Object.keys(formValues).filter(function (k) {
    return k.indexOf('placement-option-') === 0;
  }).map(function (key) {
    if (formValues[key] === false) {
      return null;
    }

    var type = key.replace('placement-option-', '');

    if (type.includes('audience-network')) {
      return 'audience_network';
    } else if (type.includes('instagram')) {
      return 'instagram';
    } else if (type.includes('messenger')) {
      return 'messenger';
    }

    return 'facebook';
  }).filter(function (k) {
    return k;
  });

  var publisher_platforms = _.uniq(publisher_platforms_raw);

  var geo_locations = {
    cities: _toConsumableArray(includedLocations ? includedLocations.filter(function (l) {
      return l.type === 'city';
    }).map(function (l) {
      return {
        country: l.country_code,
        key: l.key,
        radius: l.radius ? parseInt(l.radius.replace(' mi', '')) : 10,
        distance_unit: 'mile',
        name: l.name
      };
    }) : []),
    countries: _toConsumableArray(includedLocations ? includedLocations.filter(function (l) {
      return l.type === 'country';
    }).map(function (l) {
      return l.country_code;
    }) : []),
    regions: _toConsumableArray(includedLocations ? includedLocations.filter(function (l) {
      return l.type === 'region';
    }).map(function (l) {
      return {
        country: l.country_code,
        key: l.key,
        name: l.name
      };
    }) : []),
    zips: _toConsumableArray(includedLocations ? includedLocations.filter(function (l) {
      return l.type === 'zip';
    }).map(function (l) {
      return {
        country: l.country_code,
        key: l.key,
        name: l.name
      };
    }) : [])
  };
  var excluded_geo_locations = {
    cities: _toConsumableArray(excludedLocations ? excludedLocations.filter(function (l) {
      return l.type === 'city';
    }).map(function (l) {
      return {
        country: l.country_code,
        key: l.key,
        radius: l.radius ? parseInt(l.radius.replace(' mi', '')) : 10,
        distance_unit: 'mile',
        name: l.name
      };
    }) : []),
    countries: _toConsumableArray(excludedLocations ? excludedLocations.filter(function (l) {
      return l.type === 'country';
    }).map(function (l) {
      return l.country_code;
    }) : []),
    regions: _toConsumableArray(excludedLocations ? excludedLocations.filter(function (l) {
      return l.type === 'region';
    }).map(function (l) {
      return {
        country: l.country_code,
        key: l.key,
        name: l.name
      };
    }) : []),
    zips: _toConsumableArray(excludedLocations ? excludedLocations.filter(function (l) {
      return l.type === 'zip';
    }).map(function (l) {
      return {
        country: l.country_code,
        key: l.key,
        name: l.name
      };
    }) : [])
  };
  var age_min = selectedStartAge ? parseInt(selectedStartAge) : 21;
  var age_max = selectedEndAge ? parseInt(selectedEndAge) : 65;
  var flexible_spec = [{
    interests: _toConsumableArray(includedInterests.map(function (i) {
      return {
        id: i.id,
        name: i.name
      };
    }))
  }, {
    behaviors: _toConsumableArray(includedBehaviors.map(function (i) {
      return {
        id: i.id,
        name: i.name
      };
    }))
  }, {
    connections: formValues['page-connection'] === 'fans' ? [{
      id: facebookPageId
    }] : []
  }, {
    friends_of_connections: formValues['page-connection'] === 'friends+fans' ? [{
      id: facebookPageId
    }] : []
  }].concat(_toConsumableArray(Object.keys(includedFlexSpecDemos).map(function (key) {
    return _defineProperty({}, key, _toConsumableArray(includedFlexSpecDemos[key]));
  })));

  var exclusions = _objectSpread(_objectSpread({
    interests: _toConsumableArray(excludedInterests.map(function (i) {
      return {
        id: i.id,
        name: i.name
      };
    })),
    behaviors: _toConsumableArray(excludedBehaviors.map(function (i) {
      return {
        id: i.id,
        name: i.name
      };
    }))
  }, excludedEducationAndWork), excludedFlexSpecDemos);

  var excluded_connections = adType === 'pageLikes' ? [{
    id: facebookPageId
  }] : undefined;
  var custom_audiences = includedCustomAudiences && includedCustomAudiences.length ? includedCustomAudiences.map(function (a) {
    return a.facebookId;
  }) : undefined;
  var excluded_custom_audiences = excludedCustomAudiences && excludedCustomAudiences.length ? excludedCustomAudiences.map(function (a) {
    return a.facebookId;
  }) : undefined;

  var targetingObject = _objectSpread({
    user_device: user_device,
    excluded_user_device: excluded_user_device,
    genders: genders,
    facebook_positions: facebook_positions,
    instagram_positions: instagram_positions,
    audience_network_positions: audience_network_positions,
    messenger_positions: messenger_positions,
    publisher_platforms: publisher_platforms,
    geo_locations: geo_locations,
    excluded_geo_locations: excluded_geo_locations,
    age_min: age_min,
    age_max: age_max,
    flexible_spec: flexible_spec,
    exclusions: exclusions,
    excluded_connections: excluded_connections,
    custom_audiences: custom_audiences,
    excluded_custom_audiences: excluded_custom_audiences
  }, includedEducationAndWork); // only add device_platforms if not all


  if (deviceSelectValue !== 'all') {
    targetingObject.device_platforms = [deviceSelectValue];
  }

  return targetingObject;
}