import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import populateNewAffiliateDialog from '../../helpers/populateEditNewAffiliate';
var ns = 'likeablelocal/newAffiliate';
var EDIT_DIALOG = "".concat(ns, "/EDIT_DIALOG");
var SET_FORM_VALUE = "".concat(ns, "/SET_FORM_VALUE");
var VALIDATE = "".concat(ns, "/VALIDATE");
var CLEAR = "".concat(ns, "/CLEAR");
var EDIT_PRODUCTID = "".concat(ns, "/EDIT_PRODUCTID");
var EDIT_PRODUCTID_SUCCESS = "".concat(ns, "/EDIT_PRODUCTID_SUCCESS");
var EDIT_PRODUCTID_FAIL = "".concat(ns, "/EDIT_PRODUCTID_FAIL");
var SEND_AGREEMENT = "".concat(ns, "/SEND_AGREEMENT");
var SEND_AGREEMENT_SUCCESS = "".concat(ns, "/SEND_AGREEMENT_SUCCESS");
var SEND_AGREEMENT_FAIL = "".concat(ns, "/SEND_AGREEMENT_FAIL");
var EDIT_AFFILIATE_CLIENT = "".concat(ns, "/EDIT_AFFILIATE_CLIENT");
export var EDIT_AFFILIATE_CLIENT_SUCCESS = "".concat(ns, "/EDIT_AFFILIATE_CLIENT_SUCCESS");
var EDIT_AFFILIATE_CLIENT_FAIL = "".concat(ns, "/EDIT_AFFILIATE_CLIENT_FAIL");
var CREATE_NEW_AFFILIATE_CLIENT = "".concat(ns, "/CREATE_NEW_AFFILIATE_CLIENT");
export var CREATE_NEW_AFFILIATE_CLIENT_SUCCESS = "".concat(ns, "/CREATE_NEW_AFFILIATE_CLIENT_SUCCESS");
var CREATE_NEW_AFFILIATE_CLIENT_FAIL = "".concat(ns, "/CREATE_NEW_AFFILIATE_CLIENT_FAIL");
var initialState = {
  orgConfig: {
    billingMethod: 'affiliate',
    contractMonths: 6
  },
  orgDetails: {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    phone: '',
    email: '',
    businessName: '',
    paymentDetailEntryType: 2
  },
  validation: {
    firstPass: true,
    valid: false
  },
  validationFields: ['businessName', 'email', 'product'],
  invalidFields: [],
  affiliateConfig: {
    affiliateProductId: null
  },
  sendingAgreement: false,
  agreementSent: false,
  editingProductId: false
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case CLEAR:
      {
        return initialState;
      }

    case EDIT_DIALOG:
      {
        return action.data;
      }

    case VALIDATE:
      {
        var stateBeforeValidation = Object.assign({}, state); // validate all fields

        var stateAfterValidation = validateFields(stateBeforeValidation);
        return stateAfterValidation;
      }

    case EDIT_PRODUCTID:
      return _objectSpread(_objectSpread({}, state), {}, {
        editingProductId: true
      });

    case EDIT_PRODUCTID_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        editingProductId: false
      });

    case EDIT_PRODUCTID_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        editingProductId: false
      });

    case SEND_AGREEMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendingAgreement: true
      });

    case SEND_AGREEMENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        agreementSent: true,
        sendingAgreement: false
      });

    case SEND_AGREEMENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendingAgreement: false
      });

    case CREATE_NEW_AFFILIATE_CLIENT:
      return _objectSpread({}, state);

    case CREATE_NEW_AFFILIATE_CLIENT_SUCCESS:
      {
        return _objectSpread({}, state);
      }

    case CREATE_NEW_AFFILIATE_CLIENT_FAIL:
      return _objectSpread({}, state);

    case SET_FORM_VALUE:
      {
        var _stateAfterValidation;

        var validationKey = action.key === 'affiliateProductId' ? 'product' : action.key;

        var _stateBeforeValidation = Object.assign({}, _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.sectionKey, _objectSpread(_objectSpread({}, state[action.sectionKey]), {}, _defineProperty({}, action.key, action.value)))));

        var needToValidate = !state.firstPass && state.validationFields.includes(validationKey);

        if (needToValidate) {
          // validate all fields
          _stateAfterValidation = validateFields(_stateBeforeValidation);
        }

        return needToValidate ? _stateAfterValidation : _stateBeforeValidation;
      }

    default:
      return state;
  }
}
export function setFormValue(key, sectionKey, value) {
  return {
    type: SET_FORM_VALUE,
    key: key,
    sectionKey: sectionKey,
    value: value
  };
}
export function changeCCValidation() {
  var includeCC = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    type: CHANGE_CC_VALIDATION,
    includeCC: includeCC
  };
}
export function wipeNewClientState() {
  return {
    type: CLEAR
  };
}
export function validateForm() {
  return {
    type: VALIDATE
  };
}
export function editDialog(contractInfo) {
  var data = populateNewAffiliateDialog(contractInfo);
  return {
    type: EDIT_DIALOG,
    data: data
  };
}
export function createNewPartnerAffiliate(orgId, orgDetails, affiliateProductId, orgConfig) {
  return {
    types: [CREATE_NEW_AFFILIATE_CLIENT, CREATE_NEW_AFFILIATE_CLIENT_SUCCESS, CREATE_NEW_AFFILIATE_CLIENT_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/new/partnerClient"), {
        data: {
          orgConfig: {
            billingMethod: 'affiliate',
            contractMonths: orgConfig.contractMonths
          },
          orgDetails: orgDetails,
          affiliateProductId: affiliateProductId
        }
      });
    }
  };
}
export function editPartnerAffiliate(orgId, orgDetails, affiliateProductId, orgConfig) {
  return {
    types: [EDIT_AFFILIATE_CLIENT, EDIT_AFFILIATE_CLIENT_SUCCESS, EDIT_AFFILIATE_CLIENT_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/affiliate/contract"), {
        data: _objectSpread(_objectSpread({}, orgDetails), {}, {
          contractMonths: orgConfig.contractMonths,
          productId: affiliateProductId
        })
      });
    },
    orgId: orgId
  };
}
export function sendAffiliateClientAgreement(uuid) {
  return {
    types: [SEND_AGREEMENT, SEND_AGREEMENT_SUCCESS, SEND_AGREEMENT_FAIL],
    promise: function promise(client) {
      return client.post('sendAgreementEmail', {
        data: {
          uuid: uuid
        }
      });
    }
  };
}
export function editProductId(orgId, productId) {
  return {
    types: [EDIT_PRODUCTID, EDIT_PRODUCTID_SUCCESS, EDIT_PRODUCTID_FAIL],
    promise: function promise(client) {
      return client.put("/billing/org/".concat(orgId, "/affiliate/product"), {
        data: {
          productId: productId
        }
      });
    }
  };
}

function validateFields(stateCopy) {
  stateCopy.invalidFields = [];
  stateCopy.validationFields.forEach(function (field) {
    if (field === 'product' && !stateCopy.affiliateConfig.affiliateProductId) {
      stateCopy.invalidFields.push(field);
    } else if (stateCopy.orgDetails[field] === '') {
      stateCopy.invalidFields.push(field);
    }
  }); // we have errors

  if (stateCopy.invalidFields.length) {
    stateCopy.validation.valid = false;
  } else {
    stateCopy.validation.valid = true;
  }

  return stateCopy;
}