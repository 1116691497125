import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
var ns = 'likeablelocal/app';
var START_PAGE_LOAD = "".concat(ns, "/START_PAGE_LOAD");
var END_PAGE_LOAD = "".concat(ns, "/END_PAGE_LOAD"); // since React Router 2.x has no good way of getting this in onEnter handlers

var SET_PREVIOUS_PATH = "".concat(ns, "/SET_PREVIOUS_PATH");
var initialState = {
  loading: true
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case START_PAGE_LOAD:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });

    case END_PAGE_LOAD:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false
      });

    case SET_PREVIOUS_PATH:
      return _objectSpread(_objectSpread({}, state), {}, {
        previousPath: action.path
      });

    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
export function startLoad() {
  return {
    type: START_PAGE_LOAD
  };
}
export function endLoad() {
  return {
    type: END_PAGE_LOAD
  };
}
export function setPreviousPath(path) {
  return {
    type: SET_PREVIOUS_PATH,
    path: path
  };
}