import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
var LOAD_CONTENT = 'likeablelocal/contentLibraryQueues/LOAD_CONTENT';
var LOAD_CONTENT_SUCCESS = 'likeablelocal/contentLibraryQueues/LOAD_CONTENT_SUCCESS';
var LOAD_CONTENT_FAIL = 'likeablelocal/contentLibraryQueues/LOAD_CONTENT_FAIL';
var DELETE_ACCOUNT_LINK = 'likeablelocal/contentLibraryQueues/DELETE_ACCOUNT_LINK';
export var DELETE_ACCOUNT_LINK_SUCCESS = 'likeablelocal/contentLibraryQueues/DELETE_ACCOUNT_LINK_SUCCESS';
var DELETE_ACCOUNT_LINK_FAIL = 'likeablelocal/contentLibraryQueues/DELETE_ACCOUNT_LINK_FAIL';
var CREATE_POST_TIME = 'likeablelocal/contentLibraryQueues/CREATE_POST_TIME';
export var CREATE_POST_TIME_SUCCESS = 'likeablelocal/contentLibraryQueues/CREATE_POST_TIME_SUCCESS';
var CREATE_POST_TIME_FAIL = 'likeablelocal/contentLibraryQueues/CREATE_POST_TIME_FAIL';
var DELETE_POST_TIME = 'likeablelocal/contentLibraryQueues/DELETE_POST_TIME';
export var DELETE_POST_TIME_SUCCESS = 'likeablelocal/contentLibraryQueues/DELETE_POST_TIME_SUCCESS';
var DELETE_POST_TIME_FAIL = 'likeablelocal/contentLibraryQueues/DELETE_POST_TIME_FAIL';
var CREATE_NEEDED_QUEUES = 'likeablelocal/contentLibraryQueues/CREATE_NEEDED_QUEUES';
export var CREATE_NEEDED_QUEUES_SUCCESS = 'likeablelocal/contentLibraryQueues/CREATE_NEEDED_QUEUES_SUCCESS';
var CREATE_NEEDED_QUEUES_FAIL = 'likeablelocal/contentLibraryQueues/CREATE_NEEDED_QUEUES_FAIL';
var initialState = {
  addingSAInProgress: false
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case DELETE_ACCOUNT_LINK:
      return _objectSpread(_objectSpread({}, state), {}, {
        unfavoriting: true
      });

    case DELETE_ACCOUNT_LINK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        unfavoriting: false
      });

    case DELETE_ACCOUNT_LINK_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        unfavoriting: false
      });

    default:
      return state;
  }
}
export function deleteAccountFromTag(orgId, socialAccountIds, tagId) {
  return {
    types: [DELETE_ACCOUNT_LINK, DELETE_ACCOUNT_LINK_SUCCESS, DELETE_ACCOUNT_LINK_FAIL],
    promise: function promise(client) {
      return client.del("/org/".concat(orgId, "/channel/").concat(tagId), {
        data: {
          socialAccountIds: socialAccountIds
        }
      });
    },
    orgId: orgId
  };
}
export function addQueuePostTime(orgId, queueId, weekday, timeOfDay) {
  return {
    types: [CREATE_POST_TIME, CREATE_POST_TIME_SUCCESS, CREATE_POST_TIME_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/queue/").concat(queueId, "/postTime"), {
        data: {
          weekday: weekday,
          timeOfDay: timeOfDay
        }
      });
    },
    orgId: orgId
  };
}
export function removeQueuePostTime(qptId, orgId) {
  return {
    types: [DELETE_POST_TIME, DELETE_POST_TIME_SUCCESS, DELETE_POST_TIME_FAIL],
    promise: function promise(client) {
      return client.del("/postTime/".concat(qptId));
    },
    orgId: orgId
  };
}
export function createQueuesForOrganizationSocialAccounts(orgId) {
  return {
    types: [CREATE_NEEDED_QUEUES, CREATE_NEEDED_QUEUES_SUCCESS, CREATE_NEEDED_QUEUES_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/createNeededQueues"));
    },
    orgId: orgId
  };
}