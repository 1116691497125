import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as _ from 'lodash';
import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
var ns = 'likeablelocal/messageCenter';
var CONVERSATION_THREAD = "".concat(ns, "/CONVERSATION_THREAD");
var CONVERSATION_THREAD_SUCCESS = "".concat(ns, "/CONVERSATION_THREAD_SUCCESS");
var CONVERSATION_THREAD_FAIL = "".concat(ns, "/CONVERSATION_THREAD_FAIL");
var ENQUEUE_MESSAGE_UPDATE = "".concat(ns, "/ENQUEUE_MESSAGE_UPDATE");
var ENQUEUE_MESSAGE_UPDATE_SUCCESS = "".concat(ns, "/ENQUEUE_MESSAGE_UPDATE_SUCCESS");
var ENQUEUE_MESSAGE_UPDATE_FAIL = "".concat(ns, "/ENQUEUE_MESSAGE_UPDATE_FAIL");
var ARCHIVE_CONVERSATION = "".concat(ns, "/ARCHIVE_CONVERSATION");
var ARCHIVE_CONVERSATION_SUCCESS = "".concat(ns, "/ARCHIVE_CONVERSATION_SUCCESS");
var ARCHIVE_CONVERSATION_FAIL = "".concat(ns, "/ARCHIVE_CONVERSATION_FAIL");
var ARCHIVE_CONVERSATIONS = "".concat(ns, "/ARCHIVE_CONVERSATIONS");
var ARCHIVE_CONVERSATIONS_SUCCESS = "".concat(ns, "/ARCHIVE_CONVERSATIONS_SUCCESS");
var ARCHIVE_CONVERSATIONS_FAIL = "".concat(ns, "/ARCHIVE_CONVERSATIONS_FAIL");
var UNARCHIVE_CONVERSATIONS = "".concat(ns, "/ARCHIVE_CONVERSATIONS");
var UNARCHIVE_CONVERSATIONS_SUCCESS = "".concat(ns, "/UNARCHIVE_CONVERSATIONS_SUCCESS");
var UNARCHIVE_CONVERSATIONS_FAIL = "".concat(ns, "/UNARCHIVE_CONVERSATIONS_FAIL");
var MARK_MESSAGES_READ = "".concat(ns, "/MARK_MESSAGES_READ");
var MARK_MESSAGES_READ_SUCCESS = "".concat(ns, "/MARK_MESSAGES_READ_SUCCESS");
var MARK_MESSAGES_READ_FAIL = "".concat(ns, "/MARK_MESSAGES_READ_FAIL");
var DELETE_MESSAGE = "".concat(ns, "/DELETE_MESSAGE");
var DELETE_MESSAGE_SUCCESS = "".concat(ns, "/DELETE_MESSAGE_SUCCESS");
var DELETE_MESSAGE_FAIL = "".concat(ns, "/DELETE_MESSAGE_FAIL");
var SELECT_ITEM = "".concat(ns, "/SELECT_ITEM");
var DESELECT_ITEM = "".concat(ns, "/DESELECT_ITEM");
var TOGGLE_ALL_SELECTED = "".concat(ns, "/TOGGLE_ALL_SELECTED");
var LOAD_CONVERSATIONS = "".concat(ns, "/LOAD_CONVERSATIONS");
var LOAD_CONVERSATIONS_SUCCESS = "".concat(ns, "/LOAD_CONVERSATIONS_SUCCESS");
var LOAD_CONVERSATIONS_FAIL = "".concat(ns, "/LOAD_CONVERSATIONS_FAIL");
var SET_FILTERS = "".concat(ns, "/SET_MESSAGECENTER_FILTERS");
var CONVERSATION_REPLY = "".concat(ns, "/CONVERSATION_REPLY");
var CONVERSATION_REPLY_SUCCESS = "".concat(ns, "/CONVERSATION_REPLY_SUCCESS");
var CONVERSATION_REPLY_FAIL = "".concat(ns, "/CONVERSATION_REPLY_FAIL");
var CONVERSATION_MSG_REPLY = "".concat(ns, "/CONVERSATION_MSG_REPLY");
var CONVERSATION_MSG_REPLY_SUCCESS = "".concat(ns, "/CONVERSATION_MSG_REPLY_SUCCESS");
var CONVERSATION_MSG_REPLY_FAIL = "".concat(ns, "/CONVERSATION_MSG_REPLY_FAIL");
var DM = "".concat(ns, "/DM_REPLY");
var DM_SUCCESS = "".concat(ns, "/DM_SUCCESS");
var DM_FAIL = "".concat(ns, "/DM_FAIL");
var LIKE_POST = "".concat(ns, "/LIKE_POST");
var LIKE_POST_SUCCESS = "".concat(ns, "/LIKE_POST_SUCCESS");
var LIKE_POST_FAIL = "".concat(ns, "/LIKE_POST_FAIL");
var UNLIKE_POST = "".concat(ns, "/UNLIKE_POST");
var UNLIKE_POST_SUCCESS = "".concat(ns, "/UNLIKE_POST_SUCCESS");
var UNLIKE_POST_FAIL = "".concat(ns, "/UNLIKE_POST_FAIL");
var CHANGE_SELECTED_INDEX = "".concat(ns, "/CHANGE_SELECTED_INDEX");
var CHANGE_VIEW_TYPE = "".concat(ns, "/CHANGE_VIEW_TYPE");
var DESELECT_ALL = "".concat(ns, "/DESELECT_ALL");
var LOAD_ARCHIVED_CONVERSATIONS = "".concat(ns, "/LOAD_ARCHIVED_CONVERSATIONS");
var LOAD_ARCHIVED_CONVERSATIONS_SUCCESS = "".concat(ns, "/LOAD_ARCHIVED_CONVERSATIONS_SUCCESS");
var LOAD_ARCHIVED_CONVERSATIONS_FAIL = "".concat(ns, "/LOAD_ARCHIVED_CONVERSATIONS_FAIL");
var UPLOAD_MEDIA = "".concat(ns, "/UPLOAD_MEDIA");
var UPLOAD_MEDIA_SUCCESS = "".concat(ns, "/UPLOAD_MEDIA_SUCCESS");
var UPLOAD_MEDIA_FAIL = "".concat(ns, "/UPLOAD_MEDIA_FAIL");
var REMOVE_MEDIA = "".concat(ns, "/REMOVE_MEDIA");
var CHANGE_REPLY_TEXT = "".concat(ns, "/CHANGE_REPLY_TEXT");
var RETWEET_TWEET = "".concat(ns, "/RETWEET_TWEET");
var RETWEET_TWEET_SUCCESS = "".concat(ns, "/RETWEET_TWEET_SUCCESS");
var RETWEET_TWEET_FAIL = "".concat(ns, "/RETWEET_TWEET_FAIL");
var QUOTE_RETWEET_TWEET = "".concat(ns, "/QUOTE_RETWEET_TWEET");
var QUOTE_RETWEET_TWEET_SUCCESS = "".concat(ns, "/QUOTE_RETWEET_TWEET_SUCCESS");
var QUOTE_RETWEET_TWEET_FAIL = "".concat(ns, "/QUOTE_RETWEET_TWEET_FAIL");
var initialState = {
  threadsById: {},
  selectedConversations: [],
  isGettingRefreshedData: false,
  allSelected: false,
  filters: [],
  selectedConversationIndex: 0,
  selectedViewType: 0
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case CONVERSATION_THREAD_SUCCESS:
      var currentData = action.result.data && action.result.data.id && state.threadsById ? state.threadsById[action.result.data.id.toString()] : {
        Messages: []
      };

      var threadsById = _objectSpread({}, state.threadsById);

      threadsById[action.result.data.id.toString()] = _objectSpread(_objectSpread(_objectSpread({}, currentData), action.result.data), {}, {
        Messages: action.result.data.Messages.map(function (m) {
          var currentMessage = currentData && currentData.Messages ? currentData.Messages.find(function (cm) {
            return cm.id === m.id;
          }) : null;
          var replyText = currentMessage ? currentMessage.replyText : '';
          return _objectSpread(_objectSpread({}, m), {}, {
            replyText: replyText
          });
        }),
        parentEntity: _objectSpread(_objectSpread({}, currentData && currentData.parentEntity ? _objectSpread({}, currentData.parentEntity) : {}), action.result.data.parentEntity)
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        threadsById: threadsById,
        isGettingRefreshedData: false
      });

    case CONVERSATION_THREAD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isGettingRefreshedData: false
      });

    case CHANGE_SELECTED_INDEX:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedConversationIndex: action.index
      });

    case CHANGE_VIEW_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedViewType: action.viewType
      });

    case SELECT_ITEM:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedConversations: [].concat(_toConsumableArray(state.selectedConversations), [action.id])
      });

    case DESELECT_ITEM:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedConversations: state.selectedConversations.filter(function (c) {
          return c !== action.id;
        })
      });

    case DELETE_MESSAGE:
      return _objectSpread({}, state);

    case DELETE_MESSAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        conversations: state.conversations.filter(function (c) {
          return c.id !== action.id;
        })
      });

    case DELETE_MESSAGE_FAIL:
      return _objectSpread({}, state);

    case TOGGLE_ALL_SELECTED:
      var allSelected = !state.allSelected;
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedConversations: allSelected ? action.conversationIds : [],
        allSelected: allSelected
      });

    case DESELECT_ALL:
      return _objectSpread(_objectSpread({}, state), {}, {
        allSelected: false,
        selectedConversations: []
      });

    case LOAD_CONVERSATIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        conversations: action.result.data
      });

    case LOAD_ARCHIVED_CONVERSATIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        archivedConversations: action.result.data.conversations,
        archivedPagination: action.result.data.pagination,
        selectedConversationIndex: 0
      });

    case SET_FILTERS:
      return _objectSpread(_objectSpread({}, state), {}, {
        filters: action.filters.map(function (filter, index) {
          return _objectSpread(_objectSpread({}, filter), {}, {
            id: index
          });
        })
      });

    case ARCHIVE_CONVERSATION:
      return _objectSpread({}, state);

    case ARCHIVE_CONVERSATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        conversations: state.conversations.filter(function (c) {
          return c.id !== parseInt(action.result.data.archivedId, 10);
        })
      });

    case ARCHIVE_CONVERSATION_FAIL:
      return _objectSpread({}, state);

    case ARCHIVE_CONVERSATIONS:
      return _objectSpread({}, state);

    case ARCHIVE_CONVERSATIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        conversations: state.conversations.filter(function (c) {
          return !action.result.data.archivedIds.find(function (id) {
            return id === c.id;
          });
        }),
        selectedConversations: []
      });

    case ARCHIVE_CONVERSATIONS_FAIL:
      return _objectSpread({}, state);

    case UNARCHIVE_CONVERSATIONS_SUCCESS:
      var unarchived = [];
      var archived = [];
      state.archivedConversations.forEach(function (c) {
        var notFound = true;

        for (var i = 0; i < action.result.data.unarchivedIds.length; i++) {
          if (c.id === action.result.data.unarchivedIds[i]) {
            unarchived.push(c);
            notFound = false;
            console.log("found ".concat(c.id));
            break;
          }
        }

        if (notFound) {
          console.log("not found ".concat(c.id));
          archived.push(c);
        }
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        archivedConversations: archived,
        conversations: [].concat(_toConsumableArray(state.conversations), unarchived)
      });

    case CONVERSATION_REPLY:
      console.log('in CONVERSATION_REPLY');
      return _objectSpread(_objectSpread({}, state), {}, {
        isReplyingToConversation: true
      });

    case CONVERSATION_REPLY_SUCCESS:
      console.log('in CONVERSATION_REPLY_SUCCESS');
      console.log(action);
      var convId = action.result.data.ConversationId;
      return _objectSpread(_objectSpread({}, state), {}, {
        isReplyingToConversation: false,
        threadsById: _objectSpread(_objectSpread({}, state.threadsById), {}, _defineProperty({}, convId, _objectSpread(_objectSpread({}, state.threadsById[convId]), {}, {
          parentEntity: _objectSpread(_objectSpread({}, state.threadsById[convId].parentEntity), {}, {
            replyText: ''
          }),
          Messages: state.threadsById[convId].Messages.concat(action.result.data)
        })))
      });

    case CONVERSATION_REPLY_FAIL:
      console.log('in CONVERSATION_REPLY_FAIL');
      return _objectSpread(_objectSpread({}, state), {}, {
        isReplyingToConversation: false
      });

    case CONVERSATION_MSG_REPLY:
      console.log('in CONVERSATION_MSG_REPLY');
      return _objectSpread({}, state);

    case CONVERSATION_MSG_REPLY_SUCCESS:
      console.log('CONVERSATION_MSG_REPLY_SUCCESS');
      var cid = action.conversationId;
      var messagesClone = state.threadsById[cid].Messages;
      messagesClone.forEach(function (m) {
        if (m.id === action.messageId) {
          m.Messages.push(action.result.data); // not sure how to get the reply that was commented on, so wiping all replyText

          m.Messages.forEach(function (mM) {
            mM.replyText = '';
          });
          m.replyText = '';
        }
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        threadsById: _objectSpread(_objectSpread({}, state.threadsById), {}, _defineProperty({}, cid, _objectSpread(_objectSpread({}, state.threadsById[cid]), {}, {
          Messages: messagesClone
        })))
      });

    case CONVERSATION_MSG_REPLY_FAIL:
      console.log('CONVERSATION_MSG_REPLY_FAIL');
      return _objectSpread({}, state);

    case DM:
      var cId = action.conversationId;
      var self = action.organization.SocialAccounts.filter(function (a) {
        return a.platform === 'Facebook page';
      }); // initially set clone to first message

      var clonedMessage = _.cloneDeep(state.threadsById[cId].Messages[0]); // loop over Messages and find one to clone that is from self


      if (clonedMessage.authorPlatformId !== self.platformId) {
        state.threadsById[cId].Messages.forEach(function (m) {
          if (m.authorPlatformId === self.platformId) {
            clonedMessage = _.cloneDeep(m);
          }
        });
      } // if we still don't have a match, replace the relevant properties


      if (clonedMessage.authorPlatformId !== self.platformId) {
        clonedMessage.authorPlatformId = self.platformId;
        clonedMessage.platformId = self.platformId;
      } // for all clones, replace the message and ditch the id


      clonedMessage.id = null;
      clonedMessage.text = action.message;
      return _objectSpread(_objectSpread({}, state), {}, {
        threadsById: _objectSpread(_objectSpread({}, state.threadsById), {}, _defineProperty({}, cId, _objectSpread(_objectSpread({}, state.threadsById[cId]), {}, {
          parentEntity: _objectSpread(_objectSpread({}, state.threadsById[cId].parentEntity), {}, {
            replyText: ''
          }),
          Messages: state.threadsById[cId].Messages.concat(clonedMessage)
        })))
      });

    case DM_SUCCESS:
      return _objectSpread({}, state);

    case DM_FAIL:
      return _objectSpread({}, state);

    case ENQUEUE_MESSAGE_UPDATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isGettingRefreshedData: true
      });

    case ENQUEUE_MESSAGE_UPDATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isGettingRefreshedData: true
      });

    case ENQUEUE_MESSAGE_UPDATE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        isGettingRefreshedData: false
      });

    case LIKE_POST_SUCCESS:
      return function () {
        var thread = state.threadsById[action.result.data.conversationId];
        var messages = [];

        var searchThroughMessages = function searchThroughMessages(m) {
          if (m.id === action.result.data.messageId) {
            if (!m.details.likes) {
              m.details.likes = {
                data: [_objectSpread({}, action.result.data.facebookUser)]
              };
            } else {
              m.details.likes.data.push(_objectSpread({}, action.result.data.facebookUser));
            }
          }
        };

        thread.Messages.forEach(function (m) {
          searchThroughMessages(m);

          if (m.Messages) {
            m.Messages.forEach(function (m2) {
              searchThroughMessages(m2);
            });
          }

          messages.push(m);
        });

        var newThreads = _objectSpread({}, state.threadsById);

        newThreads[action.result.data.conversationId] = _objectSpread(_objectSpread({}, state.threadsById[action.result.data.conversationId]), {}, {
          Messages: messages
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          threadsById: newThreads
        });
      }();

    case UPLOAD_MEDIA:
      return function () {
        var updatedItem = getProperItem(state, action.conversationId, action.messageId);
        updatedItem.isUploadingMedia = true;
        var updatedConversation = updateConversation(state.threadsById[action.conversationId], updatedItem, action.conversationId, action.messageId);
        return _objectSpread(_objectSpread({}, state), {}, {
          threadsById: _objectSpread(_objectSpread({}, state.threadsById), {}, _defineProperty({}, action.conversationId, _objectSpread(_objectSpread({}, state.threadsById[action.conversationId]), updatedConversation)))
        });
      }();

    case UPLOAD_MEDIA_SUCCESS:
      return function () {
        var updatedItem = getProperItem(state, action.conversationId, action.messageId);
        updatedItem.isUploadingMedia = false;
        updatedItem.uploadedMediaUrl = action.result.data[0].fullyQualifiedUrl;
        var updatedConversation = updateConversation(state.threadsById[action.conversationId], updatedItem, action.conversationId, action.messageId);
        return _objectSpread(_objectSpread({}, state), {}, {
          threadsById: _objectSpread(_objectSpread({}, state.threadsById), {}, _defineProperty({}, action.conversationId, _objectSpread(_objectSpread({}, state.threadsById[action.conversationId]), updatedConversation)))
        });
      }();

    case UPLOAD_MEDIA_FAIL:
      return function () {
        var updatedItem = getProperItem(state, action.conversationId, action.messageId);
        updatedItem.isUploadingMedia = false;
        var updatedConversation = updateConversation(state.threadsById[action.conversationId], updatedItem, action.conversationId, action.messageId);
        return _objectSpread(_objectSpread({}, state), {}, {
          threadsById: _objectSpread(_objectSpread({}, state.threadsById), {}, _defineProperty({}, action.conversationId, _objectSpread(_objectSpread({}, state.threadsById[action.conversationId]), updatedConversation)))
        });
      }();

    case REMOVE_MEDIA:
      return function () {
        var updatedItem = getProperItem(state, action.conversationId, action.messageId);
        updatedItem.uploadedMediaUrl = null;
        var updatedConversation = updateConversation(state.threadsById[action.conversationId], updatedItem, action.conversationId, action.messageId);
        return _objectSpread(_objectSpread({}, state), {}, {
          threadsById: _objectSpread(_objectSpread({}, state.threadsById), {}, _defineProperty({}, action.conversationId, _objectSpread(_objectSpread({}, state.threadsById[action.conversationId]), updatedConversation)))
        });
      }();

    case UNLIKE_POST_SUCCESS:
      return function () {
        var thread = state.threadsById[action.result.data.conversationId];
        var messages = [];

        var searchThroughMessages = function searchThroughMessages(m) {
          if (m.id === action.result.data.messageId) {
            if (m.details.likes && m.details.likes.data) {
              for (var j = 0; j < m.details.likes.data.length; j++) {
                if (m.details.likes.data[j].id === action.result.data.facebookUser.id) {
                  m.details.likes.data.splice(j, 1);
                  break;
                }
              }
            }
          }
        };

        thread.Messages.forEach(function (m) {
          searchThroughMessages(m);

          if (m.Messages) {
            m.Messages.forEach(function (m2) {
              searchThroughMessages(m2);
            });
          }

          messages.push(m);
        });

        var newThreads = _objectSpread({}, state.threadsById);

        newThreads[action.result.data.conversationId] = _objectSpread(_objectSpread({}, state.threadsById[action.result.data.conversationId]), {}, {
          Messages: messages
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          threadsById: newThreads
        });
      };

    case MARK_MESSAGES_READ_SUCCESS:
      return function () {
        var newThreads = _objectSpread({}, state.threadsById);

        var messageIds = action.result && action.result.data ? action.result.data.messageIds : [];
        state.conversations.forEach(function (conversation) {
          var thread = newThreads[conversation.id];

          if (!thread) {
            return;
          }

          thread.Messages.forEach(function (message) {
            if (messageIds.indexOf(message.id) !== -1) {
              message.isNew = false;
            }

            if (message.Messages && message.Messages.length) {
              message.Messages.forEach(function (mmessage) {
                if (messageIds.indexOf(mmessage.id) !== -1) {
                  mmessage.isNew = false;
                }
              });
            }
          });
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          threadsById: _objectSpread({}, newThreads)
        });
      }();

    case CHANGE_REPLY_TEXT:
      return function () {
        var updatedItem = getProperItem(state, action.conversationId, action.messageId); // updatedItem will be a conversation, a message, or a child message

        updatedItem.replyText = action.replyText;
        var updatedConversation = updateConversation(state.threadsById[action.conversationId], updatedItem, action.conversationId, action.messageId);
        return _objectSpread(_objectSpread({}, state), {}, {
          threadsById: _objectSpread(_objectSpread({}, state.threadsById), {}, _defineProperty({}, action.conversationId, _objectSpread(_objectSpread({}, state.threadsById[action.conversationId]), updatedConversation)))
        });
      }();

    default:
      return state;
  }
}
export function getConversationThread(orgId, conversationId) {
  var includeArchive = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return {
    types: [CONVERSATION_THREAD, CONVERSATION_THREAD_SUCCESS, CONVERSATION_THREAD_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/conversation/").concat(conversationId, "?includeArchive=").concat(includeArchive.toString()));
    }
  };
}
export function enqueueMessageUpdate(orgId, socialAccountId) {
  return {
    types: [ENQUEUE_MESSAGE_UPDATE, ENQUEUE_MESSAGE_UPDATE_SUCCESS, ENQUEUE_MESSAGE_UPDATE_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/checkMessageUpdates/user"), {
        data: {
          socialAccountId: socialAccountId
        }
      });
    }
  };
}
export function deleteMessage(orgId, messageId) {
  return {
    types: [DELETE_MESSAGE, DELETE_MESSAGE_SUCCESS, DELETE_MESSAGE_FAIL],
    promise: function promise(client) {
      return client.del("/org/".concat(orgId, "/message/").concat(messageId));
    }
  };
}
export function archiveConversation(orgId, conversationId) {
  return {
    types: [ARCHIVE_CONVERSATION, ARCHIVE_CONVERSATION_SUCCESS, ARCHIVE_CONVERSATION_FAIL],
    promise: function promise(client) {
      return client.del("/org/".concat(orgId, "/conversation/").concat(conversationId));
    }
  };
}
export function archiveConversations(orgId, conversationIds) {
  return {
    types: [ARCHIVE_CONVERSATIONS, ARCHIVE_CONVERSATIONS_SUCCESS, ARCHIVE_CONVERSATIONS_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/archiveConversations"), {
        data: {
          conversationIds: conversationIds
        }
      });
    }
  };
}
export function unarchiveConversations(orgId, conversationIds) {
  return {
    types: [UNARCHIVE_CONVERSATIONS, UNARCHIVE_CONVERSATIONS_SUCCESS, UNARCHIVE_CONVERSATIONS_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/unarchiveConversations"), {
        data: {
          conversationIds: conversationIds
        }
      });
    }
  };
}
export function setSelectedConversationIndex(index) {
  return {
    type: CHANGE_SELECTED_INDEX,
    index: index
  };
}
export function deselectAllConversations() {
  return {
    type: DESELECT_ALL
  };
}
export function setSelectedViewType(viewType) {
  return {
    type: CHANGE_VIEW_TYPE,
    viewType: viewType
  };
}
export function selectConversation(conversationId) {
  return {
    type: SELECT_ITEM,
    id: conversationId
  };
}
export function deselectConversation(conversationId) {
  return {
    type: DESELECT_ITEM,
    id: conversationId
  };
}
export function toggleAllSelected(conversationIds) {
  return {
    type: TOGGLE_ALL_SELECTED,
    conversationIds: conversationIds
  };
}
export function loadConversations(orgId) {
  return {
    types: [LOAD_CONVERSATIONS, LOAD_CONVERSATIONS_SUCCESS, LOAD_CONVERSATIONS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/conversations"));
    }
  };
}
export function setMessageCenterFilters(filters) {
  return {
    type: SET_FILTERS,
    filters: filters
  };
}
export function replyToConversation(orgId, convoId, reply) {
  return {
    types: [CONVERSATION_REPLY, CONVERSATION_REPLY_SUCCESS, CONVERSATION_REPLY_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/conversation/").concat(convoId, "/reply"), {
        data: reply
      });
    }
  };
}
export function replyToConversationMessage(orgId, convoId, msgId, reply) {
  console.log(msgId);
  return {
    types: [CONVERSATION_MSG_REPLY, CONVERSATION_MSG_REPLY_SUCCESS, CONVERSATION_MSG_REPLY_FAIL],
    conversationId: convoId,
    messageId: msgId,
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/conversation/").concat(convoId, "/message/").concat(msgId, "/reply"), {
        data: reply
      });
    }
  };
}
export function replyToDM(orgId, accountId, conversationId, organization, reply) {
  return {
    types: [DM, DM_SUCCESS, DM_FAIL],
    orgId: orgId,
    accountId: accountId,
    conversationId: conversationId,
    organization: organization,
    message: reply.message,
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/socialAccount/").concat(accountId, "/sendDirectMessage"), {
        data: reply
      });
    }
  };
}
export function markConversationsAsRead(orgId, conversations) {
  return {
    types: [MARK_MESSAGES_READ, MARK_MESSAGES_READ_SUCCESS, MARK_MESSAGES_READ_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/conversations/markRead"), {
        data: {
          conversationIds: conversations.map(function (c) {
            return c.id;
          })
        }
      });
    }
  };
}
export function likePost(orgId, messageId, socialAccountId) {
  return {
    types: [LIKE_POST, LIKE_POST_SUCCESS, LIKE_POST_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/message/").concat(messageId, "/like"), {
        data: {
          socialAccountId: socialAccountId
        }
      });
    }
  };
}
export function unlikePost(orgId, messageId, socialAccountId) {
  return {
    types: [UNLIKE_POST, UNLIKE_POST_SUCCESS, UNLIKE_POST_FAIL],
    promise: function promise(client) {
      return client.del("/org/".concat(orgId, "/message/").concat(messageId, "/like"), {
        data: {
          socialAccountId: socialAccountId
        }
      });
    }
  };
}
export function loadArchivedConversations(orgId) {
  return {
    types: [LOAD_ARCHIVED_CONVERSATIONS, LOAD_ARCHIVED_CONVERSATIONS_SUCCESS, LOAD_ARCHIVED_CONVERSATIONS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/conversations/archived"));
    }
  };
}
export function updateReplyText(replyText, conversationId) {
  var messageId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  return {
    type: CHANGE_REPLY_TEXT,
    replyText: replyText,
    conversationId: conversationId,
    messageId: messageId
  };
}
export function uploadMedia(files, conversationId) {
  var messageId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  // Even though files is coming in as an array, we can currently assume that it will only contain a single file
  var MIMEType = files[0].type;
  var type = MIMEType.split('/')[0];
  var postUrl;

  switch (type) {
    case 'video':
      postUrl = '/videoUpload';
      break;

    default:
      postUrl = '/imageUpload';
  }

  return {
    types: [UPLOAD_MEDIA, UPLOAD_MEDIA_SUCCESS, UPLOAD_MEDIA_FAIL],
    promise: function promise(client) {
      return client.post(postUrl, {
        files: files
      });
    },
    conversationId: conversationId,
    messageId: messageId
  };
}
export function removeMedia(conversationId) {
  var messageId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var action = {
    type: REMOVE_MEDIA,
    conversationId: conversationId,
    messageId: messageId
  };
  return action;
}
export function retweetTweet(orgId, socialAccountId, tweetId) {
  return {
    types: [RETWEET_TWEET, RETWEET_TWEET_SUCCESS, RETWEET_TWEET_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/socialAccount/").concat(socialAccountId, "/retweet"), {
        data: {
          tweetId: tweetId
        }
      });
    }
  };
}
export function quoteRetweetTweet(orgId, socialAccountId, tweetId, text) {
  return {
    types: [QUOTE_RETWEET_TWEET, QUOTE_RETWEET_TWEET_SUCCESS, QUOTE_RETWEET_TWEET_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/socialAccount/").concat(socialAccountId, "/quoteRetweet"), {
        data: {
          tweetId: tweetId,
          text: text
        }
      });
    }
  };
} // Finds the a message or conversation in the state and returns a shallow copy

function getProperItem(state, conversationId, messageId) {
  return messageId ? _objectSpread({}, state.threadsById[conversationId.toString()].Messages.reduce(function (acc, m) {
    if (m.id === messageId) {
      console.log('found top level message');
      return _objectSpread({}, m);
    } else if (m.Messages.find(function (child) {
      return child.id === messageId;
    })) {
      console.log('found child');
      return _objectSpread({}, m.Messages.find(function (child) {
        return child.id === messageId;
      }));
    } else if (m.Messages.find(function (child) {
      return child.Messages.find(function (child2) {
        return child2.id === messageId;
      });
    })) {
      console.log('found child of child');
      var childMessage = m.Messages.find(function (child) {
        return child.Messages.find(function (child2) {
          return child2.id === messageId;
        });
      });
      var childOfChildMessage = childMessage.Messages.find(function (child) {
        return child.id === messageId;
      });
      return _objectSpread({}, childOfChildMessage);
    }

    console.log('found no match');
    return acc;
  }, null)) : _objectSpread({}, state.threadsById[conversationId.toString()].parentEntity);
}

function updateConversation(conversation, updatedItem, actionConversationId, actionMessageId) {
  var messages;

  if (actionMessageId) {
    messages = _toConsumableArray(conversation.Messages).map(function (m) {
      // Check if this message is the one we updated, replacing it with the one we modified with the new reply text
      if (m.id === updatedItem.id) {
        return _objectSpread(_objectSpread({}, m), updatedItem);
      } // This isn't the right message, but next we check its child messages


      var matchingChildMessageIndex = m.Messages.findIndex(function (child) {
        return child.id === updatedItem.id;
      });

      if (matchingChildMessageIndex !== -1) {
        m.Messages[matchingChildMessageIndex] = _objectSpread(_objectSpread({}, m.Messages[matchingChildMessageIndex]), updatedItem);
        return m;
      } // Search the next layer of child messages and update as necessary


      m.Messages = m.Messages.map(function (child) {
        var matchingIndex = child.Messages.findIndex(function (child2) {
          return child2.id === updatedItem.id;
        });

        if (matchingIndex !== -1) {
          child.Messages[matchingIndex] = _objectSpread(_objectSpread({}, child.Messages[matchingIndex]), updatedItem);
        }

        return child;
      });
      return m;
    });
  } else {
    return _objectSpread(_objectSpread({}, conversation), {}, {
      parentEntity: updatedItem
    });
  }

  return _objectSpread(_objectSpread({}, conversation), {}, {
    Messages: messages
  });
}