import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _actionMap;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import ReactGA from 'react-ga';
import { SUBMIT_POST_SUCCESS } from 'redux/modules/composeboxForm';
import { DELETE_POST_SUCCESS } from 'redux/modules/schedule';
import { SAVE_TIMEZONE_SUCCESS } from 'redux/modules/organization';
import { SAVE_SUCCESS as SOCIAL_CON_SAVE_SUCCESS, DEL_SUCCESS as SOCIAL_CON_DEL_SUCCESS } from 'redux/modules/socialConnection';
import { ACTIVATE_TEAM_MEMBER_SUCCESS, DEACTIVATE_TEAM_MEMBER_SUCCESS, SEND_INVITE_SUCCESS } from 'redux/modules/teamManagement';
import { SAVE_REPORT_SUCCESS } from 'redux/modules/reportBuilder';
import { SUBMIT_POST_SUCCESS as SUBMIT_GPOST_SUCCESS } from 'redux/modules/groupPostCompose';
import { SUBMIT_AD_SUCCESS } from 'redux/modules/adBuilder/actions';
var actionMap = (_actionMap = {}, _defineProperty(_actionMap, SUBMIT_POST_SUCCESS, function (action) {
  return {
    category: 'Post',
    action: action.post && action.post.existingPostId ? 'Edited' : 'Created'
  };
}), _defineProperty(_actionMap, DELETE_POST_SUCCESS, function (action) {
  return {
    category: 'Post',
    action: 'Deleted'
  };
}), _defineProperty(_actionMap, SOCIAL_CON_SAVE_SUCCESS, function (action) {
  return {
    category: 'SocialAccount',
    action: 'Connected'
  };
}), _defineProperty(_actionMap, SOCIAL_CON_DEL_SUCCESS, function (action) {
  return {
    category: 'SocialAccount',
    action: 'Deleted'
  };
}), _defineProperty(_actionMap, ACTIVATE_TEAM_MEMBER_SUCCESS, function (action) {
  return {
    category: 'OrganizationUser',
    action: 'Activated'
  };
}), _defineProperty(_actionMap, DEACTIVATE_TEAM_MEMBER_SUCCESS, function (action) {
  return {
    category: 'OrganizationUser',
    action: 'Deactivated'
  };
}), _defineProperty(_actionMap, SEND_INVITE_SUCCESS, function (action) {
  return {
    category: 'OrganizationUser',
    action: 'Invited'
  };
}), _defineProperty(_actionMap, SAVE_REPORT_SUCCESS, function (action) {
  return {
    category: 'Report',
    action: "Created ".concat(action.data.reportType)
  };
}), _defineProperty(_actionMap, SUBMIT_GPOST_SUCCESS, function (action) {
  return {
    category: 'GPost',
    action: action.post && action.post.existingPostId ? 'Edited' : 'Created'
  };
}), _defineProperty(_actionMap, SUBMIT_AD_SUCCESS, function (action) {
  return {
    category: 'Ad',
    action: 'Created'
  };
}), _defineProperty(_actionMap, SAVE_TIMEZONE_SUCCESS, function (action) {
  return {
    category: 'OrganizationTimezone',
    action: 'Edit'
  };
}), _actionMap);

var eventsTracking = function eventsTracking(store) {
  return function (next) {
    return function (action) {
      // JMW: Wrap this in a `try` so we ensure that if something goes wrong, it doesn't break the redux chain.
      try {
        var handler = actionMap[action.type];

        if (handler) {
          ReactGA.event(_objectSpread({}, handler(action)));
        }
      } catch (e) {
        console.error(e);
      }

      return next(action);
    };
  };
};

export default eventsTracking;