export default function populateNewAffiliateDialog(c) {
  var initialState = {
    orgConfig: {
      billingMethod: 'affiliate',
      contractMonths: c.ContractProducts[0].contractMonths
    },
    orgDetails: {
      firstName: c.firstName,
      lastName: c.lastName,
      address1: c.Address.address1,
      address2: c.Address.address2,
      city: c.Address.locality,
      state: c.Address.region,
      zipCode: c.Address.postcode,
      phone: c.phone,
      email: c.email,
      businessName: c.businessName,
      paymentDetailEntryType: c.paymentDetailEntryType
    },
    validation: {
      firstPass: false,
      valid: true
    },
    validationFields: ['businessName', 'email', 'product'],
    invalidFields: [],
    affiliateConfig: {
      affiliateProductId: c.ContractProducts[0].ProductId
    },
    sendingAgreement: false,
    agreementSent: false
  };
  return initialState;
}