import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var ns = 'likeablelocal/audienceInterests';
var CLEAR_DATA = "".concat(ns, "/CLEAR_DATA");
var QUERY_INTERESTS = "".concat(ns, "/QUERY_INTERESTS");
var QUERY_INTERESTS_SUCCESS = "".concat(ns, "/QUERY_INTERESTS_SUCCESS");
var QUERY_INTERESTS_FAIL = "".concat(ns, "/QUERY_INTERESTS_FAIL");
var initialState = {
  gettingInterestData: false,
  dataSource: []
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case QUERY_INTERESTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingInterestData: true
      });

    case QUERY_INTERESTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingInterestData: false,
        dataSource: action.result.data
      });

    case QUERY_INTERESTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingInterestData: false
      });

    case CLEAR_DATA:
      return {
        gettingInterestData: false,
        dataSource: []
      };

    default:
      return state;
  }
}
export function queryInterests(query) {
  var url = "/facebook/ad/interests?q=".concat(encodeURIComponent(query));
  return {
    types: [QUERY_INTERESTS, QUERY_INTERESTS_SUCCESS, QUERY_INTERESTS_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function clearInterestData() {
  return {
    type: CLEAR_DATA
  };
}