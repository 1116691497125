import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as actions from './actions';
import { whilst } from 'async';
export function updatePreviewStep(previewStep, orgId) {
  return {
    type: actions.UPDATE_PREVIEW_STEP,
    previewStep: previewStep,
    orgId: orgId
  };
}
export function clearAdPreview(orgId) {
  return {
    type: actions.CLEAR_FB_AD_PREVIEW,
    orgId: orgId
  };
}
export function clearAudience(orgId) {
  return {
    type: actions.CLEAR_AUDIENCE,
    orgId: orgId
  };
}
export function uploadCarouselImages(files, orgId) {
  return {
    types: [actions.UPLOAD_IMAGE, actions.UPLOAD_CAROUSEL_ITEM_SUCCESS, actions.UPLOAD_CAROUSEL_ITEM_FAIL],
    promise: function promise(client) {
      return client.post('/imageUpload', {
        files: files
      });
    },
    orgId: orgId
  };
}
export function uploadCarouselVideo(files, orgId) {
  return {
    types: [actions.UPLOAD_VIDEO, actions.UPLOAD_CAROUSEL_ITEM_SUCCESS, actions.UPLOAD_CAROUSEL_ITEM_FAIL],
    promise: function promise(client) {
      return client.post('/videoUpload', {
        files: files
      });
    },
    orgId: orgId
  };
}
export function uploadImages(files, orgId) {
  return {
    types: [actions.UPLOAD_IMAGE, actions.UPLOAD_IMAGE_SUCCESS, actions.UPLOAD_IMAGE_FAIL],
    promise: function promise(client) {
      return client.post('/imageUpload', {
        files: files
      });
    },
    orgId: orgId
  };
}
export function uploadFormImage(files, orgId) {
  return {
    types: [actions.UPLOAD_FORM_IMAGE, actions.UPLOAD_FORM_IMAGE_SUCCESS, actions.UPLOAD_FORM_IMAGE_FAIL],
    orgId: orgId,
    promise: function promise(client) {
      return client.post('/imageUpload', {
        files: files
      });
    }
  };
}
export function getAdBuilderFormat(orgId, facebookAdId) {
  return {
    types: [actions.GET_AD_BUILDER_FORMAT, actions.GET_AD_BUILDER_FORMAT_SUCCESS, actions.GET_AD_BUILDER_FORMAT_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/ad/").concat(facebookAdId, "/adBuilderFormat"));
    },
    orgId: orgId
  };
}
export function uploadBase64Image(file, orgId) {
  return {
    types: [actions.UPLOAD_BASE64_IMAGE, actions.UPLOAD_BASE64_IMAGE_SUCCESS, actions.UPLOAD_BASE64_IMAGE_FAIL],
    orgId: orgId,
    promise: function promise(client) {
      return new Promise(function (resolve, reject) {
        var chunkSize = 10240;
        var cursor = 0;
        var uploadId = null;
        var uploadedS3File = null;
        whilst(function () {
          return cursor < file.length;
        }, function (callback) {
          var finalize = cursor + chunkSize > file.length;
          client.post('/imageUpload/base64', {
            data: {
              data: file.substr(cursor, chunkSize),
              uploadId: uploadId,
              finalize: finalize
            }
          }).then(function (response) {
            if (response.ok) {
              if (!uploadId) {
                uploadId = response.data.uploadId;
              }

              if (finalize) {
                uploadedS3File = response.data.s3File;
              }

              cursor += chunkSize;
              callback();
            } else {
              throw new Error('Failed to init transfer');
            }
          })["catch"](reject);
        }, function (err) {
          if (err) {
            return reject(err);
          }

          resolve(uploadedS3File);
        });
      });
    }
  };
}
export function uploadVideo(files, orgId) {
  return {
    types: [actions.UPLOAD_VIDEO, actions.UPLOAD_VIDEO_SUCCESS, actions.UPLOAD_VIDEO_FAIL],
    promise: function promise(client) {
      return client.post('/videoUpload?placement=facebookAd', {
        files: files
      });
    },
    orgId: orgId
  };
}
export function submitAd(data) {
  return {
    types: [actions.SUBMIT_AD, actions.SUBMIT_AD_SUCCESS, actions.SUBMIT_AD_FAIL],
    promise: function promise(client) {
      return client.post('/createAd', {
        data: data
      });
    }
  };
}
export function updateReachEstimate(adAccountId, data, orgId) {
  var url = "/facebook/adAccount/".concat(adAccountId, "/reachEstimate");
  return {
    types: [actions.UPDATE_REACH_ESTIMATE, actions.UPDATE_REACH_ESTIMATE_SUCCESS, actions.UPDATE_REACH_ESTIMATE_FAIL],
    promise: function promise(client) {
      return client.post(url, data);
    },
    orgId: orgId
  };
}
export function queryInterests(query) {
  var url = "/facebook/ad/interests?q=".concat(encodeURIComponent(query));
  return {
    types: [actions.QUERY_INTERESTS, actions.QUERY_INTERESTS_SUCCESS, actions.QUERY_INTERESTS_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function categorySearch(category) {
  var url = "/facebook/ad/categorySearch?category=".concat(category);
  return {
    types: [actions.CATEGORY_SEARCH, actions.CATEGORY_SEARCH_SUCCESS, actions.CATEGORY_SEARCH_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function removeImageAdImage(orgId) {
  return {
    type: actions.REMOVE_IMAGE_AD_IMAGE,
    orgId: orgId
  };
}
export function removeVideoAdVideo(orgId) {
  return {
    type: actions.REMOVE_VIDEO_AD_VIDEO,
    orgId: orgId
  };
}
export function removeCarouselItem(item, orgId) {
  return {
    type: actions.REMOVE_CAROUSEL_AD_ITEM,
    item: item,
    orgId: orgId
  };
}
export function getTargetingInfo(adAccountId, type) {
  var url = "/facebook/ad/".concat(adAccountId, "/targetingbrowse/").concat(type);
  return {
    types: [actions.TARGETING_BROWSE, actions.TARGETING_BROWSE_SUCCESS, actions.TARGETING_BROWSE_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function queryLocations(query) {
  var url = "/facebook/ad/locations?q=".concat(encodeURIComponent(query));
  return {
    types: [actions.QUERY_LOCATIONS, actions.QUERY_LOCATIONS_SUCCESS, actions.QUERY_LOCATIONS_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function queryJobTitles(query) {
  var url = "/facebook/ad/jobTitles?q=".concat(encodeURIComponent(query));
  return {
    types: [actions.QUERY_JOB_TITLES, actions.QUERY_JOB_TITLES_SUCCESS, actions.QUERY_JOB_TITLES_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function queryEmployer(query) {
  var url = "/facebook/ad/employer?q=".concat(encodeURIComponent(query));
  return {
    types: [actions.QUERY_EMPLOYER, actions.QUERY_EMPLOYER_SUCCESS, actions.QUERY_EMPLOYER_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function querySchool(query) {
  var url = "/facebook/ad/school?q=".concat(encodeURIComponent(query));
  return {
    types: [actions.QUERY_SCHOOL, actions.QUERY_SCHOOL_SUCCESS, actions.QUERY_SCHOOL_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function queryMajor(query) {
  var url = "/facebook/ad/major?q=".concat(encodeURIComponent(query));
  return {
    types: [actions.QUERY_MAJOR, actions.QUERY_MAJOR_SUCCESS, actions.QUERY_MAJOR_FAIL],
    promise: function promise(client) {
      return client.get(url);
    }
  };
}
export function getCustomAudiences(orgId) {
  var url = "/org/".concat(orgId, "/customAudiences");
  return {
    types: [actions.GET_CUSTOM_AUDIENCES, actions.GET_CUSTOM_AUDIENCES_SUCCESS, actions.GET_CUSTOM_AUDIENCES_FAIL],
    promise: function promise(client) {
      return client.get(url);
    },
    orgId: orgId
  };
}
export function getFBAdPreview(orgId, socialAccountId, adType, adDetails) {
  var url = "/org/".concat(orgId, "/socialAccount/").concat(socialAccountId, "/adPreview");
  return {
    types: [actions.GET_FB_AD_PREVIEW, actions.GET_FB_AD_PREVIEW_SUCCESS, actions.GET_FB_AD_PREVIEW_FAIL],
    promise: function promise(client) {
      return client.put(url, {
        data: _objectSpread(_objectSpread({}, adDetails), {}, {
          adType: adType
        })
      });
    },
    orgId: orgId,
    adDetails: adDetails,
    data: {
      adType: adType
    }
  };
}
export function getFBAdPreviewSrc(orgId, socialAccountId, adType, adDetails) {
  var url = "/org/".concat(orgId, "/socialAccount/").concat(socialAccountId, "/adPreviewSrc");
  return {
    types: [actions.GET_FB_AD_PREVIEW_SRC, actions.GET_FB_AD_PREVIEW_SRC_SUCCESS, actions.GET_FB_AD_PREVIEW_SRC_FAIL],
    promise: function promise(client) {
      return client.put(url, {
        data: adDetails
      });
    },
    orgId: orgId,
    adDetails: adDetails,
    data: {
      adType: adType
    }
  };
}
export function getCustomPostPreview(orgId, adId, facebookPageId) {
  return {
    types: [actions.GET_CUSTOM_POST_PREVIEW, actions.GET_CUSTOM_POST_PREVIEW_SUCCESS, actions.GET_CUSTOM_POST_PREVIEW_FAIL],
    promise: function promise(client) {
      return client.get("/adsPlanner/".concat(orgId, "/plannedAd/").concat(adId, "/facebookPreview?facebookPageId=").concat(facebookPageId));
    },
    orgId: orgId
  };
}
export function createPageLikeAd(organizationId, socialAccountId, data) {
  var url = "/org/".concat(organizationId, "/socialAccount/").concat(socialAccountId, "/ads/create");
  return {
    types: [actions.CREATE_PAGE_LIKE_AD, actions.CREATE_PAGE_LIKE_AD_SUCCESS, actions.CREATE_PAGE_LIKE_AD_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: _objectSpread(_objectSpread({}, data), {}, {
          adType: 'PAGE_LIKE'
        })
      });
    },
    orgId: organizationId
  };
}
export function createWebsiteClickAd(organizationId, socialAccountId, data) {
  var url = "/org/".concat(organizationId, "/socialAccount/").concat(socialAccountId, "/ads/create");
  return {
    types: [actions.CREATE_WEBSITE_CLICK_AD, actions.CREATE_WEBSITE_CLICK_AD_SUCCESS, actions.CREATE_WEBSITE_CLICK_AD_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: _objectSpread(_objectSpread({}, data), {}, {
          adType: 'WEBSITE_CLICK'
        })
      });
    },
    orgId: organizationId
  };
}
export function createLeadGenAd(organizationId, socialAccountId, data) {
  var url = "/org/".concat(organizationId, "/socialAccount/").concat(socialAccountId, "/ads/create");
  return {
    types: [actions.CREATE_LEAD_GEN_AD, actions.CREATE_LEAD_GEN_AD_SUCCESS, actions.CREATE_LEAD_GEN_AD_FAIL],
    promise: function promise(client) {
      return client.post(url, {
        data: _objectSpread(_objectSpread({}, data), {}, {
          adType: 'LEAD_GENERATION'
        })
      });
    },
    orgId: organizationId
  };
}
export function parseLink(link, orgId) {
  return {
    types: [actions.PARSE_LINK, actions.PARSE_LINK_SUCCESS, actions.PARSE_LINK_FAIL],
    orgId: orgId,
    promise: function promise(client) {
      return client.post('/parseLink', {
        data: {
          link: link
        }
      });
    }
  };
}
export function setAdType(adType, orgId) {
  return {
    type: actions.SET_AD_TYPE,
    adType: adType,
    orgId: orgId
  };
}
export function setBackUrl(url, orgId) {
  return {
    type: actions.SET_BACK_URL,
    url: url,
    orgId: orgId
  };
}
export function resetAdBuilderState(orgId) {
  return {
    type: actions.RESET_AD_BUILDER_STATE,
    orgId: orgId
  };
}
export function clearLeadFormData(orgId) {
  return {
    type: actions.CLEAR_LEAD_FORM_DATA,
    orgId: orgId
  };
}
export function setLeadAdBuilderValue(orgId, key, value) {
  return {
    type: actions.SET_LEAD_AD_BUILDER_VALUE,
    key: key,
    value: value,
    orgId: orgId
  };
}
export function initLeadAdBuilder(orgId) {
  return {
    type: actions.INIT_LEAD_AD_BUILDER,
    orgId: orgId
  };
}
export function setLeadAdBuilderBullet(orgId, bulletIdx, value) {
  return {
    type: actions.SET_LEAD_AD_BUILDER_INTRO_BULLET,
    orgId: orgId,
    bulletIdx: bulletIdx,
    value: value
  };
}
export function setLeadAdQuestion(orgId, key, enabled) {
  return {
    type: actions.SET_LEAD_AD_BUILDER_QUESTION_CHECK,
    key: key,
    enabled: enabled,
    orgId: orgId
  };
}
export function addLeadFormCustomQuestion(orgId, questionType) {
  return {
    type: actions.ADD_CUSTOM_LEADFORM_QUESTION,
    questionType: questionType,
    orgId: orgId
  };
}
export function removeLeadFormCustomQuestion(orgId, index) {
  return {
    type: actions.REMOVE_CUSTOM_LEADFORM_QUESTION,
    orgId: orgId,
    index: index
  };
}
export function setLeadFormCustomQuestion(orgId, index, value) {
  return {
    type: actions.SET_CUSTOM_LEADFORM_QUESTION,
    orgId: orgId,
    value: value,
    index: index
  };
}
export function addLeadFormMultipleChoiceAnswer(orgId, index, value) {
  return {
    type: actions.ADD_LEADFORM_MULTI_ANSWER,
    orgId: orgId,
    value: value,
    index: index
  };
}
export function removeLeadFormMultipleChoiceAnswer(orgId, customQuestionIndex, answerIndex) {
  return {
    type: actions.REMOVE_LEADFORM_MULTI_ANSWER,
    orgId: orgId,
    customQuestionIndex: customQuestionIndex,
    answerIndex: answerIndex
  };
}
export function setAppointmentConfirmationEnabled(orgId, customQuestionIndex, value) {
  return {
    type: actions.SET_APPOINTMENT_CONFIRM_ENABLED,
    orgId: orgId,
    customQuestionIndex: customQuestionIndex,
    value: value
  };
}
export function setAppointmentConfirmationText(orgId, customQuestionIndex, value) {
  return {
    type: actions.SET_APPOINTMENT_CONFIRM_TEXT,
    orgId: orgId,
    customQuestionIndex: customQuestionIndex,
    value: value
  };
}
export function addNewCustomConsent(orgId) {
  return {
    type: actions.ADD_NEW_CONSENT,
    orgId: orgId
  };
}
export function setCustomConsentText(orgId, consentIdx, value) {
  return {
    type: actions.SET_CONSENT_TEXT,
    orgId: orgId,
    consentIdx: consentIdx,
    value: value
  };
}
export function setCustomConsentRequired(orgId, consentIdx, value) {
  return {
    type: actions.SET_CONSENT_REQUIRED,
    orgId: orgId,
    consentIdx: consentIdx,
    value: value
  };
}
export function removeCustomConsent(orgId, consentIdx) {
  return {
    type: actions.REMOVE_CUSTOM_CONSENT,
    orgId: orgId,
    consentIdx: consentIdx
  };
}
export function fetchExistingForms(orgId, socialAccountId) {
  return {
    types: [actions.FETCH_FORMS, actions.FETCH_FORMS_SUCCESS, actions.FETCH_FORMS_FAIL],
    orgId: orgId,
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/socialAccount/").concat(socialAccountId, "/leadForms"));
    }
  };
}
export function fetchFormDetails(orgId, socialAccountId, formId) {
  return {
    types: [actions.FETCH_FORM_DETAIL, actions.FETCH_FORM_DETAIL_SUCCESS, actions.FETCH_FORM_DETAIL_FAIL],
    orgId: orgId,
    formId: formId,
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/socialAccount/").concat(socialAccountId, "/leadForms/").concat(formId));
    }
  };
}
export function saveLeadForm(orgId, socialAccountId, formData) {
  return {
    types: [actions.SAVE_LEADFORM, actions.SAVE_LEADFORM_SUCCESS, actions.SAVE_LEADFORM_FAIL],
    orgId: orgId,
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/socialAccount/").concat(socialAccountId, "/leadForm"), {
        data: _objectSpread({}, formData)
      });
    }
  };
}
export function getPageConnectedInstagramAccount(orgId, socialAccountId) {
  return {
    types: [actions.GET_PAGE_INSTA, actions.GET_PAGE_INSTA_SUCCESS, actions.GET_PAGE_INSTA_FAIL],
    orgId: orgId,
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/socialAccount/").concat(socialAccountId, "/pageConnectedInstagramAccount"));
    }
  };
}
export function initAdBuilderForOrg(orgId) {
  return {
    type: actions.INIT_ADBUILDER_STATE_FOR_ORG,
    orgId: orgId
  };
}
export function clearAdBuilderForOrg(orgId) {
  return {
    type: actions.CLEAR_AD_BUILDER_FOR_ORG,
    orgId: orgId
  };
}
export function getAvailableAdFunds(orgId) {
  return {
    types: [actions.GET_AVAILABLE_AD_BUDGET, actions.GET_AVAILABLE_AD_BUDGET_SUCCESS, actions.GET_AVAILABLE_AD_BUDGET_FAIL],
    orgId: orgId,
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/availableAdsFunds"));
    }
  };
}
export function convertAdToRedux(orgId, adId) {
  return {
    types: [actions.CONVERT_AD_TO_REDUX, actions.CONVERT_AD_TO_REDUX_SUCCESS, actions.CONVERT_AD_TO_REDUX_FAIL],
    orgId: orgId,
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/ad/").concat(adId, "/adBuilderFormat"));
    }
  };
}
export function changeAdBuilderValue(orgId, keyValues) {
  return {
    type: actions.ADBUILDER_CHANGE_VALUE,
    orgId: orgId,
    keyValues: keyValues
  };
}
export function deeplinkIntoAdsBuilder(orgId, keyValues) {
  return {
    type: actions.ADBUILDER_DEEPLINK,
    orgId: orgId,
    keyValues: keyValues
  };
}