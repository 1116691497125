import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as _ from 'lodash';
import moment from 'moment';
import { GET_FB_AD_PREVIEW_SRC, GET_FB_AD_PREVIEW_SRC_SUCCESS, GET_FB_AD_PREVIEW_SRC_FAIL } from './adBuilder/actions';
import { GET_PAGE_FIELDS_SUCCESS } from './reportFacebookChecklist';
import { LOAD_REPORT_SUCCESS } from './reportBuilder';
var ns = 'likeablelocal/reportSocialMediaSummary';
export var CLEAR_SM = "".concat(ns, "/CLEAR_SM");
var TOGGLE_SHOW_PROMOTED_POSTS = "".concat(ns, "/TOGGLE_SHOW_PROMOTED_POSTS");
var TOGGLE_AUTO_NEXT_STEP = "".concat(ns, "/TOGGLE_AUTO_NEXT_STEP");
var GET_HOLIDAYS_SUCCESS = "".concat(ns, "/GET_HOLIDAYS_SUCCESS");
var GET_HOLIDAYS_FAIL = "".concat(ns, "/GET_HOLIDAYS_FAIL");
var GET_OTHER_IDEAS_SUCCESS = "".concat(ns, "/GET_OTHER_IDEAS_SUCCESS");
var GET_OTHER_IDEAS_FAIL = "".concat(ns, "/GET_OTHER_IDEAS_FAIL");
var TOGGLE_SECTION = "".concat(ns, "/TOGGLE_SECTION");
var UPDATE_TEXT_FIELD = "".concat(ns, "/UPDATE_TEXT_FIELD");
var UPDATE_FIELD = "".concat(ns, "/UPDATE_FIELD");
var GET_PAGE_INSIGHTS = "".concat(ns, "/GET_PAGE_INSIGHTS");
var GET_PAGE_INSIGHTS_SUCCESS = "".concat(ns, "/GET_PAGE_INSIGHTS_SUCCESS");
var GET_PAGE_INSIGHTS_FAIL = "".concat(ns, "/GET_PAGE_INSIGHTS_FAIL");
var GET_RECENT_POSTS = "".concat(ns, "/GET_RECENT_POSTS");
var GET_RECENT_POSTS_SUCCESS = "".concat(ns, "/GET_RECENT_POSTS_SUCCESS");
var GET_RECENT_POSTS_FAIL = "".concat(ns, "/GET_RECENT_POSTS_FAIL");
var GET_PAGE_STATS = "".concat(ns, "/GET_PAGE_STATS");
var GET_PAGE_STATS_SUCCESS = "".concat(ns, "/GET_PAGE_STATS_SUCCESS");
var GET_PAGE_STATS_FAIL = "".concat(ns, "/GET_PAGE_STATS_FAIL");
var GET_ADS = "".concat(ns, "/GET_ADS");
var GET_ADS_SUCCESS = "".concat(ns, "/GET_ADS_SUCCESS");
var GET_ADS_FAIL = "".concat(ns, "/GET_ADS_FAIL");
var initialState = {
  gettingAdPreview: 0,
  gettingAds: false,
  gettingPosts: false,
  gettingInsights: false,
  gettingPageStats: false,
  sections: [{
    key: 'overview',
    label: 'Overview',
    enabled: true
  }, {
    key: 'adPerformance',
    label: 'Ad Performance',
    enabled: true,
    showPromotedPosts: false
  }, {
    key: 'recentPosts',
    label: 'Best Performing Posts',
    enabled: true
  }, {
    key: 'pageInsights',
    label: 'Audience Insights',
    enabled: true
  }, {
    key: 'nextSteps',
    label: 'Next Steps',
    enabled: true
  }],
  overview: [{
    key: 'page_fans',
    label: 'Total Page Likes',
    value: true,
    dataPointText: 'Total Page Likes',
    data: null
  }, {
    key: 'page_impressions_unique',
    label: 'Impressions',
    value: true,
    data: null,
    dataPointText: 'Impressions'
  }, {
    key: 'page_views_total',
    label: 'Page Views',
    value: true,
    data: null,
    dataPointText: 'Views'
  }, {
    key: 'page_post_engagements',
    label: 'Engagement',
    value: true,
    data: null,
    dataPointText: 'Engagements'
  }, {
    key: 'page_total_actions',
    label: 'Total Actions',
    value: false,
    data: null,
    dataPointText: 'Actions'
  }, {
    key: 'page_video_views',
    label: 'Video Views',
    value: false,
    data: null,
    dataPointText: 'Video Views'
  }, {
    key: 'page_fan_adds_unique',
    label: 'New Page Likes',
    value: false,
    dataPointText: 'New Page Likes',
    data: null
  }],
  adPerformance: [],
  recentPosts: [],
  pageInsights: [{
    key: 'page_fans_gender_age',
    label: 'Age and Gender',
    value: true,
    data: null
  }, {
    key: 'page_fans_city',
    label: 'Locations',
    value: true,
    data: null
  }],
  nextSteps: [{
    key: 'upcoming_holidays',
    label: 'Upcoming Holidays Content',
    value: true,
    canBeAuto: true,
    auto: false,
    textFields: ['', '', '']
  }, {
    key: 'engaging_posts',
    label: 'Content Based On Engaging Posts',
    value: true,
    canBeAuto: false,
    auto: false,
    textFields: ['', '', '']
  }, {
    key: 'other_ideas',
    label: 'Other Content Ideas',
    value: true,
    canBeAuto: true,
    auto: false,
    textFields: ['', '', '']
  }, {
    key: 'audience_suggestions',
    label: 'Ads Audience Suggestions',
    value: true,
    canBeAuto: false,
    textFields: ['', '', '']
  }, {
    key: 'objectives',
    label: 'Ads Objectives',
    value: true,
    canBeAuto: false,
    textFields: ['', '', '']
  }, {
    key: 'topic_ideas',
    label: 'Ads Topic Ideas',
    value: true,
    canBeAuto: false,
    textFields: ['', '', '']
  }]
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _.cloneDeep(initialState);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case CLEAR_SM:
      return _.cloneDeep(initialState);

    case GET_PAGE_FIELDS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        overview: state.overview.map(function (o) {
          if (o.key === 'page_fans') {
            o.data = action.result.data;
          }

          return o;
        })
      });

    case LOAD_REPORT_SUCCESS:
      return function () {
        if (action.result.data.reportType === 'monthly') {
          return _objectSpread({}, action.result.data.details);
        }

        return state;
      }();

    case GET_FB_AD_PREVIEW_SRC:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAdPreview: state.gettingAdPreview + 1
      });

    case GET_FB_AD_PREVIEW_SRC_SUCCESS:
      // add in the iframe to the ad
      var updatedAdPerformance = state.adPerformance.map(function (ad) {
        if (ad.data.id === action.adDetails.adId) {
          ad.iframeSrc = action.result.data.fullyQualifiedUrl;
        }

        return ad;
      });
      var updatedRecentPosts = state.recentPosts.map(function (post) {
        if (post.data.id === action.adDetails.postId) {
          post.data.iframeSrc = action.result.data.fullyQualifiedUrl;
        }

        return post;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAdPreview: state.gettingAdPreview - 1,
        adPerformance: updatedAdPerformance,
        recentPosts: updatedRecentPosts
      });

    case GET_FB_AD_PREVIEW_SRC_FAIL:
      // this would add a default error image when the iframe image fails to load, but at present,
      // the adDetails is not returned, so it doesn't work

      /*
      const updatedAdPerformanceFail = state.adPerformance.map(ad => {
        if (ad.data.id === action.adDetails.adId) {
          ad.iframeSrc = 'https://s3.amazonaws.com/likeabucket/user_upload/22/HkwwrFuam.png';
        }
        return ad;
      });
       const updatedRecentPostsFail = state.recentPosts.map(post => {
        if (post.data.id === action.adDetails.postId) {
          post.data.iframeSrc = 'https://s3.amazonaws.com/likeabucket/user_upload/22/HkwwrFuam.png';
        }
        return post;
      });
      */
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAdPreview: state.gettingAdPreview - 1
      });

    case TOGGLE_SHOW_PROMOTED_POSTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sections: state.sections.map(function (section) {
          if (section.key === 'adPerformance') {
            section.showPromotedPosts = action.value;
          }

          return section;
        })
      });

    case TOGGLE_SECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        sections: state.sections.map(function (section) {
          if (section.key === action.sectionKey) {
            section.enabled = action.value;
          }

          return section;
        })
      });

    case TOGGLE_AUTO_NEXT_STEP:
      return _objectSpread(_objectSpread({}, state), {}, {
        nextSteps: state.nextSteps.map(function (field) {
          if (field.key === action.fieldKey) {
            field.auto = action.value;
            field.textFields = ['', '', ''];
          }

          return field;
        })
      });

    case GET_HOLIDAYS_SUCCESS:
      var results = _.sampleSize(action.result.data.holidays, 3);

      var displayResults = results.map(function (result) {
        return result.name;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        nextSteps: state.nextSteps.map(function (field) {
          if (field.key === action.fieldKey) {
            field.textFields = displayResults;
          }

          return field;
        })
      });

    case GET_OTHER_IDEAS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        nextSteps: state.nextSteps.map(function (field) {
          if (field.key === action.fieldKey) {
            field.textFields = action.result.data.ideas;
          }

          return field;
        })
      });

    case UPDATE_FIELD:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.section, state[action.section].map(function (field) {
        if (field.key === action.field) {
          field.value = action.value;
        } else if (action.radio) {
          field.value = false;
        }

        return field;
      })));

    case UPDATE_TEXT_FIELD:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.section, state[action.section].map(function (field) {
        if (field.key === action.field) {
          field.textFields = field.textFields.map(function (text, textIndex) {
            if (action.index === textIndex) {
              text = action.value;
            } else if (text === '') {
              text = '';
            }

            return text;
          });
        }

        return field;
      })));

    case GET_PAGE_INSIGHTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingInsights: true
      });

    case GET_PAGE_INSIGHTS_SUCCESS:
      var data2 = action.result.data && action.result.data.results ? action.result.data.results : null;
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingInsights: false,
        pageInsights: state.pageInsights.map(function (insight) {
          insight.data = data2 ? data2[insight.key].results : null;
          return insight;
        })
      });

    case GET_PAGE_INSIGHTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingInsights: false,
        sections: state.sections.map(function (section) {
          if (section.key === 'pageInsights') {
            section.enabled = false;
          }

          return section;
        })
      });

    case GET_RECENT_POSTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPosts: true
      });

    case GET_RECENT_POSTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPosts: false,
        recentPosts: action.result.data.posts.map(function (data, index) {
          var postTime = moment(data.created_time).format('M/DD/YYYY');
          var post = {
            buttonValue: postTime,
            label: "".concat(postTime, " - ").concat(data.insights.data.post_impressions, " Impressions, ").concat(data.insights.data.post_reactions_like_total, " Likes"),
            key: data.id,
            value: false,
            data: data
          };

          if (index === 0) {
            post.value = true;
          }

          return post;
        }),
        sections: state.sections.map(function (section) {
          if (section.key === 'recentPosts' && !action.result.data.posts.length) {
            section.enabled = false;
          } else if (section.key === 'recentPosts') {
            section.enabled = true;
          }

          return section;
        })
      });

    case GET_RECENT_POSTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPosts: false,
        sections: state.sections.map(function (section) {
          if (section.key === 'recentPosts') {
            section.enabled = false;
          }

          return section;
        })
      });

    case GET_ADS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAds: true
      });

    case GET_ADS_SUCCESS:
      var ads = action.result.data.map(function (ad) {
        ad.isPromotedPost = ad.name.slice(0, 4) === 'act_';
        return ad;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAds: false,
        sections: state.sections.map(function (section) {
          if (section.key === 'adPerformance' && !ads.length) {
            section.enabled = false;
          } else if (section.key === 'adPerformance') {
            section.enabled = true;
          }

          return section;
        }),
        adPerformance: ads.map(function (ad) {
          return {
            data: ad,
            value: !ad.isPromotedPost,
            key: ad.id,
            label: ad.name
          };
        })
      });

    case GET_ADS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAds: false,
        sections: state.sections.map(function (section) {
          if (section.key === 'adPerformance') {
            section.enabled = false;
          }

          return section;
        })
      });

    case GET_PAGE_STATS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPageStats: true
      });

    case GET_PAGE_STATS_SUCCESS:
      var data = action.result.data.results;
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPageStats: false,
        overview: state.overview.map(function (item) {
          var itemData = data[item.key];

          if (!itemData) {
            return item;
          }

          var formattedData = {
            changeDirection: itemData.changeDirection,
            changeValue: itemData.changeValue,
            changeDisplay: itemData.changeDisplay,
            result: itemData.results
          };
          return _objectSpread(_objectSpread({}, item), {}, {
            data: formattedData
          });
        })
      });

    case GET_PAGE_STATS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingPageStats: false
      });

    default:
      return state;
  }
}
export function clearTemplate() {
  return {
    type: CLEAR_SM
  };
}
export function updateField(section, field, value) {
  var radio = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  return {
    type: UPDATE_FIELD,
    section: section,
    field: field,
    value: value,
    radio: radio
  };
}
export function updateTextField(section, field, index, value) {
  return {
    type: UPDATE_TEXT_FIELD,
    section: section,
    field: field,
    index: index,
    value: value
  };
}
export function toggleSection(sectionKey, value) {
  return {
    type: TOGGLE_SECTION,
    sectionKey: sectionKey,
    value: value
  };
}
export function toggleShowPromotedPosts(value) {
  return {
    type: TOGGLE_SHOW_PROMOTED_POSTS,
    value: value
  };
}
export function toggleAutoNextStep(fieldKey, value) {
  if (value) {
    if (fieldKey === 'other_ideas') {
      var endpoint = '/randomPostIdeas';
      return {
        types: [TOGGLE_AUTO_NEXT_STEP, GET_OTHER_IDEAS_SUCCESS, GET_OTHER_IDEAS_FAIL],
        promise: function promise(client) {
          return client.get(endpoint);
        },
        fieldKey: fieldKey,
        value: value
      };
    } else if (fieldKey === 'upcoming_holidays') {
      var _endpoint = "/holidays?start=".concat(moment().format(), "&end=").concat(moment().add(1, 'month').format());

      return {
        types: [TOGGLE_AUTO_NEXT_STEP, GET_HOLIDAYS_SUCCESS, GET_HOLIDAYS_FAIL],
        promise: function promise(client) {
          return client.get(_endpoint);
        },
        fieldKey: fieldKey,
        value: value
      };
    }
  }

  return {
    type: TOGGLE_AUTO_NEXT_STEP,
    fieldKey: fieldKey,
    value: value
  };
}
export function getPageInsights(orgId, start, end) {
  var metrics = 'page_fans_city,page_fans_gender_age';
  return {
    types: [GET_PAGE_INSIGHTS, GET_PAGE_INSIGHTS_SUCCESS, GET_PAGE_INSIGHTS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/reporting/pageStats?since=").concat(start, "&until=").concat(end, "&metrics=").concat(metrics));
    }
  };
}
export function getRecentPosts(orgId, socialAccountId, start, end) {
  return {
    types: [GET_RECENT_POSTS, GET_RECENT_POSTS_SUCCESS, GET_RECENT_POSTS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/reporting/postsInRange?since=").concat(start, "&until=").concat(end));
    }
  };
}
export function getPageStats(orgId, start, end) {
  return {
    types: [GET_PAGE_STATS, GET_PAGE_STATS_SUCCESS, GET_PAGE_STATS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/reporting/pageStats?since=").concat(start, "&until=").concat(end));
    }
  };
}
export function getAds(orgId, start, end) {
  return {
    types: [GET_ADS, GET_ADS_SUCCESS, GET_ADS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/reporting/adsInRange?start=").concat(start, "&end=").concat(end));
    }
  };
}
export function getHolidays(start, end) {
  return {
    types: [GET_HOLIDAYS, GET_HOLIDAYS_SUCCESS, GET_HOLIDAYS_FAIL],
    promise: function promise(client) {
      return client.get('');
    }
  };
}