export var GET_WHITELABEL = 'likeablelocal/billing/GET_WHITELABEL';
export var GET_WHITELABEL_SUCCESS = 'likeablelocal/billing/GET_WHITELABEL_SUCCESS';
export var GET_WHITELABEL_FAIL = 'likeablelocal/billing/GET_WHITELABEL_FAIL';
export var PROCESS_PAYMENT = 'likeablelocal/billing/PROCESS_PAYMENT';
export var PROCESS_PAYMENT_SUCCESS = 'likeablelocal/billing/PROCESS_PAYMENT_SUCESS';
export var PROCESS_PAYMENT_FAIL = 'likeablelocal/billing/PROCESS_PAYMENT_FAIL';
export var INITIAL_SUBSCRIPTION = 'likeablelocal/billing/INITIAL_SUBSCRIPTION';
export var INITIAL_SUBSCRIPTION_SUCCESS = 'likeablelocal/billing/INITIAL_SUBSCRIPTION_SUCCESS';
export var INITIAL_SUBSCRIPTION_FAIL = 'likeablelocal/billing/INITIAL_SUBSCRIPTION_FAIL';
export var CLEAR_STRIPE_ERROR = 'likeablelocal/billing/CLEAR_STRIPE_ERROR';
export var SHOW_STRIPE_ERROR = 'likeablelocal/billing/SHOW_STRIPE_ERROR';
export var GET_CONNECT_VERIFICATION_FIELDS = 'likeablelocal/billing/GET_CONNECT_VERIFICATION_FIELDS';
export var GET_CONNECT_VERIFICATION_FIELDS_SUCCESS = 'likeablelocal/billing/GET_CONNECT_VERIFICATION_FIELDS_SUCESS';
export var GET_CONNECT_VERIFICATION_FIELDS_FAIL = 'likeablelocal/billing/GET_CONNECT_VERIFICATION_FIELDS_FAIL';
export var CREATE_STRIPE_BANK = 'likeablelocal/billing/CREATE_STRIPE_BANK';
export var CREATE_STRIPE_BANK_SUCCESS = 'likeablelocal/billing/CREATE_STRIPE_BANK_SUCCES';
export var CREATE_STRIPE_BANK_FAIL = 'likeablelocal/billing/CREATE_STRIPE_BANK_FAIL';
export var CREATE_COMMISSION_ACCOUNT = 'likeablelocal/billing/CREATE_COMMISSION_ACCOUNT';
export var CREATE_COMMISSION_ACCOUNT_SUCCESS = 'likeablelocal/billing/CREATE_COMMISSION_ACCOUNT_SUCCESS';
export var CREATE_COMMISSION_ACCOUNT_FAIL = 'likeablelocal/billing/CREATE_COMMISSION_ACCOUNT_FAIL';
export var LOAD_COMMISSION_ACCOUNT = 'likeablelocal/billing/LOAD_COMMISSION_ACCOUNT';
export var LOAD_COMMISSION_ACCOUNT_SUCCESS = 'likeablelocal/billing/LOAD_COMMISSION_ACCOUNT_SUCCESS';
export var LOAD_COMMISSION_ACCOUNT_FAIL = 'likeablelocal/billing/LOAD_COMMISSION_ACCOUNT_FAIL';
export var LIST_ACCOUNT_TRANFERS = 'likeablelocal/billing/LIST_ACCOUNT_TRANFERS';
export var LIST_ACCOUNT_TRANFERS_SUCCESS = 'likeablelocal/billing/LIST_ACCOUNT_TRANFERS_SUCCESS';
export var LIST_ACCOUNT_TRANFERS_FAIL = 'likeablelocal/billing/LIST_ACCOUNT_TRANFERS_FAIL';
export var SEND_CANCELLATION_EMAIL = 'likeablelocal/billing/SEND_CANCELLATION_EMAIL';
export var SEND_CANCELLATION_EMAIL_SUCCESS = 'likeablelocal/billing/SEND_CANCELLATION_EMAIL_SUCCESS';
export var SEND_CANCELLATION_EMAIL_FAIL = 'likeablelocal/billing/SEND_CANCELLATION_EMAIL_FAIL';
export var SEND_ONE_TIME_CHARGE_EMAIL = 'likeablelocal/billing/SEND_ONE_TIME_CHARGE_EMAIL';
export var SEND_ONE_TIME_CHARGE_EMAIL_SUCCESS = 'likeablelocal/billing/SEND_ONE_TIME_CHARGE_EMAIL_SUCCESS';
export var SEND_ONE_TIME_CHARGE_EMAIL_FAIL = 'likeablelocal/billing/SEND_ONE_TIME_CHARGE_EMAIL_FAIL';
export var SEND_BUDGET_CHANGE_EMAIL = 'likeablelocal/billing/SEND_BUDGET_CHANGE_EMAIL';
export var SEND_BUDGET_CHANGE_EMAIL_SUCCESS = 'likeablelocal/billing/SEND_BUDGET_CHANGE_EMAIL_SUCCESS';
export var SEND_BUDGET_CHANGE_EMAIL_FAIL = 'likeablelocal/billing/SEND_BUDGET_CHANGE_EMAIL_FAIL';
export var CHANGE_ADS_PLAN = 'likeablelocal/billing/CHANGE_ADS_PLAN';
export var CHANGE_ADS_PLAN_SUCCESS = 'likeablelocal/billing/CHANGE_ADS_PLAN_SUCCESS';
export var CHANGE_ADS_PLAN_FAIL = 'likeablelocal/billing/CHANGE_ADS_PLAN_FAIL';
export var CLEAR_BILLING_HISTORY = 'likeablelocal/billing/CLEAR_BILLING_HISTORY';
export var CLEAR_INVOICE_INFO = 'likeablelocal/billing/CLEAR_INVOICE_INFO';
export var CANCEL_CLIENT = 'likeablelocal/billing/CANCEL_CLIENT';
export var CANCEL_CLIENT_SUCCESS = 'likeablelocal/billing/CANCEL_CLIENT_SUCCESS';
export var CANCEL_CLIENT_FAIL = 'likeablelocal/billing/CANCEL_CLIENT_FAIL';
export var GET_INVOICE = 'likeablelocal/billing/GET_INVOICE';
export var GET_INVOICE_SUCCESS = 'likeablelocal/billing/GET_INVOICE_SUCCESS';
export var GET_INVOICE_FAIL = 'likeablelocal/billing/GET_INVOICE_FAIL';
export var SET_INVOICE = 'likeablelocal/billing/SET_INVOICE';
export var GET_ADS_PAYMENT_METHOD = 'likeablelocal/billing/GET_ADS_PAYMENT_METHOD';
export var GET_ADS_PAYMENT_METHOD_SUCCESS = 'likeablelocal/billing/GET_ADS_PAYMENT_METHOD_SUCCESS';
export var GET_ADS_PAYMENT_METHOD_FAIL = 'likeablelocal/billing/GET_ADS_PAYMENT_METHOD_FAIL';
export var GET_HAS_COMMISSIONS_ACCOUNT = 'likeablelocal/billing/GET_HAS_COMMISSIONS_ACCOUNT';
export var GET_HAS_COMMISSIONS_ACCOUNT_SUCCESS = 'likeablelocal/billing/GET_HAS_COMMISSIONS_ACCOUNT_SUCCESS';
export var GET_HAS_COMMISSIONS_ACCOUNT_FAIL = 'likeablelocal/billing/GET_HAS_COMMISSIONS_ACCOUNT_FAIL';
export var GET_BILLING_HISTORY = 'likeablelocal/billing/GET_BILLING_HISTORY';
export var GET_BILLING_HISTORY_SUCCESS = 'likeablelocal/billing/GET_BILLING_HISTORY_SUCCESS';
export var GET_BILLING_HISTORY_FAIL = 'likeablelocal/billing/GET_BILLING_HISTORY_FAIL';
export var GET_PARTNER_BILLING_HISTORY = 'likeablelocal/billing/GET_PARTNER_BILLING_HISTORY';
export var GET_PARTNER_BILLING_HISTORY_SUCCESS = 'likeablelocal/billing/GET_PARTNER_BILLING_HISTORY_SUCCESS';
export var GET_PARTNER_BILLING_HISTORY_FAIL = 'likeablelocal/billing/GET_PARTNER_BILLING_HISTORY_FAIL';
export var SET_ADS_PAYMENT_METHOD = 'likeablelocal/billing/SET_ADS_PAYMENT_METHOD';
export var SET_ADS_PAYMENT_METHOD_SUCCESS = 'likeablelocal/billing/SET_ADS_PAYMENT_METHOD_SUCCESS';
export var SET_ADS_PAYMENT_METHOD_FAIL = 'likeablelocal/billing/SET_ADS_PAYMENT_METHOD_FAIL';
export var ADD_FUNDS = 'likeablelocal/billing/ADD_FUNDS';
export var ADD_FUNDS_SUCCESS = 'likeablelocal/billing/ADD_FUNDS_SUCCESS';
export var ADD_FUNDS_FAIL = 'likeablelocal/billing/ADD_FUNDS_FAIL';
export var GET_CONNECT_ACCOUNT_BALANCE = 'likeablelocal/billing/GET_CONNECT_ACCOUNT_BALANCE';
export var GET_CONNECT_ACCOUNT_BALANCE_SUCCESS = 'likeablelocal/billing/GET_CONNECT_ACCOUNT_BALANCE_SUCCESS';
export var GET_CONNECT_ACCOUNT_BALANCE_FAIL = 'likeablelocal/billing/GET_CONNECT_ACCOUNT_BALANCE_FAIL';
export var GET_ORG_OWNER = 'likeablelocal/billing/GET_ORG_OWNER';
export var GET_ORG_OWNER_SUCCESS = 'likeablelocal/billing/GET_ORG_OWNER_SUCCESS';
export var GET_ORG_OWNER_FAIL = 'likeablelocal/billing/GET_ORG_OWNER_FAIL';
export var GET_AD_SPEND_SUMMARY = 'likeablelocal/billing/GET_AD_SPEND_SUMMARY';
export var GET_AD_SPEND_SUMMARY_SUCCESS = 'likeablelocal/billing/GET_AD_SPEND_SUMMARY_SUCCESS';
export var GET_AD_SPEND_SUMMARY_FAIL = 'likeablelocal/billing/GET_AD_SPEND_SUMMARY_FAIL';
export var GET_ORG_AD_SPEND_SUMMARY = 'likeablelocal/billing/GET_ORG_AD_SPEND_SUMMARY';
export var GET_ORG_AD_SPEND_SUMMARY_SUCCESS = 'likeablelocal/billing/GET_ORG_AD_SPEND_SUMMARY_SUCCESS';
export var GET_ORG_AD_SPEND_SUMMARY_FAIL = 'likeablelocal/billing/GET_ORG_AD_SPEND_SUMMARY_FAIL';
export var GET_FB_AD_CONFIG = 'likeablelocal/billing/GET_FB_AD_CONFIG';
export var GET_FB_AD_CONFIG_SUCCESS = 'likeablelocal/billing/GET_FB_AD_CONFIG_SUCCESS';
export var GET_FB_AD_CONFIG_FAIL = 'likeablelocal/billing/GET_FB_AD_CONFIG_FAIL';
export var UPSERT_FB_AD_CONFIG = 'likeablelocal/billing/UPSERT_FB_AD_CONFIG';
export var UPSERT_FB_AD_CONFIG_SUCCESS = 'likeablelocal/billing/UPSERT_FB_AD_CONFIG_SUCCESS';
export var UPSERT_FB_AD_CONFIG_FAIL = 'likeablelocal/billing/UPSERT_FB_AD_CONFIG_FAIL';
export var GET_PARTNER_DEFAULT_PAYMENT_METHOD = 'likeablelocal/billing/GET_PARTNER_DEFAULT_PAYMENT_METHOD';
export var GET_PARTNER_DEFAULT_PAYMENT_METHOD_SUCCESS = 'likeablelocal/billing/GET_PARTNER_DEFAULT_PAYMENT_METHOD_SUCCESS';
export var GET_PARTNER_DEFAULT_PAYMENT_METHOD_FAIL = 'likeablelocal/billing/GET_PARTNER_DEFAULT_PAYMENT_METHOD_FAIL';
export var UNMOUNT_AD_BUDGET = 'likeablelocal/billing/UNMOUNT_AD_BUDGET';
export var CLEAR_BILLING_ERROR = 'likeablelocal/billing/CLEAR_BILLING_ERROR';
export var CLEAR_CREDIT_CARD_INFO = 'likeablelocal/billing/CLEAR_CREDIT_CARD_INFO';
export var UPDATE_PARTNER_DEFAULT_PAYMENT_METHOD = 'likeablelocal/billing/UPDATE_PARTNER_DEFAULT_PAYMENT_METHOD';
export var UPDATE_PARTNER_DEFAULT_PAYMENT_METHOD_SUCCESS = 'likeablelocal/billing/UPDATE_PARTNER_DEFAULT_PAYMENT_METHOD_SUCCESS';
export var UPDATE_PARTNER_DEFAULT_PAYMENT_METHOD_FAIL = 'likeablelocal/billing/UPDATE_PARTNER_DEFAULT_PAYMENT_METHOD_FAIL';
export var UPDATE_PARTNER_CLIENT_BILLING = 'likeablelocal/billing/UPDATE_PARTNER_CLIENT_BILLING';
export var UPDATE_PARTNER_CLIENT_BILLING_SUCCESS = 'likeablelocal/billing/UPDATE_PARTNER_CLIENT_BILLING_SUCCESS';
export var UPDATE_PARTNER_CLIENT_BILLING_FAIL = 'likeablelocal/billing/UPDATE_PARTNER_CLIENT_BILLING_FAIL';