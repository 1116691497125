import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
var ns = 'likeablelocal/adsPlanner';
/** *******************************************************************

ACTIONS

*********************************************************************/

var GET_PLAN_BY_ID = "".concat(ns, "/GET_PLAN_BY_ID");
var GET_PLAN_BY_ID_SUCCESS = "".concat(ns, "/GET_PLAN_BY_ID_SUCCESS");
var GET_PLAN_BY_ID_FAIL = "".concat(ns, "/GET_PLAN_BY_ID_FAIL");
var GET_SUBSCRIBED_POST_COUNT = "".concat(ns, "/GET_SUBSCRIBED_POST_COUNT");
var GET_SUBSCRIBED_POST_COUNT_SUCCESS = "".concat(ns, "/GET_SUBSCRIBED_POST_COUNT_SUCCESS");
var GET_SUBSCRIBED_POST_COUNT_FAIL = "".concat(ns, "/GET_SUBSCRIBED_POST_COUNT_FAIL");
var GET_PLANNED_ADS = "".concat(ns, "/GET_PLANNED_ADS");
var GET_PLANNED_ADS_SUCCESS = "".concat(ns, "/GET_PLANNED_ADS_SUCCESS");
var GET_PLANNED_ADS_FAIL = "".concat(ns, "/GET_PLANNED_ADS_FAIL");
var EDIT_SUBSCRIBED_BOOST = "".concat(ns, "/EDIT_SUBSCRIBED_BOOST");
var EDIT_SUBSCRIBED_BOOST_SUCCESS = "".concat(ns, "/EDIT_SUBSCRIBED_BOOST_SUCCESS");
var EDIT_SUBSCRIBED_BOOST_FAIL = "".concat(ns, "/EDIT_SUBSCRIBED_BOOST_FAIL");
var GET_PLAN_WITH_TIMESTAMP = "".concat(ns, "/GET_PLAN_WITH_TIMESTAMP");
var GET_PLAN_WITH_TIMESTAMP_SUCCESS = "".concat(ns, "/GET_PLAN_WITH_TIMESTAMP_SUCCESS");
var GET_PLAN_WITH_TIMESTAMP_FAIL = "".concat(ns, "/GET_PLAN_WITH_TIMESTAMP_FAIL");
var HAS_PLAN = "".concat(ns, "/HAS_PLAN");
var HAS_PLAN_SUCCESS = "".concat(ns, "/HAS_PLAN_SUCCESS");
var HAS_PLAN_FAIL = "".concat(ns, "/HAS_PLAN_FAIL");
var APPROVE_REUSED_AD = "".concat(ns, "/APPROVE_REUSED_AD");
var APPROVE_REUSED_AD_SUCCESS = "".concat(ns, "/APPROVE_REUSED_AD_SUCCESS");
var APPROVE_REUSED_AD_FAIL = "".concat(ns, "/APPROVE_REUSED_AD_FAIL");
var COPY_PAST_PLAN = "".concat(ns, "/COPY_PAST_PLAN");
var COPY_PAST_PLAN_SUCCESS = "".concat(ns, "/COPY_PAST_PLAN_SUCCESS");
var COPY_PAST_PLAN_FAIL = "".concat(ns, "/COPY_PAST_PLAN_FAIL");
var PLAN_AD_FROM_PAST_PLANNED_AD = "".concat(ns, "/PLAN_AD_FROM_PAST_PLANNED_AD");
var PLAN_AD_FROM_PAST_PLANNED_AD_SUCCESS = "".concat(ns, "/PLAN_AD_FROM_PAST_PLANNED_AD_SUCCESS");
var PLAN_AD_FROM_PAST_PLANNED_AD_FAIL = "".concat(ns, "/PLAN_AD_FROM_PAST_PLANNED_AD_FAIL");
var CREATE_INITIAL_PLAN = "".concat(ns, "/CREATE_INITIAL_PLAN");
var CREATE_INITIAL_PLAN_SUCCESS = "".concat(ns, "/CREATE_INITIAL_PLAN_SUCCESS");
var CREATE_INITIAL_PLAN_FAIL = "".concat(ns, "/CREATE_INITIAL_PLAN_FAIL");
var CREATE_PLAN_AFTER = "".concat(ns, "/CREATE_PLAN_AFTER");
var CREATE_PLAN_AFTER_SUCCESS = "".concat(ns, "/CREATE_PLAN_AFTER_SUCCESS");
var CREATE_PLAN_AFTER_FAIL = "".concat(ns, "/CREATE_PLAN_AFTER_FAIL");
var CREATE_PLAN_ITEM = "".concat(ns, "/CREATE_PLAN_ITEM");
var CREATE_PLAN_ITEM_SUCCESS = "".concat(ns, "/CREATE_PLAN_ITEM_SUCCESS");
var CREATE_PLAN_ITEM_FAIL = "".concat(ns, "/CREATE_PLAN_ITEM_FAIL");
var DELETE_PLAN_ITEM = "".concat(ns, "/DELETE_PLAN_ITEM");
var DELETE_PLAN_ITEM_SUCCESS = "".concat(ns, "/DELETE_PLAN_ITEM_SUCCESS");
var DELETE_PLAN_ITEM_FAIL = "".concat(ns, "/DELETE_PLAN_ITEM_FAIL");
var EDIT_PLAN_ITEM = "".concat(ns, "/EDIT_PLAN_ITEM");
var EDIT_PLAN_ITEM_SUCCESS = "".concat(ns, "/EDIT_PLAN_ITEM_SUCCESS");
var EDIT_PLAN_ITEM_FAIL = "".concat(ns, "/EDIT_PLAN_ITEM_FAIL");
var GET_ADS_FOCUSES = "".concat(ns, "/GET_ADS_FOCUSES");
var GET_ADS_FOCUSES_SUCCESS = "".concat(ns, "/GET_ADS_FOCUSES_SUCCESS");
var GET_ADS_FOCUSES_FAIL = "".concat(ns, "/GET_ADS_FOCUSES_FAIL");
var SET_ADS_FOCUS_FOR_PLAN = "".concat(ns, "/SET_ADS_FOCUS_FOR_PLAN");
var SET_USE_PLANNER = "".concat(ns, "/SET_USE_PLANNER");
var SET_USE_PLANNER_SUCCESS = "".concat(ns, "/SET_USE_PLANNER_SUCCESS");
var SET_USE_PLANNER_FAIL = "".concat(ns, "/SET_USE_PLANNER_FAIL");
var APPLY_ADS_FOCUS = "".concat(ns, "/APPLY_ADS_FOCUS");
var APPLY_ADS_FOCUS_SUCCESS = "".concat(ns, "/APPLY_ADS_FOCUS_SUCCESS");
var APPLY_ADS_FOCUS_FAIL = "".concat(ns, "/APPLY_ADS_FOCUS_FAIL");
var APPROVE_ADS_PLAN = "".concat(ns, "/APPROVE_ADS_PLAN");
var APPROVE_ADS_PLAN_SUCCESS = "".concat(ns, "/APPROVE_ADS_PLAN_SUCCESS");
var APPROVE_ADS_PLAN_FAIL = "".concat(ns, "/APPROVE_ADS_PLAN_FAIL");
export var GET_FACEBOOK_ADS_FOR_PLAN = "".concat(ns, "/GET_FACEBOOK_ADS_FOR_PLAN");
export var GET_FACEBOOK_ADS_FOR_PLAN_SUCCESS = "".concat(ns, "/GET_FACEBOOK_ADS_FOR_PLAN_SUCCESS");
export var GET_FACEBOOK_ADS_FOR_PLAN_FAIL = "".concat(ns, "/GET_FACEBOOK_ADS_FOR_PLAN_FAIL");
export var TIE_POST_TO_AD = "".concat(ns, "/TIE_POST_TO_AD");
export var TIE_POST_TO_AD_SUCCESS = "".concat(ns, "/TIE_POST_TO_AD_SUCCESS");
export var TIE_POST_TO_AD_FAIL = "".concat(ns, "/TIE_POST_TO_AD_FAIL");
export var SET_RESULT_BOX_STATES = "".concat(ns, "/SET_RESULT_BOX_STATES");
var TIE_AD_TO_PLANNED_AD = 'likeablelocal/ads/TIE_AD_TO_PLANNED_AD';
var TIE_AD_TO_PLANNED_AD_SUCCESS = 'likeablelocal/ads/TIE_AD_TO_PLANNED_AD_SUCCESS';
var TIE_AD_TO_PLANNED_AD_FAIL = 'likeablelocal/ads/TIE_AD_TO_PLANNED_AD_FAIL';
/** *******************************************************************

INITIAL STATE

*********************************************************************/

var initialState = {};
var orgInitialState = {
  usePlanner: false,
  currentPlanId: 1,
  gettingPlan: true,
  adsFocuses: [],
  gettingHasPlan: true,
  hasPlan: false,
  gettingAdsFocuses: false,
  loadingFBAdsForPlan: false
};
var planInitialState = {
  trafficBudget: 0,
  trafficAds: [],
  likeBudget: 0,
  likeAds: [],
  leadBudget: 0,
  leadAds: [],
  customPostBudget: 0,
  customPosts: [],
  queuedPostBudget: 0,
  uncategorizedBudget: 0,
  uncategorizedAds: [],
  editable: true
};
/** *******************************************************************

REDUCERS

*********************************************************************/

export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
      return initialState;

    case LOGOUT_SUCCESS:
      return initialState;

    case HAS_PLAN:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingHasPlan: true
      })));

    case HAS_PLAN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        hasPlan: action.result.data.hasPlans,
        usePlanner: action.result.data.usePlanner,
        gettingHasPlan: false
      })));

    case HAS_PLAN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingHasPlan: false
      })));

    case GET_PLAN_BY_ID:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingPlan: true
      })));

    case GET_PLAN_BY_ID_SUCCESS:
      var id_res = action.result.data;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({
        gettingPlan: false,
        currentPlanId: id_res.adsPlan.id,
        paging: id_res.paging
      }, id_res.adsPlan.id, _objectSpread(_objectSpread({}, state[action.orgId][id_res.adsPlan.id]), id_res.adsPlan)))));

    case GET_PLAN_BY_ID_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingPlan: false
      })));

    case GET_SUBSCRIBED_POST_COUNT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        subscribedPostCount: action.result.data
      })));

    case GET_PLAN_WITH_TIMESTAMP:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingPlan: true
      })));

    case GET_PLAN_WITH_TIMESTAMP_SUCCESS:
      var ts_res = action.result.data;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({
        gettingPlan: false,
        currentPlanId: ts_res.adsPlan.id,
        paging: ts_res.paging
      }, ts_res.adsPlan.id, _objectSpread(_objectSpread({}, state[action.orgId][ts_res.adsPlan.id]), ts_res.adsPlan)))));

    case GET_PLAN_WITH_TIMESTAMP_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingPlan: false
      })));

    case APPROVE_REUSED_AD:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        approvingReusedAd: true
      })));

    case APPROVE_REUSED_AD_SUCCESS:
      var approveReusedAd_res = action.result.data;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({
        approvingReusedAd: false
      }, approveReusedAd_res.adsPlan.id, _objectSpread({}, approveReusedAd_res.adsPlan)))));

    case APPROVE_REUSED_AD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        approvingReusedAd: false
      })));

    case PLAN_AD_FROM_PAST_PLANNED_AD:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        planningAdFromPastPlannedAd: true
      })));

    case PLAN_AD_FROM_PAST_PLANNED_AD_SUCCESS:
      var planAdFromPastPlannedAd_res = action.result.data;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({
        planningAdFromPastPlannedAd: false
      }, planAdFromPastPlannedAd_res.adsPlan.id, _objectSpread({}, planAdFromPastPlannedAd_res.adsPlan)))));

    case PLAN_AD_FROM_PAST_PLANNED_AD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        planningAdFromPastPlannedAd: false
      })));

    case COPY_PAST_PLAN:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        isApplyingAdsFocus: true
      })));

    case COPY_PAST_PLAN_SUCCESS:
      var copyingPastPlan_res = action.result.data;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({
        isApplyingAdsFocus: false
      }, copyingPastPlan_res.adsPlan.id, _objectSpread({}, copyingPastPlan_res.adsPlan)))));

    case COPY_PAST_PLAN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        isApplyingAdsFocus: false
      })));

    case GET_PLANNED_ADS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingPlannedAds: true,
        plannedAds: {
          PAGE_LIKES: [],
          WEBSITE_TRAFFIC: [],
          LEADS: [],
          CUSTOM_POSTS: [],
          SUBSCRIBED_POSTS: []
        }
      })));

    case GET_PLANNED_ADS_SUCCESS:
      var get_planned_ads_res = action.result.data;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingPlannedAds: false,
        plannedAds: _objectSpread(_objectSpread({}, state[action.orgId].plannedAds), {}, _defineProperty({}, action.category, _toConsumableArray(get_planned_ads_res)))
      })));

    case GET_PLANNED_ADS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingPlannedAds: false
      })));

    case EDIT_SUBSCRIBED_BOOST:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        editingPlan: true
      })));

    case EDIT_SUBSCRIBED_BOOST_SUCCESS:
      var edit_subscribed_res = action.result.data;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({
        editingPlan: false,
        paging: edit_subscribed_res.paging
      }, edit_subscribed_res.adsPlan.id, _objectSpread(_objectSpread({}, state[action.orgId][edit_subscribed_res.adsPlan.id]), edit_subscribed_res.adsPlan)))));

    case EDIT_SUBSCRIBED_BOOST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        editingPlan: false
      })));

    case CREATE_INITIAL_PLAN:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingPlan: true
      })));

    case CREATE_INITIAL_PLAN_SUCCESS:
      var init_res = action.result.data;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({
        gettingPlan: false,
        currentPlanId: init_res.id,
        paging: init_res.paging
      }, init_res.id, _objectSpread(_objectSpread({}, state[action.orgId][init_res.id]), init_res)))));

    case CREATE_INITIAL_PLAN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingPlan: false
      })));

    case CREATE_PLAN_AFTER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        paging: {
          before: state[action.orgId].paging.before,
          after: true
        }
      })));

    case CREATE_PLAN_ITEM_SUCCESS:
      var item_res = action.result.data;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({}, item_res.adsPlan.id, _objectSpread(_objectSpread({}, state[action.orgId][item_res.adsPlan.id]), item_res.adsPlan)))));

    case EDIT_PLAN_ITEM_SUCCESS:
      var edit_res = action.result.data;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({}, edit_res.adsPlan.id, _objectSpread(_objectSpread({}, state[action.orgId][edit_res.adsPlan.id]), edit_res.adsPlan)))));

    case DELETE_PLAN_ITEM_SUCCESS:
      var del_res = action.result.data;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({}, del_res.adsPlan.id, _objectSpread(_objectSpread({}, state[action.orgId][del_res.adsPlan.id]), del_res.adsPlan)))));

    case GET_ADS_FOCUSES:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingAdsFocuses: true
      })));

    case GET_ADS_FOCUSES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingAdsFocuses: false,
        adsFocuses: action.result.data
      })));

    case GET_ADS_FOCUSES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingAdsFocuses: false
      })));

    case SET_ADS_FOCUS_FOR_PLAN:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({}, action.planId, _objectSpread(_objectSpread({}, state[action.orgId][action.planId]), {}, {
        adsFocus: _objectSpread({}, action.focus)
      })))));

    case APPLY_ADS_FOCUS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({}, action.planId, _objectSpread(_objectSpread(_objectSpread({}, state[action.orgId][action.planId]), state[action.orgId][action.planId]), {}, {
        isApplyingAdsFocus: true
      })))));

    case APPLY_ADS_FOCUS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({}, action.planId, _objectSpread(_objectSpread(_objectSpread({}, state[action.orgId][action.planId]), state[action.orgId][action.planId]), {}, {
        isApplyingAdsFocus: false
      })))));

    case APPLY_ADS_FOCUS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({}, action.result.data.adsPlan.id, _objectSpread(_objectSpread({}, action.result.data.adsPlan), {}, {
        adsFocus: _objectSpread({}, action.focus),
        isApplyingAdsFocus: false
      })))));

    case SET_USE_PLANNER:
      // jamming in the plannedAds initial state here because the initial state above doesn't get used anywhere
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        usePlanner: action.usePlanner,
        plannedAds: {
          PAGE_LIKES: [],
          WEBSITE_TRAFFIC: [],
          LEADS: [],
          CUSTOM_POSTS: [],
          SUBSCRIBED_POSTS: []
        }
      })));

    case APPROVE_ADS_PLAN_SUCCESS:
      var approve_res = action.result.data;
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({
        paging: approve_res.paging
      }, approve_res.adsPlan.id, _objectSpread(_objectSpread({}, state[action.orgId][approve_res.adsPlan.id]), approve_res.adsPlan)))));

    case GET_FACEBOOK_ADS_FOR_PLAN:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({
        loadingFBAdsForPlan: true
      }, action.planId, _objectSpread({}, state[action.orgId][action.planId])))));

    case GET_FACEBOOK_ADS_FOR_PLAN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({
        loadingFBAdsForPlan: false
      }, action.planId, _objectSpread(_objectSpread(_objectSpread({}, state[action.orgId][action.planId]), action.result.data.adsPlan), {}, {
        planAds: {
          ads: action.result.data.facebookAds
        },
        results: _objectSpread({}, action.result.data.results)
      })))));

    case GET_FACEBOOK_ADS_FOR_PLAN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({
        loadingFBAdsForPlan: false
      }, action.planId, _objectSpread({}, state[action.orgId][action.planId])))));

    case SET_RESULT_BOX_STATES:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({}, action.planId, _objectSpread(_objectSpread({}, state[action.orgId][action.planId]), {}, {
        resultStatTypes: _toConsumableArray(action.types)
      })))));

    case TIE_POST_TO_AD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, _defineProperty({}, action.planId, _objectSpread(_objectSpread({}, state[action.orgId][action.planId]), action.result.data.adsPlan)))));

    default:
      return state;
  }
}
/** *******************************************************************

SELECTORS

*********************************************************************/

export function setResultBoxStates(orgId, planId, types) {
  return {
    type: SET_RESULT_BOX_STATES,
    orgId: orgId,
    planId: planId,
    types: types
  };
}
export function getPlanById(orgId, planId) {
  var url = "/adsPlanner/".concat(orgId, "/plan/").concat(planId);
  return {
    types: [GET_PLAN_BY_ID, GET_PLAN_BY_ID_SUCCESS, GET_PLAN_BY_ID_FAIL],
    promise: function promise(client) {
      return client.get(url);
    },
    orgId: orgId
  };
}
export function getPlanWithTimestamp(orgId, timestamp) {
  var url = "/adsPlanner/".concat(orgId, "/at?ts=").concat(timestamp);
  return {
    types: [GET_PLAN_WITH_TIMESTAMP, GET_PLAN_WITH_TIMESTAMP_SUCCESS, GET_PLAN_WITH_TIMESTAMP_FAIL],
    promise: function promise(client) {
      return client.get(url);
    },
    orgId: orgId
  };
}
export function hasPlan(orgId) {
  var url = "/adsPlanner/".concat(orgId, "/hasAny");
  return {
    types: [HAS_PLAN, HAS_PLAN_SUCCESS, HAS_PLAN_FAIL],
    promise: function promise(client) {
      return client.get(url);
    },
    orgId: orgId
  };
}
export function createInitialPlan(orgId) {
  var url = "/adsPlanner/".concat(orgId, "/initialPlan");
  return {
    types: [CREATE_INITIAL_PLAN, CREATE_INITIAL_PLAN_SUCCESS, CREATE_INITIAL_PLAN_FAIL],
    promise: function promise(client) {
      return client.post(url);
    },
    orgId: orgId
  };
}
export function createPlanAfter(orgId, afterPlanId) {
  var url = "/adsPlanner/".concat(orgId, "/createPlanAfter/").concat(afterPlanId);
  return {
    types: [CREATE_PLAN_AFTER, CREATE_PLAN_AFTER_SUCCESS, CREATE_PLAN_AFTER_FAIL],
    promise: function promise(client) {
      return client.post(url);
    },
    orgId: orgId
  };
}
export function createPlanItem(orgId, categoryId) {
  var budgetInCents = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 200;
  var url = "/adsPlanner/".concat(orgId, "/category/").concat(categoryId, "/planAd");
  return {
    types: [CREATE_PLAN_ITEM, CREATE_PLAN_ITEM_SUCCESS, CREATE_PLAN_ITEM_FAIL],
    promise: function promise(client) {
      return client.put(url, {
        data: {
          budgetInCents: budgetInCents
        }
      });
    },
    orgId: orgId
  };
}
export function editPlanItem(orgId, adId, data) {
  var url = "/adsPlanner/".concat(orgId, "/plannedAd/").concat(adId);
  return {
    types: [EDIT_PLAN_ITEM, EDIT_PLAN_ITEM_SUCCESS, EDIT_PLAN_ITEM_FAIL],
    promise: function promise(client) {
      return client.put(url, {
        data: data
      });
    },
    orgId: orgId
  };
}
export function copyPastPlanToPlan(orgId, previousPlanId, targetPlanId) {
  var url = "/adsPlanner/".concat(orgId, "/copyPastPlanToPlan");
  return {
    types: [COPY_PAST_PLAN, COPY_PAST_PLAN_SUCCESS, COPY_PAST_PLAN_FAIL],
    promise: function promise(client) {
      return client.put(url, {
        data: {
          previousPlanId: previousPlanId,
          targetPlanId: targetPlanId
        }
      });
    },
    orgId: orgId
  };
}
export function deletePlanItem(orgId, adId) {
  var url = "/adsPlanner/".concat(orgId, "/plannedAd/").concat(adId);
  return {
    types: [DELETE_PLAN_ITEM, DELETE_PLAN_ITEM_SUCCESS, DELETE_PLAN_ITEM_FAIL],
    promise: function promise(client) {
      return client.del(url);
    },
    orgId: orgId
  };
}
export function getAdsFocuses(orgId, prevPlanId) {
  var query = prevPlanId ? "?prevPlanId=".concat(prevPlanId) : '';
  var url = "/adsPlanner/".concat(orgId, "/adsFocuses").concat(query);
  return {
    types: [GET_ADS_FOCUSES, GET_ADS_FOCUSES_SUCCESS, GET_ADS_FOCUSES_FAIL],
    promise: function promise(client) {
      return client.get(url);
    },
    orgId: orgId
  };
}
export function getSubscribedPostCount(orgId) {
  return {
    types: [GET_SUBSCRIBED_POST_COUNT, GET_SUBSCRIBED_POST_COUNT_SUCCESS, GET_SUBSCRIBED_POST_COUNT_FAIL],
    promise: function promise(client) {
      return client.get("/adsPlanner/".concat(orgId, "/activePlanSubscribedPosts"));
    },
    orgId: orgId
  };
}
export function setAdsFocusForPlan(focus, orgId, planId) {
  return {
    focus: focus,
    planId: planId,
    orgId: orgId,
    type: SET_ADS_FOCUS_FOR_PLAN
  };
}
export function setUsePlanner(orgId, usePlanner) {
  return {
    orgId: orgId,
    usePlanner: usePlanner,
    types: [SET_USE_PLANNER, SET_USE_PLANNER_SUCCESS, SET_USE_PLANNER_FAIL],
    promise: function promise(client) {
      return client.put("/adsPlanner/".concat(orgId, "/status"), {
        data: {
          enabled: usePlanner
        }
      });
    }
  };
}
export function applyAdsFocusToPlan(focus, orgId, planId) {
  var url = "/adsPlanner/".concat(orgId, "/adsFocuses/").concat(planId, "/apply");
  return {
    types: [APPLY_ADS_FOCUS, APPLY_ADS_FOCUS_SUCCESS, APPLY_ADS_FOCUS_FAIL],
    promise: function promise(client) {
      return client.put(url, {
        data: focus
      });
    },
    orgId: orgId,
    focus: focus,
    planId: planId
  };
}
export function editSubscribedBoost(orgId, planId, countOfPosts, budgetPerPost) {
  console.log('editSubscribedBoost');
  console.log("orgId = ".concat(orgId));
  console.log("planId = ".concat(planId));
  console.log("countOfPosts = ".concat(countOfPosts));
  console.log("budgetPerPost = ".concat(budgetPerPost));
  var url = "/adsPlanner/".concat(orgId, "/plan/").concat(planId, "/subscribedPostBoostSettings");
  return {
    types: [EDIT_SUBSCRIBED_BOOST, EDIT_SUBSCRIBED_BOOST_SUCCESS, EDIT_SUBSCRIBED_BOOST_FAIL],
    promise: function promise(client) {
      return client.put(url, {
        data: {
          countOfPosts: countOfPosts,
          budgetPerPostInCents: budgetPerPost
        }
      });
    },
    orgId: orgId,
    focus: focus,
    planId: planId
  };
}
export function approveAdsPlan(orgId, planId) {
  var url = "/adsPlanner/".concat(orgId, "/plan/").concat(planId, "/approve");
  return {
    types: [APPROVE_ADS_PLAN, APPROVE_ADS_PLAN_SUCCESS, APPROVE_ADS_PLAN_FAIL],
    promise: function promise(client) {
      return client.put(url);
    },
    orgId: orgId,
    planId: planId
  };
}
export function getFacebookAdsForPlan(orgId, planId, fbAdAccountId) {
  var resultsFields = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  var url = "/adsPlanner/".concat(orgId, "/plan/").concat(planId, "/facebookAds?fbAdAccountId=").concat(fbAdAccountId).concat(resultsFields.length > 0 ? "&resultStats=".concat(resultsFields.join(',')) : '');
  return {
    types: [GET_FACEBOOK_ADS_FOR_PLAN, GET_FACEBOOK_ADS_FOR_PLAN_SUCCESS, GET_FACEBOOK_ADS_FOR_PLAN_FAIL],
    promise: function promise(client) {
      return client.get(url);
    },
    orgId: orgId,
    planId: planId
  };
}
export function tiePostToAd(orgId, planId, postId, adId, startDate, endDate) {
  return {
    types: [TIE_POST_TO_AD, TIE_POST_TO_AD_SUCCESS, TIE_POST_TO_AD_FAIL],
    promise: function promise(client) {
      return client.post("/adsPlanner/".concat(orgId, "/plan/").concat(planId, "/plannedAd/").concat(adId, "/tieToPost"), {
        data: {
          postId: postId,
          startDate: startDate,
          endDate: endDate
        }
      });
    },
    orgId: orgId,
    planId: planId
  };
}
export function approveReusedAd(orgId, planId, fbAdId, linkedPlannedAdId, previousPlannedAdId) {
  return {
    types: [APPROVE_REUSED_AD, APPROVE_REUSED_AD_SUCCESS, APPROVE_REUSED_AD_FAIL],
    promise: function promise(client) {
      return client.post("/adsPlanner/".concat(orgId, "/plan/").concat(planId, "/duplicateAd/").concat(fbAdId, "/linkToPlannedAd/").concat(linkedPlannedAdId), {
        data: {
          previousPlannedAdId: previousPlannedAdId
        }
      });
    },
    orgId: orgId,
    planId: planId,
    fbAdId: fbAdId,
    linkedPlannedAdId: linkedPlannedAdId
  };
}
export function getPlannedAds(orgId, start, end, category) {
  return {
    types: [GET_PLANNED_ADS, GET_PLANNED_ADS_SUCCESS, GET_PLANNED_ADS_FAIL],
    promise: function promise(client) {
      return client.get("/adsPlanner/".concat(orgId, "/plannedAds?after=").concat(start, "&before=").concat(end, "&category=").concat(category));
    },
    orgId: orgId,
    category: category
  };
}
export function planAdFromPastPlannedAd(orgId, planId, adId, pastAdId) {
  return {
    types: [PLAN_AD_FROM_PAST_PLANNED_AD, PLAN_AD_FROM_PAST_PLANNED_AD_SUCCESS, PLAN_AD_FROM_PAST_PLANNED_AD_FAIL],
    promise: function promise(client) {
      return client.put("/adsPlanner/".concat(orgId, "/plan/").concat(planId, "/plannedAd/").concat(adId, "/fromPastPlannedAd/").concat(pastAdId));
    },
    orgId: orgId,
    planId: planId,
    pastAdId: pastAdId
  };
}
export function tieAdToPlannedAd(orgId, adId, plannedAdId) {
  var url = "/org/".concat(orgId, "/adsPlanner/tieAdToPlannedAd");
  return {
    types: [TIE_AD_TO_PLANNED_AD, TIE_AD_TO_PLANNED_AD_SUCCESS, TIE_AD_TO_PLANNED_AD_FAIL],
    adId: adId,
    plannedAdId: plannedAdId,
    orgId: orgId,
    promise: function promise(client) {
      return client.post(url, {
        data: {
          adId: adId,
          plannedAdId: plannedAdId
        }
      });
    }
  };
}
/** *******************************************************************

CUSTOM METHODS

*********************************************************************/

function _mapGroupToPrettyName(group) {
  var prettyName = 'Like';

  if (group === 'leadAds') {
    prettyName = 'Lead';
  } else if (group === 'trafficAds') {
    prettyName = 'Traffic';
  } else if (group === 'uncategorizedAds') {
    prettyName = 'Uncategorized';
  } else if (group === 'customPosts') {
    prettyName = 'Post';
  }

  return prettyName;
}