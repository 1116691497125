export default {
  elevation0: {
    boxShadow: 'none'
  },
  elevation1: {
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),' + '0 3px 6px -2px rgba(0, 0, 0, 0.1)'
  },
  elevation2: {
    boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 10px 0px rgba(0, 0, 0, 0.1)'
  },
  elevation3: {
    boxShadow: '0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3)'
  },
  elevation4: {
    boxShadow: '0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3)'
  },
  elevation5: {
    boxShadow: '0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), ' + '0 5px 5px -3px rgba(0, 0, 0, 0.3)'
  },
  elevation6: {
    boxShadow: '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), ' + '0 8px 10px -5px rgba(0, 0, 0, 0.3)'
  }
};