import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as _ from 'lodash';
import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
var GET_SINGLE_POST = 'likeablelocal/contentLibraryContent/GET_SINGLE_POST';
var GET_SINGLE_POST_SUCCESS = 'likeablelocal/contentLibraryContent/GET_SINGLE_POST_SUCCESS';
var GET_SINGLE_POST_FAIL = 'likeablelocal/contentLibraryContent/GET_SINGLE_POST_FAIL';
var GET_BUCKETS = 'likeablelocal/contentLibraryContent/GET_BUCKETS';
var GET_BUCKETS_SUCCESS = 'likeablelocal/contentLibraryContent/GET_BUCKETS_SUCCESS';
var GET_BUCKETS_FAIL = 'likeablelocal/contentLibraryContent/GET_BUCKETS_FAIL';
var GET_CATEGORY_CONTENT = 'likeablelocal/contentLibraryContent/GET_CATEGORY_CONTENT';
var GET_CATEGORY_CONTENT_SUCCESS = 'likeablelocal/contentLibraryContent/GET_CATEGORY_CONTENT_SUCCESS';
var GET_CATEGORY_CONTENT_FAIL = 'likeablelocal/contentLibraryContent/GET_CATEGORY_CONTENT_FAIL';
var initialState = {
  buckets: [],
  categories: [],
  categoryContent: {},
  loadingBuckets: false,
  loadingCategoryContent: false,
  gettingCategoryContent: false,
  singlePosts: [],
  loadingSinglePost: false
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case GET_SINGLE_POST:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSinglePost: true
      });

    case GET_SINGLE_POST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSinglePost: false,
        singlePosts: [].concat(_toConsumableArray(state.singlePosts), [action.result.data])
      });

    case GET_SINGLE_POST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingSinglePost: false
      });

    case GET_BUCKETS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingBuckets: true
      });

    case GET_BUCKETS_SUCCESS:
      var categoriesWithDuplicates = action.result.data.reduce(function (acc, cur) {
        return [].concat(_toConsumableArray(acc), _toConsumableArray(cur.Categories));
      }, []);

      var categories = _.uniqBy(categoriesWithDuplicates, 'id'); // turn array of categories into an Object with tagId as key


      var categoryContent = categories.reduce(function (acc, cur) {
        acc[cur.slug] = cur;
        return acc;
      }, {});
      return _objectSpread(_objectSpread({}, state), {}, {
        buckets: action.result.data,
        categoryContent: categoryContent,
        categories: categories,
        loadingBuckets: false
      });

    case GET_BUCKETS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingBuckets: false
      });

    case GET_CATEGORY_CONTENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCategoryContent: true
      });

    case GET_CATEGORY_CONTENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        categoryContent: _objectSpread(_objectSpread({}, state.categoryContent), {}, _defineProperty({}, action.slug, _objectSpread(_objectSpread({}, state.categoryContent && state.categoryContent[action.slug] ? state.categoryContent[action.slug] : {}), {}, {
          data: state.categoryContent && state.categoryContent[action.slug] && state.categoryContent[action.slug].data ? [].concat(_toConsumableArray(state.categoryContent[action.slug].data), _toConsumableArray(action.result.data.content)) : action.result.data.content,
          hasMore: action.result.data.hasMore,
          offset: action.offset,
          limit: action.limit
        }))),
        loadingCategoryContent: false
      });

    case GET_CATEGORY_CONTENT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingCategoryContent: false
      });

    default:
      return state;
  }
}
export function getBuckets() {
  return {
    types: [GET_BUCKETS, GET_BUCKETS_SUCCESS, GET_BUCKETS_FAIL],
    promise: function promise(client) {
      return client.get('/contentLibrary/buckets');
    }
  };
}
export function getCategoryContent(slug) {
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 15;
  return {
    types: [GET_CATEGORY_CONTENT, GET_CATEGORY_CONTENT_SUCCESS, GET_CATEGORY_CONTENT_FAIL],
    promise: function promise(client) {
      return client.get("/contentLibrary/category/".concat(slug, "/content?offset=").concat(offset, "&limit=").concat(limit));
    },
    slug: slug,
    offset: offset,
    limit: limit
  };
}
export function getSinglePost(postId) {
  return {
    types: [GET_SINGLE_POST, GET_SINGLE_POST_SUCCESS, GET_SINGLE_POST_FAIL],
    promise: function promise(client) {
      return client.get("/contentLibrary/post/".concat(postId));
    },
    postId: postId
  };
}