import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
var SET_INITIAL_STATE = 'likeablelocal/composebox/SET_INITIAL_STATE';
var UPLOAD_IMAGE = 'likeablelocal/composebox/UPLOAD_IMAGE';
var UPLOAD_IMAGE_SUCCESS = 'likeablelocal/composebox/UPLOAD_IMAGE_SUCCESS';
var UPLOAD_IMAGE_FAIL = 'likeablelocal/newContract/UPLOAD_IMAGE_FAIL';
var UPLOAD_VIDEO = 'likeablelocal/composebox/UPLOAD_VIDEO';
var UPLOAD_VIDEO_PROCESSING = 'likeablelocal/composebox/UPLOAD_VIDEO_PROCESSING';
var UPLOAD_VIDEO_SUCCESS = 'likeablelocal/composebox/UPLOAD_VIDEO_SUCCESS';
var UPLOAD_VIDEO_FAIL = 'likeablelocal/composebox/UPLOAD_VIDEO_FAIL';
var GET_EXISTING_POST_DATA = 'likeablelocal/composebox/GET_EXISTING_POST_DATA';
var GET_EXISTING_POST_DATA_SUCCESS = 'likeablelocal/composebox/GET_EXISTING_POST_DATA_SUCCESS';
var GET_EXISTING_POST_DATA_FAIL = 'likeablelocal/composebox/GET_EXISTING_POST_DATA_FAIL';
var SET_BACK_URL = 'likeablelocal/composebox/SET_BACK_URL';
var RESTORE = 'likeablelocal/RESTORE_STATE';
var initialState = {
  saveError: null,
  backUrls: {},
  uploadingImage: false,
  uploadingVideo: false,
  processingVideo: false,
  composeboxInitialState: false
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case SET_INITIAL_STATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        composeboxInitialState: _objectSpread(_objectSpread({}, state.composeboxInitialState), {}, _defineProperty({}, action.orgId, action.initialState))
      });

    case SET_BACK_URL:
      var backUrls = _objectSpread(_objectSpread({}, state.backUrls), {}, _defineProperty({}, action.orgId, action.url));

      return _objectSpread(_objectSpread({}, state), {}, {
        backUrls: backUrls
      });

    case UPLOAD_IMAGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadingImage: true,
        fileUploadError: null
      });

    case UPLOAD_IMAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadingImage: false,
        fileUploadError: null
      });

    case UPLOAD_IMAGE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadingImage: false,
        fileUploadError: action.error.error.message
      });

    case UPLOAD_VIDEO:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadingVideo: true,
        fileUploadError: null
      });

    case UPLOAD_VIDEO_PROCESSING:
      return _objectSpread(_objectSpread({}, state), {}, {
        processingVideo: true,
        fileUploadError: null
      });

    case UPLOAD_VIDEO_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadingVideo: false,
        fileUploadError: null
      });

    case UPLOAD_VIDEO_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        uploadingVideo: false,
        fileUploadError: action.error.error.message
      });

    case RESTORE:
      return _objectSpread(_objectSpread({}, state), action.savedState.composebox);

    case GET_EXISTING_POST_DATA_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        existingPost: action.result.data
      });

    default:
      return state;
  }
}
export function uploadImages(files) {
  return {
    types: [UPLOAD_IMAGE, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAIL],
    promise: function promise(client) {
      return client.post('/imageUpload', {
        files: files
      });
    }
  };
}
export function uploadVideo(files) {
  return {
    types: [UPLOAD_VIDEO, UPLOAD_VIDEO_SUCCESS, UPLOAD_VIDEO_FAIL],
    promise: function promise(client) {
      return client.post('/videoUpload', {
        files: files
      });
    }
  };
}
export function notifyWhenDone(notifySidWhenDone, file) {
  return {
    types: [UPLOAD_VIDEO_PROCESSING, UPLOAD_VIDEO_SUCCESS, UPLOAD_VIDEO_FAIL],
    promise: function promise(client) {
      return client.post("/registerForCallback/", {
        data: {
          file: file,
          SID: notifySidWhenDone
        }
      });
    }
  };
}
export function setBackUrl(url, orgId) {
  return {
    type: SET_BACK_URL,
    url: url,
    orgId: orgId
  };
}
export function getExistingPost(postId) {
  return {
    types: [GET_EXISTING_POST_DATA, GET_EXISTING_POST_DATA_SUCCESS, GET_EXISTING_POST_DATA_FAIL],
    promise: function promise(client) {
      return client.get("/post/".concat(postId));
    }
  };
}
export function setInitialState(initialState, orgId) {
  return {
    type: SET_INITIAL_STATE,
    orgId: orgId,
    initialState: initialState
  };
}