import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as _ from 'lodash';
var ns = 'likeablelocal/organization';
var CLEAR_FORM = "".concat(ns, "/CLEAR_FORM");
var CLEAR_ALL = "".concat(ns, "/CLEAR_ALL");
var STATE_ONLY_ARCHIVE_PRODUCT = "".concat(ns, "/STATE_ONLY_ARCHIVE_PRODUCT");
var STATE_ONLY_SAVE_PRODUCT = "".concat(ns, "/STATE_ONLY_SAVE_PRODUCT");
var SET_PRODUCTS = "".concat(ns, "/SET_PRODUCTS");
var ADD_PRODUCT = "".concat(ns, "/ADD_PRODUCT");
var EDIT_PRODUCT_FIELD = "".concat(ns, "/EDIT_PRODUCT_FIELD");
var ARCHIVE_PRODUCT = "".concat(ns, "/ARCHIVE_PRODUCT");
var ARCHIVE_PRODUCT_SUCCESS = "".concat(ns, "/ARCHIVE_PRODUCT_SUCCESS");
var ARCHIVE_PRODUCT_FAIL = "".concat(ns, "/ARCHIVE_PRODUCT_FAIL");
var POST_PRODUCT = "".concat(ns, "/POST_PRODUCT");
var POST_PRODUCT_SUCCESS = "".concat(ns, "/POST_PRODUCT_SUCCESS");
var POST_PRODUCT_FAIL = "".concat(ns, "/POST_PRODUCT_FAIL");
var GET_PRODUCTS = "".concat(ns, "/GET_PRODUCTS");
var GET_PRODUCTS_SUCCESS = "".concat(ns, "/GET_PRODUCTS_SUCCESS");
var GET_PRODUCTS_FAIL = "".concat(ns, "/GET_PRODUCTS_FAIL");
var initialState = {
  gettingProducts: false,
  agreementProducts: null,
  creatingProduct: false,
  archivingProduct: false,
  newProduct: null,
  invalidFields: [],
  productsByOrgId: {}
};
var initialProductState = {
  title: 'Title',
  description: 'Description',
  retailMonthlyPrice: 100,
  retailSetupFee: 0,
  adsBudget: 0,
  monthlyPriceApplicationFee: 50,
  setupFeeApplicationFee: 0,
  monthlySliderRatio: 0.5,
  setupSliderRatio: 1.0
};

function defaultKeyResolver(action) {
  return action.meta ? action.meta.controlInstanceKey : undefined;
}

export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _.cloneDeep(initialState);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case CLEAR_ALL:
      {
        return _.cloneDeep(initialState);
      }

    case CLEAR_FORM:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          creatingProduct: false,
          archivingProduct: false,
          invalidFields: [],
          newProduct: null
        });
      }

    case STATE_ONLY_SAVE_PRODUCT:
      {
        var tempProduct = _.cloneDeep(action.product);

        tempProduct.id = Date.now();
        tempProduct.retailMonthlyPrice = action.product.retailMonthlyPrice * 100;
        tempProduct.retailSetupFee = action.product.retailSetupFee * 100;
        tempProduct.adsBudget = action.product.adsBudget * 100;
        tempProduct.monthlyPriceApplicationFee = action.product.monthlyPriceApplicationFee * 100;
        tempProduct.setupFeeApplicationFee = action.product.setupFeeApplicationFee * 100;
        return _objectSpread(_objectSpread({}, state), {}, {
          agreementProducts: [].concat(_toConsumableArray(state.agreementProducts), [tempProduct])
        });
      }

    case STATE_ONLY_ARCHIVE_PRODUCT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          agreementProducts: state.agreementProducts.filter(function (p) {
            return p.id !== action.productId;
          })
        });
      }

    case SET_PRODUCTS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          agreementProducts: action.products
        });
      }

    case ADD_PRODUCT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          newProduct: _.cloneDeep(initialProductState)
        });
      }

    case ARCHIVE_PRODUCT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          archivingProduct: true
        });
      }

    case ARCHIVE_PRODUCT_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          archivingProduct: false,
          productsByOrgId: _objectSpread(_objectSpread({}, state.productsByOrgId), {}, _defineProperty({}, action.orgId, state.productsByOrgId[action.orgId].filter(function (p) {
            return p.id !== action.productId;
          })))
        });
      }

    case ARCHIVE_PRODUCT_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          archivingProduct: false
        });
      }

    case POST_PRODUCT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          creatingProduct: true
        });
      }

    case POST_PRODUCT_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          creatingProduct: false,
          productsByOrgId: _objectSpread(_objectSpread({}, state.productsByOrgId), {}, _defineProperty({}, action.orgId, [].concat(_toConsumableArray(state.productsByOrgId[action.orgId]), [action.result.data]))),
          newProduct: null
        });
      }

    case POST_PRODUCT_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          creatingProduct: false
        });
      }

    case GET_PRODUCTS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          gettingProducts: true
        });
      }

    case GET_PRODUCTS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          productsByOrgId: _objectSpread(_objectSpread({}, state.productsByOrgId), {}, _defineProperty({}, action.orgId, action.result.data)),
          gettingProducts: false
        });
      }

    case GET_PRODUCTS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          gettingProducts: false,
          affiliateProducts: null
        });
      }

    case EDIT_PRODUCT_FIELD:
      {
        var valid = !!action.value.length || action.value > 0;
        return _objectSpread(_objectSpread({}, state), {}, {
          newProduct: _objectSpread(_objectSpread({}, state.newProduct), {}, _defineProperty({}, action.fieldName, action.value)),
          invalidFields: valid ? state.invalidFields.filter(function (f) {
            return f !== action.fieldName;
          }) : [].concat(_toConsumableArray(state.invalidFields), [action.fieldName])
        });
      }

    default:
      return state;
  }
}
export function clear() {
  return {
    type: CLEAR_ALL
  };
}
export function clearForm() {
  return {
    type: CLEAR_FORM
  };
}
export function addProduct() {
  return {
    type: ADD_PRODUCT
  };
}
export function stateOnlySaveProduct(product) {
  return {
    type: STATE_ONLY_SAVE_PRODUCT,
    product: product
  };
}
export function stateOnlyArchiveProduct(productId) {
  return {
    type: STATE_ONLY_ARCHIVE_PRODUCT,
    productId: productId
  };
}
export function setProducts(products) {
  return {
    type: SET_PRODUCTS,
    products: products
  };
}
export function editProductField(fieldName, value) {
  return {
    type: EDIT_PRODUCT_FIELD,
    fieldName: fieldName,
    value: value
  };
}
export function getProducts(orgId) {
  return {
    types: [GET_PRODUCTS, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/affiliateProducts"));
    },
    orgId: orgId
  };
}
export function saveProduct(product, orgId) {
  return {
    types: [POST_PRODUCT, POST_PRODUCT_SUCCESS, POST_PRODUCT_FAIL],
    promise: function promise(client) {
      return client.post("org/".concat(orgId, "/affiliateProduct"), {
        data: {
          title: product.title,
          description: product.description,
          retailMonthlyPrice: product.retailMonthlyPrice * 100.0,
          retailSetupFee: product.retailSetupFee * 100.0,
          adsBudget: product.adsBudget * 100.0,
          monthlyPriceApplicationFee: product.monthlyPriceApplicationFee * 100.0,
          setupFeeApplicationFee: product.setupFeeApplicationFee * 100.0
        }
      });
    },
    product: product,
    orgId: orgId
  };
}
export function archiveProduct(productId, orgId) {
  return {
    types: [ARCHIVE_PRODUCT, ARCHIVE_PRODUCT_SUCCESS, ARCHIVE_PRODUCT_FAIL],
    promise: function promise(client) {
      return client.del("/org/".concat(orgId, "/affiliateProduct/").concat(productId));
    },
    productId: productId,
    orgId: orgId
  };
}