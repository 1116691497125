module.exports = (function() {
  const configurations = {
    local: {
      stripePublicKey: 'pk_test_PsG3o8ODa9yZqPogQVdHsoFw'
    },
    rc: {
      stripePublicKey: 'pk_test_PsG3o8ODa9yZqPogQVdHsoFw',
      features: {
        showQueue: true,
        showTwitter: true,
        showInstagram: false,
        showLinkedIn: true,
        dev: true
      }
    },
    development: {
      stripePublicKey: 'pk_test_PsG3o8ODa9yZqPogQVdHsoFw',
      features: {
        showQueue: true,
        showTwitter: true,
        showInstagram: false,
        showLinkedIn: true,
        dev: true
      }
    },
    staging: {
      stripePublicKey: 'pk_test_PsG3o8ODa9yZqPogQVdHsoFw',
      features: {
        showQueue: true,
        showTwitter: true,
        showInstagram: false,
        showLinkedIn: true,
        staging: true
      }
    },
    production: {
      stripePublicKey: 'pk_live_OHMQnMoRHepmGRu4oULFZ7G1',
      features: {
        showQueue: true,
        showTwitter: true,
        showInstagram: false,
        showLinkedIn: true,
        production: true
      }
    }
  };

  const config = configurations.development;
  return config;
}());
