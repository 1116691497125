export default (function (language) {
  var strings = {
    en: {
      emailLabel: 'Email',
      passwordLabel: 'Password',
      persistLoginLabel: 'Keep me logged in'
    }
  };
  var usingLanguage = Object.keys(strings).indexOf(language) === -1 ? 'en' : language;
  return strings[usingLanguage];
});