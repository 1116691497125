import moment from 'moment';

var getUrls = require('get-urls');

function getGroupPostSocialAccounts(post) {
  var sa = {};

  if (post.GroupPostOrganizations && !!post.GroupPostOrganizations.length) {
    post.GroupPostOrganizations.forEach(function (org) {
      if (org.GroupPostOrganizationSocialPlatforms && !!org.GroupPostOrganizationSocialPlatforms.length) {
        sa[org.OrganizationId] = {};
        sa[org.OrganizationId].unlinked = org.unlinked;
        org.GroupPostOrganizationSocialPlatforms.forEach(function (platform) {
          var key = _.camelCase(platform.platform);

          sa[org.OrganizationId][key] = true;
        });
      }
    });
  }

  return sa;
}

export default function convertGroupPost(post) {
  var isNew = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var linkData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var postType = 'textPost'; // posts from schedule view come here

  console.dir(post);

  if (post.videoUrl) {
    postType = 'videoPost';
  } else if (post.linkUrl && post.linkTitle) {
    postType = 'linkPost';
  } else if (post.S3Files.length) {
    if (post.S3Files.length === 1 && post.S3Files[0].fullyQualifiedUrl.indexOf('.mp4') !== -1) {
      postType = 'videoPost';
      post.videoUrl = post.S3Files[0].fullyQualifiedUrl;
    } else {
      postType = 'imagePost';
    }
  }

  console.log('in convert helper');
  console.log(post);
  var selectedAccounts = getGroupPostSocialAccounts(post);
  var messages = {
    global: post.message,

    /*    facebook: post.SocialAccountPosts.filter(p => p.SocialAccount && p.SocialAccount.platform.indexOf('Facebook') === 0)
      .length
      ? post.SocialAccountPosts.filter(p => p.SocialAccount && p.SocialAccount.platform.indexOf('Facebook') === 0)[0]
          .message
      : undefined,
    twitter: post.SocialAccountPosts.filter(p => p.SocialAccount && p.SocialAccount.platform.indexOf('Twitter') === 0)
      .length
      ? post.SocialAccountPosts.filter(p => p.SocialAccount && p.SocialAccount.platform.indexOf('Twitter') === 0)[0]
          .message
      : undefined,
    linkedin: post.SocialAccountPosts.filter(p => p.SocialAccount && p.SocialAccount.platform.indexOf('LinkedIn') === 0)
      .length
      ? post.SocialAccountPosts.filter(p => p.SocialAccount && p.SocialAccount.platform.indexOf('LinkedIn') === 0)[0]
          .message
      : undefined
      */
    facebook: null,
    twitter: null,
    linkedin: null
  };
  console.log("this post's publish at is ".concat(post.publishAt));
  var initialState = {
    existingPostId: isNew === false ? post.id : undefined,
    images: post.S3Files.map(function (file) {
      return file.fullyQualifiedUrl;
    }),
    linkData: {
      caption: linkData && linkData.siteName ? linkData.siteName : null,
      description: linkData && linkData.description ? linkData.description : null,
      image: linkData && linkData.imageUrl ? linkData.imageUrl : null,
      title: linkData && linkData.title ? linkData.title : null,
      link: post.linkUrl
    },
    messages: messages,
    postType: postType,
    publishAt: isNew ? new Date().getTime() : moment.utc(post.publishAt).valueOf(),
    selectedAccounts: selectedAccounts,
    videoUrl: post.videoUrl
  };
  console.log('converted Post will be:');
  console.log(initialState);
  return initialState;
}