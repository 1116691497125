import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import ReactGA from 'react-ga';
import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE, LOAD_SUCCESS } from './auth';

var clientConfig = require('../../../config/clientConfig');

var LOAD_USER_TYPE = 'likeablelocal/config/LOAD_USER_TYPE';
var LOAD_USER_TYPE_SUCCESS = 'likeablelocal/config/LOAD_USER_TYPE_SUCCESS';
var LOAD_USER_TYPE_FAIL = 'likeablelocal/config/LOAD_USER_TYPE_FAIL';
var SET_USER_TYPE_FROM_LOGIN = 'likeablelocal/config/SET_USER_TYPE_FROM_LOGIN';
var LOAD_STRIPE_CONFIG = 'likeablelocal/config/LOAD_STRIPE_CONFIG';
var LOAD_STRIPE_CONFIG_SUCCESS = 'likeablelocal/config/LOAD_STRIPE_CONFIG_SUCCESS';
var LOAD_STRIPE_CONFIG_FAIL = 'likeablelocal/config/LOAD_STRIPE_CONFIG_FAIL';
var LOAD_WHITELABEL = 'likeablelocal/config/LOAD_WHITELABEL';
export var LOAD_WHITELABEL_SUCCESS = 'likeablelocal/config/LOAD_WHITELABEL_SUCCESS';
var LOAD_WHITELABEL_FAIL = 'likeablelocal/config/LOAD_WHITELABEL_FAIL';
var LOAD_ENV = 'likeablelocal/config/LOAD_ENV';
var LOAD_ENV_SUCCESS = 'likeablelocal/config/LOAD_ENV_SUCCESS';
var LOAD_ENV_FAIL = 'likeablelocal/config/LOAD_ENV_FAIL';
var LOAD_WEBAGREEMENT = 'likeablelocal/config/LOAD_WEBAGREEMENT';
var LOAD_WEBAGREEMENT_SUCCESS = 'likeablelocal/config/LOAD_WEBAGREEMENT_SUCCESS';
var LOAD_WEBAGREEMENT_FAIL = 'likeablelocal/config/LOAD_WEBAGREEMENT_FAIL';
var LOAD_TIMEZONE = 'likeablelocal/config/LOAD_TIMEZONE';
var LOAD_TIMEZONE_SUCCESS = 'likeablelocal/config/LOAD_TIMEZONE_SUCCESS';
var LOAD_TIMEZONE_FAIL = 'likeablelocal/config/LOAD_TIMEZONE_FAIL';
var initialState = {
  userType: {
    isPartner: false,
    isLikeableUser: false
  }
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return _objectSpread(_objectSpread({}, initialState), {}, {
        version: 2
      });

    case SET_USER_TYPE_FROM_LOGIN:
      return _objectSpread(_objectSpread({}, state), {}, {
        userType: {
          isPartner: action.data.isPartner,
          isLikeableUser: action.data.isLikeableLocal
        }
      });

    case LOAD_USER_TYPE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        userType: {
          isPartner: action.result.data.isPartner,
          isLikeableUser: action.result.data.isLikeableLocal,
          likeablelocalAllowedWhitelabel: action.result.data.likeablelocalAllowedWhitelabel
        }
      });

    case LOAD_STRIPE_CONFIG_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        stripePublicKey: action.result.data,
        features: clientConfig.features
      });

    case LOAD_STRIPE_CONFIG_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        stripePublicKey: clientConfig.stripePublicKey,
        features: clientConfig.features
      });

    case LOAD_WHITELABEL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        whitelabelSettings: action.result.data
      });

    case LOAD_ENV_SUCCESS:
      ReactGA.set({
        appEnv: action.result.data
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        appEnv: action.result.data
      });

    case LOAD_WEBAGREEMENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        webagreement: _objectSpread(_objectSpread({}, state.webagreement), {}, _defineProperty({}, action.result.data.type, action.result.data))
      });

    case LOAD_TIMEZONE_SUCCESS:
      var timezones = action.result.data.timezones.filter(function (t) {
        return t.name.includes('US/') || t.name.includes('Canada/');
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        timezones: timezones
      });

    case LOAD_SUCCESS:
      ReactGA.set({
        userId: "".concat(state.appEnv, "_").concat(action.result.user.id)
      });
      return state;

    default:
      return state;
  }
}
export function loadWebagreement(type) {
  return {
    types: [LOAD_WEBAGREEMENT, LOAD_WEBAGREEMENT_SUCCESS, LOAD_WEBAGREEMENT_FAIL],
    promise: function promise(client) {
      return client.get("/webAgreement?type=".concat(type));
    }
  };
}
export function loadStripeConfig() {
  return {
    types: [LOAD_STRIPE_CONFIG, LOAD_STRIPE_CONFIG_SUCCESS, LOAD_STRIPE_CONFIG_FAIL],
    promise: function promise(client) {
      return client.get('/stripeConfig');
    }
  };
}
export function loadAppEnv() {
  return {
    types: [LOAD_ENV, LOAD_ENV_SUCCESS, LOAD_ENV_FAIL],
    promise: function promise(client) {
      return client.get('/appEnv');
    }
  };
}
export function loadUserType() {
  return {
    types: [LOAD_USER_TYPE, LOAD_USER_TYPE_SUCCESS, LOAD_USER_TYPE_FAIL],
    promise: function promise(client) {
      return client.get('/userType');
    }
  };
}
export function loadUserTypeFromLogin(userTypeInfo) {
  return {
    type: SET_USER_TYPE_FROM_LOGIN,
    data: userTypeInfo
  };
}
export function loadWhitelabel(orgId) {
  return {
    types: [LOAD_WHITELABEL, LOAD_WHITELABEL_SUCCESS, LOAD_WHITELABEL_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/whiteLabel"));
    }
  };
}
export function loadTimezone(orgId) {
  return {
    types: [LOAD_TIMEZONE, LOAD_TIMEZONE_SUCCESS, LOAD_TIMEZONE_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/timezones"));
    }
  };
}

function currentDomain() {
  var currentDomain = window.location.host;
  var domain = currentDomain;

  if (domain.indexOf(':') !== -1) {
    domain = domain.split(':')[0];
  }

  return domain;
}