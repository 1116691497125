import memoize from 'lru-memoize';
import { createValidator, required, maxLength, email } from 'utils/validation';
var billingValidation = createValidator({
  name: [required, maxLength(255)],
  address1: [required, maxLength(255)],
  address2: [maxLength(255)],
  locality: [required, maxLength(255)],
  region: [required, maxLength(255)],
  postcode: [required, maxLength(20)],
  number: [required, maxLength(30)],
  expMonth: [required, maxLength(2)],
  expYear: [required, maxLength(2)],
  cvc: [required, maxLength(10)]
});
export default memoize(10)(billingValidation);