import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as _ from 'lodash';
import moment from 'moment';
var ns = 'likeablelocal/draftPosts';
var INIT_ORG = "".concat(ns, "/INIT_ORG");
var LOGGED_OUT_DRAFT_APPROVAL = "".concat(ns, "/LOGGED_OUT_DRAFT_APPROVAL");
var LOGGED_OUT_DRAFT_APPROVAL_SUCCESS = "".concat(ns, "/LOGGED_OUT_DRAFT_APPROVAL_SUCCESS");
var LOGGED_OUT_DRAFT_APPROVAL_FAIL = "".concat(ns, "/LOGGED_OUT_DRAFT_APPROVAL_FAIL");
var DELETE_POST = "".concat(ns, "/DELETE_POST");
var DELETE_POST_SUCCESS = "".concat(ns, "/DELETE_POST_SUCCESS");
var DELETE_POST_FAIL = "".concat(ns, "/DELETE_POST_FAIL");
var REQUEST_BULK_DRAFT_APPROVAL = "".concat(ns, "/REQUEST_BULK_DRAFT_APPROVAL");
var REQUEST_BULK_DRAFT_APPROVAL_SUCCESS = "".concat(ns, "/REQUEST_BULK_DRAFT_APPROVAL_SUCCESS");
var REQUEST_BULK_DRAFT_APPROVAL_FAIL = "".concat(ns, "/REQUEST_BULK_DRAFT_APPROVAL_FAIL");
var REQUEST_DRAFT_APPROVAL = "".concat(ns, "/REQUEST_DRAFT_APPROVAL");
var REQUEST_DRAFT_APPROVAL_SUCCESS = "".concat(ns, "/REQUEST_DRAFT_APPROVAL_SUCCESS");
var REQUEST_DRAFT_APPROVAL_FAIL = "".concat(ns, "/REQUEST_DRAFT_APPROVAL_FAIL");
var APPROVE_DRAFT = "".concat(ns, "/APPROVE_DRAFT");
var APPROVE_DRAFT_SUCCESS = "".concat(ns, "/APPROVE_DRAFT_SUCCESS");
var APPROVE_DRAFT_FAIL = "".concat(ns, "/APPROVE_DRAFT_FAIL");
var GET_DRAFT_POSTS = "".concat(ns, "/GET_DRAFT_POSTS");
var GET_DRAFT_POSTS_SUCCESS = "".concat(ns, "/GET_DRAFT_POSTS_SUCCESS");
var GET_DRAFT_POSTS_FAIL = "".concat(ns, "/GET_DRAFT_POSTS_FAIL");
var VALIDATE_APPROVAL_TOKEN = "".concat(ns, "/VALIDATE_APPROVAL_TOKEN_POSTS");
var VALIDATE_APPROVAL_TOKEN_SUCCESS = "".concat(ns, "/VALIDATE_APPROVAL_TOKEN_SUCCESS");
var VALIDATE_APPROVAL_TOKEN_FAIL = "".concat(ns, "/VALIDATE_APPROVAL_TOKEN_FAIL");
var initialState = {};
var orgInitialState = {
  deletingPost: false,
  gettingDraftPosts: false,
  posts: [],
  approvingDraft: false,
  requestingApproval: false,
  loggedOutDraftApprovalInProgress: false
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _.cloneDeep(initialState);
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case INIT_ORG:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _.cloneDeep(orgInitialState)));

    case DELETE_POST:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        deletingPost: true,
        posts: state[action.orgId].posts.filter(function (p) {
          return parseInt(p.id) !== parseInt(action.postId);
        })
      })));

    case REQUEST_DRAFT_APPROVAL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        requestingApproval: true,
        posts: state[action.orgId].posts.map(function (p) {
          if (parseInt(p.id) === parseInt(action.postId)) {
            p.DraftPost.requestedApprovalAt = new Date().getTime();
          }

          return p;
        })
      })));

    case REQUEST_DRAFT_APPROVAL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        requestingApproval: false
      })));

    case REQUEST_DRAFT_APPROVAL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        requestingApproval: false
      })));

    case REQUEST_BULK_DRAFT_APPROVAL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        requestingApproval: true,
        posts: state[action.orgId].posts.map(function (p) {
          if (action.postIdList.includes(p.id)) {
            p.DraftPost.requestedApprovalAt = new Date().getTime();
          }

          return p;
        })
      })));

    case REQUEST_BULK_DRAFT_APPROVAL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        requestingApproval: false
      })));

    case REQUEST_BULK_DRAFT_APPROVAL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        requestingApproval: false
      })));

    case APPROVE_DRAFT:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        approvingDraft: true,
        posts: state[action.orgId].posts.map(function (p) {
          if (parseInt(p.id) === parseInt(action.postId)) {
            p.DraftPost.approvedAt = action.approved ? new Date().getTime() : null;
            p.DraftPost.requestedApprovalAt = p.DraftPost.requestedApprovalAt && action.approved ? p.DraftPost.requestedApprovalAt : new Date().getTime();
          }

          return p;
        })
      })));

    case APPROVE_DRAFT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        approvingDraft: false
      })));

    case APPROVE_DRAFT_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        approvingDraft: false
      })));

    case LOGGED_OUT_DRAFT_APPROVAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggedOutDraftApprovalInProgress: true
      });

    case LOGGED_OUT_DRAFT_APPROVAL_SUCCESS:
      var posts = state.posts.map(function (p) {
        if (parseInt(p.id) === parseInt(action.result.data.id)) {
          var DraftPost = _objectSpread(_objectSpread({}, p.DraftPost), action.result.data.DraftPost);

          var post = _objectSpread(_objectSpread({}, action.result.data), {}, {
            DraftPost: DraftPost
          });

          return _objectSpread(_objectSpread({}, p), post);
        }

        return p;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        loggedOutDraftApprovalInProgress: false,
        posts: posts
      });

    case LOGGED_OUT_DRAFT_APPROVAL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loggedOutDraftApprovalInProgress: false
      });

    case GET_DRAFT_POSTS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingDraftPosts: true
      })));

    case GET_DRAFT_POSTS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingDraftPosts: false,
        posts: action.result.data
      })));

    case GET_DRAFT_POSTS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        gettingDraftPosts: false
      })));

    case VALIDATE_APPROVAL_TOKEN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        posts: action.result.data.posts
      });

    default:
      return state;
  }
}
export function validateApprovalToken(token) {
  return {
    types: [VALIDATE_APPROVAL_TOKEN, VALIDATE_APPROVAL_TOKEN_SUCCESS, VALIDATE_APPROVAL_TOKEN_FAIL],
    promise: function promise(client) {
      return client.get("/post/approval/validateToken?token=".concat(token));
    },
    token: token
  };
}
export function initOrgDrafts(orgId) {
  return {
    type: INIT_ORG,
    orgId: orgId
  };
}
export function getDraftPosts(orgId) {
  return {
    types: [GET_DRAFT_POSTS, GET_DRAFT_POSTS_SUCCESS, GET_DRAFT_POSTS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/posts/drafts"));
    },
    orgId: orgId
  };
}
export function approveDraftPost(orgId, postId) {
  var approved = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return {
    types: [APPROVE_DRAFT, APPROVE_DRAFT_SUCCESS, APPROVE_DRAFT_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/posts/draft/").concat(postId, "/approval"), {
        data: {
          approved: approved
        }
      });
    },
    orgId: orgId,
    postId: postId,
    approved: approved
  };
}
export function loggedOutDraftApproval(token, postId) {
  return {
    types: [LOGGED_OUT_DRAFT_APPROVAL, LOGGED_OUT_DRAFT_APPROVAL_SUCCESS, LOGGED_OUT_DRAFT_APPROVAL_FAIL],
    promise: function promise(client) {
      return client.post('/posts/draft/approve', {
        data: {
          token: token,
          postId: postId
        }
      });
    },
    token: token,
    postId: postId
  };
}
export function requestDraftApproval(orgId, postId, approverUserId, emailList, feedbackEmailList) {
  return {
    types: [REQUEST_DRAFT_APPROVAL, REQUEST_DRAFT_APPROVAL_SUCCESS, REQUEST_DRAFT_APPROVAL_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/posts/draft/requestApproval"), {
        data: {
          emailList: emailList,
          requestedApprover: parseInt(approverUserId, 10),
          postIds: [postId],
          feedbackEmailList: feedbackEmailList
        }
      });
    },
    orgId: orgId,
    postId: postId
  };
}
export function requestBulkDraftApproval(orgId, postIdList, approverUserId, emailList, feedbackEmailList) {
  return {
    types: [REQUEST_BULK_DRAFT_APPROVAL, REQUEST_BULK_DRAFT_APPROVAL_SUCCESS, REQUEST_BULK_DRAFT_APPROVAL_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/posts/draft/requestApproval"), {
        data: {
          emailList: emailList,
          requestedApprover: parseInt(approverUserId, 10),
          postIds: postIdList,
          feedbackEmailList: feedbackEmailList
        }
      });
    },
    orgId: orgId,
    postIdList: postIdList
  };
}
export function deletePost(orgId, postId) {
  return {
    types: [DELETE_POST, DELETE_POST_SUCCESS, DELETE_POST_FAIL],
    promise: function promise(client) {
      return client.del("/organization/".concat(orgId, "/post/").concat(postId));
    },
    orgId: orgId,
    postId: postId
  };
}