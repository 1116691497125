module.exports = {
  e: {},
  publish: function publish(name, data) {
    (this.e[name] = this.e[name] || new Event(name)).data = data;
    dispatchEvent(this.e[name]);
  },
  subscribe: function subscribe(name, handler, context) {
    addEventListener(name, handler.bind(context));
  },
  unsubscribe: function unsubscribe(name, handler, context) {
    removeEventListener(name, handler.bind(context));
  }
};