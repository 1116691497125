import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var USE_PREV_SEARCH = 'likeablelocal/contentLibrary/USE_PREV_SEARCH';
var CLEAR_CURRENT_SEARCH = 'likeablelocal/contentLibrary/CLEAR_CURRENT_SEARCH';
var UPDATE_SEARCH_QUERY = 'likeablelocal/contentLibrary/UPDATE_SEARCH_QUERY';
var CONTENT_SEARCH = 'likeablelocal/contentLibrary/CONTENT_SEARCH';
var CONTENT_SEARCH_SUCCESS = 'likeablelocal/contentLibrary/CONTENT_SEARCH_SUCCESS';
var CONTENT_SEARCH_FAIL = 'likeablelocal/contentLibrary/CONTENT_SEARCH_FAIL';
var initialState = {
  searching: false,
  searchResults: {},
  lastQueryIn: ''
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case UPDATE_SEARCH_QUERY:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchResults: _objectSpread(_objectSpread({}, state.searchResults), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state.searchResults[action.orgId]), {}, {
          currentQuery: action.query
        })))
      });

    case USE_PREV_SEARCH:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchResults: _objectSpread(_objectSpread({}, state.searchResults), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state.searchResults[action.orgId]), {}, {
          currentQuery: state.searchResults[action.orgId].prevQuery,
          currentResults: state.searchResults[action.orgId].prevResults
        })))
      });

    case CLEAR_CURRENT_SEARCH:
      return _objectSpread(_objectSpread({}, state), {}, {
        searchResults: _objectSpread(_objectSpread({}, state.searchResults), {}, _defineProperty({}, action.orgId, {
          prevQuery: state.searchResults[action.orgId] && state.searchResults[action.orgId].currentResults ? state.searchResults[action.orgId].currentQuery : null,
          currentQuery: '',
          prevResults: state.searchResults[action.orgId] && state.searchResults[action.orgId].currentResults ? state.searchResults[action.orgId].currentResults : null,
          currentResults: null
        }))
      });

    case CONTENT_SEARCH:
      return _objectSpread(_objectSpread({}, state), {}, {
        searching: true,
        lastQueryIn: action.query,
        searchResults: _objectSpread(_objectSpread({}, state.searchResults), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state.searchResults[action.orgId]), {}, {
          currentResults: null
        })))
      });

    case CONTENT_SEARCH_SUCCESS:
      if (state.lastQueryIn === action.query) {
        return _objectSpread(_objectSpread({}, state), {}, {
          searching: false,
          searchResults: _objectSpread(_objectSpread({}, state.searchResults), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state.searchResults[action.orgId]), {}, {
            currentResults: action.result.data
          })))
        });
      }

    case CONTENT_SEARCH_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        searching: false
      });

    default:
      return state;
  }
}
export function searchContent(orgId, query) {
  return {
    types: [CONTENT_SEARCH, CONTENT_SEARCH_SUCCESS, CONTENT_SEARCH_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/contentLibrary/search?query=").concat(query));
    },
    orgId: orgId,
    query: query
  };
}
export function clearCurrentSearch(orgId) {
  return {
    type: CLEAR_CURRENT_SEARCH,
    orgId: orgId
  };
}
export function updateSearchQuery(orgId, query) {
  return {
    type: UPDATE_SEARCH_QUERY,
    orgId: orgId,
    query: query
  };
}
export function usePrevSearch(orgId) {
  return {
    type: USE_PREV_SEARCH,
    orgId: orgId
  };
}