import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var PARSE_LINK = 'likeablelocal/theme/PARSE_LINK';
var PARSE_LINK_SUCCESS = 'likeablelocal/theme/PARSE_LINK_SUCCESS';
var PARSE_LINK_FAIL = 'likeablelocal/theme/PARSE_LINK_FAIL';
var initialState = {};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case PARSE_LINK:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.link, {
        parsing: true
      }));

    case PARSE_LINK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.link, _objectSpread(_objectSpread({}, action.result.data), {}, {
        parsing: false
      })));

    case PARSE_LINK_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.link, {
        parsing: false
      }));

    default:
      return state;
  }
}
export function parseLink(link) {
  return {
    types: [PARSE_LINK, PARSE_LINK_SUCCESS, PARSE_LINK_FAIL],
    promise: function promise(client) {
      return client.post('/parseLink', {
        data: {
          link: link
        }
      });
    },
    link: link
  };
}