import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
var TOGGLE_LEFT_NAV_SECTION = 'likeablelocal/contentLibrary/TOGGLE_LEFT_NAV_SECTION';
var SUBMIT_ACCOUNT_LINK = 'likeablelocal/contentLibrary/SUBMIT_ACCOUNT_LINK';
export var SUBMIT_ACCOUNT_LINK_SUCCESS = 'likeablelocal/contentLibrary/SUBMIT_ACCOUNT_LINK_SUCCESS';
var SUBMIT_ACCOUNT_LINK_FAIL = 'likeablelocal/contentLibrary/SUBMIT_ACCOUNT_LINK_FAIL';
var DELETE_ACCOUNT_LINK = 'likeablelocal/contentLibrary/DELETE_ACCOUNT_LINK';
var DELETE_ACCOUNT_LINK_SUCCESS = 'likeablelocal/contentLibrary/DELETE_ACCOUNT_LINK_SUCCESS';
var DELETE_ACCOUNT_LINK_FAIL = 'likeablelocal/contentLibrary/DELETE_ACCOUNT_LINK_FAIL';
var ENQUEUE_POST = 'likeablelocal/contentLibrary/ENQUEUE_POST';
export var ENQUEUE_POST_SUCCESS = 'likeablelocal/contentLibrary/ENQUEUE_POST_SUCCESS';
var ENQUEUE_POST_FAIL = 'likeablelocal/contentLibrary/ENQUEUE_POST_FAIL';
var GET_FAVORITES = 'likeablelocal/contentLibrary/GET_FAVORITES';
var GET_FAVORITES_SUCCESS = 'likeablelocal/contentLibrary/GET_FAVORITES_SUCCESS';
var GET_FAVORITES_FAIL = 'likeablelocal/contentLibrary/GET_FAVORITES_FAIL';
var FAVORITE_CHANNEL = 'likeablelocal/contentLibrary/FAVORITE_CHANNEL';
var FAVORITE_CHANNEL_SUCCESS = 'likeablelocal/contentLibrary/FAVORITE_CHANNEL_SUCCESS';
var FAVORITE_CHANNEL_FAIL = 'likeablelocal/contentLibrary/FAVORITE_CHANNEL_FAIL';
var UNFAVORITE_CHANNEL = 'likeablelocal/contentLibrary/UNFAVORITE_CHANNEL';
var UNFAVORITE_CHANNEL_SUCCESS = 'likeablelocal/contentLibrary/UNFAVORITE_CHANNEL_SUCCESS';
var UNFAVORITE_CHANNEL_FAIL = 'likeablelocal/contentLibrary/UNFAVORITE_CHANNEL_FAIL';
var SET_LEFT_NAV_SCROLLTOP = 'likeablelocal/contentLibrary/SET_LEFT_NAV_SCROLLTOP';
var initialState = {
  addingPostToQueue: false,
  addingSAInProgress: false,
  deletingAccountLink: false,
  loadingFavorites: false,
  searching: false,
  searchResults: {}
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case DELETE_ACCOUNT_LINK:
      var favorites2 = state[action.orgId].favorites.map(function (f) {
        f.Queues = f.Queues.filter(function (q) {
          return q.id !== action.queue.id;
        });
        f.OtherQueues = [action.queue].concat(_toConsumableArray(f.OtherQueues));
        return f;
      });
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({
        deletingAccountLink: true
      }, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        favorites: favorites2
      })));

    case DELETE_ACCOUNT_LINK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        deletingAccountLink: false
      });

    case DELETE_ACCOUNT_LINK_FAIL:
      if (window.confirm('There was an error removing that category from your queue. Please try again. We need to reload the page first - be right back!')) {
        window.location.reload();
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        deletingAccountLink: false
      });

    case SUBMIT_ACCOUNT_LINK:
      var favorites = state[action.orgId].favorites.map(function (f) {
        f.OtherQueues = f.OtherQueues.filter(function (oq) {
          return !action.queues.includes(oq);
        });
        f.Queues = [].concat(_toConsumableArray(action.queues), _toConsumableArray(f.Queues));
        return f;
      });
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({
        addingSAInProgress: true
      }, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        favorites: favorites
      })));

    case SUBMIT_ACCOUNT_LINK_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        addingSAInProgress: false
      });

    case SUBMIT_ACCOUNT_LINK_FAIL:
      if (window.confirm('There was an error adding to that queue. Please try again. We need to reload the page first - be right back!')) {
        window.location.reload();
      }

      return _objectSpread(_objectSpread({}, state), {}, {
        addingSAInProgress: false
      });

    case ENQUEUE_POST:
      return _objectSpread(_objectSpread({}, state), {}, {
        addingPostToQueue: true
      });

    case ENQUEUE_POST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        addingPostToQueue: false
      });

    case ENQUEUE_POST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        addingPostToQueue: false
      });

    case TOGGLE_LEFT_NAV_SECTION:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        leftNavSectionsOpen: _objectSpread(_objectSpread({}, state[action.orgId].leftNavSectionsOpen), {}, _defineProperty({}, action.section, action.open))
      })));

    case FAVORITE_CHANNEL:
      var newCategory = _objectSpread(_objectSpread({}, action.category), {}, {
        Queues: [],
        OtherQueues: action.queues
      });

      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        favorites: [].concat(_toConsumableArray(state[action.orgId].favorites), [newCategory])
      })));

    case FAVORITE_CHANNEL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        favorites: state[action.orgId].favorites.filter(function (f) {
          return f.id === action.category.id;
        })
      })));

    case UNFAVORITE_CHANNEL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId]), {}, {
        favorites: state[action.orgId].favorites.filter(function (f) {
          return f.id !== action.tagId;
        })
      })));

    case UNFAVORITE_CHANNEL_FAIL:
      window.location.reload();
      return _objectSpread(_objectSpread({}, state), {}, {
        unfavoriting: true
      });

    case GET_FAVORITES:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingFavorites: true
      });

    case GET_FAVORITES_SUCCESS:
      var orderedFavs = _.sortBy(action.result.data, function (fav) {
        return fav.displayName;
      });

      var filteredFavorites = action.result.data.favoriteCategories.map(function (f) {
        f.Queues = f.Queues.filter(function (q) {
          return q.SocialAccounts && !!q.SocialAccounts.length && q.type !== 'Facebook profile';
        });
        f.OtherQueues = f.OtherQueues.filter(function (q) {
          return q.SocialAccounts && !!q.SocialAccounts.length && q.type !== 'Facebook profile';
        });
        return f;
      });
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({
        loadingFavorites: false
      }, action.orgId, {
        favorites: filteredFavorites,
        recommended: action.result.data.recommendedCategories,
        leftNavSectionsOpen: state[action.orgId] && state[action.orgId].leftNavSectionsOpen ? state[action.orgId].leftNavSectionsOpen : action.result.data.favoriteCategories.length ? {
          favorites: true,
          recommended: true,
          other: false
        } : {
          favorites: true,
          recommended: true,
          other: true
        }
      }));

    case GET_FAVORITES_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingFavorites: false
      });

    case SET_LEFT_NAV_SCROLLTOP:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.orgId, _objectSpread(_objectSpread({}, state[action.orgId] ? state[action.orgId] : {}), {}, {
        leftScrollTop: action.val
      })));

    default:
      return state;
  }
}
export function setLeftNavScrollTop(val, orgId) {
  return {
    type: SET_LEFT_NAV_SCROLLTOP,
    val: val,
    orgId: orgId
  };
}
export function getFavorites(orgId) {
  return {
    types: [GET_FAVORITES, GET_FAVORITES_SUCCESS, GET_FAVORITES_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/contentLibrary/favorites"));
    },
    orgId: orgId
  };
}
export function favoriteChannel(tagId, orgId, category, queues) {
  return {
    types: [FAVORITE_CHANNEL, FAVORITE_CHANNEL_SUCCESS, FAVORITE_CHANNEL_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/channel/favorite/").concat(tagId), {
        data: {
          tagId: tagId
        }
      });
    },
    orgId: orgId,
    tagId: tagId,
    category: category,
    queues: queues
  };
}
export function unFavoriteChannel(tagId, orgId) {
  return {
    types: [UNFAVORITE_CHANNEL, UNFAVORITE_CHANNEL_SUCCESS, UNFAVORITE_CHANNEL_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/channel/unfavorite/").concat(tagId), {
        data: {
          tagId: tagId
        }
      });
    },
    orgId: orgId,
    tagId: tagId
  };
}
export function toggleLeftNavSection(orgId, section, open) {
  return {
    type: TOGGLE_LEFT_NAV_SECTION,
    section: section,
    open: open,
    orgId: orgId
  };
}
export function enqueuePost(orgId, postId, queueId) {
  return {
    types: [ENQUEUE_POST, ENQUEUE_POST_SUCCESS, ENQUEUE_POST_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/addPostToQueue"), {
        data: {
          postTemplateId: postId,
          queueId: queueId
        }
      });
    },
    orgId: orgId
  };
}
export function subscribeAccountsToTag(orgId, socialAccountIds, tagId, queues) {
  var data = {
    posttemplatetagid: tagId,
    socialaccountids: socialAccountIds
  };
  return {
    types: [SUBMIT_ACCOUNT_LINK, SUBMIT_ACCOUNT_LINK_SUCCESS, SUBMIT_ACCOUNT_LINK_FAIL],
    data: data,
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/channel"), {
        data: data
      });
    },
    orgId: orgId,
    tagId: tagId,
    socialAccountIds: socialAccountIds,
    queues: queues
  };
}
export function deleteAccountFromTag(orgId, socialAccountIds, tagId, queue) {
  return {
    types: [DELETE_ACCOUNT_LINK, DELETE_ACCOUNT_LINK_SUCCESS, DELETE_ACCOUNT_LINK_FAIL],
    promise: function promise(client) {
      return client.del("/org/".concat(orgId, "/channel/").concat(tagId), {
        data: {
          socialAccountIds: socialAccountIds
        }
      });
    },
    orgId: orgId,
    queue: queue
  };
}