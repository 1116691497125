module.exports = {
  hexToRGB: function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(alpha, ")");
    }

    return "rgb(".concat(r, ", ").concat(g, ", ").concat(b, ")");
  },
  formatCurrency: function formatCurrency(amount) {
    var cents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    var amountInDollars = cents ? amount / 100.0 : amount;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(amountInDollars);
  },
  makeInitials: function makeInitials(name) {
    var initials = "".concat(name).split(' ').map(function (s) {
      return s[0];
    });
    return initials.length > 1 ? [initials[0], initials.pop()].join('').toUpperCase() : "".concat(initials[0]).toUpperCase();
  }
};