import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as form } from 'redux-form';
import ads from './modules/ads';
import adminDashboard from './modules/adminDashboard';
import adsDashboard from './modules/adsDashboard';
import adsPlan from './modules/adsPlan';
import adsPlanner from './modules/adsPlanner';
import agreement from './modules/agreement';
import app from './modules/app';
import adsAudiences from './modules/adsAudiences';
import adBuilder from './modules/adBuilder';
import audience from './modules/audience';
import audienceDemographics from './modules/audienceDemographics';
import audienceInterests from './modules/audienceInterests';
import audienceLocations from './modules/audienceLocations';
import auth from './modules/auth';
import billing from './modules/billing';
import composebox from './modules/composebox';
import composeboxForm from './modules/composeboxForm';
import customAudiences from './modules/customAudiences';
import contract from './modules/contract';
import contentSearch from './modules/contentSearch';
import contentLibrary from './modules/contentLibrary';
import contentLibraryContent from './modules/contentLibraryContent';
import contentLibraryQueues from './modules/contentLibraryQueues';
import draftPosts from './modules/draftPosts';
import feedback from './modules/feedback';
import groupPosts from './modules/groupPosts';
import groupPostCompose from './modules/groupPostCompose';
import modalLayout from './modules/modalLayout';
import partners from './modules/partners';
import navbarTabs from './modules/navbarTabs';
import newAffiliate from './modules/newAffiliate';
import newClient from './modules/newClient';
import newContract from './modules/newContract';
import parsedLinks from './modules/parsedLinks';
import products from './modules/products';
import queues from './modules/queues';
import reportUpcomingContent from './modules/reportUpcomingContent';
import reportSocialMediaSummary from './modules/reportSocialMediaSummary';
import reportFacebookChecklist from './modules/reportFacebookChecklist';
import reportBuilder from './modules/reportBuilder';
import signContract from './modules/signContract';
import organization from './modules/organization';
import reporting from './modules/reporting';
import schedule from './modules/schedule';
import socialConnection from './modules/socialConnection';
import imagePicker from './modules/imagePicker';
import userSettings from './modules/userSettings';
import config from './modules/config';
import teamManagement from './modules/teamManagement';
import theme from './modules/theme';
import whiteLabel from './modules/whiteLabel';
import messageCenter from './modules/messageCenter2';
import forms from './modules/forms';
import googleMyBusiness from './modules/googleMyBusiness';
import partnerOnboarding from './modules/partnerOnboarding';
export default function createReducer(asyncReducers) {
  return combineReducers(_objectSpread(_objectSpread({}, asyncReducers), {}, {
    adBuilder: adBuilder,
    adminDashboard: adminDashboard,
    ads: ads,
    adsAudiences: adsAudiences,
    adsDashboard: adsDashboard,
    adsPlan: adsPlan,
    adsPlanner: adsPlanner,
    agreement: agreement,
    app: app,
    audience: audience,
    audienceDemographics: audienceDemographics,
    audienceInterests: audienceInterests,
    auth: auth,
    billing: billing,
    composebox: composebox,
    composeboxForm: composeboxForm,
    config: config,
    contentLibrary: contentLibrary,
    contentSearch: contentSearch,
    contentLibraryContent: contentLibraryContent,
    contentLibraryQueues: contentLibraryQueues,
    contract: contract,
    customAudiences: customAudiences,
    draftPosts: draftPosts,
    feedback: feedback,
    form: form,
    groupPostCompose: groupPostCompose,
    groupPosts: groupPosts,
    imagePicker: imagePicker,
    modalLayout: modalLayout,
    navbarTabs: navbarTabs,
    newAffiliate: newAffiliate,
    newClient: newClient,
    newContract: newContract,
    online: function online() {
      var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      return v;
    },
    organization: organization,
    parsedLinks: parsedLinks,
    partners: partners,
    products: products,
    queues: queues,
    reportFacebookChecklist: reportFacebookChecklist,
    reportSocialMediaSummary: reportSocialMediaSummary,
    reportUpcomingContent: reportUpcomingContent,
    reportBuilder: reportBuilder,
    reporting: reporting,
    routing: routerReducer,
    // reduxAsyncConnect,
    schedule: schedule,
    signContract: signContract,
    socialConnection: socialConnection,
    teamManagement: teamManagement,
    theme: theme,
    userSettings: userSettings,
    whiteLabel: whiteLabel,
    messageCenter: messageCenter,
    forms: forms,
    googleMyBusiness: googleMyBusiness,
    partnerOnboarding: partnerOnboarding
  }));
}
export function injectAsyncReducer(store, name, asyncReducer) {
  store.asyncReducers[name] = asyncReducer;
  store.replaceReducer(createReducer(store.asyncReducers));
}