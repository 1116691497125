import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export default function clientMiddleware(client) {
  return function (_ref) {
    var dispatch = _ref.dispatch,
        getState = _ref.getState;
    return function (next) {
      return function (action) {
        var _getState = getState(),
            auth = _getState.auth;

        client.setJwtToken(auth.token || null);

        if (typeof action === 'function') {
          return action(dispatch, getState, client);
        }

        var promise = action.promise,
            types = action.types,
            rest = _objectWithoutProperties(action, ["promise", "types"]); // eslint-disable-line no-redeclare


        if (!promise) {
          return next(action);
        }

        var _types = _slicedToArray(types, 3),
            REQUEST = _types[0],
            SUCCESS = _types[1],
            FAILURE = _types[2];

        if (REQUEST) {
          next(_objectSpread(_objectSpread({}, rest), {}, {
            type: REQUEST
          }));
        }

        var actionPromise = promise(client, dispatch);
        actionPromise.then(function (result) {
          if (SUCCESS) {
            var _objectSpread2;

            var resultKeyName = action.asyncResultKeyName || 'result';
            return next(_objectSpread(_objectSpread({}, rest), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, resultKeyName, result), _defineProperty(_objectSpread2, "type", SUCCESS), _objectSpread2)));
          }
        }, function (error) {
          if (FAILURE) {
            next(_objectSpread(_objectSpread({}, rest), {}, {
              error: error,
              type: FAILURE
            }));
          }

          if (error.error && error.redirect) {
            return window.location.replace(error.redirect);
          }
        })["catch"](function (error) {
          console.error('MIDDLEWARE ERROR:', error);

          if (FAILURE) {
            next(_objectSpread(_objectSpread({}, rest), {}, {
              error: error,
              type: FAILURE
            }));
          }
        });
        return actionPromise;
      };
    };
  };
}