import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import _ from 'lodash';
import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
var ns = 'likeablelocal/messageCenter2';
var INIT_ORG = "".concat(ns, "/INIT_ORG");
var LOAD_CONVERSATIONS = "".concat(ns, "/LOAD_CONVERSATIONS");
var LOAD_CONVERSATIONS_SUCCESS = "".concat(ns, "/LOAD_CONVERSATIONS_SUCCESS");
var LOAD_CONVERSATIONS_FAIL = "".concat(ns, "/LOAD_CONVERSATIONS_FAIL");
var TOGGLE_ALL_SELECTED = "".concat(ns, "/TOGGLE_ALL_SELECTED");
var CHANGE_SELECTED_CONVERSATION = "".concat(ns, "/CHANGE_SELECTED_CONVERSATION");
var CHANGE_VIEW_TYPE = "".concat(ns, "/CHANGE_VIEW_TYPE");
var SET_CONVERSATION_CHECKED = "".concat(ns, "/SET_CONVERSATION_CHECKED");
var CREATE_COMMENT = "".concat(ns, "/CREATE_COMMENT");
var CREATE_COMMENT_SUCCESS = "".concat(ns, "/CREATE_COMMENT_SUCCESS");
var CREATE_COMMENT_FAIL = "".concat(ns, "/CREATE_COMMENT_FAIL");
var REACT_TO_COMMENT = "".concat(ns, "/REACT_TO_COMMENT");
var REACT_TO_COMMENT_SUCCESS = "".concat(ns, "/REACT_TO_COMMENT_SUCCESS");
var REACT_TO_COMMENT_FAIL = "".concat(ns, "/REACT_TO_COMMENT_FAIL");
var ARCHIVE_CONVERSATION = "".concat(ns, "/ARCHIVE_CONVERSATION");
var ARCHIVE_CONVERSATION_SUCCESS = "".concat(ns, "/ARCHIVE_CONVERSATION_SUCCESS");
var ARCHIVE_CONVERSATION_FAIL = "".concat(ns, "/ARCHIVE_CONVERSATION_FAIL");
var UNARCHIVE_CONVERSATION = "".concat(ns, "/UNARCHIVE_CONVERSATION");
var UNARCHIVE_CONVERSATION_SUCCESS = "".concat(ns, "/UNARCHIVE_CONVERSATION_SUCCESS");
var UNARCHIVE_CONVERSATION_FAIL = "".concat(ns, "/UNARCHIVE_CONVERSATION_FAIL");
var initialState = {
  byOrgId: {}
};
var orgInitialState = {
  conversations: [],
  checkedConversations: {},
  selectedConversationId: false,
  selectedViewType: 0,
  archivedConversations: [],
  creatingComment: false,
  loadingConversations: false,
  conversationPage: 0
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case INIT_ORG:
      return _objectSpread(_objectSpread({}, state), {}, {
        byOrgId: _objectSpread(_objectSpread({}, state.byOrgId), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread({}, orgInitialState)))
      });

    case LOAD_CONVERSATIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        byOrgId: _objectSpread(_objectSpread({}, state.byOrgId), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state.byOrgId["".concat(action.orgId)] ? state.byOrgId["".concat(action.orgId)] : orgInitialState), {}, {
          loadingConversations: true
        })))
      });

    case LOAD_CONVERSATIONS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        byOrgId: _objectSpread(_objectSpread({}, state.byOrgId), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state.byOrgId["".concat(action.orgId)] ? state.byOrgId["".concat(action.orgId)] : orgInitialState), {}, {
          loadingConversations: false
        })))
      });

    case LOAD_CONVERSATIONS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        byOrgId: _objectSpread(_objectSpread({}, state.byOrgId), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread(_objectSpread({}, state.byOrgId["".concat(action.orgId)] ? state.byOrgId["".concat(action.orgId)] : orgInitialState), {}, {
          conversations: _.uniqBy([].concat(_toConsumableArray(state.byOrgId["".concat(action.orgId)].conversations ? state.byOrgId["".concat(action.orgId)].conversations : []), _toConsumableArray(action.result.data)), 'id').sort(function (a, b) {
            return b.Subject.platformCreatedAt - a.Subject.platformCreatedAt;
          })
        }, state.byOrgId["".concat(action.orgId)].selectedConversationId === false ? {
          selectedConversationId: action.result.data.length > 0 ? _toConsumableArray(action.result.data)[0].id : false
        } : {}), {}, {
          loadingConversations: false,
          conversationPage: state.byOrgId["".concat(action.orgId)].conversationPage + 1
        })))
      });

    case CHANGE_SELECTED_CONVERSATION:
      return _objectSpread(_objectSpread({}, state), {}, {
        byOrgId: _objectSpread(_objectSpread({}, state.byOrgId), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state.byOrgId["".concat(action.orgId)] ? state.byOrgId["".concat(action.orgId)] : orgInitialState), {}, {
          selectedConversationId: action.conversationId
        })))
      });

    case SET_CONVERSATION_CHECKED:
      return _objectSpread(_objectSpread({}, state), {}, {
        byOrgId: _objectSpread(_objectSpread({}, state.byOrgId), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state.byOrgId["".concat(action.orgId)] ? state.byOrgId["".concat(action.orgId)] : orgInitialState), {}, {
          checkedConversations: _objectSpread(_objectSpread({}, state.byOrgId["".concat(action.orgId)].checkedConversations), {}, _defineProperty({}, "".concat(action.conversationId), action.isChecked))
        })))
      });

    case CREATE_COMMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        byOrgId: _objectSpread(_objectSpread({}, state.byOrgId), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state.byOrgId["".concat(action.orgId)] ? state.byOrgId["".concat(action.orgId)] : orgInitialState), {}, {
          creatingComment: true
        })))
      });

    case CREATE_COMMENT_FAIL:
    case CREATE_COMMENT_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        byOrgId: _objectSpread(_objectSpread({}, state.byOrgId), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state.byOrgId["".concat(action.orgId)] ? state.byOrgId["".concat(action.orgId)] : orgInitialState), {}, {
          creatingComment: false
        })))
      });

    case CHANGE_VIEW_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        byOrgId: _objectSpread(_objectSpread({}, state.byOrgId), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state.byOrgId["".concat(action.orgId)] ? state.byOrgId["".concat(action.orgId)] : orgInitialState), {}, {
          selectedViewType: action.viewType,
          checkedConversations: {}
        })))
      });

    case ARCHIVE_CONVERSATION:
    case UNARCHIVE_CONVERSATION:
    case UNARCHIVE_CONVERSATION_SUCCESS:
    case ARCHIVE_CONVERSATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        byOrgId: _objectSpread(_objectSpread({}, state.byOrgId), {}, _defineProperty({}, "".concat(action.orgId), _objectSpread(_objectSpread({}, state.byOrgId["".concat(action.orgId)] ? state.byOrgId["".concat(action.orgId)] : orgInitialState), {}, {
          checkedConversations: {},
          conversations: _toConsumableArray(state.byOrgId["".concat(action.orgId)].conversations.map(function (c) {
            return _objectSpread(_objectSpread({}, c), action.conversationIds.includes("".concat(c.id)) ? {
              archived: action.archived
            } : {});
          }))
        })))
      });

    default:
      return state;
  }
}
export function initOrg(orgId) {
  return {
    type: INIT_ORG,
    orgId: orgId
  };
}
export function loadConversations(orgId) {
  var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
  var offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  return {
    types: [LOAD_CONVERSATIONS, LOAD_CONVERSATIONS_SUCCESS, LOAD_CONVERSATIONS_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/conversations?limit=").concat(limit, "&offset=").concat(offset));
    },
    orgId: orgId
  };
}
export function archiveConversations(orgId, conversationIds) {
  return {
    types: [ARCHIVE_CONVERSATION, ARCHIVE_CONVERSATION_SUCCESS, ARCHIVE_CONVERSATION_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/conversations/archive"), {
        data: {
          conversationIds: conversationIds
        }
      });
    },
    orgId: orgId,
    conversationIds: conversationIds,
    archived: true
  };
}
export function unarchiveConversations(orgId, conversationIds) {
  return {
    types: [UNARCHIVE_CONVERSATION, UNARCHIVE_CONVERSATION_SUCCESS, UNARCHIVE_CONVERSATION_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/conversations/unarchive"), {
        data: {
          conversationIds: conversationIds
        }
      });
    },
    orgId: orgId,
    conversationIds: conversationIds,
    archived: false
  };
}
export function createComment(orgId) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var files = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  console.log('create comment');
  return {
    types: [CREATE_COMMENT, CREATE_COMMENT_SUCCESS, CREATE_COMMENT_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/messageCenter/comment"), _objectSpread(_objectSpread({}, data ? {
        fields: function () {
          return Object.keys(data).map(function (key) {
            return {
              key: key,
              value: data[key]
            };
          });
        }()
      } : {}), {}, {
        files: files
      }));
    },
    orgId: orgId
  };
}
export function reactToComment(orgId, targetId, remove) {
  var type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'LIKE';
  return {
    types: [REACT_TO_COMMENT, REACT_TO_COMMENT_SUCCESS, REACT_TO_COMMENT_FAIL],
    promise: function promise(client) {
      return client.put("/org/".concat(orgId, "/messageCenter/comment/reaction"), {
        data: {
          targetId: targetId,
          remove: !!remove,
          type: type
        }
      });
    },
    orgId: orgId
  };
}
export function toggleAllSelected(conversationIds) {
  return {
    type: TOGGLE_ALL_SELECTED,
    conversationIds: conversationIds
  };
}
export function setSelectedConversation(orgId, conversationId) {
  return {
    type: CHANGE_SELECTED_CONVERSATION,
    orgId: orgId,
    conversationId: conversationId
  };
}
export function setConversationChecked(orgId, conversationId, isChecked) {
  return {
    type: SET_CONVERSATION_CHECKED,
    orgId: orgId,
    conversationId: conversationId,
    isChecked: isChecked
  };
}
export function setSelectedViewType(orgId, viewType) {
  return {
    type: CHANGE_VIEW_TYPE,
    viewType: viewType,
    orgId: orgId
  };
}