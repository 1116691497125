import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var ns = 'likeablelocal/app';
import { LOGOUT_SUCCESS, RESET_TO_INITIAL_STATE } from './auth';
var CLEAR_ADS_PLAN = "".concat(ns, "/CLEAR_ADS_PLAN");
var GET_ADS_PLAN = "".concat(ns, "/GET_ADS_PLAN");
var GET_ADS_PLAN_SUCCESS = "".concat(ns, "/GET_ADS_PLAN_SUCCESS");
var GET_ADS_PLAN_FAIL = "".concat(ns, "/GET_ADS_PLAN_FAIL");
var REMOVE_ADS_PLAN = "".concat(ns, "/REMOVE_ADS_PLAN");
var REMOVE_ADS_PLAN_SUCCESS = "".concat(ns, "/REMOVE_ADS_PLAN_SUCCESS");
var REMOVE_ADS_PLAN_FAIL = "".concat(ns, "/REMOVE_ADS_PLAN_FAIL");
var SET_ADS_PLAN = "".concat(ns, "/SET_ADS_PLAN");
var SET_ADS_PLAN_SUCCESS = "".concat(ns, "/SET_ADS_PLAN_SUCCESS");
var SET_ADS_PLAN_FAIL = "".concat(ns, "/SET_ADS_PLAN_FAIL");
var initialState = {
  adsPlan: null,
  gettingAdsPlan: false,
  settingAdsPlan: false,
  removingAdsPlan: false
};
export default function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case RESET_TO_INITIAL_STATE:
    case LOGOUT_SUCCESS:
      return initialState;

    case GET_ADS_PLAN:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAdsPlan: true
      });

    case GET_ADS_PLAN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAdsPlan: false,
        adsPlan: action.result.data
      });

    case GET_ADS_PLAN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        gettingAdsPlan: false
      });

    case SET_ADS_PLAN:
      return _objectSpread(_objectSpread({}, state), {}, {
        settingAdsPlan: true
      });

    case SET_ADS_PLAN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        settingAdsPlan: false,
        adsPlan: action.result.data
      });

    case SET_ADS_PLAN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        settingAdsPlan: false
      });

    case REMOVE_ADS_PLAN:
      return _objectSpread(_objectSpread({}, state), {}, {
        removingAdsPlan: true
      });

    case REMOVE_ADS_PLAN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        adsPlan: null,
        removingAdsPlan: false
      });

    case REMOVE_ADS_PLAN_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        removingAdsPlan: false
      });

    case CLEAR_ADS_PLAN:
      return _objectSpread(_objectSpread({}, state), {}, {
        adsPlan: null
      });

    default:
      return state;
  }
}
export function setAdsPlan(orgId, data) {
  return {
    types: [SET_ADS_PLAN, SET_ADS_PLAN_SUCCESS, SET_ADS_PLAN_FAIL],
    promise: function promise(client) {
      return client.post("/org/".concat(orgId, "/adsPlan"), {
        data: data
      });
    }
  };
}
export function removeAdsPlan(orgId) {
  return {
    types: [REMOVE_ADS_PLAN, REMOVE_ADS_PLAN_SUCCESS, REMOVE_ADS_PLAN_FAIL],
    promise: function promise(client) {
      return client.del("/org/".concat(orgId, "/adsPlan"));
    }
  };
}
export function getAdsPlan(orgId) {
  return {
    types: [GET_ADS_PLAN, GET_ADS_PLAN_SUCCESS, GET_ADS_PLAN_FAIL],
    promise: function promise(client) {
      return client.get("/org/".concat(orgId, "/adsPlan"));
    }
  };
}
export function clearAdsPlan() {
  return {
    type: CLEAR_ADS_PLAN
  };
}